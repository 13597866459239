import {OrderStatus} from "./orderStatus";
import {Order} from "../order";
import {ProductionSpeed} from "./ProductionSpeeds";
import {ProductTypes} from "./ProductTypes";


export enum OrderDefaults {
    OrderText = "Hey {{FIRST NAME}},\nI see that you purchased your home at 123 N Jefferson St. If you are considering selling, I would love to have a chat.\n\nCheers!\nJohn Smith\n(855)333-3915",
    OrderText2 = "P.S. Can't wait to share more opportunities like that with you!"
}

export enum OrderFontDefaults {
    MinHandwrittenFontSizeReturnAddress = 22,
    DefaultHandwrittenFontSizeReturnAddress = 24,
    MaxHandwrittenFontSizeReturnAddress = 26,
    MinPostcardFontSizeReturnAddress = 8,
    DefaultPostcardFontSizeReturnAddress = 11,
    MaxPostcardFontSizeReturnAddress = 13,
    MinHandWrittenTextFontSize = 17,
    DefaultHandWrittenTextFontSize = 23,
    MaxHandWrittenTextFontSize = 25
}

export function setOrderDefaults(order: Order, newProduct: string) {
    order.orderStatus = OrderStatus.Draft
    order.productionSpeed = ProductionSpeed.Normal
    order.product = newProduct
    order.hasBeenProgrammaticallyUpgraded = false
    order.font = "Derek"
    order.fontSize = OrderFontDefaults.MinHandWrittenTextFontSize
    order.fontSizeReturnAddress = newProduct === ProductTypes.HandwrittenPostCardA8 ? OrderFontDefaults.DefaultPostcardFontSizeReturnAddress : OrderFontDefaults.DefaultHandwrittenFontSizeReturnAddress
    order.isNew = true
    order.text = OrderDefaults.OrderText
    order.text2 = OrderDefaults.OrderText2
}
