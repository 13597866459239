import { IOrderArtifacts } from "./orderArtifact";
import { IOrderTransactions } from "./orderTransaction";
import {IOrderRecipients} from "./orderRecipient";
import {ProductionSpeed} from "./enums/ProductionSpeeds";
import {OrderStatus} from "./enums/orderStatus";

export interface IOrder {
  address1: string;
  address2: string;
  advancedFeaturesEnabled: string;
  campaignName: string;
  city: string;
  customerId: string;
  customerEmail: string;
  dataUrl: string;
  description: string;
  differentPayer: string;
  doubleSided: string;
  executionDate: string;
  expectedMailDate: Date;
  firstName: string;
  font: string;
  fontSize: number;
  fontSizeReturnAddress: number;
  holdUntilDate: string;
  id: number;
  isIntegrationOrderTemplate: boolean;
  isNew: boolean;
  isTemplate: boolean;
  lastName: string;
  mailedDate: Date;
  mailToMe: string;
  name: string;
  noFrontLogo: string;
  orderArtifacts: IOrderArtifacts;
  orderRecipients: IOrderRecipients;
  orderStatus: OrderStatus;
  orderTransactions: IOrderTransactions;
  orderVerified: boolean;
  orderVolume: number;
  paidDate: Date;
  payeeEmail: string;
  payeeFirstName: string;
  payeeLastName: string;
  postageType: string;
  product: string;
  profileId: number;
  qrUrl: string;
  qrCodeX: number;
  qrCodeY: number;
  qrCodeWidth: number;
  qrCodeHeight: number;
  qrCodeSide: string;
  qrAngle: number;
  recipientsCsvBoxUrl: string;
  recipientsUploaded: boolean;
  returnAddress1: string;
  returnAddress2: string;
  returnCity: string;
  returnFirstName: string;
  returnLastName: string;
  returnOrganization: string;
  returnState: string;
  returnTitle: string;
  returnZip: string;
  sceneUrl: string;
  state: string;
  submittedDate: Date;
  text: string;
  text2: string;
  trackingPhoneNumber: string;
  updatedDate: Date;
  useQr: boolean;
  zip: string;
  templateId: string;
  productionSpeed: ProductionSpeed;
  hasBeenProgrammaticallyFixed: boolean
  hasBeenProgrammaticallyUpgraded: boolean
  hasDiscount: boolean 
  multiUseCouponId: number | null;
  couponListId: number | null;
  [key: string]: any; // To handle dynamic keys
}

export interface IOrders extends Array<IOrder> {}

export class Order implements IOrder {
  constructor(id: number) {
    this.id = id;
    this.recipientsUploaded = false;
    this.advancedFeaturesEnabled = "No";
  }
  address1: string;
  address2: string;
  advancedFeaturesEnabled: string;
  campaignName: string;
  city: string;
  customerId: string;
  customerEmail: string;
  dataUrl: string;
  description: string;
  differentPayer: string;
  doubleSided: string;
  executionDate: string;
  expectedMailDate: Date;
  firstName: string;
  font: string;
  fontSize: number;
  fontSizeReturnAddress: number;
  holdUntilDate: string;
  id: number;
  isIntegrationOrderTemplate: boolean;
  isNew: boolean;
  isTemplate: boolean;
  lastName: string;
  mailedDate: Date;
  mailToMe: string;
  name: string;
  noFrontLogo: string;
  orderArtifacts: IOrderArtifacts;
  orderRecipients: IOrderRecipients;
  orderStatus: OrderStatus;
  orderTransactions: IOrderTransactions;
  orderVerified: boolean;
  orderVolume: number;
  paidDate: Date;
  payeeEmail: string;
  payeeFirstName: string;
  payeeLastName: string;
  postageType: string;
  product: string;
  profileId: number;
  qrUrl: string;
  qrCodeX: number;
  qrCodeY: number;
  qrCodeWidth: number;
  qrCodeHeight: number;
  qrCodeSide: string;
  qrAngle: number;
  recipientsCsvBoxUrl: string;
  recipientsUploaded: boolean;
  returnAddress1: string;
  returnAddress2: string;
  returnCity: string;
  returnFirstName: string;
  returnLastName: string;
  returnOrganization: string;
  returnState: string;
  returnTitle: string;
  returnZip: string;
  sceneUrl: string;
  state: string;
  submittedDate: Date;
  text: string;
  text2: string;
  trackingPhoneNumber: string;
  updatedDate: Date;
  useQr: boolean;
  zip: string;
  templateId: string;
  productionSpeed: ProductionSpeed;
  hasBeenProgrammaticallyFixed: boolean
  hasBeenProgrammaticallyUpgraded: boolean
  hasDiscount: boolean 
  multiUseCouponId: number | null;
  couponListId: number | null;
}