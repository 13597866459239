// @ts-ignore
import Retool from "react-retool";
import MDBox from "material-ui/components/MDBox";

function AdminProfilesPage(){
    return <MDBox style={{ height: "90vh" }}>
        <Retool url="https://lettrlabs.retool.com/embedded/public/1663e221-1c05-4f51-b6d6-10d027a12789" />
    </MDBox>
}

export default AdminProfilesPage