// @ts-ignore
import Retool from "react-retool"
import MDBox from "material-ui/components/MDBox";

function AdminDashboardPage(){
    return <MDBox style={{ height: "90vh" }}>
        <Retool url="https://lettrlabs.retool.com/embedded/public/1c137a79-9bca-41e3-acab-944003eea2b7" />
    </MDBox>
}

export default AdminDashboardPage