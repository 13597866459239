import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const BrandArtworkIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.1077 7.39229C20.7798 7.06376 20.3699 6.9 19.885 6.9H15.6564L12.5707 3.81429L12.5 3.74358L12.4293 3.81429L9.34358 6.9H5.115C4.63012 6.9 4.22019 7.06374 3.89233 7.39225C3.56377 7.72012 3.4 8.13008 3.4 8.615V19.385C3.4 19.8699 3.56378 20.2802 3.89229 20.6087C4.22018 20.9366 4.63013 21.1 5.115 21.1H19.885C20.3699 21.1 20.7802 20.9362 21.1087 20.6077C21.4366 20.2798 21.6 19.8699 21.6 19.385V8.615C21.6 8.1301 21.4362 7.72015 21.1077 7.39229ZM21.1077 7.39229C21.1077 7.39226 21.1077 7.39224 21.1076 7.39222L21.037 7.463L21.1078 7.39237C21.1078 7.39234 21.1077 7.39232 21.1077 7.39229ZM7.92044 17.4394L7.79818 17.6H8H17.154H17.354L17.234 17.44L14.407 13.67L14.3291 13.5661L14.2486 13.668L11.7102 16.879L10.039 14.8593L9.95874 14.7622L9.88244 14.8624L7.92044 17.4394ZM16.5592 12.7486L16.5593 12.7487C16.7934 12.9828 17.0835 13.1 17.423 13.1C17.7625 13.1 18.0526 12.9828 18.2867 12.7487C18.5208 12.5146 18.638 12.2245 18.638 11.885C18.638 11.5449 18.5209 11.2545 18.2867 11.0203C18.0526 10.7862 17.7625 10.669 17.423 10.669C17.0835 10.669 16.7934 10.7862 16.5593 11.0203C16.3251 11.2545 16.208 11.5449 16.208 11.885C16.208 12.2239 16.3252 12.5139 16.5592 12.7486ZM19.885 19.9H5.115C4.99296 19.9 4.87628 19.8502 4.76272 19.7373C4.64981 19.6237 4.6 19.507 4.6 19.385V8.615C4.6 8.49297 4.6498 8.37629 4.76269 8.26273C4.87626 8.14981 4.99295 8.1 5.115 8.1H19.885C20.007 8.1 20.1237 8.14978 20.2372 8.26263C20.3502 8.37622 20.4 8.49293 20.4 8.615V19.385C20.4 19.5071 20.3502 19.6238 20.2372 19.7374C20.1237 19.8502 20.007 19.9 19.885 19.9ZM14.1206 6.9H10.8794L12.5 5.27942L14.1206 6.9Z" fill="currentColor" stroke="currentColor" strokeWidth="0.2"/>
            </svg>
        </SvgIcon>
    )
}

export default BrandArtworkIcon;