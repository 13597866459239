export interface IVM_CopyOrder {
    cardDesign: boolean,
    handwrittenText: boolean,
    orderId: number,
    recipients: boolean
    returnAddress: boolean,
}

export class VM_CopyOrder implements IVM_CopyOrder {
    constructor(orderId:number) { 
        this.cardDesign = true
        this.handwrittenText = true
        this.orderId = orderId
        this.recipients = true
        this.returnAddress = true
    }  
    cardDesign: boolean;
    handwrittenText: boolean;
    orderId: number;
    recipients: boolean;
    returnAddress: boolean;
}