import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SheetAddIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.375 19.5H20.625M3.375 19.5C3.07663 19.5 2.79048 19.3815 2.5795 19.1705C2.36853 18.9595 2.25 18.6734 2.25 18.375M3.375 19.5H10.875C11.496 19.5 12 18.996 12 18.375M20.625 19.5C21.246 19.5 21.75 18.996 21.75 18.375M20.625 19.5H13.125C12.8266 19.5 12.5405 19.3815 12.3295 19.1705C12.1185 18.9595 12 18.6734 12 18.375M2.25 18.375V5.625M2.25 18.375V16.875C2.25 16.254 2.754 15.75 3.375 15.75M12 18.375V16.875M2.25 5.625C2.25 5.004 2.754 4.5 3.375 4.5H20.625C21.246 4.5 21.75 5.004 21.75 5.625M2.25 5.625V7.125C2.25 7.746 2.754 8.25 3.375 8.25M3.375 15.75C2.754 15.75 2.25 15.246 2.25 14.625V13.125C2.25 12.504 2.754 12 3.375 12M3.375 15.75H10.875M21.75 18.375V5.625M21.75 18.375V16.875C21.75 16.254 21.246 15.75 20.625 15.75M21.75 5.625V7.125C21.75 7.746 21.246 8.25 20.625 8.25M20.625 15.75C21.246 15.75 21.75 15.246 21.75 14.625V13.125C21.75 12.504 21.246 12 20.625 12M20.625 15.75H13.125M20.625 8.25H3.375M20.625 8.25H13.125C12.504 8.25 12 8.754 12 9.375M20.625 8.25C21.246 8.25 21.75 8.754 21.75 9.375V10.875C21.75 11.496 21.246 12 20.625 12M3.375 8.25H10.875C11.496 8.25 12 8.754 12 9.375M3.375 8.25C2.754 8.25 2.25 8.754 2.25 9.375V10.875C2.25 11.496 2.754 12 3.375 12M12 9.375V10.875M3.375 12H10.875M20.625 12H13.125M10.875 12C11.496 12 12 11.496 12 10.875M10.875 12C11.496 12 12 12.504 12 13.125M12 10.875C12 11.496 12.504 12 13.125 12M13.125 12C12.504 12 12 12.504 12 13.125M12 13.125V14.625M10.875 15.75C11.496 15.75 12 15.246 12 14.625M10.875 15.75C11.496 15.75 12 16.254 12 16.875M12 14.625C12 15.246 12.504 15.75 13.125 15.75M13.125 15.75C12.504 15.75 12 16.254 12 16.875"
                    stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                    d="M5 3.66667V6.33333V3.66667ZM6.33333 5H3.66667H6.33333ZM9 5C9 5.52529 8.89654 6.04543 8.69552 6.53073C8.4945 7.01604 8.19986 7.45699 7.82843 7.82843C7.45699 8.19986 7.01604 8.4945 6.53073 8.69552C6.04543 8.89654 5.52529 9 5 9C4.47471 9 3.95457 8.89654 3.46927 8.69552C2.98396 8.4945 2.54301 8.19986 2.17157 7.82843C1.80014 7.45699 1.5055 7.01604 1.30448 6.53073C1.10346 6.04543 1 5.52529 1 5C1 3.93913 1.42143 2.92172 2.17157 2.17157C2.92172 1.42143 3.93913 1 5 1C6.06087 1 7.07828 1.42143 7.82843 2.17157C8.57857 2.92172 9 3.93913 9 5Z"
                    fill="black"/>
                <path
                    d="M5 3.66667V6.33333M6.33333 5H3.66667M9 5C9 5.52529 8.89654 6.04543 8.69552 6.53073C8.4945 7.01604 8.19986 7.45699 7.82843 7.82843C7.45699 8.19986 7.01604 8.4945 6.53073 8.69552C6.04543 8.89654 5.52529 9 5 9C4.47471 9 3.95457 8.89654 3.46927 8.69552C2.98396 8.4945 2.54301 8.19986 2.17157 7.82843C1.80014 7.45699 1.5055 7.01604 1.30448 6.53073C1.10346 6.04543 1 5.52529 1 5C1 3.93913 1.42143 2.92172 2.17157 2.17157C2.92172 1.42143 3.93913 1 5 1C6.06087 1 7.07828 1.42143 7.82843 2.17157C8.57857 2.92172 9 3.93913 9 5Z"
                    stroke="#F3F5FB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export default SheetAddIcon