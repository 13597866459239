import * as Yup from "yup"

export const ReturnAddressValidation = Yup.object({
    name: Yup.string().required("Required"),
    firstName: Yup.string(),
    lastName: Yup.string(),
    toOrganization: Yup.string(),
    address1: Yup.string().required("Required"),
    address2: Yup.string(),
    city: Yup.string().required("Required"),
    zip: Yup.string().length(5, 'Zip must be 5 digits long').required("Required"),
    state: Yup.string().required("Required").nullable(),
    isDefault: Yup.boolean()
})

export const AddressToReturn = Yup.object({
    returnAddress1: Yup.string().required("Required"),
    address2: Yup.string(),
    returnCity: Yup.string().required("Required"),
    returnFirstName: Yup.string(),
    returnLastName: Yup.string(),
    returnOrganization: Yup.string(),
    returnState: Yup.string().nullable().required("Required"),
    returnZip: Yup.string().length(5, 'Zip must be 5 digits long').required("Required"),
})
