import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SidebarAddressBookIcon = (props: any) => {
    return (
        <SvgIcon {...props} viewBox={"0 0 32 32"}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 8.50391C6 7.04522 6.57946 5.64627 7.61091 4.61482C8.64236 3.58337 10.0413 3.00391 11.5 3.00391C12.9587 3.00391 14.3576 3.58337 15.3891 4.61482C16.4205 5.64627 17 7.04522 17 8.50391C17 9.9626 16.4205 11.3615 15.3891 12.393C14.3576 13.4244 12.9587 14.0039 11.5 14.0039C10.0413 14.0039 8.64236 13.4244 7.61091 12.393C6.57946 11.3615 6 9.9626 6 8.50391ZM19 11.5039C19 10.913 19.1164 10.3278 19.3425 9.78183C19.5687 9.23587 19.9002 8.73979 20.318 8.32193C20.7359 7.90406 21.232 7.57259 21.7779 7.34645C22.3239 7.1203 22.9091 7.00391 23.5 7.00391C24.091 7.00391 24.6761 7.1203 25.2221 7.34645C25.768 7.57259 26.2641 7.90406 26.682 8.32193C27.0998 8.73979 27.4313 9.23587 27.6575 9.78183C27.8836 10.3278 28 10.913 28 11.5039C28 12.6974 27.5259 13.842 26.682 14.6859C25.8381 15.5298 24.6935 16.0039 23.5 16.0039C22.3065 16.0039 21.1619 15.5298 20.318 14.6859C19.4741 13.842 19 12.6974 19 11.5039ZM2 25.5039C2 22.9844 3.00089 20.568 4.78249 18.7864C6.56408 17.0048 8.98044 16.0039 11.5 16.0039C14.0196 16.0039 16.4359 17.0048 18.2175 18.7864C19.9991 20.568 21 22.9844 21 25.5039V25.5079L20.9987 25.6666C20.9958 25.8361 20.9499 26.0022 20.8653 26.1491C20.7806 26.2961 20.66 26.4191 20.5147 26.5066C17.7936 28.1452 14.6764 29.0088 11.5 29.0039C8.204 29.0039 5.11867 28.0919 2.48667 26.5066C2.34113 26.4193 2.22022 26.2963 2.13532 26.1494C2.05041 26.0024 2.0043 25.8363 2.00133 25.6666L2 25.5039ZM23 25.5079L22.9987 25.6999C22.9912 26.1443 22.8851 26.5815 22.688 26.9799C25.0156 27.1235 27.3405 26.6594 29.4347 25.6332C29.5966 25.5541 29.7342 25.4326 29.8327 25.2817C29.9313 25.1308 29.9873 24.956 29.9947 24.7759C30.0417 23.6576 29.7992 22.546 29.2906 21.5489C28.7819 20.5518 28.0244 19.703 27.0914 19.0846C26.1584 18.4662 25.0815 18.0992 23.965 18.0192C22.8485 17.9392 21.7303 18.1489 20.7187 18.6279C22.2029 20.6127 23.003 23.0255 22.9987 25.5039L23 25.5079Z" fill="white"/>
            </svg>
        </SvgIcon>
    )
}

export default SidebarAddressBookIcon;
