import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT from "../endpoint";
import {RecipientSearch} from "models/recipient-search/recipientSearch";

function RecipientSearchService(){
    const {execute} = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/recipientsearch`

    function getRecipientSearches(){
        return execute({
            endpoint: `${API_PATH}`,
            method: "GET"
        })
    }

    function getRecipientSearch(orderId: number){
        return execute({
            endpoint: `${API_PATH}/${orderId}`,
            method: "GET"
        })
    }

    function postRecipientSearch(data: RecipientSearch){
        return execute({
            endpoint: `${API_PATH}`,
            method: "POST",
            data: data
        })
    }

    function prepareRecipientSearch(data: RecipientSearch){
        return execute({
            endpoint: `${API_PATH}/prepare`,
            method: "POST",
            data: data
        })
    }

    function getRecipientSearchCheckout(orderId: number){
        return execute({
            endpoint: `${API_ENDPOINT}/recipientSearchCheckout/${orderId}`,
            method: "GET"
        })
    }

    function postRecipientSearchCheckout(orderId: number){
        return execute({
            endpoint: `${API_ENDPOINT}/recipientSearchCheckout/${orderId}`,
            method: "POST"
        })
    }

    function deleteRecipientSearch(recipientSearchId: number){
        return execute({
            endpoint: `${API_PATH}/${recipientSearchId}`,
            method: "DELETE"
        })
    }

    function copyRecipientSearch(recipientSearchId: number, recipientSearchName: string){
        return execute({
            endpoint: `${API_PATH}/copyorder/${recipientSearchId}`,
            method: "POST",
            data: {
                orderName: recipientSearchName
            }
        })
    }

    return {getRecipientSearches, getRecipientSearch, prepareRecipientSearch, postRecipientSearch, getRecipientSearchCheckout, postRecipientSearchCheckout, deleteRecipientSearch, copyRecipientSearch}
}

export default RecipientSearchService