import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SidebarIntegrationsIcon = (props: any) => {
    return (
        <SvgIcon {...props} viewBox={"0 0 32 32"}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M16.0009 9.00378C16.0006 7.91045 16.2564 6.83225 16.7478 5.85558C17.2393 4.87892 17.9526 4.03095 18.8308 3.37963C19.709 2.7283 20.7275 2.29174 21.8047 2.10491C22.882 1.91808 23.988 1.98619 25.0342 2.30378C25.1996 2.35387 25.3491 2.44595 25.4683 2.57104C25.5875 2.69614 25.6722 2.84997 25.7142 3.01756C25.7563 3.18515 25.7542 3.36076 25.7081 3.52729C25.6621 3.69382 25.5737 3.84558 25.4515 3.96778L21.0249 8.39311C21.1089 9.02644 21.3929 9.63844 21.8795 10.1251C22.3662 10.6118 22.9782 10.8958 23.6129 10.9784L28.0369 6.55311C28.1591 6.43097 28.3108 6.34257 28.4774 6.29653C28.6439 6.25049 28.8195 6.24838 28.9871 6.29041C29.1547 6.33244 29.3085 6.41718 29.4336 6.53636C29.5587 6.65553 29.6508 6.80509 29.7009 6.97044C30.0331 8.06492 30.0922 9.22414 29.8728 10.3467C29.6534 11.4693 29.1623 12.521 28.4424 13.4098C27.7224 14.2986 26.7957 14.9975 25.7432 15.4453C24.6907 15.8931 23.5445 16.0761 22.4049 15.9784C21.0475 15.8638 19.9115 16.1118 19.3262 16.8238L9.79286 28.4038C9.40252 28.8755 8.91797 29.2604 8.37026 29.534C7.82254 29.8076 7.22372 29.9639 6.61214 29.9927C6.00057 30.0215 5.38971 29.9223 4.81869 29.7014C4.24767 29.4805 3.72907 29.1428 3.2961 28.7099C2.86312 28.2771 2.52531 27.7585 2.3043 27.1876C2.08329 26.6166 1.98395 26.0058 2.01264 25.3942C2.04133 24.7826 2.19742 24.1837 2.47091 23.636C2.7444 23.0882 3.12927 22.6036 3.60086 22.2131L15.1795 12.6784C15.8902 12.0918 16.1395 10.9571 16.0249 9.59978C16.0085 9.40153 16.0005 9.20269 16.0009 9.00378ZM5.4902 25.5038C5.4902 25.2386 5.59555 24.9842 5.78309 24.7967C5.97063 24.6091 6.22498 24.5038 6.4902 24.5038H6.50086C6.76608 24.5038 7.02043 24.6091 7.20797 24.7967C7.39551 24.9842 7.50086 25.2386 7.50086 25.5038V25.5144C7.50086 25.7797 7.39551 26.034 7.20797 26.2216C7.02043 26.4091 6.76608 26.5144 6.50086 26.5144H6.4902C6.22498 26.5144 5.97063 26.4091 5.78309 26.2216C5.59555 26.034 5.4902 25.7797 5.4902 25.5144V25.5038Z" fill="white"/>
                <path d="M13.4343 11.5249L10.4996 8.59154V6.50354C10.4996 6.3309 10.4549 6.1612 10.3699 6.01096C10.2848 5.86072 10.1623 5.73506 10.0143 5.64621L5.0143 2.64621C4.82307 2.53157 4.599 2.48413 4.37772 2.51143C4.15643 2.53873 3.95061 2.6392 3.79297 2.79688L2.79297 3.79688C2.63529 3.95452 2.53482 4.16034 2.50752 4.38162C2.48023 4.60291 2.52766 4.82697 2.6423 5.01821L5.6423 10.0182C5.73116 10.1662 5.85682 10.2887 6.00706 10.3738C6.15729 10.4588 6.32699 10.5035 6.49964 10.5035H8.58497L11.3343 13.2529L13.4343 11.5235V11.5249Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M16.7422 23.1087L22.3195 28.6847C22.7374 29.1026 23.2335 29.4341 23.7794 29.6602C24.3254 29.8864 24.9106 30.0028 25.5015 30.0028C26.0925 30.0028 26.6776 29.8864 27.2236 29.6602C27.7696 29.4341 28.2657 29.1026 28.6835 28.6847C29.1014 28.2669 29.4329 27.7708 29.659 27.2248C29.8852 26.6788 30.0015 26.0937 30.0015 25.5027C30.0015 24.9118 29.8852 24.3266 29.659 23.7806C29.4329 23.2347 29.1014 22.7386 28.6835 22.3207L24.2755 17.9141C23.5998 18.0099 22.9155 18.0291 22.2355 17.9714C21.7102 17.9261 21.3262 17.9634 21.0795 18.0274C21.0015 18.0439 20.9264 18.0722 20.8569 18.1114L16.7422 23.1087ZM21.2942 21.2954C21.4817 21.1081 21.7359 21.0029 22.0009 21.0029C22.2659 21.0029 22.52 21.1081 22.7075 21.2954L25.2075 23.7967C25.3058 23.8883 25.3846 23.9987 25.4392 24.1213C25.4939 24.244 25.5233 24.3764 25.5256 24.5107C25.528 24.645 25.5033 24.7783 25.453 24.9029C25.4027 25.0274 25.3279 25.1405 25.2329 25.2354C25.1379 25.3304 25.0248 25.4053 24.9003 25.4556C24.7758 25.5059 24.6424 25.5306 24.5082 25.5282C24.3739 25.5258 24.2415 25.4964 24.1188 25.4418C23.9961 25.3871 23.8857 25.3083 23.7942 25.2101L21.2942 22.7101C21.1069 22.5226 21.0017 22.2684 21.0017 22.0034C21.0017 21.7384 21.1069 21.4842 21.2942 21.2967V21.2954Z" fill="white"/>
            </svg>
        </SvgIcon>
    )
}

export default SidebarIntegrationsIcon;
