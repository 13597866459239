import Dialog from "@mui/material/Dialog";
import {DialogContent, Stack} from "@mui/material";
import MDInput from "material-ui/components/MDInput";
import MDButton from "material-ui/components/MDButton";
import {useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {Order} from "models/order";
import OrderService from "services/order";
import {useNavigate} from "react-router-dom";
import { useGlobal } from "context/global-context";

interface CreateOrderFlowSmallProps{
    show: boolean
    setShow: Function
}

function CreateOrderFlowSmall({show, setShow}: CreateOrderFlowSmallProps){
    const navigate = useNavigate()
    const {setShowLoader} = useGlobal()
    const queryClient = useQueryClient()
    const [orderName, setOrderName] = useState<string>("")

    const {postOrder} = OrderService()

    const createOrder = useMutation({
        mutationFn: () => {
            let o: Order = new Order(0)
            o.name = orderName

            return postOrder(o)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({queryKey: ["orders"]})
            setShow(false)
            setShowLoader(false)
            navigate(`/order/${data.id}`)
        }
    })

    return <Dialog open={show} onClose={() => setShow()} maxWidth={false}>
        <DialogContent sx={{width: "500px"}}>
            <MDInput
                value={orderName}
                onChange={(e: { target: { value: any } }) => {
                    setOrderName(e.target.value)
                }}
                label={"Order Name"}
                fullWidth={true}
                helperText={"You can name your order to help you identify it later"}
            />

            <Stack
                direction="row"
                spacing={1}
                marginTop={2}
                justifyContent="flex-end"
            >
                <MDButton
                    color={"light"}
                    circular={true}
                    onClick={() => setShow()}
                >
                    Cancel
                </MDButton>

                <MDButton
                    onClick={() => createOrder.mutate()}
                    circular={true}
                >
                    Create
                </MDButton>
            </Stack>
        </DialogContent>
    </Dialog>
}

export default CreateOrderFlowSmall