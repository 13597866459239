/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS Base Styles
import colors from "material-ui/theme/base/colors";
import boxShadows from "material-ui/theme/base/boxShadows";

// Material Dashboard 2 PRO React TS Helper Functions
import linearGradient from "material-ui/theme/functions/linearGradient";

const { white, gradients, grey, transparent } = colors;
const { md } = boxShadows;

// types
type Types = any;

const switchButton: Types = {
  defaultProps: {
    disableRipple: false,
  },

  styleOverrides: {
    root: {
      padding: 0,
      height: "auto",
      width: "auto"
    },

    switchBase: {
      color: gradients.dark.main,
      padding: `0 !important`,
      left: 3,
      top: 3,

      "&:hover": {
        backgroundColor: transparent.main,
      },

      "&.Mui-checked": {
        color: gradients.dark.main,
        transform: `translateX(20px) !important`,

        "&:hover": {
          backgroundColor: transparent.main,
        },

        "& + .MuiSwitch-track": {
          backgroundColor: `${colors.primary.main} !important`,
          opacity: 1,
        },
      },

      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: "0.3 !important",
      },

      "&.Mui-focusVisible .MuiSwitch-thumb": {
        backgroundImage: linearGradient(gradients.info.main, gradients.info.state),
      },
    },

    thumb: {
      backgroundColor: white.main,
      boxShadow: md,
      width: `18px !important`,
      height: `18px !important`
    },

    track: {
      width: 44,
      height: 24,
      backgroundColor: grey[400],
      opacity: 1,
      borderRadius: 12
    },

    checked: {},
  },
};

export default switchButton;
