import ReactDOM from "react-dom/client"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication, } from "@azure/msal-browser"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "@mui/material/styles"
import { MsalProvider } from "@azure/msal-react";

import App from "App"
import { msalConfig } from "authConfig"
import { MaterialUIControllerProvider } from "./context/material-ui";
import DataSourceCallback from "pages/data-source-callback"
import theme from "material-ui/theme"
import { GlobalProvider } from "context/global-context"

export const msalInstance = new PublicClientApplication(msalConfig)

const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult
    const account = payload.account

    msalInstance.setActiveAccount(account)

    localStorage.setItem("token", payload.idToken)
  }
  if (event.eventType === EventType.LOGOUT_SUCCESS) {
    localStorage.removeItem("token")
  }
})

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <ThemeProvider theme={theme}>
        <GlobalProvider>
          <Routes>
            <Route path="/oauth/callback/:source" element={<DataSourceCallback />} />
          </Routes>
          <MsalProvider instance={msalInstance}>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </MsalProvider>
        </GlobalProvider>
      </ThemeProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
)
