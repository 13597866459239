import NewOrderStep1Modal from "./components/new-order-step1-modal";
import {useEffect, useState} from "react";
import {CreateOrderFlowSteps} from "models/order-creation-flow/create-order-flow-steps";
import {SelectFrom} from "models/order-creation-flow/select-from";
import {ITemplate} from "models/template";
import NewOrderStep2TemplatesModal from "./components/new-order-step2-templates-modal";
import NewOrderStep2PremadeDesignsModal from "./components/new-order-step2-premade-designs-modal";
import {PremadeDesign} from "models/premadeDesign";
import NewOrderStep3Modal from "./components/new-order-step3-modal";
import OrderService from "services/order";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {IProduct} from "models/product";
import {useNavigate} from "react-router-dom";
import TemplateService from "services/template";
import {Order} from "models/order";
import {GridSearchParams} from "models/gridSearchParams";
import { useGlobal } from "context/global-context";

interface CreateOrderFlowProps{
    show: boolean
    setShow: Function
    startAtStep?: CreateOrderFlowSteps,
    recipientIds?: number[]
    recipientSearchId?: number
    lastSearchParams?: GridSearchParams
    template?: ITemplate
    design?: PremadeDesign
    showSelectFromPremadeDesignOption?: boolean
    selectedAll?: boolean
}

function CreateOrderFlow({show, setShow, startAtStep = CreateOrderFlowSteps.Step1, recipientIds, recipientSearchId, template, design, showSelectFromPremadeDesignOption = false, lastSearchParams, selectedAll = false}: CreateOrderFlowProps){
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const {setShowLoader} = useGlobal()
    const {createOrderFromRecipientsAndTemplateOrDesign, createOrderFromRecipientSearchAndTemplateOrDesign, postOrder, createOrderFromPremadeDesign} = OrderService()
    const {createOrderFromTemplate} = TemplateService()

    const [showStep1, setShowStep1] = useState<boolean>()
    const [showStep2, setShowStep2] = useState<boolean>()
    const [showStep3, setShowStep3] = useState<boolean>()

    const [selectFrom, setSelectFrom] = useState<SelectFrom>()

    const [selectedTemplate, setSelectedTemplate] = useState<ITemplate>()
    const [selectedPremadeDesign, setSelectedPremadeDesign] = useState<PremadeDesign>()

    const [productType, setProductType] = useState<IProduct>()
    const [orderName, setOrderName] = useState<string>("")

    const createOrderFromRecipientsAndTemplateOrDesignMutation = useMutation({
        mutationFn: () => {
            return createOrderFromRecipientsAndTemplateOrDesign(orderName, recipientIds, lastSearchParams, selectedTemplate?.id, selectedPremadeDesign?.thumbUrl, selectedAll)
        },
        onMutate: function (){
            setShowLoader(true)
        },
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({queryKey: ["orders"]})
            setShow(false)
            setShowLoader(false)
            navigate(`/order/${data.payload.id}`)
        }
    })

    const createOrderFromRecipientSearchAndTemplateOrDesignMutation = useMutation({
        mutationFn: () => {
            return createOrderFromRecipientSearchAndTemplateOrDesign(orderName, recipientSearchId, selectedTemplate?.id, selectedPremadeDesign?.id, productType?.id)
        },
        onMutate: function (){
            setShowLoader(true)
        },
        onSuccess: (data: any, variables: any, context: any) => {
            setShowLoader(false)
            setShow(false)

            navigate(`/order/${data.payload.id}`)
        }
    })

    const createOrderFromTemplateMutation = useMutation({
        mutationFn: () => {
            return createOrderFromTemplate(orderName, template.id)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({queryKey: ["orders"]})
            setShow(false)
            setShowLoader(false)
            navigate(`/order/${data.id}`)
        }
    })

    const createBlankOrderMutation = useMutation({
        mutationFn: () => {
            let o: Order = new Order(0)
            o.name = orderName

            return postOrder(o)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({queryKey: ["orders"]})
            setShow(false)
            setShowLoader(false)
            navigate(`/order/${data.id}`)
        }
    })

    const createOrderFromPremadeDesignMutation = useMutation({
        mutationFn: () => {
            return createOrderFromPremadeDesign(orderName, selectedPremadeDesign.id, productType.id)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({queryKey: ["orders"]})
            setShow(false)
            setShowLoader(false)
            navigate(`/order/${data.id}`)
        }
    })

    useEffect(() => {
        if(showStep1){
            setSelectedTemplate(null)
            setSelectedPremadeDesign(null)
            setProductType(null)
            setOrderName("")
        }
    }, [showStep1]);

    useEffect(() => {
        if(show){
            if(startAtStep === CreateOrderFlowSteps.Step1){
                setShowStep1(true)
            }
            else if(startAtStep === CreateOrderFlowSteps.Step2){
                setShowStep2(true)
            }
            else if(startAtStep === CreateOrderFlowSteps.Step3){
                if(design){
                    setSelectedPremadeDesign(design)
                }
                if(template){
                    setSelectedTemplate(template)
                }
                setShowStep3(true)
            }
        }
        else{
            setShowStep1(false)
            setShowStep2(false)
            setShowStep3(false)
            setSelectFrom(SelectFrom.None)
            setSelectedTemplate(null)
            setSelectedPremadeDesign(null)
            setProductType(null)
            setOrderName("")
        }
    }, [show, startAtStep, design, template]);

    function createOrder(){
        // Creating an order with selected recipients
        if((recipientIds && recipientIds.length) || lastSearchParams){
            createOrderFromRecipientsAndTemplateOrDesignMutation.mutate()
        }
        // Creating an order from a recipient search
        else if(recipientSearchId){
            createOrderFromRecipientSearchAndTemplateOrDesignMutation.mutate({})
        }
        else{
            if(template){
                createOrderFromTemplateMutation.mutate()
                return
            }
            if(design){
                createOrderFromPremadeDesignMutation.mutate()
                return
            }

            createBlankOrderMutation.mutate()
        }
    }

    return <>
        <NewOrderStep1Modal
            show={show && showStep1}
            setShow={setShowStep1}
            selectFrom={selectFrom}
            setSelectFrom={setSelectFrom}
            showSelectFromPremadeDesignOption={showSelectFromPremadeDesignOption}
            onClose={() => {
                setShow(false)
            }}
            onBack={() => {
                setShow(false)
            }}
            onNext={() => {
                setShowStep1(false)

                if(selectFrom === SelectFrom.None){
                    setShowStep3(true)
                }
                else{
                    setShowStep2(true)
                }
            }}
        />

        <NewOrderStep2TemplatesModal
            show={show && showStep2 && selectFrom === SelectFrom.MyTemplates}
            setShow={setShowStep2}
            selectFrom={selectFrom}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            onClose={() => {
                setShow(false)
            }}
            onNext={() => {
                setShowStep2(false)
                setShowStep3(true)
            }}
            onBack={() => {
                setShowStep2(false)
                setShowStep1(true)
            }}
        />

        <NewOrderStep2PremadeDesignsModal
            show={show && showStep2 && selectFrom === SelectFrom.PremadeDesigns}
            setShow={setShowStep2}
            selectFrom={selectFrom}
            selectedPremadeDesign={selectedPremadeDesign}
            setSelectedPremadeDesign={setSelectedPremadeDesign}
            onClose={() => {
                setShow(false)
            }}
            onNext={() => {
                setShowStep2(false)
                setShowStep3(true)
            }}
            onBack={() => {
                setShowStep2(false)
                setShowStep1(true)
            }}
        />

        <NewOrderStep3Modal
            show={show && showStep3}
            setShow={setShowStep3}
            selectedPremadeDesign={selectedPremadeDesign}
            selectedTemplate={selectedTemplate}
            onNext={createOrder}
            onBack={() => {
                setShowStep3(false)

                if(startAtStep === CreateOrderFlowSteps.Step1){
                    if(selectFrom === SelectFrom.None){
                        setShowStep1(true)
                    }
                    else if(selectFrom === SelectFrom.MyTemplates || selectFrom === SelectFrom.PremadeDesigns){
                        setShowStep2(true)
                    }
                }
                else if(startAtStep === CreateOrderFlowSteps.Step3){
                    setShow(false)
                }
            }}
            onClose={() => {
                setShow(false)
            }}
            productType={productType}
            setProductType={setProductType}
            orderName={orderName}
            setOrderName={setOrderName}
        />
    </>
}

export default CreateOrderFlow