import MDBox from "material-ui/components/MDBox";
import {FormControlLabel, Grid, Link, Radio} from "@mui/material";
import MDTypography from "material-ui/components/MDTypography";
import RadioSelectWrapper from "components/radio-select-wrapper";
import HandwrittenCardEnvelop from "assets/icons/handwritten-card-envelop.svg"
import HandwrittenPostcard from "assets/icons/handwritten-postcard.svg"
import PrintedPostcard from "assets/icons/printed-postcard.svg"
import Check2Icon from "assets/icons/check-2";
import ProductImagesCarousel from "./components/product-images-carousel";
import {productCategories, products} from "helpers/products";
import {formatPrice} from "helpers/formatters";
import React, {useEffect, useState} from "react";
import {IProduct, IProductCategory} from "models/product";
import {Order} from "models/order";
import InfoModal from "components/info-modal";
import {useEditor} from "context/editor-context";
import LayoutMain from "pages/order/components/layout-main";
import {IProfile} from "models/profile";
import {useQuery} from "@tanstack/react-query";
import ProfileService from "services/profile";
import MDInput from "material-ui/components/MDInput";
import { useGlobal } from "context/global-context";
import { ProductTypes } from "models/enums/ProductTypes";

interface ProductSubpageProps {
    order: Order
    setOrder: Function
    showWarningModal: boolean
    setShowWarningModal: Function
    product: IProduct
    setProduct: Function
    onNext: Function
    orderName: string
    setOrderName: Function
}

function ProductSubpage({order, setOrder, showWarningModal, setShowWarningModal, product, setProduct, onNext, orderName, setOrderName}: ProductSubpageProps) {
    const {setShowLoader} = useGlobal()
    const {resetProduct} = useEditor()

    const {getProfile} = ProfileService()

    const [profile, setProfile] = useState<IProfile>(null)

    const [productCategory, setProductCategory] = useState<IProductCategory>(null)

    useEffect(() => {
        setOrderName(order.name)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getProfileQuery = useQuery({
        queryKey: ["profile"],
        queryFn: () => {
            return getProfile()
        },
    })

    useEffect(() => {
        if (order) {
            let p: IProduct;

            if (order.product) {
                p = products.find(a => a.name === order.product)
            } else {
                p = products[0]
            }

            setProduct(p)
            setProductCategory(productCategories.find(a => a.products.includes(p.id)))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    useEffect(function () {
        if(getProfileQuery.data){
            setProfile(getProfileQuery.data)
        }
    }, [getProfileQuery.data])

    return <LayoutMain showBackButton={false} showNextButton={true} nextButtonDisabled={!product} nextButtonOnClick={onNext} customMaxWidth={1300}>
        {profile?
            <Grid container gap={4} flexWrap={"nowrap"} justifyContent={"center"}>
                <Grid item xs={6} mt={1}>
                    <MDBox>
                        <MDTypography fontWeight={"bold"} sx={{fontSize: "26px"}}>Select a product</MDTypography>
                    </MDBox>

                    <MDBox mt={3}>
                        <Grid container alignItems={"center"} justifyContent={"center"} gap={1} flexWrap={"nowrap"}>
                            <Grid item xs={4}>
                                <RadioSelectWrapper selected={productCategory?.id === 0} onClick={() => {
                                    let newCategory = productCategories[0]
                                    let newProduct = products.find((a) => a.id === newCategory.products[0])

                                    setProductCategory(newCategory)
                                    setProduct(newProduct)
                                }}>
                                    <MDBox p={2}>
                                        <img alt={""} src={HandwrittenCardEnvelop}/>
                                        <MDTypography variant={"h5"} fontWeight={"bold"}>Handwritten Card</MDTypography>
                                        <MDTypography variant={"h5"} fontWeight={"bold"}>+ Envelope</MDTypography>
                                    </MDBox>
                                </RadioSelectWrapper>
                            </Grid>

                            <Grid item xs={4}>
                                <RadioSelectWrapper selected={productCategory?.id === 1} onClick={() => {
                                    let newCategory = productCategories[1]
                                    let newProduct = products.find((a) => a.id === newCategory.products[0])

                                    setProductCategory(newCategory)
                                    setProduct(newProduct)
                                }}>
                                    <MDBox p={2}>
                                        <img alt={""} src={HandwrittenPostcard}/>
                                        <MDTypography variant={"h5"} fontWeight={"bold"}>Printed</MDTypography>
                                        <MDTypography variant={"h5"} fontWeight={"bold"}>Postcard</MDTypography>
                                    </MDBox>
                                </RadioSelectWrapper>
                            </Grid>

                            <Grid item xs={4}>
                                <RadioSelectWrapper selected={productCategory?.id === 2} onClick={() => {
                                    let newCategory = productCategories[2]
                                    let newProduct = products.find((a) => a.id === newCategory.products[0])

                                    setProductCategory(newCategory)
                                    setProduct(newProduct)
                                }}>
                                    <MDBox p={2}>
                                        <img alt={""} src={PrintedPostcard}/>
                                        <MDTypography variant={"h5"} fontWeight={"bold"}>Handwritten</MDTypography>
                                        <MDTypography variant={"h5"} fontWeight={"bold"}>Postcard</MDTypography>
                                    </MDBox>
                                </RadioSelectWrapper>
                            </Grid>
                        </Grid>
                    </MDBox>

                    <MDBox mt={1}>
                        {productCategory?.products.map((a, index) => {
                            let p = products.find(x => x.id === a)

                            return <MDBox key={p.id} mb={1}>
                                <RadioSelectWrapper selected={p.id === product?.id}>
                                    <MDBox pl={2}>
                                        <FormControlLabel
                                            checked={p.id === product?.id}
                                            control={<Radio color={"primary"}/>}
                                            disableTypography={true}
                                            sx={{display: "flex"}}
                                            label={
                                                <Grid container alignItems={"center"} justifyContent={"space-between"}>
                                                    <Grid item>
                                                        <MDTypography variant={"h5"}>{p.label}</MDTypography>
                                                    </Grid>

                                                    <Grid item>
                                                        <MDTypography variant={"h5"} fontWeight={"regular"}>
                                                            as low as {profile.customerSubscription.subscription.subscriptionPricings?.length? formatPrice(profile.customerSubscription.subscription.subscriptionPricings.find((a) => a.productId === p.id).price) : formatPrice(p.priceBeforeAddons)} ea before add ons
                                                        </MDTypography>
                                                    </Grid>
                                                </Grid>
                                            }
                                            onChange={() => {
                                                setProduct(p)
                                            }}
                                        />
                                    </MDBox>
                                </RadioSelectWrapper>
                            </MDBox>
                        })}
                    </MDBox>

                    <MDBox mt={4}>
                        <MDBox>
                            <MDTypography fontWeight={"bold"}>Product Details</MDTypography>
                        </MDBox>

                        <MDBox mt={2}>
                            <Grid container alignItems={"center"}>
                                <Grid item display={"flex"}>
                                    <Check2Icon/>
                                </Grid>

                                <Grid item ml={"15px"}>
                                    <MDTypography variant={"h5"} fontWeight={"bold"}>Dimensions: </MDTypography>
                                </Grid>

                                <Grid item ml={"5px"}>
                                    <MDTypography variant={"h5"} fontWeight={"normal"}>
                                        {product?.bullet1?? ""}
                                    </MDTypography>
                                </Grid>
                            </Grid>

                            <Grid container mt={2} alignItems={"center"}>
                                <Grid item display={"flex"}>
                                    <Check2Icon/>
                                </Grid>

                                <Grid item ml={"15px"}>
                                    <MDTypography variant={"h5"} fontWeight={"bold"}>
                                        Handwritten Text Length:
                                    </MDTypography>
                                </Grid>

                                <Grid item ml={"5px"}>
                                    <MDTypography variant={"h5"} fontWeight={"normal"}>{product?.bullet2?? ""}</MDTypography>
                                </Grid>
                            </Grid>

                            <Grid container mt={2} alignItems={"center"}>
                                <Grid item display={"flex"}>
                                    <Check2Icon/>
                                </Grid>

                                <Grid item ml={"15px"}>
                                    <MDTypography variant={"h5"} fontWeight={"bold"}>Envelope: </MDTypography>
                                </Grid>

                                <Grid item ml={"5px"}>
                                    <MDTypography variant={"h5"} fontWeight={"normal"}>
                                        {product?.bullet3?? ""}
                                    </MDTypography>
                                </Grid>
                            </Grid>

                            <Grid container mt={2} alignItems={"center"}>
                                <Grid item display={"flex"}>
                                    <Check2Icon/>
                                </Grid>

                                <Grid item ml={"15px"}>
                                    <MDTypography variant={"h5"} fontWeight={"bold"}>Stamp: </MDTypography>
                                </Grid>

                                <Grid item ml={"5px"}>
                                    <MDTypography variant={"h5"} fontWeight={"normal"}>{product?.bullet4?? ""}</MDTypography>
                                </Grid>
                            </Grid>
                        </MDBox>
                    </MDBox>

                    {product && product.name !== ProductTypes.HandwrittenBiFoldCard?
                        <MDBox mt={2}>
                            <Link href={product.designSpecsUrl} target="_blank">
                                <MDTypography color={"primary"} sx={{textDecoration: "underline", fontSize: 16}} fontWeight={"bold"}>Click here for Design Specs</MDTypography>
                            </Link>
                        </MDBox>
                        :
                        null
                    }

                    <MDBox mt={4}>
                        <MDTypography fontWeight={"bold"}>{order.isTemplate? 'Template' : 'Order'} Name</MDTypography>

                        <MDBox mt={1}>
                            <MDInput
                                value={orderName}
                                onChange={(e: { target: { value: any } }) => {
                                    setOrderName(e.target.value)
                                }}
                                fullWidth={true}
                                helperText={`You can name your ${order.isTemplate? 'template' : 'order'} to help you identify it later`}
                            />
                        </MDBox>
                    </MDBox>
                </Grid>

                <Grid item xs={6}>
                    <MDBox mb={3}>
                        <MDTypography color={"black"} fontWeight={"bold"} sx={{fontSize: "26px"}}>&nbsp;</MDTypography>
                    </MDBox>

                    <ProductImagesCarousel productId={product?.id}/>
                </Grid>
            </Grid>
            :
            null
        }

        <InfoModal
            show={showWarningModal}
            setShow={setShowWarningModal}
            headerText={"Switching products will clear your images and QR code. Switch products now?"}
            showCancelButton={true}
            showConfirmButton={true}
            cancelButtonOnClick={() => {
                let oldProduct = products.find(a => a.name === order.product)

                setProduct(oldProduct)
                setProductCategory(productCategories.find(a => a.products.includes(oldProduct.id)))

                setShowWarningModal(false)
            }}
            confirmButtonOnClick={async () => {
                setShowLoader(true)

                await resetProduct(product.id, product.name)

                onNext()
            }}
        />

    </LayoutMain>
}

export default ProductSubpage