import { useEffect, useState } from "react";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";
import MDBox from "material-ui/components/MDBox";
import StyledDataGrid from "components/styled-data-grid";
import { useQueryClient } from "@tanstack/react-query";
import InfoModal from "components/info-modal";
import { IVM_GridOrder } from "models/views/gridOrderVm";
import IntegrationOrderService from "services/integration-order";
import { IntegrationOrderStatus } from "models/enums/IntegrationOrderStatus";
import { GridRowId } from "@mui/x-data-grid";
import IntegrationOrderDetail from "components/integration-order-detail";
import OrderService from "services/order";
import TwoButtonWithOptionalFormFieldModal from "components/modal-with-two-buttons-and-textfield";
import TemplateService from "services/template";
import { OrderType } from "models/enums/OrderType";
import CopyOrderModal from "components/order-details-modal/components/copy-order-modal";
import { gridColumns } from "helpers/gridColumns";
import { useGlobal } from "context/global-context";
import ConfirmStatusChange from "components/confirm-status-change";

interface IntegrationOrdersGridProps {
    data: IVM_GridOrder[]
    gridHeight: string
    isLoading: boolean
}

function IntegrationOrdersGrid({ data, gridHeight, isLoading }: IntegrationOrdersGridProps) {
    const { setShowLoader } = useGlobal()

    const queryClient = useQueryClient()

    const navigate = useNavigate()

    const { changeOrderName } = OrderService();
    const {  deleteIntegrationOrder, copyIntegrationOrder } = IntegrationOrderService()
    const { createTemplateFromOrder } = TemplateService();

    const [expandedRowId, setExpandedRowId] = useState<GridRowId>(null)

    const [currentOrder, setCurrentOrder] = useState<IVM_GridOrder>(null)
    const [orderName, setOrderName] = useState<string>('');
    const [newStatus, setNewStatus] = useState<IntegrationOrderStatus>(null)
    const [templateName, setTemplateName] = useState<string>('');
    const [newIntegrationOrderName, setNewIntegrationOrderName] = useState<string>('');

    const [showDeleteOrderModal, setShowDeleteOrderModal] = useState<boolean>(false)
    const [showEditNameModal, setShowEditNameModal] = useState<boolean>(false);
    const [showCreateTemplateModal, setShowCreateTemplateModal] = useState<boolean>(false);
    const [showCopyOrderModal, setShowCopyOrderModal] = useState<boolean>(false);
    const [showCopyIntegrationOrderModal, setShowCopyIntegrationOrderModal] = useState<boolean>(false);
    const [showConfirmStatusModal, setShowConfirmStatusModal] = useState<boolean>(false);

    const apiRef = useGridApiRef()

    useEffect(() => {
        if (showEditNameModal) setOrderName(currentOrder.name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showEditNameModal]);


    useEffect(() => {
        if (showCreateTemplateModal) {
            setTemplateName('');
        }
    }, [showCreateTemplateModal]);

    const updateOrderName = () => {
        setShowLoader(true);
        changeOrderName({ ...currentOrder, name: orderName }).then(() => {
            const gridApi = apiRef.current;
            if (gridApi) gridApi.updateRows([{ id: currentOrder.id, name: orderName }]);
            setShowEditNameModal(false);
            setShowLoader(false);
        });
    }

    const columns: GridColDef[] = gridColumns({
        setCurrentOrder,
        setShowEditNameModal,
        setOrderName,
        handleStatusChange: (order, value) => {
            setNewStatus(value)
            setCurrentOrder(order)
            setShowConfirmStatusModal(true);
          },
        setShowDeleteOrderModal,
        setShowCreateTemplateModal,
        setShowCopyIntegrationOrderModal,
        setShowCopyOrderModal,
        navigate
    });

    function deleteOrder() {
        setShowLoader(true)
        deleteIntegrationOrder(currentOrder.id).then((response) => {
            setShowLoader(false)
            setShowDeleteOrderModal(false)

            queryClient.invalidateQueries({ queryKey: ["allIntegrationOrders"] })
            queryClient.invalidateQueries({ queryKey: ["orders"] })
        })
    }

    function _createTemplateFromOrder() {
        setShowLoader(true);

        createTemplateFromOrder(
            currentOrder.orderType === OrderType.Integration ? currentOrder.templateId : currentOrder.id,
            templateName
        ).then((response) => {
            setShowLoader(false);
            setShowCreateTemplateModal(false);
            navigate('/my-templates');
        });
    }

    function _copyIntegrationOrder() {
        setShowLoader(true);

        let newOrder = { ...currentOrder } as IVM_GridOrder;
        newOrder.name = newIntegrationOrderName;

        return copyIntegrationOrder(newOrder).then((response) => {
            setShowLoader(false);
            setShowCopyIntegrationOrderModal(false);
            queryClient.invalidateQueries({ queryKey: ['allIntegrationOrders'] });
            queryClient.invalidateQueries({ queryKey: ['orders'] });
        });
    }

    return <MDBox height={gridHeight ?? "auto"}>
        <StyledDataGrid
            apiRef={apiRef}
            backgroundColor={"white"}
            loading={isLoading}
            rows={data}
            columns={columns}
            disableRowSelectionOnClick={true}
            pagination={false}
            hideFooter={true}
            getDetailPanelHeight={() => "auto"}
            detailPanelExpandedRowIds={expandedRowId ? [expandedRowId] : []}
            onDetailPanelExpandedRowIdsChange={(ids) => {
                if (!ids) {
                    setExpandedRowId(null)
                    return
                }

                // Only allow the user to expand one row at a time since the google api only allows 1 map shown at a time
                setExpandedRowId(ids[ids.length - 1])
            }}
            getDetailPanelContent={({ row }) => {
                return <IntegrationOrderDetail key={row.name} orderId={row.id} />
            }}
        />

        <InfoModal
            show={showDeleteOrderModal}
            setShow={setShowDeleteOrderModal}
            headerText={"Are you sure you want to delete this order?"}
            showCancelButton={true}
            showConfirmButton={true}
            cancelButtonOnClick={() => {
                setShowDeleteOrderModal(false)
            }}
            confirmButtonOnClick={() => {
                deleteOrder()
            }}
        />

        <TwoButtonWithOptionalFormFieldModal
            open={showEditNameModal}
            onClose={() => setShowEditNameModal(false)}
            formFieldValue={orderName}
            formFieldOnChange={setOrderName}
            formFieldLabel="Edit Name"
            formFieldHelperText="This will help you identify your integration order"
            primaryButtonText="Okay"
            primaryButtonOnClick={updateOrderName}
            secondaryButtonOnClick={() => setShowEditNameModal(false)}
            secondaryButtonText="Cancel"
        />

        <TwoButtonWithOptionalFormFieldModal
            open={showCreateTemplateModal}
            onClose={() => setShowCreateTemplateModal(false)}
            formFieldLabel="Template Name"
            formFieldHelperText="Please name your template, this will help you identify it later"
            formFieldValue={templateName}
            formFieldOnChange={setTemplateName}
            primaryButtonText="Create"
            primaryButtonOnClick={() => _createTemplateFromOrder()}
            secondaryButtonText="Cancel"
            secondaryButtonOnClick={() => setShowCreateTemplateModal(false)}
        />

        <TwoButtonWithOptionalFormFieldModal
            open={showCopyIntegrationOrderModal}
            onClose={() => setShowCopyIntegrationOrderModal(false)}
            formFieldLabel="Order Name"
            formFieldHelperText="Please name your order, this will help you identify it later"
            formFieldValue={newIntegrationOrderName}
            formFieldOnChange={setNewIntegrationOrderName}
            primaryButtonText="Copy"
            primaryButtonOnClick={() => _copyIntegrationOrder()}
            secondaryButtonText="Cancel"
            secondaryButtonOnClick={() => setShowCopyIntegrationOrderModal(false)}
        />

        <CopyOrderModal
            show={showCopyOrderModal}
            setShow={setShowCopyOrderModal}
            order={currentOrder}
        />

        <ConfirmStatusChange 
          order={currentOrder}
          status={newStatus}
          open={showConfirmStatusModal}
          setOpen={setShowConfirmStatusModal}
          updateRows={apiRef.current?.updateRows}
        />
    </MDBox>
}

export default IntegrationOrdersGrid