import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT from "../endpoint";

function PremadeDesignService(){
    const {execute} = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/designs`

    // Gets all premade designs
    function getPremadeDesigns(){
        return execute({
            endpoint: `${API_PATH}`,
            method: "GET"
        })
    }

    return {getPremadeDesigns}
}

export default PremadeDesignService