import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import MDCard from 'material-ui/components/MDCard';
import SmartAutomationForm from './components/smart-automation-form';
import { Alert, Box, MenuItem, Select, SelectChangeEvent, Snackbar, Typography } from '@mui/material';
import useDataSourcesService from 'services/data-sources';
import lettrLabsLogo from 'assets/images/logo-inverted.png';
import TwoButtonWithOptionalFormFieldModal from 'components/modal-with-two-buttons-and-textfield';
import { AuthenticatedStatusType, DataSourceType, IDataSource } from 'services/data-sources/data-sources.types';
import { useGlobal } from 'context/global-context';
import SmartAutomationService from 'services/smart-automation';
import { ISmartAutomationPayload } from 'services/smart-automation/smart-automation.types';
import { ITemplate } from 'models/template';
import { convertKeysToCamelCase } from 'helpers/pascalToCamelCase';
import TemplateService from 'services/template';
import MoversFormik from './components/movers-formik';
import Loader from 'components/loader';
import MoversContextProvider from 'context/movers-context';
import { IObjectResult } from 'services/endpoint';

const SmartAutomation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showLoader, setShowLoader, templateFlow, setTemplateFlow } = useGlobal();

  const { id: automationId } = useParams();
  const isEdit = automationId !== 'new';

  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const source = queryParams.get('source');
  const name = queryParams.get('name');

  // TODO: replace any
  const [initialValues, setInitialValues] = useState<any>();
  const [dataSource, setDataSource] = useState<IDataSource>();
  const [newSource, setNewSource] = useState<number>(null);
  const [error, setError] = useState<string>('');

  const { getTemplate } = TemplateService();
  const { getAllDataSources } = useDataSourcesService();
  const { getSmartAutomation, createSmartAutomation, updateSmartAutomation } = SmartAutomationService();

  const mutationOptions = {
    onMutate: () => setShowLoader(true),
    onSuccess: (response: IObjectResult<ISmartAutomationPayload>) => {
      if (response.hasErrors) {
        setError(response.errors[0]);
      } else {
        navigate(`/smart-automation?id=${response.payload.id}`);
        setShowLoader(false);
      }
    },
  };

  const { mutate: createAutomation, isPending: isCreating } = useMutation({
    mutationFn: createSmartAutomation,
    onSuccess: (response) => navigate(`/smart-automation?id=${response.payload.id}`),
  });

  const { mutate: updateAutomation, isPending: isUpdating } = useMutation({
    mutationFn: updateSmartAutomation,
    ...mutationOptions,
  });

  const {
    data: smartAutomation,
    isLoading: isLoadingSM,
    isFetching: isFetchingSM,
  } = useQuery({
    queryKey: ['getSmartAutomation', isEdit ? Number(automationId) : null],
    queryFn: () => getSmartAutomation(isEdit ? Number(automationId) : null),
    refetchOnWindowFocus: false,
  });

  const { data: dataSourcesData } = useQuery({
    queryKey: ['getAllDataSources'],
    queryFn: () => getAllDataSources(),
    refetchOnWindowFocus: false,
  });
  const dataSources = dataSourcesData?.payload;

  const {
    data: template,
    refetch: refetchTemplate,
    isLoading: isLoadingTemplate,
    isFetching: isFetchingTemplate,
  } = useQuery({
    queryKey: ['getTemplate', initialValues?.template?.id || smartAutomation?.payload.templateId],
    queryFn: () => getTemplate(initialValues?.template?.id || smartAutomation?.payload.templateId),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    setShowLoader(
      isCreating ||
        isUpdating ||
        isLoadingSM ||
        isFetchingSM ||
        isLoadingTemplate ||
        isFetchingTemplate ||
        (smartAutomation?.payload?.payload && JSON.parse(smartAutomation.payload.payload)?.IsCalculatingTargets)
    );

    return () => setShowLoader(false);
  }, [
    smartAutomation,
    isCreating,
    isUpdating,
    isFetchingSM,
    isFetchingTemplate,
    isLoadingSM,
    isLoadingTemplate,
    showLoader,
    setShowLoader,
  ]);

  useEffect(() => {
    if (dataSources) {
      if (source) {
        const auxSource = dataSources?.find((ds) => ds.id === Number(source));
        if (
          (auxSource?.isEnabled && auxSource.authenticationStatus === AuthenticatedStatusType.Authenticated) ||
          isEdit
        )
          setDataSource(auxSource);
        else navigate('/smart-automation');
        setShowLoader(false);
      } else if (smartAutomation) {
        const { dataSourceId } = smartAutomation.payload;
        navigate(`${pathname}?source=${dataSourceId}`, { replace: true });
      } else setShowLoader(true);
    } else setShowLoader(true);
  }, [dataSources, smartAutomation, navigate, setShowLoader, source, pathname, isEdit]);

  useEffect(() => {
    if (templateFlow.order?.template) {
      setInitialValues(templateFlow.order);
      setTemplateFlow({});
    } else if (isEdit) {
      if (!smartAutomation?.payload) return;
      const { templateId, payload } = smartAutomation?.payload;
      const newInitialValues = payload ? { ...convertKeysToCamelCase(JSON.parse(payload)) } : {};
      if (templateId && !template) refetchTemplate();
      else if (!templateId) newInitialValues.template = null;
      else newInitialValues.template = template[0];
      if (newInitialValues.template !== undefined) setInitialValues(newInitialValues);
    }
  }, [isEdit, refetchTemplate, setTemplateFlow, smartAutomation?.payload, template, templateFlow.order]);

  useEffect(() => {
    if (initialValues?.template?.id) refetchTemplate();
  }, [initialValues?.template?.id, refetchTemplate]);

  const handleSourceChange = (e: SelectChangeEvent<string | number>) => {
    const newSourceId = Number(e.target.value);
    if (newSourceId !== Number(source)) setNewSource(newSourceId);
  };

  const handleReset = () => {
    navigate(`/smart-automation/new?source=${newSource}&name=${name}`);
    setNewSource(null);
  };

  const handleSubmit = <T extends { template: ITemplate }>(values: T) => {
    queryClient.invalidateQueries({ queryKey: ['allSmartAutomations'] });
    const { template, ...payload } = values;
    const smartAutomationPayload: ISmartAutomationPayload = {
      dataSourceId: dataSource.id,
      templateId: template.id,
      payload: JSON.stringify(payload),
      name: name || smartAutomation?.payload.name,
    };
    if (isEdit) updateAutomation({ ...smartAutomationPayload, id: smartAutomation?.payload.id });
    else createAutomation(smartAutomationPayload);
  };

  const disabled = isEdit && !smartAutomation?.payload.canEdit;
  const renderContent = () => {
    if (dataSource?.displayName === DataSourceType.Movers) {
      return (
        <MoversContextProvider disabled={disabled}>
          <MoversFormik initialValues={initialValues} />
        </MoversContextProvider>
      );
    }
    if (dataSource?.displayName) {
      return (
        <SmartAutomationForm
          status={smartAutomation?.payload?.status}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          disabled={disabled}
        />
      );
    }
    if (smartAutomation?.hasErrors) return <Alert severity="error">{smartAutomation.errors[0]}</Alert>;
    return (
      <Box display="flex" justifyContent="center">
        <Loader />
      </Box>
    );
  };

  return (
    <>
      {disabled && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          <Typography variant="h4" fontWeight="normal">
            This automation cannot be edited, as it is not in a Testing status.
          </Typography>
        </Alert>
      )}
      <MDCard sx={{ flexDirection: 'row', p: 2, mb: 1 }}>
        <Typography fontSize={24} fontWeight={'bold'} color={'secondary'} flex={1}>
          {isEdit ? 'Edit' : 'New'} Smart Automation
        </Typography>
        <Box display="flex" alignItems={'center'}>
          <Typography variant="h4" pb={1} mr={2}>
            Source:{' '}
          </Typography>
          <Select value={source ?? ''} sx={{ width: 220 }} onChange={handleSourceChange} disabled={isEdit}>
            <MenuItem value="" disabled>
              Select an option
            </MenuItem>
            {dataSources?.map((source) => (
              <MenuItem
                key={`source${source.displayName}`}
                value={source.id}
                disabled={!source.isEnabled || source.authenticationStatus !== AuthenticatedStatusType.Authenticated}
              >
                <Box display="flex" gap={1}>
                  <img alt="" src={source.logoImageUrl || lettrLabsLogo} width={22} height={22} />
                  {source.displayName}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </Box>
      </MDCard>
      {renderContent()}
      <TwoButtonWithOptionalFormFieldModal
        open={newSource !== null}
        onClose={() => setNewSource(null)}
        primaryButtonOnClick={() => handleReset()}
        primaryButtonText="Continue"
        secondaryButtonOnClick={() => setNewSource(null)}
        secondaryButtonText="Never mind"
        title={'Confirm Source Change'}
        description="Changing the source will reset your current selections for templates and filters. Do you want to continue?"
      />
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setError('')} severity="error" variant="standard" sx={{ py: 0 }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SmartAutomation;
