import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT from "../endpoint";
import {IProfileTag} from "models/profileTag";

function ProfileTagService(){
    const {execute} = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/profileTags`

    // Gets all of the user's tags
    function getProfileTags(){
        return execute({
            endpoint: API_PATH,
            method: "GET"
        })
    }

    function postProfileTag(tag: IProfileTag){
        return execute({
            endpoint: API_PATH,
            method: "POST",
            data: tag
        })
    }

    function deleteProfileTag(id: number){
        return execute({
            endpoint: `${API_PATH}/${id}}`,
            method: "DELETE"
        })
    }

    return {getProfileTags, deleteProfileTag, postProfileTag}
}

export default ProfileTagService