import {useQuery} from "@tanstack/react-query";
import ProfileService from "services/profile";
import {PaymentElement} from '@stripe/react-stripe-js'

export function StripePaymentForm() {
    const {getProfile} = ProfileService()

    const getProfileQuery = useQuery({
        queryKey: ["profile"],
        queryFn: () => {
            return getProfile()
        },
    })

    return <PaymentElement
        options={{
            defaultValues: {
                billingDetails: {
                    name: (getProfileQuery.data && getProfileQuery.data.firstName && getProfileQuery.data.lastName) ? `${getProfileQuery.data.firstName} ${getProfileQuery.data.lastName}` : ``,
                    address: {
                        country: "US"
                    }
                }
            }
        }}/>
}