import React, {useEffect, useRef, useState} from "react";
import {useEditor} from "context/editor-context";

import MDCard from "material-ui/components/MDCard";
import MDBox from "material-ui/components/MDBox";
import MDIconButton from "material-ui/components/MDIconButton";
import {Grid, Select, Tooltip} from "@mui/material";

import secondaryFonts from 'helpers/fonts.json';

import {setSelectedBlockTextAlignment, setSelectedBlockColor, getSelectedBlockColor, removeBlock, setTextFontById} from "helpers/ImgLyHelper";

import TextIcon from "assets/icons/text";
import TextColorIcon from "assets/icons/text-color";
import TextAlignLeftIcon from "assets/icons/text-align-left";
import TextAlignRightIcon from "assets/icons/text-align-right";
import TextAlignCenterIcon from "assets/icons/text-align-center";
import MDInput from "material-ui/components/MDInput";
import {hexStringToImglyRGBA, imglyRGBAToHexString} from "context/editor-context/lib/CreativeEngineUtils";
import ColorCircle from "./components/color-circle";
import MenuItem from "@mui/material/MenuItem";
import MDTypography from "material-ui/components/MDTypography";
import DeleteIcon from "assets/icons/delete";
import LayerManagementBar from "../layer-management-bar";
import { ProductTypes } from "models/enums/ProductTypes";

enum TextAlignment{
    Left="Left", Center="Center", Right="Right"
}

function TextBar(){
    const {order, creativeEngine, selectedBlocks, checkForBlocksOnGivenSide, setSelectedBlocks} = useEditor()

    const fontIconRef = useRef(null)

    const [showColorPicker, setShowColorPicker] = useState<boolean>(false)
    const [showFontDropdown, setShowFontDropdown] = useState<boolean>(false)

    const colors = ["#d991a5", "#ff0000", "#e96f2e", "#f1bd40", "#93d9b7", "#5dcb89", "#9fcdf9", "#4191db", "#9358df", "#ececec", "#adb7c5", "#303030"]

    const [currentlySelectedTextBlockColor, setCurrentlySelectedTextBlockColor] = useState<string>("")

    const [alignment, setAlignment] = useState<TextAlignment>(null)

    useEffect(function () {
        if(!creativeEngine || !selectedBlocks || !selectedBlocks.length){
            return
        }

        if(showColorPicker){
            let hex = imglyRGBAToHexString(getSelectedBlockColor(creativeEngine))

            setCurrentlySelectedTextBlockColor(hex)
        }
        else{
            setCurrentlySelectedTextBlockColor("")
        }
    }, [showColorPicker, creativeEngine, selectedBlocks])

    useEffect(function () {
        if(!selectedBlocks?.length){
            return
        }

        setAlignment(creativeEngine.block.getEnum(selectedBlocks[0].id, "text/horizontalAlignment") as TextAlignment)
    }, [selectedBlocks, creativeEngine.block])

    return <MDCard sx={{width: "100%"}} boxShadow={false} border={true} borderColor={"light"} borderRadiusSize={"md"}>
        <MDBox p={1.5}>
            <Grid container alignItems={"center"} justifyContent={"space-between"}>
                <Grid item>
                    <Grid container alignItems={"center"} gap={1}>
                        <Grid item>
                            <Tooltip title={"Font"}>
                            <MDIconButton backgroundColor={"light"} ref={fontIconRef} onClick={() => {setShowFontDropdown(prevState => !prevState)}}>
                                <style>
                                    {secondaryFonts.map((a, index: number) => {
                                        return `
                                        @font-face {
                                          src: url('${creativeEngine.editor.defaultURIResolver(a.fontPath)}');
                                          font-family: '${a.fontFamily}';
                                          font-weight: ${a.fontWeight};
                                        }`
                                    })}
                                </style>

                                <TextIcon/>

                                <Select
                                    sx={{display: "none"}}
                                    open={showFontDropdown}
                                    MenuProps={{
                                        anchorEl: fontIconRef.current
                                    }}
                                    onChange={(event) => {
                                        setTextFontById(creativeEngine, selectedBlocks[0].id, event.target.value)
                                    }}
                                >
                                    {secondaryFonts.map((font, index: number) => {
                                        return <MenuItem key={index} value={font.fontPath}>
                                            <MDTypography sx={{fontFamily: font.fontFamily}}>Ag {font.fontFamily}</MDTypography>
                                        </MenuItem>
                                    })}
                                </Select>
                            </MDIconButton>
                            </Tooltip>
                        </Grid>

                        <Grid item>
                            <Tooltip
                                open={showColorPicker}
                                title={
                                    <MDBox>
                                        <Grid container gap={1} alignItems={"center"}>
                                            {colors.map((c, index) => {
                                                return <Grid item key={index} xs={"auto"}>
                                                    <ColorCircle backgroundColor={c} selected={currentlySelectedTextBlockColor && currentlySelectedTextBlockColor === c} onClick={() => {
                                                        setSelectedBlockColor(creativeEngine, hexStringToImglyRGBA(c))
                                                        setCurrentlySelectedTextBlockColor(c)
                                                        setShowColorPicker(false)
                                                    }}/>
                                                </Grid>
                                            })}
                                        </Grid>

                                        <MDBox mt={2}>
                                            <MDInput
                                                InputProps={{
                                                    startAdornment: "#"
                                                }}
                                                inputProps={{
                                                    maxLength: 6
                                                }}
                                                fullWidth={true}
                                                size={"small"}
                                                label={"Custom color"}
                                                onChange={(event: any) => {
                                                    let val = event.target.value

                                                    if(val.length < 6){
                                                        return
                                                    }

                                                    val = `#${event.target.value}`.toLowerCase()

                                                    setSelectedBlockColor(creativeEngine, hexStringToImglyRGBA(val))
                                                    setCurrentlySelectedTextBlockColor(val)
                                                }}
                                            />
                                        </MDBox>
                                    </MDBox>
                                }
                                classes={{tooltip: "color-picker-tooltip"}}
                                arrow={false}
                            >
                                <Tooltip title={"Color"}>
                                    <MDIconButton backgroundColor={"light"} onClick={() => {setShowColorPicker(prevState => !prevState)}}>
                                        <TextColorIcon/>
                                    </MDIconButton>
                                </Tooltip>
                            </Tooltip>
                        </Grid>

                        <Grid item>
                            <Tooltip title={"Align Left"}>
                                <MDIconButton backgroundColor={"light"} active={alignment === TextAlignment.Left} onClick={() => {
                                    setSelectedBlockTextAlignment(creativeEngine, "Left")
                                    setAlignment(TextAlignment.Left)
                                }}>
                                    <TextAlignLeftIcon/>
                                </MDIconButton>
                            </Tooltip>
                        </Grid>

                        <Grid item>
                            <Tooltip title={"Align Center"}>
                                <MDIconButton backgroundColor={"light"} active={alignment === TextAlignment.Center} onClick={() => {
                                    setSelectedBlockTextAlignment(creativeEngine, "Center")
                                    setAlignment(TextAlignment.Center)
                                }}>
                                    <TextAlignCenterIcon/>
                                </MDIconButton>
                            </Tooltip>
                        </Grid>

                        <Grid item>
                            <Tooltip title={"Align Right"}>
                                <MDIconButton backgroundColor={"light"} active={alignment === TextAlignment.Right} onClick={() => {
                                    setSelectedBlockTextAlignment(creativeEngine, "Right")
                                    setAlignment(TextAlignment.Right)
                                }}>
                                    <TextAlignRightIcon/>
                                </MDIconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Tooltip title={"Delete"}>
                        <MDIconButton backgroundColor={"light"} onClick={() => {
                            removeBlock(creativeEngine, selectedBlocks[0].id)

                            setSelectedBlocks(null)

                            if(order?.product === ProductTypes.HandwrittenBiFoldCard) checkForBlocksOnGivenSide(0)
                            else checkForBlocksOnGivenSide(1)
                        }}>
                            <DeleteIcon color={"error"}/>
                        </MDIconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            <LayerManagementBar/>
        </MDBox>
    </MDCard>
}

export default TextBar