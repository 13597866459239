import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT from "../endpoint";
import {IProfile, Profile} from "models/profile";
import {IProfileContact} from "../../models/profileContact";

function ProfileService(){
    const {execute} = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/profile`

    // Gets an order
    function getProfile(){
        return execute({
            endpoint: `${API_PATH}`,
            method: "GET"
        })
    }

    function getLoggedInProfile(): Promise<IProfile>{
        return execute({
            endpoint: `${API_PATH}/loggedin`,
            method: "GET"
        })
    }

    function postProfile(profile: Profile){
        return execute({
            endpoint: `${API_PATH}`,
            method: "POST",
            data: profile
        })
    }

    function postProfileContact(contact: IProfileContact){
        return execute({
            endpoint: `${API_PATH}/contact`,
            method: "POST",
            data: {
                profileContact: contact
            }
        })
    }

    function getOrderTransactions(){
        return execute({
            endpoint: `${API_PATH}/getOrderTransactions?timestamp=${new Date().getTime()}`,
            method: "GET"
        })
    }

    function getCreditTransactions(){
        return execute({
            endpoint: `${API_PATH}/getCreditTransactions?timestamp=${new Date().getTime()}`,
            method: "GET"
        })
    }

    function getProfilePaymentMethods(){
        return execute({
            endpoint: `${API_PATH}/profilePaymentMethods`,
            method: "GET"
        })
    }

    function deleteProfilePaymentMethod(paymentMethodId: string){
        return execute({
            endpoint: `${API_PATH}/profilePaymentMethods/${paymentMethodId}`,
            method: "DELETE"
        })
    }

    function addProfilePaymentMethod(paymentMethodId: string){
        return execute({
            endpoint: `${API_PATH}/profilePaymentMethods`,
            method: "POST",
            data: {
                paymentMethodId
            }
        })
    }

    return {getProfile, getLoggedInProfile, postProfile, postProfileContact, getOrderTransactions, getCreditTransactions, getProfilePaymentMethods, deleteProfilePaymentMethod, addProfilePaymentMethod}
}

export default ProfileService