import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Field, FieldArray, FieldProps, Form, Formik, FormikProps } from 'formik';
import {
  Alert,
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import MDButton from 'material-ui/components/MDButton';
import StepIndicator from 'components/step-indicator';
import FormikErrorMessage from 'components/formik-error-message';
import SmartAutomationFilterGroup from './smart-automation-filter-group';
import useFormikValidation from 'hooks/useFilterValidation';
import MDCard from 'material-ui/components/MDCard';
import useSmartAutomationService from 'services/smart-automation';
import {
  ISmartAutomationSchema,
  ISourceViewModel,
  IUserCreatedFilter,
  PostageTypeEnum,
} from 'services/smart-automation/smart-automation.types';
import SelectTemplate from 'components/select-template';
import { smartAutomationValidation } from 'services/smart-automation/smart-automation.schema';
import { convertKeysToCamelCase } from 'helpers/pascalToCamelCase';
import RadioSelectWrapper from 'components/radio-select-wrapper';
import { orderProducUsesLetterUSPSPricing } from 'models/enums/ProductTypes';
import LeadRevealEnable from './lead-reveal-enable';
import SelectDiscountCodes from 'components/select-discount-codes';
import Loader from 'components/loader';
import { IntegrationOrderStatus } from 'models/enums/IntegrationOrderStatus';

export const emptyFilter: IUserCreatedFilter = { dataSourceFilter: null, operator: null, value: null };

interface SmartAutomationFormContentProps extends FormikProps<ISmartAutomationSchema> {
  disabled?: boolean;
}

const SmartAutomationFormContent = ({
  disabled,
  values,
  setValues,
  setFieldValue,
  isValid,
  isSubmitting,
  getFieldMeta,
  errors,
}: SmartAutomationFormContentProps) => {
  const navigate = useNavigate();
  const { getViewModelByDataSource } = useSmartAutomationService();
  const [segmentValue, setSegmentValue] = useState<string>('');

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const source = queryParams.get('source');

  const { data: vmData } = useQuery({
    queryKey: ['getViewModelByDataSource', Number(source)],
    queryFn: () => getViewModelByDataSource(Number(source)),
    refetchOnWindowFocus: false,
  });

  const sourceVm: ISourceViewModel = vmData?.payload ? convertKeysToCamelCase(JSON.parse(vmData.payload)) : {}
  const schema = smartAutomationValidation(!!sourceVm.isFilterRequired, Number(source))
  const [isFilterValid] = useFormikValidation<ISmartAutomationSchema, typeof schema>(schema, 'userCreatedFilters');

  return (
    <Form>
      <Grid container gap={3} py={1}>
        <StepIndicator stepNumber={1} checked={!!values.template} />
        <Box>
          <Divider light orientation="vertical" />
        </Box>
        <Grid item flex={1}>
          <Typography variant="h4" pb={2}>
            Template
          </Typography>
          <Field name="template">
            {({ field, meta }: FieldProps) => (
              <SelectTemplate
                meta={meta}
                order={values}
                onDelete={() => setFieldValue('template', null)}
                onTemplateChange={(template) => setFieldValue('template', template)}
                template={field.value}
                disabled={disabled}
              />
            )}
          </Field>
          {values.template?.hasDiscount && (
            <Box mt={1}>
              <SelectDiscountCodes
                disabled={disabled}
                error={!!errors.multiUseCouponId}
                selectedDiscount={values}
                setSelectedDiscount={(discount) => setValues({ ...values, ...discount })}
              />
            </Box>
          )}
        </Grid>
      </Grid>

      <Divider light />

      <Grid container gap={3}>
        <StepIndicator stepNumber={2} checked={isFilterValid && !!values.segmentId} />
        <Box>
          <Divider light orientation="vertical" />
        </Box>
        <Grid item container flexDirection={'column'} flex={1} gap={2}>
          {sourceVm?.segmentDropdownValues && (
            <Grid item maxWidth={350} minWidth={200} flex={1}>
              <Field name="segmentId">
                {({ field, meta }: FieldProps) => (
                  <FormControl fullWidth>
                    <Typography component="label" htmlFor="sa-segment" variant="h4" pb={1}>
                      Segment
                    </Typography>
                    <Autocomplete
                      {...field}
                      disablePortal
                      id="sa-segment"
                      sx={{ width: 300 }}
                      value={field.value || ''}
                      inputValue={segmentValue}
                      onInputChange={(_, newValue) => setSegmentValue(newValue)}
                      options={[...sourceVm.segmentDropdownValues.map((el) => el.item1)]}
                      renderInput={(params) => <TextField {...params} />}
                      getOptionLabel={(option) =>
                        sourceVm.segmentDropdownValues.find((el) => el.item1 === option)?.item2 ?? ''
                      }
                      onChange={(_, newValue) => setFieldValue('segmentId', newValue)}
                      isOptionEqualToValue={(option, value) => option === value}
                    />
                    <FormikErrorMessage meta={meta} />
                  </FormControl>
                )}
              </Field>
            </Grid>
          )}
          {sourceVm?.storeDropdownValues && (
            <Grid item maxWidth={350} minWidth={200} flex={1}>
              <Field name="profileDataProviderId">
                {({ field, meta }: FieldProps) => (
                  <FormControl fullWidth>
                    <Typography component="label" htmlFor="sa-store" variant="h4" pb={1}>Store</Typography>
                    <Select
                      {...field}
                      fullWidth
                      id="sa-store"
                      value={field.value || ""}
                      error={!!meta.touched && !!meta.error}
                      disabled={disabled}
                    >
                      <MenuItem value="" disabled>Select an option</MenuItem>
                      {sourceVm.storeDropdownValues.map(({ item1, item2 }) => (
                        <MenuItem key={`store${item1}`} value={item1} >
                          <Box display="flex" gap={1}>
                            {item2}
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                    <FormikErrorMessage meta={meta} />
                  </FormControl>
                )}
              </Field>
            </Grid>
          )}
          <Grid item flex={1} gap={1} display={"flex"} flexDirection={"column"} >
            <Typography variant="h4" pr={4}>Profile Filters {!sourceVm.isFilterRequired && "(Optional)"}</Typography>
            <FieldArray
              name="userCreatedFilters"
              render={(arrayHelpers) =>
                values.userCreatedFilters?.map((orGroup, orIndex) => (
                  <SmartAutomationFilterGroup
                    key={`orGroup${orIndex}`}
                    disabled={disabled}
                    orGroup={orGroup}
                    orIndex={orIndex}
                    {...arrayHelpers}
                  />
                ))
              }
            />
            {!values.userCreatedFilters?.length && (
              <Box>
                <MDButton
                  disabled={disabled}
                  sx={{ width: 200 }}
                  onClick={() => setFieldValue('userCreatedFilters', [{ integrationOrderFilters: [emptyFilter] }])}
                >
                  Add Filter
                </MDButton>
                {sourceVm.isFilterRequired && getFieldMeta('userCreatedFilters').touched && (
                  <Typography sx={{ fontSize: '12px !important' }} color="error">
                    Required
                  </Typography>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Divider light />

      <Grid container gap={3}>
        <StepIndicator stepNumber={3} checked={true} />
        <Box>
          <Divider light orientation="vertical" />
        </Box>
        <Grid item flex={1}>
          <Typography variant="h4" pb={2}>
            Postage Type
          </Typography>
          <Field name="postageType">
            {({ field }: FieldProps) => (
              <FormControl disabled={disabled}>
                <RadioGroup value={field.value} onChange={(e) => setFieldValue('postageType', Number(e.target.value))}>
                  <RadioSelectWrapper selected={field.value === PostageTypeEnum.FirstClass}>
                    <FormControlLabel
                      control={<Radio />}
                      value={PostageTypeEnum.FirstClass}
                      label={
                        <Box display={'flex'} alignItems={'center'} gap={1}>
                          <Typography variant="h5">First Class Postage</Typography>
                          <Typography variant="h5" fontWeight={'normal'}>
                            (3-5 day USPS estimated delivery)
                          </Typography>
                          <Chip
                            label={orderProducUsesLetterUSPSPricing(values.template?.product) ? '+$0.15' : '+$0.05'}
                          />
                        </Box>
                      }
                    />
                  </RadioSelectWrapper>
                  <Box mt={1} />
                  <RadioSelectWrapper selected={field.value === PostageTypeEnum.Standard}>
                    <FormControlLabel
                      control={<Radio />}
                      value={PostageTypeEnum.Standard}
                      label={
                        <Box display={'flex'} alignItems={'center'} gap={1}>
                          <Typography variant="h5">Standard Postage</Typography>
                          <Typography variant="h5" fontWeight={'normal'}>
                            (10-14 day USPS estimated delivery)
                          </Typography>
                        </Box>
                      }
                    />
                  </RadioSelectWrapper>
                </RadioGroup>
              </FormControl>
            )}
          </Field>
        </Grid>
      </Grid>

      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} marginTop={2} justifyContent="flex-end">
        <MDButton disabled={isSubmitting || disabled} color="light" onClick={() => navigate('/smart-automation')}>
          Cancel
        </MDButton>
        <MDButton
          disabled={isSubmitting || disabled}
          type="submit"
          color="primary"
          onClick={() => !isValid && window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          {isSubmitting ? <CircularProgress color="secondary" size={20} /> : 'Save'}
        </MDButton>
      </Stack>
    </Form>
  );
};

interface SmartAutomationFormProps {
  disabled?: boolean;
  status?: IntegrationOrderStatus;
  onSubmit: (values: ISmartAutomationSchema) => void;
  initialValues: ISmartAutomationSchema;
}

const SmartAutomationForm = ({
  disabled,
  initialValues,
  onSubmit,
  status = IntegrationOrderStatus.Testing,
}: SmartAutomationFormProps) => {
  const { id: automationId } = useParams();
  const isEdit = automationId !== 'new';

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const source = queryParams.get('source');

  const [currentSource, setCurrentSource] = useState<number>();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [leadRevealModalProps, setLeadRevealModalProps] = useState<{
    open?: boolean;
    payload?: ISmartAutomationSchema;
  }>({});

  const { getViewModelByDataSource } = useSmartAutomationService();

  const {
    data: vmData,
    refetch: refetchVM,
    isLoading,
  } = useQuery({
    queryKey: ['getViewModelByDataSource', Number(source)],
    queryFn: () => getViewModelByDataSource(Number(source)),
    refetchOnWindowFocus: false,
  });

  const sourceVm: ISourceViewModel =
    typeof vmData?.payload === 'string' ? convertKeysToCamelCase(JSON.parse(vmData.payload)) : {};

  useEffect(() => {
    const newSource = Number(source);
    if (currentSource !== newSource) {
      setCurrentSource(newSource);
      refetchVM();
    }
  }, [currentSource, source, refetchVM]);

  const handleSubmit = (values: ISmartAutomationSchema) => {
    const shouldShowLRModal = !isEdit && sourceVm?.requiresConfirmation && !leadRevealModalProps.open;
    setLeadRevealModalProps({ open: shouldShowLRModal, payload: shouldShowLRModal ? values : undefined });
    const shouldShowConfirmationModal =
      currentSource === 2 && status !== IntegrationOrderStatus.Active && !showConfirmationModal;
    setShowConfirmationModal(shouldShowConfirmationModal);
    if (!shouldShowLRModal && !shouldShowConfirmationModal) onSubmit(values);
  };

  const handleCancelSubmit = (setSubmitting: (isSubmitting: boolean) => void) => {
    setShowConfirmationModal(false);
    setLeadRevealModalProps({});
    setSubmitting(false);
  };

  const customValidation = (values: ISmartAutomationSchema) => {
    if (values.template && values.template.hasDiscount && !values.multiUseCouponId && !values.couponListId) {
      return { multiUseCouponId: 'Required' };
    }
    return {};
  };

  if (vmData?.hasErrors) return <Alert severity="error">{vmData.errors[0]}</Alert>;
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <Loader />
      </Box>
    );
  }
  return (
    <MDCard>
      <Box width={'100%'} maxWidth={1300} mx={'auto'} p={4}>
        <Formik
          initialValues={initialValues || { segmentId: "", profileDataProviderId: "", template: null, userCreatedFilters: [], postageType: PostageTypeEnum.Standard }}
          validationSchema={smartAutomationValidation(!!sourceVm.isFilterRequired, currentSource)}
          onSubmit={handleSubmit}
          validate={customValidation}
          enableReinitialize
        >
          {(formikProps) => (
            <>
              <SmartAutomationFormContent disabled={disabled} {...formikProps} />
              <LeadRevealEnable
                {...leadRevealModalProps}
                onSubmit={() => handleSubmit(formikProps.values)}
                onCancel={() => handleCancelSubmit(formikProps.setSubmitting)}
              />
              <Dialog
                open={showConfirmationModal}
                onClose={() => handleCancelSubmit(formikProps.setSubmitting)}
                fullWidth
                maxWidth={'sm'}
              >
                <DialogContent>
                  <Stack spacing={2}>
                    <Typography variant="h4" fontSize={24}>
                      Test your Smart Automation
                    </Typography>
                    <Box component={'ul'} pl={3}>
                      <Typography component={'li'} fontSize={16}>
                        To verify that your Smart Automation is working, we're pulling the 20 most recent recipients
                        from your segment.
                      </Typography>
                      <Typography component={'li'} fontSize={16}>
                        Once testing is complete, please change the status to "Active" to start mailing.
                      </Typography>
                    </Box>
                    <Box display={'flex'} justifyContent={'space-between'} gap={2}>
                      <MDButton fullWidth onClick={() => handleSubmit(formikProps.values)}>
                        Start Testing
                      </MDButton>
                      <MDButton color="light" onClick={() => handleCancelSubmit(formikProps.setSubmitting)}>
                        Cancel
                      </MDButton>
                    </Box>
                  </Stack>
                </DialogContent>
              </Dialog>
            </>
          )}
        </Formik>
      </Box>
    </MDCard>
  );
};

export default SmartAutomationForm;
