export function downloadCsvResponse(result: any, fileName: string){
    let blob = new Blob([result], { type: 'application/octet-stream' });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function getFileName(headers: Headers){
    const contentDisposition = headers.get('Content-Disposition');
    if (contentDisposition) {
        return contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
    }
    return null;
}