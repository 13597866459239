import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CartIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2757_13562)">
                    <g clipPath="url(#clip1_2757_13562)">
                        <path
                            d="M8.44378 14.9298H8.4447C8.44546 14.9298 8.44622 14.9297 8.44699 14.9297H19.0703C19.3318 14.9297 19.5618 14.7562 19.6337 14.5047L21.9774 6.3016C22.0279 6.12475 21.9925 5.93463 21.8819 5.78784C21.7711 5.64105 21.5979 5.55469 21.414 5.55469H7.09246L6.67361 3.66977C6.61395 3.40167 6.37622 3.21094 6.10156 3.21094H2.58594C2.2623 3.21094 2 3.47324 2 3.79687C2 4.12051 2.2623 4.38281 2.58594 4.38281H5.63159C5.70575 4.71683 7.63598 13.403 7.74707 13.9028C7.12436 14.1735 6.6875 14.7943 6.6875 15.5156C6.6875 16.4849 7.47607 17.2734 8.44531 17.2734H19.0703C19.3939 17.2734 19.6562 17.0111 19.6562 16.6875C19.6562 16.3639 19.3939 16.1016 19.0703 16.1016H8.44531C8.12228 16.1016 7.85937 15.8386 7.85937 15.5156C7.85937 15.193 8.12136 14.9306 8.44378 14.9298ZM20.6372 6.72656L18.6283 13.7578H8.91528L7.35278 6.72656H20.6372Z"
                            fill="currentColor"/>
                        <path
                            d="M7.85938 19.0312C7.85938 20.0005 8.64795 20.7891 9.61719 20.7891C10.5864 20.7891 11.375 20.0005 11.375 19.0312C11.375 18.062 10.5864 17.2734 9.61719 17.2734C8.64795 17.2734 7.85938 18.062 7.85938 19.0312ZM9.61719 18.4453C9.94021 18.4453 10.2031 18.7082 10.2031 19.0312C10.2031 19.3543 9.94021 19.6172 9.61719 19.6172C9.29416 19.6172 9.03125 19.3543 9.03125 19.0312C9.03125 18.7082 9.29416 18.4453 9.61719 18.4453Z"
                            fill="currentColor"/>
                        <path
                            d="M16.1406 19.0312C16.1406 20.0005 16.9292 20.7891 17.8984 20.7891C18.8677 20.7891 19.6562 20.0005 19.6562 19.0312C19.6562 18.062 18.8677 17.2734 17.8984 17.2734C16.9292 17.2734 16.1406 18.062 16.1406 19.0312ZM17.8984 18.4453C18.2215 18.4453 18.4844 18.7082 18.4844 19.0312C18.4844 19.3543 18.2215 19.6172 17.8984 19.6172C17.5754 19.6172 17.3125 19.3543 17.3125 19.0312C17.3125 18.7082 17.5754 18.4453 17.8984 18.4453Z"
                            fill="currentColor"/>
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_2757_13562">
                        <rect width="24" height="24" fill="white"/>
                    </clipPath>
                    <clipPath id="clip1_2757_13562">
                        <rect width="20" height="20" fill="white" transform="translate(2 2)"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    )
}

export default CartIcon;