import { ITemplate } from 'models/template';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface ITemplateFlow {
  // TODO: remove any
  order?: any;
  showModal?: boolean;
  originalRoute?: string;
  onTemplateChange?: (template: ITemplate) => void;
  customCreateCallback?: () => void;
}

interface GlobalProps {
  navigationCallback: Function;
  setNavigationCallback: React.Dispatch<React.SetStateAction<Function>>;
  showLoader: boolean;
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>;
  templateFlow: ITemplateFlow;
  setTemplateFlow: React.Dispatch<React.SetStateAction<ITemplateFlow>>;
  allowDiscount: boolean;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isSidebarOpen?: boolean) => void;
}

const defaultGlobalContext: GlobalProps = {
  navigationCallback: Function,
  setNavigationCallback: () => {},
  showLoader: false,
  setShowLoader: () => {},
  templateFlow: {},
  setTemplateFlow: () => {},
  allowDiscount: false,
  isSidebarOpen: true,
  setIsSidebarOpen: () => {},
};

const GlobalContext = createContext<GlobalProps>(defaultGlobalContext);
GlobalContext.displayName = 'GlobalContext';

interface GlobalProviderProps {
  children: ReactNode;
}

export const GlobalProvider = ({ children }: GlobalProviderProps) => {
  const { pathname } = useLocation();
  const [navigationCallback, setNavigationCallback] = useState<Function>(null);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [templateFlow, setTemplateFlow] = useState<ITemplateFlow>({});
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);

  const allowDiscount =
    (templateFlow?.originalRoute?.includes('source=2') || //if Klaviyo
      (pathname.includes('template') && !templateFlow.originalRoute) || // if template creation
      pathname.includes('order/')); // if single order

  useEffect(() => {
    if (!pathname.includes('template')) setTemplateFlow({});
  }, [pathname]);

  return (
    <GlobalContext.Provider
      value={{
        navigationCallback,
        setNavigationCallback,
        showLoader,
        setShowLoader,
        templateFlow,
        setTemplateFlow,
        allowDiscount,
        isSidebarOpen,
        setIsSidebarOpen,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error('useGlobal must be used within a GlobalProvider');
  }
  return context;
};
