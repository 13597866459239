import {Dialog, DialogContent, Grid} from "@mui/material";
import MDBox from "material-ui/components/MDBox";
import MDTypography from "material-ui/components/MDTypography";
import MDCard from "material-ui/components/MDCard";
import React from "react";

interface PreviewCardImagesModalProps{
    show: boolean
    setShow: Function
    images: string[]
    text1?: string
    text2?: string
}

function PreviewCardImagesModal({show, setShow, text1, text2, images}: PreviewCardImagesModalProps){
    return <Dialog open={show} onClose={() => setShow(false)} maxWidth={false} fullWidth={true}>
        <DialogContent>
            <MDBox>
                {text1?
                    <MDTypography variant="h4" fontWeight="bold" color="text">
                        {text1}
                    </MDTypography>
                    :
                    null
                }

                {text2?
                    <MDTypography variant="button" color="text">
                        {text2}
                    </MDTypography>
                    :
                    null
                }
            </MDBox>

            <Grid container mt={4} gap={2} alignItems={"center"} justifyContent={"space-between"} flexWrap={"nowrap"}>
                {images.map((image: string, index: number) => {
                    return <Grid item key={index} xs={12 / images.length}>
                        <MDCard borderRadiusSize={0} borderSize={2} borderColor={"darkest"} border={true} boxShadow={false}>
                            <img alt={""} style={{width: "100%", height: "auto", borderRadius: "inherit"}} src={image}/>
                        </MDCard>
                    </Grid>
                })}
            </Grid>
        </DialogContent>
    </Dialog>
}

export default PreviewCardImagesModal