import colors from "material-ui/theme/base/colors";
import {Typography} from "@mui/material";

interface RecipientTagProps{
    name: string
}

function RecipientTag({name}: RecipientTagProps){
    return <Typography
        variant={"h5"}
        fontWeight={"normal"}
        sx={{
            padding: "5px",
            backgroundColor: colors.primary.main,
            color: "#ffffff",
            borderRadius: "8px",
            marginRight: "5px",
            display: "inline-block",
            marginTop: "5px",
            marginBottom: "5px",
            whiteSpace: "pre"
        }}
    >
        {name}
    </Typography>
}

export default RecipientTag