import {Grid} from "@mui/material";
import borders from "material-ui/theme/base/borders";
import MDTypography from "material-ui/components/MDTypography";
import MDBox from "material-ui/components/MDBox";
import React from "react";
import colors from "material-ui/theme/base/colors";

interface StatusTrackerProps {
    statuses: string[]
    currentStatus: string
}

function StatusTracker({statuses, currentStatus}: StatusTrackerProps) {
    const currentStatusColor = colors.primary.main
    const completedStatusColor = colors.secondary.main
    const futureStatusColor = "#929CB2"

    const statusIndex = statuses.findIndex((value, index, obj) => value === currentStatus)

    return <MDBox position={"relative"} width={"100%"}>
        <Grid container alignItems={"center"} justifyContent={"space-between"} position={"relative"} width={"100%"} overflow={"hidden"} sx={{borderRadius: borders.borderRadius.xxxl}}>
            {currentStatus === statuses[statuses.length - 1]?
                <Grid item flex={1} p={"6px"} sx={{backgroundColor: completedStatusColor}}>
                    <MDTypography variant={"h6"} color={"white"} textAlign={"center"} width={"100%"} fontWeight={"bold"}>
                        {currentStatus}
                    </MDTypography>
                </Grid>
                :
                statuses.map((status: string, index: number) => {
                    let backgroundColor = futureStatusColor

                    if (index < statusIndex) {
                        backgroundColor = completedStatusColor
                    } else if (index === statusIndex) {
                        backgroundColor = currentStatusColor
                    }

                    return <Grid key={index} item flex={1} p={"6px"} position={"relative"} sx={{backgroundColor: backgroundColor}} alignSelf={"stretch"}>
                        <MDTypography variant={"h6"} color={"light"} textAlign={"center"}
                                      fontWeight={index === statusIndex ? "bold" : "normal"} zIndex={2}
                                      position={"relative"}>{index >= statusIndex? status : ""}</MDTypography>

                        {index < statuses.length ?
                            <MDBox sx={{
                                position: "absolute",
                                width: 20,
                                height: 42,
                                right: -7,
                                top: -7,
                                zIndex: 1,
                                background: backgroundColor,
                                transform: "rotate(20deg)"
                            }}></MDBox>
                            :
                            null
                        }
                    </Grid>
                })
            }
        </Grid>
    </MDBox>
}

export default StatusTracker