
import { Box, Paper, SxProps, Theme } from "@mui/material";

interface TemplateScenePreviewProps {
  sceneFrontThumb: string
  sceneBackThumb: string
  envelopeFull: string
  variant?: "elevation" | "outlined"
  sx?: SxProps<Theme>
}

const TemplateScenePreview = ({ sceneFrontThumb, sceneBackThumb, envelopeFull, variant = "outlined", sx }: TemplateScenePreviewProps) => {
  const renderImage = (src: string) => (
    <Paper square variant={variant} elevation={4} sx={{ flex: 1, display: "flex" }}>
      <img alt="" src={src} style={{ width: "100%", height: "auto" }} />
    </Paper>
  )

  return (
    <Box display="flex" gap={1} flexWrap="nowrap" sx={sx}>
      {renderImage(sceneFrontThumb)}
      {renderImage(sceneBackThumb)}
      {envelopeFull && renderImage(envelopeFull)}
    </Box>
  )
}

export default TemplateScenePreview