import React from 'react';

const SocialLinkedIn = (props: any) => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="24" fill="#3375B0"/>
            <path
                d="M32.0002 31.9986V26.1386C32.0002 23.2586 31.3802 21.0586 28.0202 21.0586C26.4002 21.0586 25.3202 21.9386 24.8802 22.7786H24.8402V21.3186H21.6602V31.9986H24.9802V26.6986C24.9802 25.2986 25.2402 23.9586 26.9602 23.9586C28.6602 23.9586 28.6802 25.5386 28.6802 26.7786V31.9786H32.0002V31.9986Z"
                fill="#F3F5FB"/>
            <path d="M16.2617 21.3203H19.5817V32.0003H16.2617V21.3203Z" fill="#F3F5FB"/>
            <path
                d="M17.92 16C16.86 16 16 16.86 16 17.92C16 18.98 16.86 19.86 17.92 19.86C18.98 19.86 19.84 18.98 19.84 17.92C19.84 16.86 18.98 16 17.92 16Z"
                fill="#F3F5FB"/>
        </svg>
    )
}

export default SocialLinkedIn