import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const PostageIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_7103_73356)">
                    <path
                        d="M5 5.5C4.00544 5.5 3.05161 5.89509 2.34835 6.59835C1.64509 7.30161 1.25 8.25544 1.25 9.25V16.75H8.75V9.25C8.75 8.25544 8.35491 7.30161 7.65165 6.59835C6.94839 5.89509 5.99456 5.5 5 5.5ZM5 4.25H15C16.3261 4.25 17.5979 4.77678 18.5355 5.71447C19.4732 6.65215 20 7.92392 20 9.25V16.75C20 17.0815 19.8683 17.3995 19.6339 17.6339C19.3995 17.8683 19.0815 18 18.75 18H1.25C0.918479 18 0.600537 17.8683 0.366117 17.6339C0.131696 17.3995 0 17.0815 0 16.75V9.25C0 7.92392 0.526784 6.65215 1.46447 5.71447C2.40215 4.77678 3.67392 4.25 5 4.25ZM8.3075 5.5C8.8401 5.96872 9.26658 6.54568 9.55844 7.19235C9.8503 7.83902 10.0008 8.54052 10 9.25V16.75H18.75V9.25C18.75 8.25544 18.3549 7.30161 17.6517 6.59835C16.9484 5.89509 15.9946 5.5 15 5.5H8.3075Z"
                        fill="#130B46"/>
                    <path
                        d="M14.7413 11.125H11.25V9.875H17.5C17.6658 9.875 17.8247 9.94085 17.9419 10.0581C18.0592 10.1753 18.125 10.3342 18.125 10.5V11.75C18.125 11.9158 18.0592 12.0747 17.9419 12.1919C17.8247 12.3092 17.6658 12.375 17.5 12.375H16.25C16.1679 12.3751 16.0865 12.3591 16.0106 12.3278C15.9347 12.2965 15.8656 12.2505 15.8075 12.1925L14.7413 11.125ZM6.25 9.25C6.25 9.94 5.69 9.25 5 9.25C4.31 9.25 3.75 9.94 3.75 9.25C3.75 8.91848 3.8817 8.60054 4.11612 8.36612C4.35054 8.1317 4.66848 8 5 8C5.33152 8 5.64946 8.1317 5.88388 8.36612C6.1183 8.60054 6.25 8.91848 6.25 9.25Z"
                        fill="#130B46"/>
                </g>
                <defs>
                    <clipPath id="clip0_7103_73356">
                        <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    )
}

export default PostageIcon;