import {Dialog, DialogContent, Grid, Stack} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import React, {useEffect, useState} from "react";
import MDButton from "material-ui/components/MDButton";
import {
    GridColDef,
    GridValueGetterParams
} from "@mui/x-data-grid-pro";
import MDInput from "material-ui/components/MDInput";
import {RecipientSearchStatus} from "models/recipient-search/recipientSearchStatus";
import {formatDate, formatPrice} from "helpers/formatters";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import OrderRecipientService from "services/order-recipient";
import StyledDataGrid from "components/styled-data-grid";
import RecipientSearchService from "services/recipient-search";
import MDBox from "material-ui/components/MDBox";
import {RecipientSearch} from "models/recipient-search/recipientSearch";
import { useGlobal } from "context/global-context";

interface AddRecipientsFromRecipientSearchModalProps {
    show: boolean
    setShow: Function
    orderId: number
    setShowGrid: Function
}

function AddRecipientsFromRecipientSearchModal({show, setShow, orderId, setShowGrid}: AddRecipientsFromRecipientSearchModalProps) {
    let [search, setSearch] = useState<string>("")
    let [selectedRows, setSelectedRows] = useState([])

    let columns: GridColDef[] = [
        {
            headerName: "Last Modified Date",
            field: "updatedDate",
            width: 150,
            valueFormatter: (params) => {
                return formatDate(params.value)
            },
        },
        {
            headerName: "Search Name",
            field: "name",
            width: 350,
            flex: 1
        },
        {
            headerName: "Status",
            field: "status",
            width: 200,
            valueGetter: function (params: GridValueGetterParams) {
                let statusStr = ""

                switch (params.row.status) {
                    case RecipientSearchStatus.New:
                        statusStr = "Draft"
                        break
                    case RecipientSearchStatus.ProcessingCount:
                        statusStr = "Calculating Recipients"
                        break
                    case RecipientSearchStatus.ReadyForPayment:
                        statusStr = "Checkout"
                        break
                    case RecipientSearchStatus.ProcessingOrder:
                        statusStr = "Processing Order"
                        break
                    case RecipientSearchStatus.Complete:
                        statusStr = "Complete"
                        break
                    case RecipientSearchStatus.Error:
                        statusStr = "Error"
                        break
                    case RecipientSearchStatus.UnexpectedError:
                        statusStr = "Error"
                        break
                    default:
                        break
                }

                return statusStr
            },
        },
        {
            headerName: "Recipients",
            field: "totalDesiredQuantity",
            width: 200
        },
        {
            headerName: "Total Cost",
            field: "totalPrice",
            width: 200,
            valueFormatter: (params) => {
                return params.value ? `${formatPrice(params.value)}` : null
            },
        }
    ]

    const queryClient = useQueryClient()
    const {setShowLoader} = useGlobal()

    const {addFromRecipientSearch} = OrderRecipientService()
    const {getRecipientSearches} = RecipientSearchService()

    const recipientSearchesQuery = useQuery({
        queryKey: ["recipientSearches"],
        queryFn: getRecipientSearches
    })

    const addFromRecipientSearchMutation = useMutation({
        mutationFn: () => {
            return addFromRecipientSearch(orderId, selectedRows)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: () => {
            setShowLoader(false)
            setShowGrid(true)
            setShow(false)
            queryClient.invalidateQueries({queryKey: ["orderRecipients", orderId]})
        }
    })

    useEffect(() => {
        if(!show){
            setSelectedRows([])
        }
    }, [show]);

    return <Dialog open={show} onClose={() => setShow(false)} maxWidth={false}>
        <DialogContent sx={{width: "1500px"}}>
            <DialogTitle>
                Add recipients from your recipient searches
            </DialogTitle>

            <Grid container justifyContent={"flex-end"}>
                <Grid item>
                    <MDInput
                        width={"14rem"}
                        placeholder="Search..."
                        fullWidth
                        value={search}
                        onChange={({currentTarget}: any) => {
                            let val = currentTarget.value

                            setSearch(val)
                        }}
                    />
                </Grid>
            </Grid>

            <MDBox height={"55vh"} mt={2}>
                <StyledDataGrid
                    loading={recipientSearchesQuery.isLoading}
                    rows={recipientSearchesQuery.data?.payload.filter((a: RecipientSearch) => a.status === RecipientSearchStatus.Complete)?? []}
                    columns={columns}
                    pagination={true}
                    initialState={{
                        pagination: {paginationModel: {pageSize: 25}},
                    }}
                    pageSizeOptions={[25, 50, 100]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={(selectionModel: any) => {
                        setSelectedRows(selectionModel)
                    }}
                    rowSelectionModel={selectedRows}
                />
            </MDBox>

            <Stack
                direction="row"
                spacing={1}
                marginTop={2}
                justifyContent="flex-end"
            >
                <MDButton
                    circular={true}
                    color="light"
                    onClick={() => setShow(false)}
                >
                    Cancel
                </MDButton>
                <MDButton
                    circular={true}
                    color="primary"
                    onClick={() => {
                        addFromRecipientSearchMutation.mutate()
                    }}
                    disabled={!selectedRows.length || addFromRecipientSearchMutation.isPending}
                >
                    Save
                </MDButton>
            </Stack>
        </DialogContent>
    </Dialog>
}

export default AddRecipientsFromRecipientSearchModal;