import { Field, FieldProps } from 'formik';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';

import FormikErrorMessage from 'components/formik-error-message';
import { States } from 'models/enums/States';

export default function AddressFormikFields() {
  const fields = [
    { name: 'firstName', label: 'First Name', md: 6 },
    { name: 'lastName', label: 'Last Name', md: 6 },
    { name: 'email', label: 'Email', md: 12 },
    { name: 'address1', label: 'Address 1', md: 12 },
    { name: 'address2', label: 'Address 2', md: 12 },
    { name: 'city', label: 'City', md: 5 },
  ];

  return (
    <Grid container spacing={1}>
      {fields.map((f) => (
        <Field name={f.name}>
          {({ field, meta }: FieldProps) => (
            <Grid item xs={12} md={f.md}>
              <Typography variant="h5">{f.label}</Typography>
              <TextField {...field} fullWidth />
              <FormikErrorMessage meta={meta} />
            </Grid>
          )}
        </Field>
      ))}

      <Field name={'state'}>
        {({ field, meta, form }: FieldProps) => (
          <Grid item xs={6} md={3}>
            <Typography variant="h5">State</Typography>
            <Autocomplete
              value={field.value}
              options={Object.keys(States)}
              onChange={(_, value) => form.setFieldValue('state', value)}
              onInputChange={(_, value) => field.value !== value && States[value] && form.setFieldValue('state', value)}
              renderInput={(props) => <TextField {...props} fullWidth />}
            />
            <FormikErrorMessage meta={meta} />
          </Grid>
        )}
      </Field>

      <Field name={'zipCode'}>
        {({ field, meta }: FieldProps) => (
          <Grid item xs={6} md={4}>
            <Typography variant="h5">Zip Code</Typography>
            <TextField {...field} fullWidth />
            <FormikErrorMessage meta={meta} />
          </Grid>
        )}
      </Field>
    </Grid>
  );
}
