import { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Formik, FormikProps } from 'formik';
import { Box, Divider, Grid, MenuItem, Paper, Select, Tooltip, Typography } from '@mui/material';
import { Order } from 'models/order';
import MDInput from 'material-ui/components/MDInput';
import ProfileReturnAddress from 'services/profile-return-address';
import { ReturnAddress } from 'models/returnAddress';
import { OrderHelper } from 'helpers/OrderHelper';
import MDButton from 'material-ui/components/MDButton';
import ReturnAddressModal from './components/return-address-modal';
import EditIcon from 'assets/icons/edit';
import CardCanvas from 'components/img-ly/card-canvas';
import { ApiResponse } from 'models/apiResponse';
import LayoutMain from '../../layout-main';
import { orderProductIsPrintedCard } from 'models/enums/ProductTypes';
import { AddressToReturn } from 'models/modelValidations/returnAddress';
import ReturnAddressForm from './components/return-address-form';
import useReturnAddressHandler from 'hooks/useReturnAddressHandler';
import { useEditor } from 'context/editor-context';
import IncrementDecrementButton from 'components/increment-decrement-button';
import { useGlobal } from 'context/global-context';

interface ReturnAddressSubpageProps {
  order: Order;
  setOrder: Function;
  onBack: Function;
  onNext: Function;
}

const defaultReturnValues = {
  returnFirstName: '',
  returnLastName: '',
  returnOrganization: '',
  returnCity: '',
  returnState: '',
  returnZip: '',
  returnAddress1: '',
  returnAddress2: '',
};

const ReturnAddressSubpage = ({ order, setOrder, onNext, onBack }: ReturnAddressSubpageProps) => {
  const formRef = useRef<FormikProps<typeof defaultReturnValues>>();
  const { getProfileReturnAddresses } = ProfileReturnAddress();
  const { setShowLoader } = useGlobal();
  const { setReturnAddressScene } = useReturnAddressHandler();
  const { sceneIsLoaded, creativeEngine } = useEditor();

  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [submitType, setSubmitType] = useState<string>('');
  const [selectedReturnAddress, setSelectedReturnAddress] = useState<ReturnAddress>(null);
  const [returnAddressToManage, setReturnAddressToManage] = useState<ReturnAddress>(null);
  const [showReturnAddressModal, setShowReturnAddressModal] = useState<boolean>(false);

  const { data } = useQuery<ApiResponse<ReturnAddress[]>>({
    queryKey: ['returnAddresses'],
    queryFn: getProfileReturnAddresses,
    refetchOnWindowFocus: false,
  });
  const addresses = data?.payload;

  const handleReturnAddressChange = useCallback(
    (returnAddress: ReturnAddress | null) => {
      setSelectedReturnAddress(returnAddress);
      const newFormValue = {
        returnFirstName: returnAddress?.firstName || '',
        returnLastName: returnAddress?.lastName || '',
        returnOrganization: returnAddress?.toOrganization || '',
        returnCity: returnAddress?.city || '',
        returnState: returnAddress?.state || '',
        returnZip: returnAddress?.zip || '',
        returnAddress1: returnAddress?.address1 || '',
        returnAddress2: returnAddress?.address2 || '',
      };
      setReturnAddressScene(newFormValue);
      formRef.current?.setValues(newFormValue);
    },
    [setReturnAddressScene]
  );

  useEffect(() => {
    if (addresses && selectedReturnAddress) {
      let currentReturnAddress = addresses.find((address) => address.id === selectedReturnAddress.id);
      if (!currentReturnAddress) currentReturnAddress = addresses.find((address) => address.isDefault);
      handleReturnAddressChange(currentReturnAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses]);

  useEffect(() => {
    if (isFirstLoad && order && addresses && sceneIsLoaded && creativeEngine) {
      if (order.returnAddress1) {
        setSelectedReturnAddress(null);
        const returnAddressFromOrder = {
          returnFirstName: order.returnFirstName || '',
          returnLastName: order.returnLastName || '',
          returnOrganization: order.returnOrganization || '',
          returnCity: order.returnCity || '',
          returnState: order.returnState || '',
          returnZip: order.returnZip || '',
          returnAddress1: order.returnAddress1 || '',
          returnAddress2: order.returnAddress2 || '',
        };
        setReturnAddressScene(returnAddressFromOrder);
        formRef.current?.setValues(returnAddressFromOrder);
      } else {
        const defaultReturnAddress = addresses.find((address) => address.isDefault);
        handleReturnAddressChange(defaultReturnAddress);
      }
      setIsFirstLoad(false);
    }
  }, [addresses, creativeEngine, handleReturnAddressChange, isFirstLoad, order, sceneIsLoaded, setReturnAddressScene]);

  useEffect(() => {
    if (submitType) {
      formRef.current?.handleSubmit();
      setSubmitType('');
    }
  }, [submitType]);

  const renderAdornment = (type: 'minus' | 'plus') => (
    <IncrementDecrementButton
      type={type}
      value={order.fontSizeReturnAddress}
      minValue={OrderHelper.getMinFontsSizeForReturnAddress(order.product)}
      maxValue={OrderHelper.getMaxFontsSizeForReturnAddress(order.product)}
      setValue={(newValue) => setOrder((prev: Order) => ({ ...prev, fontSizeReturnAddress: newValue }))}
    />
  );

  return (
    <LayoutMain
      showBackButton
      showNextButton
      backButtonOnClick={() => setSubmitType('back')}
      nextButtonOnClick={() => setSubmitType('next')}
    >
      <Grid container>
        <Grid item xs={12} lg={6}>
          <Paper variant="outlined" sx={{ p: 2, mr: 1 }}>
            <Typography fontWeight={'bold'}>Return Address</Typography>
            <Box display="flex" alignItems={'center'} gap={1} mt={2}>
              <Select value={selectedReturnAddress?.name ?? 'Custom'} renderValue={(value) => value}>
                <MenuItem
                  value={'Custom'}
                  onClick={() => selectedReturnAddress !== null && handleReturnAddressChange(null)}
                >
                  Custom
                </MenuItem>
                {addresses?.map((address: ReturnAddress) => (
                  <MenuItem
                    key={address.id}
                    value={address.id}
                    onClick={() => handleReturnAddressChange(address)}
                    sx={{ justifyContent: 'space-between' }}
                  >
                    {address.name}
                    <Tooltip title={'Edit Address'}>
                      <EditIcon
                        onClick={() => {
                          setReturnAddressToManage(address);
                          setShowReturnAddressModal(true);
                        }}
                      />
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>

              <MDButton
                sx={{ minWidth: 120 }}
                onClick={() => {
                  setReturnAddressToManage(null);
                  setShowReturnAddressModal(true);
                }}
              >
                Add new
              </MDButton>

              {!orderProductIsPrintedCard(order.product) && (
                <MDInput
                  value={order.fontSizeReturnAddress}
                  InputProps={{ startAdornment: renderAdornment('minus'), endAdornment: renderAdornment('plus') }}
                  inputProps={{ disabled: true, sx: { textAlign: 'center', paddingRight: 0 } }}
                />
              )}
            </Box>

            <Divider light />

            <Formik
              innerRef={formRef}
              initialValues={defaultReturnValues}
              validationSchema={AddressToReturn}
              validate={(values) => {
                const errors: any = {};
                const emailRegex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
                Object.entries(values).forEach(([field, value]) => {
                  if (emailRegex.test(value)) return (errors[field] = 'Should not contain an email address');
                });
                return errors;
              }}
              onSubmit={() => {
                setShowLoader(true);
                setOrder((prev: Order) => ({ ...prev, ...(formRef.current?.values || defaultReturnValues) }));
                submitType === 'back' ? onBack() : onNext();
              }}
            >
              {({ values, setFieldValue, isSubmitting }) => (
                <ReturnAddressForm values={values} setFieldValue={setFieldValue} isSubmitting={isSubmitting} />
              )}
            </Formik>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6} pl={2}>
          <CardCanvas editorType={'Envelope'} />
        </Grid>
      </Grid>

      <ReturnAddressModal
        show={showReturnAddressModal}
        setShow={setShowReturnAddressModal}
        returnAddress={returnAddressToManage}
        isFirst={!addresses?.length || (returnAddressToManage?.isDefault && addresses?.length === 1)}
      />
    </LayoutMain>
  );
};

export default ReturnAddressSubpage;
