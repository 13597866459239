// @ts-ignore
import Retool from "react-retool"
import MDBox from "material-ui/components/MDBox";

function AdminPricingTiersPage(){
    return <MDBox style={{ height: "90vh" }}>
        <Retool url="https://lettrlabs.retool.com/embedded/public/10beb39b-30bb-49eb-8875-d7b89d032bbc" />
    </MDBox>
}

export  default AdminPricingTiersPage