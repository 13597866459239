import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CloseIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3654_20685)">
                    <path d="M7 17L17 7M7 7L17 17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_3654_20685">
                        <rect width="24" height="24" fill="#F3F5FB"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    )
}

export default CloseIcon;
