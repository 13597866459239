import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const HomeEditIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 18.0007V13.0007C7.5 12.5586 7.6756 12.1347 7.98816 11.8221C8.30072 11.5096 8.72464 11.334 9.16667 11.334H10.8333C11.3708 11.334 11.8483 11.5882 12.1533 11.984" stroke="#130B46" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M16.6667 9.66667L10 3L2.5 10.5H4.16667V16.3333C4.16667 16.7754 4.34226 17.1993 4.65482 17.5118C4.96738 17.8244 5.39131 18 5.83333 18H9.16667M15.35 13.5083C15.5125 13.3458 15.7054 13.2169 15.9178 13.129C16.1301 13.041 16.3577 12.9957 16.5875 12.9957C16.8173 12.9957 17.0449 13.041 17.2572 13.129C17.4696 13.2169 17.6625 13.3458 17.825 13.5083C17.9875 13.6708 18.1164 13.8638 18.2044 14.0761C18.2923 14.2884 18.3376 14.516 18.3376 14.7458C18.3376 14.9757 18.2923 15.2032 18.2044 15.4156C18.1164 15.6279 17.9875 15.8208 17.825 15.9833L15 18.8333H12.5V16.3333L15.35 13.5083Z" stroke="#130B46" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export default HomeEditIcon;