import {Order} from "models/order";
import LayoutMain from "pages/order/components/layout-main";
import MailboxBigIcon from "assets/icons/mailbox-big";
import MDBox from "material-ui/components/MDBox";
import MDTypography from "material-ui/components/MDTypography";
import {Divider, Grid} from "@mui/material";
import MDButton from "material-ui/components/MDButton";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import CreateOrderFlowSmall from "components/create-order-flow-small";

interface SuccessSubpageProps {
    order: Order
}

function SuccessSubpage({order}: SuccessSubpageProps) {
    const navigate = useNavigate()

    const [showCreateOrderModal, setShowCreateOrderModal] = useState<boolean>(false)

    return <LayoutMain>
        <MDBox display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} mt={6}>
            <MDBox textAlign={"center"} maxWidth={"500px"}>
                <MailboxBigIcon sx={{width: "44px", height: "44px"}}/>

                <MDTypography mt={2} variant={"h2"} color={"black"}>Thank You!</MDTypography>

                <MDTypography variant={"h4"} fontWeight={"regular"} mt={4}>Your order {order.name} has been placed!</MDTypography>

                <Grid container gap={1} mt={4} justifyContent={"center"}>
                    <Grid item>
                        <MDButton color={"light"} size={"large"} circular={true} onClick={() => {
                            setShowCreateOrderModal(true)
                        }}>Create New Order</MDButton>
                    </Grid>

                    <Grid item>
                        <MDButton color={"primary"} size={"large"} circular={true} onClick={() => {
                            navigate('/orders')
                        }}>View Order</MDButton>
                    </Grid>
                </Grid>
            </MDBox>

            <MDBox my={6} width={"100%"}>
                <Divider light={true}/>
            </MDBox>

            <MDBox textAlign={"center"} maxWidth={"500px"}>
                <MDTypography variant={"h5"} fontWeight={"normal"} color={"secondary"}>
                    If you have any questions or encountered any issues you can contact us at:
                </MDTypography>

                <MDTypography variant={"h5"} fontWeight={"normal"} color={"primary"}>
                    <a href={"mailto: billing@lettrlabs.com"} style={{color: "inherit"}}>billing@lettrlabs.com</a>
                </MDTypography>
            </MDBox>
        </MDBox>

        <CreateOrderFlowSmall
            show={showCreateOrderModal}
            setShow={setShowCreateOrderModal}
        />
    </LayoutMain>
}

export default SuccessSubpage