import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT from "../endpoint";
import {ServiceArea} from "models/hail-trace/ServiceArea";

function StormAutomationService(){
    const {execute} = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/HailTraceProfileServiceAreas`

    function getServiceArea(serviceAreaId: number){
        return execute({
            endpoint: `${API_PATH}/${serviceAreaId}`,
            method: "GET"
        })
    }

    function postServiceArea(serviceArea: ServiceArea){
        return execute({
            endpoint: `${API_PATH}`,
            method: "POST",
            data: serviceArea
        })
    }

    function putServiceAreaStatus(serviceArea: ServiceArea){
        return execute({
            endpoint: `${API_PATH}:status`,
            method: "PUT",
            data: serviceArea
        })
    }

    function putServiceArea(serviceArea: ServiceArea){
        if(serviceArea.id > 0){
            return execute({
                endpoint: `${API_PATH}`,
                method: "PUT",
                data: serviceArea
            })
        }
    }

    function copyServiceArea(serviceArea: ServiceArea){
        return execute({
            endpoint: `${API_PATH}:copy`,
            method: "POST",
            data: serviceArea
        })
    }

    function deleteServiceArea(serviceAreaId: number){
        return execute({
            endpoint: `${API_PATH}/${serviceAreaId}`,
            method: "DELETE"
        })
    }

    function getServiceAreas(){
        return execute({
            endpoint: `${API_PATH}`,
            method: "GET"
        })
    }

    return {getServiceArea, postServiceArea, putServiceAreaStatus, putServiceArea, copyServiceArea, deleteServiceArea, getServiceAreas}
}

export default StormAutomationService