import MDBox from "material-ui/components/MDBox"
import Loader from "../loader";

function DataGridSpinner() {
	return (
		<MDBox sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#ffffff9c" }}>
			<Loader/>
		</MDBox>
	)
}

export default DataGridSpinner
