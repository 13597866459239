import SvgIcon from '@mui/material/SvgIcon';

const EyeHiddenIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.81633 6.8525C3.03671 7.77265 2.45617 8.84434 2.11133 10C3.188 13.615 6.53633 16.25 10.4997 16.25C11.3272 16.25 12.1272 16.135 12.8855 15.9208M5.68966 5.19C7.11685 4.24817 8.78973 3.74735 10.4997 3.75C14.463 3.75 17.8105 6.385 18.8872 9.99833C18.2971 11.9727 17.0305 13.6763 15.3097 14.81M5.68966 5.19L2.99966 2.5M5.68966 5.19L8.73133 8.23167M15.3097 14.81L17.9997 17.5M15.3097 14.81L12.268 11.7683C12.5002 11.5362 12.6843 11.2605 12.81 10.9572C12.9356 10.6539 13.0003 10.3287 13.0003 10.0004C13.0003 9.67208 12.9356 9.34697 12.81 9.04363C12.6843 8.74029 12.5002 8.46467 12.268 8.2325C12.0358 8.00033 11.7602 7.81617 11.4569 7.69052C11.1535 7.56487 10.8284 7.5002 10.5001 7.5002C10.1717 7.5002 9.84663 7.56487 9.54329 7.69052C9.23995 7.81617 8.96433 8.00033 8.73216 8.2325M12.2672 11.7675L8.733 8.23333" stroke="#929CB2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export default EyeHiddenIcon