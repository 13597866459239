import MDCard from "material-ui/components/MDCard";
import { Box, Chip, Grid, SxProps, Tooltip, Typography } from "@mui/material";
import MDTypography from "material-ui/components/MDTypography";
import MDButton from "material-ui/components/MDButton";
import { Order } from "models/order";
import { useQuery } from "@tanstack/react-query";
import Info2Icon from "assets/icons/info-2";
import FlipIcon from "assets/icons/flip";
import CardCanvas from "components/img-ly/card-canvas";
import { ReactNode, useEffect, useRef, useState } from "react";
import WarningIcon from "assets/icons/warning";
import { useEditor } from "context/editor-context";
import { YesNo } from "models/enums/yesNo";
import {
	getExtraCharactersCountForProduct,
	getIncludedCharacterCountForProduct,
	getMaxCharacterCountForProduct, getPriceForAdditionalCharacters, mailMergeFields
} from "helpers/OrderHelper";
import { orderProductIsPrintedCard, ProductTypes } from "models/enums/ProductTypes";
import OrderService from "services/order";
import LayoutMain from "../../layout-main";
import { OrderParameter } from "models/orderParameter";
import TemplateIcon from "assets/icons/template";
import { formatPrice } from "helpers/formatters";
import InfoModal from "components/info-modal";
import { OrderDefaults } from "models/enums/OrderDefaults";
import DesignSubpageLeftSide from "./components/design-subpage-left-side";
import TooltipTutorial from "components/tooltip-tutorial";
import { TooltipTutorialtype } from "components/tooltip-tutorial/tooltip-tutorial.util";
import canvaLogo from "assets/images/canva-logo.png";
import ProfileService from "services/profile";

interface DesignSubpageProps {
	order: Order
	setOrder: Function
	orderParameters: OrderParameter[]
	setOrderParameters: Function
	onNext: Function
	onBack: Function
	hasSceneWarnings: boolean
	setHasSceneWarnings: Function
	showErrorModal: boolean
	setShowErrorModal: Function
}

interface SceneError {
	icon: ReactNode
	shortDescription: string
	longDescription: string | ReactNode
	additionalStyles?: SxProps
}

const canvaUrls: { [key: string]: string } = {
	[ProductTypes.PrintedPostcard4x6]: "https://www.canva.com/design/DAGHXtYzF18/G66n89YUYopEs8hUuibm3g/view?utm_content=DA[…]share&utm_medium=link&utm_source=publishsharelink&mode=preview",
	[ProductTypes.PrintedPostcard6x9]: "https://www.canva.com/design/DAGHT6JGWAY/pO7InGOAI_OstmgKXuNt6g/view?utm_content=DA[…]share&utm_medium=link&utm_source=publishsharelink&mode=preview",
	[ProductTypes.PrintedPostcard6x11]: "https://www.canva.com/design/DAGHXsO1iwI/Mbb4BeYcV3tAVNNpyIOkDQ/view?utm_content=DA[…]share&utm_medium=link&utm_source=publishsharelink&mode=preview",
	[ProductTypes.LargeHandwrittenCardA8]: "https://www.canva.com/design/DAGHX5G9G0Y/XzeloLzVVGqN2_zqtigxXg/view?utm_content=DA[…]share&utm_medium=link&utm_source=publishsharelink&mode=preview",
	[ProductTypes.HandwrittenPostCardA8]: "https://www.canva.com/design/DAGHX24AM60/igz6x4lwdO52oihBp5i4pg/view?utm_content=DA[…]share&utm_medium=link&utm_source=publishsharelink&mode=preview",
	[ProductTypes.HandwrittenBiFoldCard]: "https://www.canva.com/design/DAGKxaflIio/f3SN3xK1rNsIj37AXyda2Q/view?utm_content=DA[…]share&utm_medium=link&utm_source=publishsharelink&mode=preview",
}

function DesignSubpage({ order, setOrder, orderParameters, setOrderParameters, onNext, onBack, hasSceneWarnings, setHasSceneWarnings, showErrorModal, setShowErrorModal }: DesignSubpageProps) {
	const {
		currentStep,
		setCurrentStep,
		lowestFoundResolution,
		isRearImageLowResolution,
		isThereImageBetweenSafeZoneAndBleedZone,
		hasInvalidMailMergePlaceholders,
		hasInvalidImageSize,
		hasTextOutsideOfHandwrittenTextArea,
		hasMissingRearImage,
		hasMissingOutsideFrontImage
	} = useEditor()

	const canvasContainerRef = useRef(null)
	const [canvasContainerHeight, setCanvasContainerHeight] = useState<number>(0)

	useEffect(() => {
		setCanvasContainerHeight(canvasContainerRef.current.offsetWidth * 0.83)
	}, []);

	const { getOrderParameters } = OrderService()
	const { getProfile } = ProfileService()

	const [noTextWarning, setNoTextWarning] = useState<boolean>(false)
	const [defaultTextWarning, setDefaultTextWarning] = useState<boolean>(false)

	const [currentCharacters, setCurrentCharacters] = useState<number>(0)
	const [maxCharacters, setMaxCharacters] = useState<number>(0)
	const [includedCharacters, setIncludedCharacters] = useState<number>(0)
	const [extraCharactersForDivision, setExtraCharactersForDivision] = useState<number>(0)
	const [priceForAdditionalCharacters, setPriceForAdditionalCharacters] = useState<number>(0)

	const [inExtraCharacterMode, setInExtraCharacterMode] = useState(false)

	const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)

	const [sceneErrors, setSceneErrors] = useState<SceneError[]>([])

	const [expandErrors, setExpandErrors] = useState<boolean>()

	const getOrderParametersQuery = useQuery({
		queryKey: ["orderParameters", order.id],
		queryFn: () => {
			return getOrderParameters(order.id)
		},
		refetchOnWindowFocus: false
	})

	const { data: profile } = useQuery({
		queryKey: ["profile"],
		queryFn: () => getProfile(),
		refetchOnWindowFocus: false
	})

	//create a useeffect that runs on first page load, if order.orderVerified = true, set validationHandler to empty string
	useEffect(() => {
		//Printed card has no order.text
		if (!orderProductIsPrintedCard(order.product)) {
			if (!order.text && !order.text2) {
				setNoTextWarning(true)
			}
			else if (order.text === OrderDefaults.OrderText || (order.text2 === OrderDefaults.OrderText2 && order.product === ProductTypes.HandwrittenBiFoldCard)) {
				setDefaultTextWarning(true)
			}
			else {
				setNoTextWarning(false)
				setDefaultTextWarning(false)
			}
		}
		else {
			setNoTextWarning(false)
			setDefaultTextWarning(false)
		}

		let totalTextLength = order.text.length 
		if(order.product === ProductTypes.HandwrittenBiFoldCard) totalTextLength = totalTextLength + (order.text2?.length ?? 0)
		//Check on text length, display warning about extra cost if over max
		setCurrentCharacters(totalTextLength)

		var includedChars = getIncludedCharacterCountForProduct(order.product)
		if (totalTextLength >= includedChars && order.product !== ProductTypes.HandwrittenPostCardA8) {
			setInExtraCharacterMode(true)
		}
		else {
			setInExtraCharacterMode(false)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order.doubleSided, order.text, order.text2, order.product])

	useEffect(() => {
		if (order && order.product) {
			setIncludedCharacters(getIncludedCharacterCountForProduct(order.product))
			setMaxCharacters(getMaxCharacterCountForProduct(order.product))
			setExtraCharactersForDivision(getExtraCharactersCountForProduct(order.product))
			setPriceForAdditionalCharacters(getPriceForAdditionalCharacters(order.product))
		}
	}, [order, order.product])

	useEffect(() => {
		let errors: SceneError[] = []

		let canTheUserProceed = true

		if (defaultTextWarning) {
			errors.push({
				icon: <WarningIcon color={"error"} />,
				shortDescription: "Change Default Text",
				longDescription: "Please change the default text of your card"
			})

			canTheUserProceed = false
		}
		if (noTextWarning) {
			errors.push({
				icon: <WarningIcon color={"error"} />,
				shortDescription: "No Text",
				longDescription: "Please add some text to your card"
			})

			canTheUserProceed = false
		}
		if (isThereImageBetweenSafeZoneAndBleedZone) {
			errors.push({
				icon: <TemplateIcon color={"error"} />,
				shortDescription: "Full Bleed Issue",
				longDescription: <ul style={{ paddingLeft: "20px" }}>
					<li>Your card design must be adjusted to avoid uneven white space around your card.</li>
					<li><span style={{ fontWeight: "bold" }}>How to Fix:</span> Adjust your image to dotted purple Full Bleed lines. Please note due to variation with card cutting please keep important portions of your image (text and images) within the green dotted safe zone lines</li>
				</ul>,
				additionalStyles: {
					maxWidth: "unset",
					textAlign: "left",
					width: "400px"
				}
			})

			canTheUserProceed = false
		}
		if (lowestFoundResolution < 250) {
			let message1 = ""
			let message2 = ""

			if (lowestFoundResolution >= 200) {
				message1 = "Low Image Resolution"
				message2 = "Low Image Resolution: 200-249 DPI. We recommend improving the image DPI to avoid blurry images once printed. Reduce the image size to improve the DPI or replace with a higher resolution image"
			}
			else {
				message1 = "Bad Image Resolution"
				message2 = "Unacceptable Image Resolution: Less then 200 DPI. Image will appear pixelated or blurry once printed. Reduce the image size to improve the DPI or replace with a higher resolution image"
			}

			if (isRearImageLowResolution) {
				canTheUserProceed = false
			}

			errors.push({
				icon: <TemplateIcon color={isRearImageLowResolution ? "error" : "warning"} />,
				shortDescription: message1,
				longDescription: message2
			})
		}
		if (hasInvalidMailMergePlaceholders) {
			errors.push({
				icon: <WarningIcon color={"error"} />,
				shortDescription: "Invalid usage of mail merge fields",
				longDescription: "Invalid Mail-Merge field: Refrain from typing your own text inside the {{brackets}}. Please use the Mail-Merge buttons instead",
			})

			canTheUserProceed = false
		}
		if (hasInvalidImageSize) {
			errors.push({
				icon: <WarningIcon color={"error"} />,
				shortDescription: "Image too large",
				longDescription: "We do not currently support images over 25 megabytes in size",
			})
		}
		if (hasTextOutsideOfHandwrittenTextArea) {
			errors.push({
				icon: <WarningIcon color={"error"} />,
				shortDescription: "Text out of bounds",
				longDescription: "Your handwritten text is outside of its safe zone, please adjust before moving forward",
			})

			canTheUserProceed = false
		}

		if (hasMissingRearImage && orderProductIsPrintedCard(order.product)) {
			errors.push({
				icon: <WarningIcon color={"error"} />,
				shortDescription: "Missing card rear image",
				longDescription: "Printed postcards must have an image in the card rear, please adjust before moving forward",
			})

			canTheUserProceed = false
		}

		if (hasMissingOutsideFrontImage && order.product === ProductTypes.HandwrittenBiFoldCard) {
			errors.push({
				icon: <WarningIcon color={"warning"} />,
				shortDescription: "Missing Outside Image",
				longDescription: "Bi-fold cards look better with an image on the outside.",
			})
		}

		setSceneErrors(errors)
		setHasSceneWarnings(!canTheUserProceed)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultTextWarning, noTextWarning, isThereImageBetweenSafeZoneAndBleedZone, lowestFoundResolution, hasInvalidMailMergePlaceholders, hasInvalidImageSize, hasTextOutsideOfHandwrittenTextArea, hasMissingRearImage, hasMissingOutsideFrontImage]);

	useEffect(() => {
		if (!getOrderParametersQuery.data) {
			return;
		}
	
		let oldOrderParameters = orderParameters;
	
		if (isFirstLoad) {
			oldOrderParameters = getOrderParametersQuery.data.payload;
			setIsFirstLoad(false);
		}
	
		let allParameters = mailMergeFields;
	
		const extractPlaceholders = (text: string | undefined) => {
			if (!text) return [];
	
			// Get all words that start with {{ and end with }}
			let match = text.match(/{{([\s\S]+?)}}/g);
			if (!match || !match.length) {
				return [];
			}
	
			// Remove {{ and }} from words
			let foundWordsInText = match.map(word => word.replaceAll("{", "").replaceAll("}", "").toUpperCase());
	
			// Remove words that are not mail merge fields
			foundWordsInText = foundWordsInText.filter(a => allParameters.includes(a));
	
			// Removes duplicates
			return Array.from(new Set(foundWordsInText));
		};
	
		const foundWordsInText1 = extractPlaceholders(order.text);
		const foundWordsInText2 = extractPlaceholders(order.text2);
	
		const foundWordsInText = Array.from(new Set([...foundWordsInText1, ...foundWordsInText2]));
	
		let newOrderParameters: OrderParameter[] = [];
		for (let word of foundWordsInText) {
			let newParam: OrderParameter = {
				id: 0,
				parameterType: word,
				parameterText: ""
			};
	
			for (let param of oldOrderParameters) {
				if (param.parameterType === word) {
					newParam.id = param.id;
					newParam.parameterText = param.parameterText;
					break;
				}
			}
	
			newOrderParameters.push(newParam);
		}
	
		setOrderParameters(newOrderParameters);
	
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order.text, order.text2, getOrderParametersQuery.data]);
	

	return (
		<LayoutMain showNextButton showBackButton backButtonOnClick={onBack} nextButtonOnClick={onNext}>
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<DesignSubpageLeftSide
						order={order}
						setOrder={setOrder}
						orderParameters={orderParameters}
						setOrderParameters={setOrderParameters}
					/>
				</Grid>

				<Grid item flex={1}>
					<Grid container gap={2} flexWrap={"nowrap"} minHeight={"130px"}>
						<Grid item xs={4}>
							{!orderProductIsPrintedCard(order.product) ?
								<Box mb={1}>
									<MDCard color={"light"} borderRadiusSize={"xl"} boxShadow={false} border={false}>
										<Typography fontWeight={"bold"} fontSize={14} px={2} py={1}>Characters:
											<Typography component={"span"} fontSize={14} ml={1}>{currentCharacters} / {includedCharacters} ({maxCharacters} Max) </Typography>
										</Typography>
									</MDCard>
								</Box>
								:
								null
							}

							<Box>
								<MDButton fullWidth size="small" onClick={() => setCurrentStep(currentStep === "Write" ? "Design" : "Write")}>
									<FlipIcon sx={{ mr: 1 }} />
									Flip {order?.product === ProductTypes.HandwrittenBiFoldCard ? (currentStep === "Write" ? "to Outside" : "to Inside") : "Card"}
								</MDButton>
								<TooltipTutorial
									keepTooltip
									tutorialName={TooltipTutorialtype.CanvaEditableTemplateTutorial}
									title="Want to create good looking templates?"
									description="Check this Canva to learn how!"
								>
									<MDButton sx={{ mt: 1, p: 0 }} fullWidth color="secondary" onClick={() => window.open(canvaUrls[order?.product], '_blank', 'noopener,noreferrer')}>
										<img src={canvaLogo} alt="canva" height={20} style={{ marginRight: 12 }} />
										Editable Template
									</MDButton>
								</TooltipTutorial>
							</Box>
						</Grid>

						{!orderProductIsPrintedCard(order.product) && (
							<Grid item xs={4}>
								<MDCard color={"light"} borderRadiusSize={"xl"} boxShadow={false} border={false} sx={{ height: "100%" }}>
									<Box px={2} py={1}>
										<Typography fontWeight={"bold"} fontSize={18}>Add Ons:</Typography>

										{inExtraCharacterMode ?
											<Grid container mt={0.5} alignItems={"center"} gap={0.5} flexDirection={"row"} flexWrap={"nowrap"}>
												<Grid item>
													<MDTypography variant={"h5"} color={"secondary"}>Extra Characters: </MDTypography>
												</Grid>

												<Grid item>
													<Chip label={formatPrice(Math.ceil((currentCharacters - includedCharacters) / extraCharactersForDivision) * priceForAdditionalCharacters)} />
												</Grid>
											</Grid>
											:
											null
										}

										{!(orderProductIsPrintedCard(order.product) || order.product === ProductTypes.HandwrittenPostCardA8)  && order.doubleSided === YesNo.Yes ?
											<Grid container mt={0.5} alignItems={"center"} gap={0.5} flexDirection={"row"} flexWrap={"nowrap"}>
												<Grid item>
													<MDTypography variant={"h5"} color={"secondary"}>Double Sided Printing: </MDTypography>
												</Grid>

												<Grid item>
													<Chip label={profile?.hasSubscription ? "$0.10" : "$0.20"} />
												</Grid>
											</Grid>
											:
											null
										}
									</Box>
								</MDCard>
							</Grid>
						)}

						<Grid item xs={4} position={"relative"}>
							{sceneErrors.length ?
								<MDCard customColor={"#fef7e8"} borderRadiusSize={"lg"} boxShadow={false} sx={{ position: "absolute", left: 0, top: 0, width: "100%", height: expandErrors ? "fit-content" : "100%", zIndex: 999 }}>
									<Grid container flexDirection={'column'} px={2} py={1} height={"100%"} flexWrap={"nowrap"}>
										<Grid item>
											<Typography fontWeight={"bold"} fontSize={18}>Errors & Warnings:</Typography>
										</Grid>

										<Grid item mt={1} flex={1}>
											<Grid container flexDirection={"column"} justifyContent={"space-between"} height={"100%"} gap={0.5}>
												<Grid item>
													<Grid container flexDirection={"column"} alignItems={"center"} spacing={0.5}>
														{sceneErrors.slice(0, expandErrors ? sceneErrors.length : 2).map((err, index) => {
															return <Grid container alignItems={"center"} gap={1} flexWrap={"nowrap"} key={index}>
																<Grid item display={"flex"}>
																	{err.icon}
																</Grid>

																<Grid item>
																	<MDTypography variant={"h5"} color={"black"}>
																		{err.shortDescription}
																	</MDTypography>
																</Grid>

																<Grid item>
																	<Tooltip title={err.longDescription} componentsProps={{ tooltip: { sx: err.additionalStyles } }}>
																		<Box display={"flex"}>
																			<Info2Icon color={"light2"} />
																		</Box>
																	</Tooltip>
																</Grid>
															</Grid>
														})}
													</Grid>
												</Grid>

												<Grid item flex={1}>
													{sceneErrors.length > 2 ?
														<MDButton
															fullWidth
															size="small"
															color={"primary"}
															onClick={() => {
																setExpandErrors(prevState => !prevState)
															}}
														>
															{expandErrors ? "Show less errors" : "Show all errors"}
														</MDButton>
														:
														null
													}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</MDCard>
								:
								null
							}
						</Grid>
					</Grid>

					<Box ref={canvasContainerRef} style={{ height: canvasContainerHeight, position: "relative" }}>
						<CardCanvas editorType={"Card"} />
					</Box>
				</Grid>
			</Grid >

			<InfoModal
				show={showErrorModal}
				setShow={setShowErrorModal}
				headerText={`Please address the errors before continuing`}
				showCancelButton={false}
				showConfirmButton
				confirmButtonOnClick={() => setShowErrorModal(false)}
			/>
		</LayoutMain >
	)
}

export default DesignSubpage