import { LogLevel } from "@azure/msal-browser";

//process.env are configured in the gihub workflow as .env: variables. Locally they are in the .env file under the frontend folder
//look at .env.template for examples
//Tenant name
const AZURE_TENANT_NAME = process.env.REACT_APP_AZURE_TENANT_NAME;
//App registration under aadb2c for this app
const APP_REGISTRATION_CLIENT_ID = process.env.REACT_APP_AADB2C_APP_REGISTRATION_CLIENT_ID;
const AZURE_AUTH_DOMAIN = AZURE_TENANT_NAME + ".b2clogin.com";
const AADB2C_URI = "https://" + AZURE_AUTH_DOMAIN + "/" + AZURE_TENANT_NAME + ".onmicrosoft.com/"

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_susi",
        forgotPassword: "b2c_1_reset",
        editProfile: "b2c_1_edit"
    },
    authorities: {
        signUpSignIn: {
            authority: AADB2C_URI + "b2c_1_susi",
        },
        forgotPassword: {
            authority: AADB2C_URI + "b2c_1_reset",
        },
        editProfile: {
            authority: AADB2C_URI + "b2c_1_edit"
        }
    },
    authorityDomain: AZURE_AUTH_DOMAIN
}

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
    auth: {
        clientId: APP_REGISTRATION_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: "https://lettrlabs.com", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        // console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
    scopes: ["https://dev.lettrlabs.com/app-dev-api/dev-api.read"],
    apiProducts: {
        endpoint: "/api/products/"
    }, apiSubscription: {
        endpoint: "/api/subscription/"
    }, apiOrders: {
        endpoint: "/api/orders/"
    }, apiOrderTemplates: {
        endpoint: "/api/orderTemplates/"
    }, apiTrickleOrder: {
        endpoint: "/api/trickleOrder/"
    }, apiCheckout: {
        endpoint: "/api/checkout"
    }, apiFonts: {
        endpoint: "/api/fonts/"
    }, apiOrderArtifacts: {
        endpoint: "/api/orderArtifacts/",
    }, apiUpdateOrderArtifactType: {
        endpoint: "/api/orderartifacts-update-type/",
    }, apiGenerateQrCode: {
        endpoint: "/api/orderArtifacts/generateQrCode/",
    }, apiScene: {
        endpoint: "/api/scene/",
    }, apiFeedback: {
        endpoint: "/api/feedback/",
    }, apiClearScene: {
        endpoint: "/api/clearscene/",
    }, apiProfile: {
        endpoint: "/api/profile/"
    }, apiLoggedInProfile: {
        endpoint: "/api/profile/loggedin"
    }, apiProfiles: {
        endpoint: "/api/profiles/"
    }, apiGetOrderTransactions: {
        endpoint: "/api/profile/getOrderTransactions/"
    }, apiGetCreditTransactions: {
        endpoint: "/api/profile/getCreditTransactions/"
    }, apiProfileContact: {
        endpoint: "/api/profile/contact"
    }, apiApiKey: {
        endpoint: "/api/apikey/"
    }, apiPostProcessOrderRecipients: {
        endpoint: "/api/orderRecipient/postprocess/",
    }, apiOrderRecipients: {
        endpoint: "/api/orderRecipient/"
    }, apiIntegrationOrderRecipients: {
        endpoint: "/api/integrationorderRecipient"
    }, apiProfileRecipients: {
        endpoint: "/api/profileRecipients"
    }, admin: {
        profiles: {
            endpoint: "/api/adm/profiles/"
        },
        profile: {
            endpoint: "/api/adm/profile/"
        },
        uploadArtifacts: {
            endpoint: "/api/adm/uploadArtifacts/"
        },
        orders: {
            endpoint: "/api/adm/orders/"
        },
        order: {
            endpoint: "/api/adm/order/"
        }
    }, apiPremadeTemplates: {
        endpoint: "/api/designs"
    }, apiCreateOrderFromDesign: {
        endpoint: "/api/orders/createfromdesigntemplate"
    }, apiProfileTags: {
        endpoint: "api/profileTags"
    }, apiAddProfileTags: {
        endpoint: "api/profileRecipients/addtags"
    }, apiRemoveProfileTags: {
        endpoint: "api/profileRecipients/removetags"
    }, apiDeleteProfileRecipient: {
        endpoint: "api/profileRecipients"
    }, apiDeleteOrderRecipient: {
        endpoint: "/api/orderRecipient"
    }, apiIntegrationOrderUpdateStatus: {
        endpoint: "/api/integrationorder/changestatus"
    }, apiPhoneNumber: {
        endpoint: "/api/phonenumber"
    }, apiRecipientSearch: {
        endpoint: "/api/recipientsearch"
    }, apiRecipientSearchCheckout: {
        endpoint: "/api/recipientSearchCheckout"
    }, apiRecipientEnrichment: {
        endpoint: "/api/RecipientDataEnrichment"
    }
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [...protectedResources.scopes]
};