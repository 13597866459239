/* eslint-disable prefer-destructuring */
/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import {styled, Theme} from "@mui/material/styles";
import {Card} from "@mui/material";

export default styled(Card)(({theme, ownerState}: { theme?: Theme | any; ownerState: any }) => {
    const {palette} = theme;
    const {color, border, boxShadow, borderRadiusSize, borderColor, customColor, borderSize, boxShadowSize} = ownerState;

    const background = (customColor: string) => ({
        backgroundColor: customColor? customColor : palette[color].main
    })

    const borderStyles = () => {
        let _borderColor = ""

        if (borderColor === "light") {
            _borderColor = "#EDEFF5"
        }
        else if (borderColor === "dark") {
            _borderColor = "#CCD4D6"
        }
        else if (borderColor === "darkest") {
            _borderColor = "#A4A9AA"
        }

        return {
            border: `${borderSize}px solid ${_borderColor}`,
        }
    };

    const boxShadowStyles = () => {
        if(boxShadow){
            return {
                boxShadow: boxShadowSize === "small"? `11px 12px 24px 0px #130B4608` : `6px 6px 9px 0px #130b461f`
            }
        }

        return {
            boxShadow: "none"
        }
    }

    return {
        ...(border && borderStyles()),
        ...(boxShadowStyles()),
        ...(background(customColor)),
        borderRadius: borderRadiusSize? theme.borders.borderRadius[borderRadiusSize] : 0
    };
});