import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const RenameIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.125 4.5C9.95924 4.5 9.80027 4.56585 9.68306 4.68306C9.56585 4.80027 9.5 4.95924 9.5 5.125C9.5 5.29076 9.56585 5.44973 9.68306 5.56694C9.80027 5.68415 9.95924 5.75 10.125 5.75H11.375V18.25H10.125C9.95924 18.25 9.80027 18.3158 9.68306 18.4331C9.56585 18.5503 9.5 18.7092 9.5 18.875C9.5 19.0408 9.56585 19.1997 9.68306 19.3169C9.80027 19.4342 9.95924 19.5 10.125 19.5H13.875C14.0408 19.5 14.1997 19.4342 14.3169 19.3169C14.4342 19.1997 14.5 19.0408 14.5 18.875C14.5 18.7092 14.4342 18.5503 14.3169 18.4331C14.1997 18.3158 14.0408 18.25 13.875 18.25H12.625V5.75H13.875C14.0408 5.75 14.1997 5.68415 14.3169 5.56694C14.4342 5.44973 14.5 5.29076 14.5 5.125C14.5 4.95924 14.4342 4.80027 14.3169 4.68306C14.1997 4.56585 14.0408 4.5 13.875 4.5H10.125ZM7 7H10.125V8.25H7C6.66848 8.25 6.35054 8.3817 6.11612 8.61612C5.8817 8.85054 5.75 9.16848 5.75 9.5V14.4962C5.75 14.8278 5.8817 15.1457 6.11612 15.3801C6.35054 15.6146 6.66848 15.7462 7 15.7462H10.125V16.9962H7C6.33696 16.9962 5.70107 16.7329 5.23223 16.264C4.76339 15.7952 4.5 15.1593 4.5 14.4962V9.5C4.5 8.83696 4.76339 8.20107 5.23223 7.73223C5.70107 7.26339 6.33696 7 7 7ZM17 15.7462H13.875V16.9962H17C17.663 16.9962 18.2989 16.7329 18.7678 16.264C19.2366 15.7952 19.5 15.1593 19.5 14.4962V9.5C19.5 8.83696 19.2366 8.20107 18.7678 7.73223C18.2989 7.26339 17.663 7 17 7H13.875V8.25H17C17.3315 8.25 17.6495 8.3817 17.8839 8.61612C18.1183 8.85054 18.25 9.16848 18.25 9.5V14.4962C18.25 14.8278 18.1183 15.1457 17.8839 15.3801C17.6495 15.6146 17.3315 15.7462 17 15.7462Z"
                    fill="currentColor"/>
            </svg>
        </SvgIcon>
    )
}

export default RenameIcon;