import MDBox from "material-ui/components/MDBox";
import MDTypography from "material-ui/components/MDTypography";
import MDCard from "material-ui/components/MDCard";
import StyledDataGrid from "components/styled-data-grid";
import  {useEffect, useState} from "react";
import {GridColDef} from "@mui/x-data-grid-pro";
import MDButton from "material-ui/components/MDButton";
import {Divider, Grid, Tooltip} from "@mui/material";
import Link from "@mui/material/Link";
import SocialFb from "assets/icons/social-fb";
import SocialLinkedIn from "assets/icons/social-linkedin";
import {useQuery} from "@tanstack/react-query";
import ProfileService from "services/profile";
import copy from "copy-to-clipboard";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";
import SubscriptionService from "services/subscription";
import {formatNumber} from "helpers/formatters";
import SocialX from "assets/icons/social-x";
import { useGlobal } from "context/global-context";

function ReferalPage() {
    const MONTHLY_PRICING_URL = "https://www.lettrlabs.com/contact/monthly-subscription-handwritten-letter";

    const {setShowLoader} = useGlobal()

    const {getProfile} = ProfileService()
    const {getSubscriptionBonuses} = SubscriptionService()

    const [showCopiedTooltip, setShowCopiedTooltip] = useState<boolean>(false)

    const getProfileQuery = useQuery({
        queryKey: ["profile"],
        queryFn: getProfile
    })

    const getSubscriptionBonusesQuery = useQuery({
        queryKey: ["subscriptionBonuses"],
        queryFn: getSubscriptionBonuses
    })

    const columns: GridColDef[] = [
        {
            headerName: "Their Plan",
            field: "price",
            flex: 1,
            align: "center",
            disableColumnMenu: true,
            sortable: false,
            headerAlign: "center",
            renderCell: (params) => {
                return <MDTypography sx={{fontSize: 16}} fontWeight={"bold"}>${formatNumber(params.value)}</MDTypography>
            }
        },
        {
            headerName: "You",
            field: "referralAmount",
            flex: 1,
            sortable: false,
            align: "center",
            disableColumnMenu: true,
            headerAlign: "center",
            renderCell: (params) => {
                return <MDTypography sx={{fontSize: 16}} fontWeight={"bold"}>${formatNumber(params.value)}</MDTypography>
            }
        },
        {
            headerName: "Them",
            field: "referralAmount",
            flex: 1,
            sortable: false,
            align: "center",
            disableColumnMenu: true,
            headerAlign: "center",
            renderCell: (params) => {
                return <MDTypography sx={{fontSize: 16}} fontWeight={"bold"} color={"primary"}>${formatNumber(params.value)}</MDTypography>
            }
        },
    ]

    useEffect(() => {
        if (getProfileQuery.isLoading || getSubscriptionBonusesQuery.isLoading){
            setShowLoader(true)
        }
        else{
            setShowLoader(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getProfileQuery.isLoading, getSubscriptionBonusesQuery.isLoading]);

    return <MDCard color={"white"} boxShadow={false} border={false}>
        <MDBox p={4}>
            <MDTypography sx={{fontSize: 24}} fontWeight={"bold"}>Refer a Friend or Colleague</MDTypography>

            <MDBox mt={3}>
                <MDTypography sx={{fontSize: 16}}>
                    Refer a friend and receive a one-time credit bonus for both you and them!
                    Credits are based on the plan they sign up with.
                </MDTypography>
            </MDBox>

            <MDBox width={"700px"} mt={3}>
                <StyledDataGrid
                    rows={getSubscriptionBonusesQuery.data?.payload?? []}
                    columns={columns}
                    pagination={false}
                    disableRowSelectionOnClick={true}
                    hideFooter={true}
                />
            </MDBox>

            <MDBox mt={6}>
                <MDTypography sx={{fontSize: 16}}>
                    This is a one-time referral bonus. Credits do not reduce monthly
                    subscription costs, rather provide each party with additional credits redeemed for products and
                    services.
                </MDTypography>

                <MDTypography sx={{fontSize: 16}}>
                    Copy the link below to have them sign up with your unique referral code.
                </MDTypography>
            </MDBox>

            <MDBox mt={3}>
                <MDTypography sx={{fontSize: 24}} fontWeight={"bold"}>
                    Promo Code: {getProfileQuery.data?.referralCode}
                </MDTypography>
            </MDBox>

            <MDBox mt={4}>
                <Tooltip title={"Copied!"} open={showCopiedTooltip}>
                    <MDButton color={"primary"} circular={true} size={"large"} onClick={() => {
                        if (getProfileQuery.data) {
                            copy(getProfileQuery.data.referralCode)

                            setShowCopiedTooltip(true)

                            setTimeout(() => {
                                setShowCopiedTooltip(false)
                            }, 3000)
                        }

                    }}>Copy Referral Code</MDButton>
                </Tooltip>
            </MDBox>

            <MDBox py={2}>
                <Divider light={true}/>
            </MDBox>

            <MDTypography sx={{fontSize: 16}}>Have your friends visit the link below and enter your promo code at checkout.</MDTypography>

            <MDBox mt={1}>
                <Link display={"block"} width={"fit-content"} href={"https://www.lettrlabs.com/contact/monthly-subscription-handwritten-letter"} target="_blank">
                    <MDTypography sx={{fontSize: 16, width: "fit-content"}} isLink={true}>https://www.lettrlabs.com/contact/monthly-subscription-handwritten-letter</MDTypography>
                </Link>
            </MDBox>

            <Grid container alignItems={"center"} gap={1} mt={3}>
                <Grid item>
                    <FacebookShareButton url={MONTHLY_PRICING_URL}>
                        <SocialFb/>
                    </FacebookShareButton>
                </Grid>

                <Grid item>
                    <LinkedinShareButton url={MONTHLY_PRICING_URL}>
                        <SocialLinkedIn/>
                    </LinkedinShareButton>
                </Grid>

                <Grid item>
                    <TwitterShareButton url={MONTHLY_PRICING_URL}>
                        <SocialX/>
                    </TwitterShareButton>
                </Grid>
            </Grid>
        </MDBox>
    </MDCard>
}

export default ReferalPage