import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const AlignBottomLeftIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3653_20544)">
                    <rect x="20.5" y="19.5" width="17" height="16" rx="1.5" transform="rotate(180 20.5 19.5)" stroke="#CFD5E7"/>
                    <path d="M3 15V18C3 19.1046 3.89543 20 5 20H8" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_3653_20544">
                        <rect width="24" height="24" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    )
}

export default AlignBottomLeftIcon;