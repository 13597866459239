import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const StretchFullIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_6132_51812)">
                    <rect x="3.5" y="3.5" width="17" height="16" rx="1.5" stroke="#CFD5E7"/>
                    <path d="M3 8V5C3 3.89543 3.89543 3 5 3H8" stroke="#130B46" strokeWidth="2" strokeLinecap="round"/>
                    <path d="M21 8V5C21 3.89543 20.1046 3 19 3H16" stroke="#130B46" strokeWidth="2" strokeLinecap="round"/>

                    <path d="M21 16V19C21 20.1046 20.1046 21 19 21H16" stroke="#130B46" strokeWidth="2" strokeLinecap="round"/>
                    <path d="M3 16V19C3 20.1046 3.89543 21 5 21H8" stroke="#130B46" strokeWidth="2" strokeLinecap="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_6132_51812">
                        <rect width="24" height="24" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

        </SvgIcon>
    )
}

export default StretchFullIcon;