import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { useState } from 'react';

export default function LeadRevealHelper() {
  const [expanded, setExpanded] = useState<string>('');
  const infolist = [
    'First Name',
    'Last Name',
    'Address',
    'City',
    'State',
    'Zip Code',
    'Personal Email (in many cases)',
  ];
  return (
    <>
      <Typography fontSize={14} fontWeight={'normal'} mb={2}>
        Do you want to re-target your anonymous website traffic with direct mail and increase your website conversions?
        LeadReveal is LettrLabs newest solution that allows us to unmask your anonymous website traffic using our patent
        pending unmasking solution. Our solution DOESN'T USE IP addresses and isn't impacted by iOS14 privacy changes.
      </Typography>
      <Typography variant="h5" fontSize={16} mb={1}>
        FAQ
      </Typography>
      <Accordion
        disableGutters
        expanded={expanded === 'LeadsInfo'}
        onChange={() => setExpanded(expanded === 'LeadsInfo' ? '' : 'LeadsInfo')}
      >
        <AccordionSummary expandIcon={<ExpandMore />} sx={{ backgroundColor: '#F3F5FB' }}>
          What information does LeadReveal unveil?
        </AccordionSummary>
        <AccordionDetails>
          <Box component={'ul'} ml={3} mb={2}>
            {infolist.map((item) => (
              <Typography component={'li'} fontSize={14} fontWeight={'normal'}>
                {item}
              </Typography>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        disableGutters
        expanded={expanded === 'Cost'}
        onChange={() => setExpanded(expanded === 'Cost' ? '' : 'Cost')}
      >
        <AccordionSummary expandIcon={<ExpandMore />} sx={{ backgroundColor: '#F3F5FB' }}>
          How much does LeadReveal Cost?
        </AccordionSummary>
        <AccordionDetails>
          <Typography fontSize={14} fontWeight={'normal'}>
            $0.22 per lead we unmask
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        disableGutters
        expanded={expanded === 'SetupIssue'}
        onChange={() => setExpanded(expanded === 'SetupIssue' ? '' : 'SetupIssue')}
      >
        <AccordionSummary expandIcon={<ExpandMore />} sx={{ backgroundColor: '#F3F5FB' }}>
          What should I do if I encountered an issue during setup and can't complete it?
        </AccordionSummary>
        <AccordionDetails>
          <Typography fontSize={14} fontWeight={'normal'}>
            Follow this <a href="https://support.google.com/accounts/answer/13533235?hl=en" target='_blank' rel="noreferrer">Google suport page steps</a>
            , delete all connections you have with the service "GTM Api" and try to setup again
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
