import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT from "../endpoint";
import {IProfileRecipient} from "models/profileRecipient";
import {GridSearchParams} from "../../models/gridSearchParams";

function ProfileRecipientService() {
    const {execute} = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/profileRecipients`

    function generateRecipientsCsv(lastSearchParams: GridSearchParams, recipientIds: number[], selectedAll: boolean) {
        return execute({
            endpoint: `${API_PATH}/generate-recipients-csv`,
            method: "POST",
            data: {
                lastSearchParams,
                recipientIds,
                selectedAll
            }
        })
      }

    // Gets all of the user's profile recipients
    function getProfileRecipientsServerPagination(page = 1, pageSize = 20, sortBy = '', sortDirection = 'asc', search = '', filters = '') {
        // Construct the query parameters string
        let queryParams = `page=${page}&pageSize=${pageSize}`;

        // Append sortBy and sortDirection to the query parameters if they are provided
        if (sortBy) {
            queryParams += `&sortBy=${encodeURIComponent(sortBy)}`;
        }
        if (sortDirection) {
            queryParams += `&sortDirection=${encodeURIComponent(sortDirection)}`;
        }
        if (filters) {
            queryParams += `&filters=${encodeURIComponent(filters)}`;
        }
        if (search) {
            queryParams += `&search=${encodeURIComponent(search)}`;
        }

        // Execute the API call with the constructed query parameters
        return execute({
            endpoint: `${API_PATH}?${queryParams}`,
            method: "GET"
        });
    }  

    function getProfileRecipients() {
        return execute({
            endpoint: API_PATH,
            method: "GET"
        })
    }

    // Gets a single profile recipient
    function getProfileRecipient(id: number) {
        return execute({
            endpoint: `${API_PATH}/${id}`,
            method: "GET"
        })
    }

    // Upserts a profile recipient
    function postProfileRecipient(profileRecipient: IProfileRecipient) {
        return execute({
            endpoint: `${API_PATH}`,
            method: "POST",
            data: profileRecipient
        })
    }

    // Bulk updates organizationName, radiusPinAddress and radiusPinStreet of the provided profileRecipientIds
    function bulkUpdateProfileRecipients(data: {
        profileRecipientIds: number[],
        organizationName?: string,
        radiusPinAddress?: string,
        radiusPinStreet?: string
    }) {
        return execute({
            endpoint: `${API_PATH}/batchupdate`,
            method: "POST",
            data: data
        })
    }

    // Deletes either all or an array of profile recipients
    function deleteProfileRecipients(profileRecipientIds: number[], lastSearchParams: object, selectedAll: boolean) {
        return execute({
            endpoint: `${API_PATH}`,
            method: "DELETE",
            data: {
                profileRecipientIds,
                lastSearchParams,
                selectedAll
            }
        })
    }

    function addTagsToProfileRecipients(profileRecipientIds: number[], profileTagIds: number[], lastSearchParams: object, selectedAll: boolean) {
        return execute({
            endpoint: `${API_PATH}/addtags`,
            method: "POST",
            data: {
                profileRecipientIds,
                profileTagIds,
                lastSearchParams,
                selectedAll
            }
        })
    }

    function removeTagsFromProfileRecipients(profileRecipientIds: number[], profileTagIds: number[], lastSearchParams: object, selectedAll: boolean) {
        return execute({
            endpoint: `${API_PATH}/removetags`,
            method: "POST",
            data: {
                profileRecipientIds,
                profileTagIds,
                lastSearchParams,
                selectedAll
            }
        })
    }

    return {
        getProfileRecipients,
        getProfileRecipient,
        postProfileRecipient,
        addTagsToProfileRecipients,
        removeTagsFromProfileRecipients,
        bulkUpdateProfileRecipients,
        deleteProfileRecipients,
        getProfileRecipientsServerPagination,
        generateRecipientsCsv
    }
}

export default ProfileRecipientService