import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useGlobal } from 'context/global-context';
import useDataSourcesService from 'services/data-sources';

type InstallSourceType = 'klaviyo' | 'lead-reveal' | 'shopify';

const useSourceInstallRequest = () => {
  const { setShowLoader } = useGlobal();
  const { getSourceInstallRequest } = useDataSourcesService();
  const [installSource, setInstallSource] = useState<InstallSourceType>();

  const {
    data: authData,
    refetch: getSourceAuthRequest,
    isLoading,
  } = useQuery({
    queryKey: ['getSourceInstallRequest', installSource],
    queryFn: () => getSourceInstallRequest(installSource),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    setShowLoader(isLoading);
  }, [isLoading, setShowLoader]);

  useEffect(() => {
    const authUrl = authData?.payload || '';
    if (authUrl) window.location.href = authUrl;
  }, [authData?.payload]);

  useEffect(() => {
    if (installSource) getSourceAuthRequest();
  }, [getSourceAuthRequest, installSource]);

  return { setInstallSource };
};

export default useSourceInstallRequest;
