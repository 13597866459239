export const pascalToCamel = (str: string): string => {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export const convertKeysToCamelCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(item => convertKeysToCamelCase(item));
  } else if (obj !== null && typeof obj === 'object') {
    const newObj: { [key: string]: any } = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = pascalToCamel(key);
        newObj[newKey] = convertKeysToCamelCase(obj[key]);
      }
    }
    return newObj;
  } else {
    return obj;
  }
}
