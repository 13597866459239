import {Coordinate} from "../coordinate";
import {ITemplate} from "../template";
import {OwnerOrRenterFilter, PropertyTypeFilter} from "../radius-mailing/filters";

export interface ServiceArea{
    id: number;
    name: string;
    totalStructures: number;
    estimatedPricePerCard: number,
    canDelete: boolean;
    canEdit: boolean;
    isProcessed: boolean;
    status: ServiceAreaStatus;
    template: ITemplate;
    homeOwnerFilter: OwnerOrRenterFilter;
    propertyTypeFilter: PropertyTypeFilter;
    hailSizeMinFilter: number;
    windSpeedMinFilter: number;
    cardsToSendPerStormMax: number;
    resendAfterWeeks: number;
    startSendingAtDate: string;
    stopSendingAtDate: string;
    polygons: ServiceAreaPolygon[];
    radii: ServiceAreaRadius[]
    queuedCount: number
    inProductionCount: number
    mailedCount: number
}

export enum ServiceAreaStatus{
    Draft=0,
    Active=1,
    Inactive=2
}

export interface ServiceAreaRadius{
    id: number
    recipientSearchEntryId: number
    radius: number
    name: string
    coordinate: {
        lat: number
        lng: number
    }
    isNew?: boolean
    structures: number
    isProcessed: boolean
    type?: string
}

export interface ServiceAreaPolygon{
    id: number
    recipientSearchEntryId?: number
    name: string
    coordinates: Coordinate[]
    isNew?: boolean
    structures: number
    isProcessed: boolean
    type?: string
}