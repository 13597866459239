import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const DuplicateIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.125 16.375V19.1875C15.125 19.705 14.705 20.125 14.1875 20.125H6.0625C5.81386 20.125 5.5754 20.0262 5.39959 19.8504C5.22377 19.6746 5.125 19.4361 5.125 19.1875V8.5625C5.125 8.045 5.545 7.625 6.0625 7.625H7.625C8.04381 7.62472 8.46192 7.65928 8.875 7.72834M15.125 16.375H17.9375C18.455 16.375 18.875 15.955 18.875 15.4375V11.375C18.875 7.65834 16.1725 4.57417 12.625 3.97834C12.2119 3.90928 11.7938 3.87472 11.375 3.875H9.8125C9.295 3.875 8.875 4.295 8.875 4.8125V7.72834M15.125 16.375H9.8125C9.56386 16.375 9.3254 16.2762 9.14959 16.1004C8.97377 15.9246 8.875 15.6861 8.875 15.4375V7.72834M18.875 13.25V11.6875C18.875 10.9416 18.5787 10.2262 18.0512 9.69876C17.5238 9.17132 16.8084 8.875 16.0625 8.875H14.8125C14.5639 8.875 14.3254 8.77623 14.1496 8.60041C13.9738 8.4246 13.875 8.18614 13.875 7.9375V6.6875C13.875 6.31816 13.8023 5.95243 13.6609 5.6112C13.5196 5.26998 13.3124 4.95993 13.0512 4.69876C12.7901 4.4376 12.48 4.23043 12.1388 4.08909C11.7976 3.94775 11.4318 3.875 11.0625 3.875H10.125"
                    stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export default DuplicateIcon;