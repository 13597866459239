import Carousel, {CarouselProps} from "react-multi-carousel";
import {useRef, useState} from "react";
import "./index.scss"
import MDBox from "material-ui/components/MDBox";

interface StyledCarouselProps extends CarouselProps {

}


function StyledCarousel({children, ...rest}: StyledCarouselProps) {
    const ref = useRef(null)

    const [isPreviousButtonDisabled, setIsPreviousButtonDisabled] = useState<boolean>(!rest.infinite)
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(false)

    return <MDBox width={"100%"} position={"relative"}>
        {rest.arrows ?
            <MDBox className={"carousel__arrow_container left"}>
                <button className={"carousel__arrow "} onClick={() => ref.current.previous()} disabled={isPreviousButtonDisabled}>
                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.875 8.75L1.125 5L4.875 1.25" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
            </MDBox>
            :
            null
        }

        <MDBox px={3}>
            <Carousel
                afterChange={(previousSlide, state) => {
                    if(rest.infinite) {
                        return
                    }

                    if(state.currentSlide === 0) {
                        setIsPreviousButtonDisabled(true)
                    }
                    else {
                        setIsPreviousButtonDisabled(false)
                    }

                    if(state.currentSlide === state.totalItems - state.slidesToShow) {
                        setIsNextButtonDisabled(true)
                    }
                    else {
                        setIsNextButtonDisabled(false)
                    }
                }}
                ref={ref}
                {...rest}
                arrows={false}
            >
                {children}
            </Carousel>
        </MDBox>

        {rest.arrows ?
            <MDBox className={"carousel__arrow_container right"}>
                <button className={"carousel__arrow"} onClick={() => ref.current.next()} disabled={isNextButtonDisabled}>
                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.125 8.75L4.875 5L1.125 1.25" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
            </MDBox>
            :
            null
        }
    </MDBox>
}

export default StyledCarousel