import SvgIcon from '@mui/material/SvgIcon';

const MailboxIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7 7C6.00544 7 5.05161 7.39509 4.34835 8.09835C3.64509 8.80161 3.25 9.75544 3.25 10.75V18.25H10.75V10.75C10.75 9.75544 10.3549 8.80161 9.65165 8.09835C8.94839 7.39509 7.99456 7 7 7ZM7 5.75H17C18.3261 5.75 19.5979 6.27678 20.5355 7.21447C21.4732 8.15215 22 9.42392 22 10.75V18.25C22 18.5815 21.8683 18.8995 21.6339 19.1339C21.3995 19.3683 21.0815 19.5 20.75 19.5H3.25C2.91848 19.5 2.60054 19.3683 2.36612 19.1339C2.1317 18.8995 2 18.5815 2 18.25V10.75C2 9.42392 2.52678 8.15215 3.46447 7.21447C4.40215 6.27678 5.67392 5.75 7 5.75ZM10.3075 7C10.8401 7.46872 11.2666 8.04568 11.5584 8.69235C11.8503 9.33902 12.0008 10.0405 12 10.75V18.25H20.75V10.75C20.75 9.75544 20.3549 8.80161 19.6517 8.09835C18.9484 7.39509 17.9946 7 17 7H10.3075Z"
                    fill="currentColor"/>
                <path
                    d="M16.7413 12.625H13.25V11.375H19.5C19.6658 11.375 19.8247 11.4408 19.9419 11.5581C20.0592 11.6753 20.125 11.8342 20.125 12V13.25C20.125 13.4158 20.0592 13.5747 19.9419 13.6919C19.8247 13.8092 19.6658 13.875 19.5 13.875H18.25C18.1679 13.8751 18.0865 13.8591 18.0106 13.8278C17.9347 13.7965 17.8656 13.7505 17.8075 13.6925L16.7413 12.625ZM8.25 10.75C8.25 11.44 7.69 10.75 7 10.75C6.31 10.75 5.75 11.44 5.75 10.75C5.75 10.4185 5.8817 10.1005 6.11612 9.86612C6.35054 9.6317 6.66848 9.5 7 9.5C7.33152 9.5 7.64946 9.6317 7.88388 9.86612C8.1183 10.1005 8.25 10.4185 8.25 10.75Z"
                    fill="currentColor"/>
            </svg>
        </SvgIcon>
    )
}

export default MailboxIcon;