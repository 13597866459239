import {Dialog, DialogContent, Stack, Typography} from "@mui/material";
import React, {FormEvent, useEffect, useState} from "react";
import MDButton from "material-ui/components/MDButton";
import ProfileService from "services/profile";
import {useQueryClient} from "@tanstack/react-query";
import InfoModal from "components/info-modal";
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {IProfile} from "models/profile";
import MDBox from "material-ui/components/MDBox";
import MDCard from "material-ui/components/MDCard";
import { useGlobal } from "context/global-context";

interface AddPaymentMethodModalProps {
    show: boolean
    setShow: Function
    setProfile: Function
}

function AddPaymentMethodModal({show, setShow, setProfile}: AddPaymentMethodModalProps) {
    const [stripePromise, setStripePromise] = useState(null)

    useEffect(() => {
        setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY))
    }, []);

    return <Elements stripe={stripePromise}>
        <Content show={show} setShow={setShow} setProfile={setProfile}/>
    </Elements>
}

function Content({show, setShow, setProfile}: AddPaymentMethodModalProps) {
    const stripe = useStripe();
    const elements = useElements();

    const {getProfile} = ProfileService()

    const {setShowLoader} = useGlobal()

    const queryClient = useQueryClient()

    const {addProfilePaymentMethod} = ProfileService()

    const [canSubmit, setCanSubmit] = useState<boolean>(false)

    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [errorModalText, setErrorModalText] = useState<string>("")

    async function submit(event: FormEvent) {
        event.preventDefault()

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            setErrorModalText(error.message)
            setShowErrorModal(true)
            return
        }

        setShowLoader(true)

        addProfilePaymentMethod(paymentMethod.id).then((result) => {
            if(result.hasErrors){
                setShowLoader(false)
                setErrorModalText(result.errors[0])
                setShowErrorModal(true)
                return
            }

            getProfile().then((response) => {
                setShowLoader(false)

                setProfile((prevState: IProfile) => {
                    return {...prevState, defaultPaymentMethodId: response.defaultPaymentMethodId, autobill: response.autobill}
                })
            })

            queryClient.invalidateQueries({queryKey: ["profilePaymentMethods"]})

            setShow(false)
        })
    }

    return <Dialog open={show} onClose={() => setShow(false)}>
        <DialogContent sx={{width: "500px"}}>
            <Typography mb={4} sx={{fontSize: 20}} fontWeight={"bold"}>Add a new Payment Method</Typography>

            <form onSubmit={submit}>
                <MDCard borderRadiusSize={"md"} border={true} boxShadow={true} boxShadowSize={"small"}>
                    <MDBox p={1}>
                        <CardElement onChange={(e) => {
                            setCanSubmit(e.complete)
                        }}/>
                    </MDBox>
                </MDCard>

                <Stack
                    p={1}
                    direction="row"
                    spacing={2}
                    marginTop={2}
                    justifyContent="flex-end"
                >
                    <MDButton
                        circular={true}
                        color="light"
                        onClick={() => setShow(false)}
                    >
                        Cancel
                    </MDButton>
                    <MDButton
                        disabled={!canSubmit}
                        circular={true}
                        color="primary"
                        type={"submit"}
                    >
                        Add
                    </MDButton>
                </Stack>
            </form>
        </DialogContent>

        <InfoModal
            show={showErrorModal}
            setShow={setShowErrorModal}
            headerText={errorModalText}
            showConfirmButton={true}
            confirmButtonOnClick={() => {
                setShowErrorModal(false)
            }}
        />
    </Dialog>
}

export default AddPaymentMethodModal