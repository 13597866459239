import MDBox from "material-ui/components/MDBox";
import MDTypography from "material-ui/components/MDTypography";
import {Dialog, DialogContent, Stack} from "@mui/material";
import React from "react";
import {Template} from "models/template";

interface ShowTemplateImagesModalProps {
    show: boolean
    setShow: Function
    template: Template
}

function ShowTemplateImagesModal({show, setShow, template}: ShowTemplateImagesModalProps) {
    return <Dialog open={show} onClose={() => setShow(false)} maxWidth={false}>
        <DialogContent sx={{width: "600px"}}>
            <MDBox>
                <MDTypography variant="h4" fontWeight="bold" color="text">
                    Preview: {template.name}
                </MDTypography>
                <MDTypography variant="button" color="text">
                    {template.product}
                </MDTypography>
            </MDBox>

            <Stack direction="row" justifyContent="space-between" spacing={2} mt={2}>
                <MDBox
                    width={"50%"}
                    sx={{
                        borderColor: "gray",
                        boxShadow:
                            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                    }}
                    className={"pdf-full-height"}
                >
                    <img alt={""} style={{width: "100%", height: "100%"}} src={template.sceneFrontThumb}/>
                </MDBox>
                <MDBox
                    width={"50%"}
                    sx={{
                        borderColor: "gray",
                        boxShadow:
                            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                    }}
                    className={"pdf-full-height"}
                >
                    <img alt={""} style={{width: "100%", height: "100%"}} src={template.sceneBackThumb}/>
                </MDBox>
            </Stack>
        </DialogContent>
    </Dialog>
}

export default ShowTemplateImagesModal