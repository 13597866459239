import { useState } from "react";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Box, Dialog, DialogContent, DialogTitle, Stack, Tooltip } from "@mui/material";
import MDButton from "material-ui/components/MDButton";
import ProfileRecipientService from "services/profile-recipient";
import OrderRecipientService from "services/order-recipient";
import RecipientTag from "components/recipient-tag";
import { GridSearchParams } from "models/gridSearchParams";
import Table from "components/table";
import { useGlobal } from "context/global-context";

interface AddRecipientsFromAddressBookModalProps {
  show: boolean
  setShow: Function
  orderId: number
  setShowGrid: Function
}

const defaultSearchParams: GridSearchParams = { page: 1, pageSize: 100, sortBy: "", sortDirection: "asc", search: "", filters: "" }

const AddRecipientsFromAddressBookModal = ({ show, setShow, orderId, setShowGrid }: AddRecipientsFromAddressBookModalProps) => {
  const queryClient = useQueryClient()
  const { setShowLoader } = useGlobal()

  const { getProfileRecipientsServerPagination } = ProfileRecipientService()
  const { addFromAddressBook } = OrderRecipientService()

  const [allSelected, setAllSelected] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [searchParams, setSearchParams] = useState<GridSearchParams>(defaultSearchParams);

  const { data, isLoading } = useQuery({
    queryKey: ['profileRecipients', searchParams],
    queryFn: () => getProfileRecipientsServerPagination(...Object.values(searchParams)),
    refetchOnWindowFocus: false,
  });
  const addresses = data?.payload || []
  const totalRowCount = data?.totalCount

  const { mutate: updateSelectedAddress, isPending } = useMutation({
    mutationFn: () => addFromAddressBook(orderId, selectedRows, searchParams, allSelected),
    onMutate: () => setShowLoader(true),
    onSuccess: () => {
      setShowLoader(false)
      setShowGrid(true)
      setShow(false)
      queryClient.invalidateQueries({ queryKey: ["orderRecipients", orderId] })
    }
  })

  const handleCloseModal = () => {
    setSelectedRows([])
    setShow(false)
  }

  const renderTags = (params: GridRenderCellParams) => {
    if (!params.value) return null
    const tagsList: string[] = params.value.split(',')
    const tags = tagsList.map((tag, index) => <RecipientTag name={tag} key={index} />)
    return (
      <Tooltip arrow={false} title={<Box>{tags}</Box>}>
        <Box overflow={"hidden"} whiteSpace={"nowrap"} textOverflow={"ellipsis"}>
          {tags}
        </Box>
      </Tooltip>
    )
  }

  const columns: GridColDef[] = [
    { headerName: "First Name", field: "firstName", width: 150 },
    { headerName: "Last Name", field: "lastName", width: 150 },
    { headerName: "Address 1", field: "address1", flex: 1, minWidth: 200 },
    { headerName: "Address 2", field: "address2", width: 150 },
    { headerName: "City", field: "city", width: 110 },
    { headerName: "State", field: "state", width: 72, align: "center" },
    { headerName: "Zip Code", field: "zipCode", width: 92, align: "center" },
    { headerName: "To Organization", field: "toOrganization", width: 150 },
    { headerName: "Tags", field: "tags", flex: 1, headerAlign: "right", align: "right", minWidth: 200, renderCell: renderTags }
  ]

  return (
    <Dialog fullWidth open={show} onClose={handleCloseModal} maxWidth={false} sx={{ maxWidth: 2000, marginX: "auto" }}>
      <DialogContent>
        <DialogTitle>Add recipients from your address book</DialogTitle>
        <Table
          serverDriven
          rows={addresses}
          columns={columns}
          isLoading={isLoading}
          tableHeight={"55vh"}
          totalRowCount={totalRowCount}
          selectedRows={selectedRows}
          allSelected={allSelected}
          setAllSelected={setAllSelected}
          setSearchParams={setSearchParams}
          setSelectedRows={setSelectedRows}
        />

        <Stack direction="row" spacing={2} marginTop={2} justifyContent="flex-end" >
          <MDButton circular color="light" onClick={handleCloseModal}>
            Cancel
          </MDButton>
          <MDButton
            circular
            color="primary"
            onClick={() => updateSelectedAddress()}
            disabled={!selectedRows.length || isPending}
          >
            Save
          </MDButton>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default AddRecipientsFromAddressBookModal;