import { useEffect, useState } from 'react';
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import { useLocation, useNavigate } from 'react-router-dom';
import MDBox from 'material-ui/components/MDBox';
import StyledDataGrid from 'components/styled-data-grid';
import { useQueryClient } from '@tanstack/react-query';
import InfoModal, { InfoModalProps } from 'components/info-modal';
import { IVM_GridOrder } from 'models/views/gridOrderVm';
import { IntegrationOrderStatus } from 'models/enums/IntegrationOrderStatus';
import { GridRowId } from '@mui/x-data-grid';
import OrderService from 'services/order';
import TwoButtonWithOptionalFormFieldModal, {
  TwoButtonModalProps,
} from 'components/modal-with-two-buttons-and-textfield';
import TemplateService from 'services/template';
import CopyOrderModal from 'components/order-details-modal/components/copy-order-modal';
import SmartAutomationService from 'services/smart-automation';
import { gridColumns } from 'helpers/gridColumns';
import SmartAutomationDetail from '../smart-automation-detail';
import { ISmartAutomation } from 'services/smart-automation/smart-automation.types';
import ConfirmStatusChange from 'components/confirm-status-change';

interface SmartAutomationGridProps {
  data: ISmartAutomation[];
  isLoading: boolean;
}

const SmartAutomationGrid = ({ data, isLoading }: SmartAutomationGridProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const automationId = queryParams.get('id');

  const { changeOrderName } = OrderService();
  const { createTemplateFromOrder } = TemplateService();
  const { deleteSmartAutomation, copySmartAutomation } = SmartAutomationService();

  const [expandedRowId, setExpandedRowId] = useState<GridRowId>(null);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const [currentOrder, setCurrentOrder] = useState<IVM_GridOrder>(null);
  const [orderName, setOrderName] = useState<string>('');
  const [newStatus, setNewStatus] = useState<IntegrationOrderStatus>(null);
  const [templateName, setTemplateName] = useState<string>('');
  const [newName, setNewName] = useState<string>('');

  const [showConfirmStatusModal, setShowConfirmStatusModal] = useState<boolean>(false);
  const [showCopyOrderModal, setShowCopyOrderModal] = useState<boolean>(false);
  const [showFormModal, setShowFormModal] = useState<string>('');
  const [showInfoModal, setShowInfoModal] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const apiRef = useGridApiRef();

  useEffect(() => {
    if (showFormModal === 'editName') setOrderName(currentOrder?.name);
    else if (showFormModal === 'createTemplate') setTemplateName('');
  }, [currentOrder?.name, showFormModal]);

  useEffect(() => {
    if (automationId) setExpandedRowId(Number(automationId));
  }, [automationId]);

  const columns: GridColDef[] = gridColumns({
    setCurrentOrder,
    setShowEditNameModal: () => setShowFormModal('editName'),
    setOrderName,
    handleStatusChange: (order, value) => {
      setNewStatus(value);
      setCurrentOrder(order);
      setShowConfirmStatusModal(true);
    },
    setShowDeleteOrderModal: () => setShowInfoModal('delete'),
    setShowCreateTemplateModal: () => setShowFormModal('createTemplate'),
    setShowCopyIntegrationOrderModal: () => setShowFormModal('copyAutomation'),
    setShowCopyOrderModal,
    navigate,
    showPlatform: true,
  });

  const handleAutomationNameChange = () => {
    setShowFormModal('');
    setShowLoading(true);
    changeOrderName({ ...currentOrder, name: orderName }).then(() => {
      const gridApi = apiRef.current;
      if (gridApi) gridApi.updateRows([{ id: currentOrder.id, name: orderName }]);
      setShowLoading(false);
    });
  };

  const handleCreateTemplate = () => {
    setShowFormModal('');
    setShowLoading(true);
    createTemplateFromOrder(currentOrder.templateId, templateName).then((response) => {
      setShowLoading(false);
      navigate('/my-templates');
    });
  };

  const handleCopyAutomation = () => {
    setShowFormModal('');
    setShowLoading(true);
    copySmartAutomation({ ...currentOrder, name: newName }).then((response) => {
      setShowLoading(false);
      queryClient.invalidateQueries({ queryKey: ['allSmartAutomations'] });
    });
  };

  const handleDeleteAutomation = () => {
    setShowInfoModal('');
    setShowLoading(true);
    deleteSmartAutomation(currentOrder.id).then(() => {
      queryClient.invalidateQueries({ queryKey: ['allSmartAutomations'] });
      setShowLoading(false);
    });
  };

  const infoModalOptions: { [key: string]: Omit<InfoModalProps, 'show' | 'setShow'> } = {
    success: {
      showCancelButton: false,
      headerText: 'Automation triggered successfully! Please check back in a few minutes to see the results.',
      confirmButtonOnClick: () => setShowInfoModal(''),
    },
    delete: {
      headerText: 'Are you sure you want to delete this smart automation?',
      confirmButtonOnClick: () => handleDeleteAutomation(),
    },
    error: {
      showCancelButton: false,
      headerText: errorMessage,
      confirmButtonOnClick: () => {
        setShowInfoModal('');
        setErrorMessage('');
      },
    },
  };

  const formModalOptions: {
    [key: string]: Omit<TwoButtonModalProps, 'onClose' | 'open' | 'secondaryButtonOnClick' | 'secondaryButtonText'>;
  } = {
    editName: {
      formFieldValue: orderName,
      formFieldOnChange: setOrderName,
      formFieldLabel: 'Edit Name',
      formFieldHelperText: 'This will help you identify your smart automation',
      primaryButtonText: 'Okay',
      primaryButtonOnClick: handleAutomationNameChange,
    },
    createTemplate: {
      formFieldLabel: 'Template Name',
      formFieldHelperText: 'Please name your template, this will help you identify it later',
      formFieldValue: templateName,
      formFieldOnChange: setTemplateName,
      primaryButtonText: 'Create',
      primaryButtonOnClick: () => handleCreateTemplate(),
    },
    copyAutomation: {
      formFieldLabel: 'Smart Automation Name',
      formFieldHelperText: 'Please name your utomation, this will help you identify it later',
      formFieldValue: newName,
      formFieldOnChange: setNewName,
      primaryButtonText: 'Copy',
      primaryButtonOnClick: () => handleCopyAutomation(),
    },
  };

  return (
    <MDBox height={'auto'}>
      <StyledDataGrid
        apiRef={apiRef}
        backgroundColor={'white'}
        loading={isLoading || showLoading}
        rows={data}
        columns={columns}
        disableRowSelectionOnClick
        pagination={false}
        hideFooter
        getDetailPanelHeight={() => 'auto'}
        detailPanelExpandedRowIds={expandedRowId ? [expandedRowId] : []}
        onDetailPanelExpandedRowIdsChange={(ids) => setExpandedRowId(ids?.[ids.length - 1] || null)}
        getDetailPanelContent={({ row }) => (
          <SmartAutomationDetail
            key={`${row.name}${row.status}`}
            orderId={row.id}
            status={row.status}
            updateRows={apiRef.current?.updateRows}
          />
        )}
      />

      <InfoModal
        showCancelButton
        showConfirmButton
        show={!!showInfoModal}
        setShow={() => setShowInfoModal('')}
        cancelButtonOnClick={() => setShowInfoModal('')}
        {...infoModalOptions[showInfoModal || 'confirm']}
      />

      <ConfirmStatusChange
        order={currentOrder}
        status={newStatus}
        open={showConfirmStatusModal}
        setOpen={setShowConfirmStatusModal}
        updateRows={apiRef.current?.updateRows}
      />

      <TwoButtonWithOptionalFormFieldModal
        open={!!showFormModal}
        onClose={() => setShowFormModal('')}
        secondaryButtonOnClick={() => setShowFormModal('')}
        secondaryButtonText="Cancel"
        {...formModalOptions[showFormModal || 'editName']}
      />

      <CopyOrderModal show={showCopyOrderModal} setShow={setShowCopyOrderModal} order={currentOrder} />
    </MDBox>
  );
};

export default SmartAutomationGrid;
