import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const PastImagesIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3423_16493)">
                    <path d="M17.375 4.375V3.75C17.3736 3.25316 17.1756 2.77709 16.8242 2.42577C16.4729 2.07445 15.9968 1.87644 15.5 1.875H3C2.50316 1.87644 2.02709 2.07445 1.67577 2.42577C1.32445 2.77709 1.12644 3.25316 1.125 3.75V13.75C1.12644 14.2468 1.32445 14.7229 1.67577 15.0742C2.02709 15.4256 2.50316 15.6236 3 15.625H3.625" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
                    <path d="M18.0785 5H6.04648C5.05431 5 4.25 5.80431 4.25 6.79648V16.3285C4.25 17.3207 5.05431 18.125 6.04648 18.125H18.0785C19.0707 18.125 19.875 17.3207 19.875 16.3285V6.79648C19.875 5.80431 19.0707 5 18.0785 5Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
                    <path d="M15.0672 9.77305C15.731 9.77305 16.2691 9.23876 16.2691 8.57969C16.2691 7.92061 15.731 7.38633 15.0672 7.38633C14.4034 7.38633 13.8652 7.92061 13.8652 8.57969C13.8652 9.23876 14.4034 9.77305 15.0672 9.77305Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"/>
                    <path d="M13.8652 14.5379L10.4609 11.1633C10.2433 10.9483 9.95222 10.8238 9.64642 10.815C9.34062 10.8063 9.04287 10.9138 8.81328 11.116L4.25 15.1422M10.8605 18.125L15.493 13.5262C15.7061 13.3159 15.9896 13.1921 16.2887 13.1786C16.5878 13.1652 16.8813 13.2629 17.1125 13.4531L19.875 15.7387" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_3423_16493">
                        <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
                    </clipPath>
                </defs>
            </svg>

        </SvgIcon>
    )
}

export default PastImagesIcon;