import {Grid} from "@mui/material";
import EmptyCartIcon from "assets/icons/empty-cart";
import React from "react";

interface NoOrdersBlockProps{
    description: React.ReactNode
}

function NoOrdersBlock({description}: NoOrdersBlockProps){
    return <Grid container alignItems={"center"} gap={1} flexDirection={"column"}>
        <Grid item>
            <EmptyCartIcon sx={{width: "60px", height: "60px"}} color={"primary"}/>
        </Grid>

        <Grid item>
            {description}
        </Grid>
    </Grid>
}

export default NoOrdersBlock
