import { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Box } from '@mui/material';
import MDButton from 'material-ui/components/MDButton';
import { useGlobal } from 'context/global-context';
import FormikErrorMessage from 'components/formik-error-message';
import MDCard from 'material-ui/components/MDCard';

interface MoversStripeProps {
  buttonText: string
  setOpen: (open: boolean) => void;
  submit: (paymentId: string) => void;
}

const MoversStripe = ({ buttonText, setOpen, submit }: MoversStripeProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const { setShowLoader } = useGlobal();
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const enableAutomation = async () => {
    if (!stripe || !elements) return;
    setShowLoader(true);
    const card = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({ type: 'card', card });
    if (error) setErrorMessage(error.message);
    else submit(paymentMethod.id);
    setShowLoader(false);
  };

  return (
    <>
      <MDCard borderRadiusSize={'md'} border={true} boxShadow={true} boxShadowSize={'small'} sx={{ p: 1, my: 1 }}>
        <CardElement onChange={(e) => setCanSubmit(e.complete)} />
      </MDCard>
      <FormikErrorMessage meta={{ touched: true, error: errorMessage }} />
      <Box display={'flex'} justifyContent={'space-between'} gap={2}>
        <MDButton fullWidth onClick={() => enableAutomation()} disabled={!canSubmit}>
          {buttonText}
        </MDButton>
        <MDButton color="light" onClick={() => setOpen(false)}>
          Cancel
        </MDButton>
      </Box>
    </>
  );
};

export default MoversStripe;
