import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import useSourceInstallRequest from 'hooks/useSourceInstallRequest';

const DataSourceSetup = () => {
  const navigate = useNavigate();
  const { source } = useParams();
  const { setInstallSource } = useSourceInstallRequest();

  useEffect(() => {
    if (source === 'klaviyo') setInstallSource('klaviyo');
    else navigate('/settings/smart-automation-setup');
  }, [setInstallSource, navigate, source]);

  const transformString = (str: string) => {
    let words = str.split('-');
    words = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return words.join(' ');
  };

  return (
    <Box
      height={'100vh'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      color={'#F05A2A'}
    >
      <Typography variant="h3" color={'#130B46'} mb={3} textAlign={'center'} width={'90vw'}>
        Setting up {transformString(source)}...
      </Typography>
    </Box>
  );
};

export default DataSourceSetup;
