import classes from "./index.module.scss"
import MDBox from "material-ui/components/MDBox"
import {useEffect, useRef} from "react";
import {useEditor} from "context/editor-context";
import Loader from "components/loader";

interface CardCanvasProps {
    editorType: "Card" | "Envelope"
}

function CardCanvas({editorType}: CardCanvasProps): JSX.Element {
    const wrapperRef = useRef(null)

    const {sceneIsLoaded, engineIsLoaded, creativeEngine, loadCurrentScene} = useEditor()

    useEffect(() => {
        if (!engineIsLoaded || !sceneIsLoaded) {
            return
        }

        const container = wrapperRef.current
        const canvas = creativeEngine.element

        canvas.style.height = "100%"
        canvas.style.width = "100%"

        container.append(canvas)
    }, [engineIsLoaded, sceneIsLoaded, creativeEngine])

    useEffect(() => {
        if (engineIsLoaded) {
            loadCurrentScene(editorType)
        }
        //setEditorDoesNotUpdateOrder(false)
        // Add this for scene texting to the mdbox below
        // <MDButton onClick={() => {loadCurrentScene(editorType)}}>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [engineIsLoaded])

    return (
        engineIsLoaded && sceneIsLoaded?
            <MDBox className={classes.canvasWrapper} ref={wrapperRef}>
            </MDBox>
            :
            <MDBox sx={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Loader/>
            </MDBox>
    )
}

export default CardCanvas
