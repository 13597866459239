import { AdminPanelSettings, Feedback, ManageSearch, Money, SupportAgent, TrackChanges, Tune } from '@mui/icons-material';

import AdminFeedbackPage from 'pages/admin/feedback';
import AdminCustomerSuccessDashboardPage from 'pages/admin/customer-success';
import AdminSalesRevenueDashboard from 'pages/admin/sales-revenue';
import AdminChangeOrderStatusPage from 'pages/superadmin/change-order-status';
import AdminOrderTracking from 'pages/admin/order-tracking';
import AdminOrdersPage from 'pages/superadmin/order';

import { RouteType } from './types';

const sidebarAdminRoutes: RouteType[] = [
  {
    icon: <AdminPanelSettings />,
    name: 'Admin',
    hasDivider: true,
    collapse: [
      {
        icon: <Feedback />,
        name: 'Customer Feedback',
        route: '/admin/feedback',
        component: <AdminFeedbackPage />,
      },
      {
        icon: <SupportAgent />,
        name: 'Customer Success',
        route: '/admin/customer-success',
        component: <AdminCustomerSuccessDashboardPage />,
      },
      {
        icon: <Money />,
        name: 'Sales Dashboard',
        route: '/admin/sales-revenue',
        component: <AdminSalesRevenueDashboard />,
      },
      {
        icon: <Tune />,
        name: 'Change Order Status',
        route: '/admin/order-statuses',
        component: <AdminChangeOrderStatusPage />,
      },
      {
        icon: <ManageSearch />,
        name: 'Order',
        route: '/admin/order',
        component: <AdminOrdersPage />,
      },
      {
        icon: <TrackChanges />,
        name: "Order Tracking",
        route: "/admin/order-tracking",
        component: <AdminOrderTracking />,
      },
    ],
  },
];

export default sidebarAdminRoutes;
