import MDBox from "material-ui/components/MDBox";
import MDCard from "material-ui/components/MDCard";
import {ReactNode} from "react";
import {Grid, Stack} from "@mui/material";
import MDButton from "material-ui/components/MDButton";

interface LayoutMainProps{
    children: ReactNode
    showBackButton?: boolean
    showNextButton?: boolean
    backButtonOnClick?: Function
    nextButtonOnClick?: Function
    backButtonDisabled?: boolean
    nextButtonDisabled?: boolean
    maxWidth?: boolean
    backButtonText?: string
    nextButtonText?: string
    customMaxWidth?: number
}

function LayoutMain({children, showBackButton, showNextButton, backButtonOnClick, nextButtonOnClick, backButtonDisabled, nextButtonDisabled, maxWidth=true, backButtonText = "Back", nextButtonText = "Next", customMaxWidth=1530}: LayoutMainProps){
    return <MDCard color={"white"} borderRadiusSize={"xxxl"} sx={{height: "100%"}}>
        <MDBox p={2} display={"flex"} justifyContent={"center"} sx={{height: "100%"}}>
            <MDBox width={"100%"} maxWidth={maxWidth? `${customMaxWidth}px` : "unset"} sx={{height: "100%"}}>
                <Grid container gap={2} flexWrap={"nowrap"} flexDirection={"column"} height={"100%"}>
                    <Grid item flex={1}>
                        {children}
                    </Grid>

                    <Grid item>
                        <Grid item>
                            <Stack direction="row" mt={3} justifyContent="space-between">
                                <Grid item>
                                    {showBackButton?
                                        <MDButton color={"light"} circular={true} onClick={() => backButtonOnClick()} disabled={backButtonDisabled}>
                                            {backButtonText}
                                        </MDButton>
                                        :
                                        null
                                    }
                                </Grid>

                                <Grid item>
                                    {showNextButton?
                                        <MDButton color={"primary"} circular={true} onClick={() => nextButtonOnClick()} disabled={nextButtonDisabled}>
                                            {nextButtonText}
                                        </MDButton>
                                        :
                                        null
                                    }
                                </Grid>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    </MDCard>
}

export default LayoutMain