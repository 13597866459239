import { useEffect, useState } from 'react';
import { Field, FieldProps, Form } from 'formik';
import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material';
import { Order } from 'models/order';
import MDInput from 'material-ui/components/MDInput';
import { ReturnAddress } from 'models/returnAddress';
import states from 'helpers/states';
import FormikErrorMessage from 'components/formik-error-message';
import useReturnAddressHandler from 'hooks/useReturnAddressHandler';
import { useGlobal } from 'context/global-context';

interface ReturnAddressFormProps {
  values: Partial<Order>;
  setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void;
  isSubmitting: boolean;
}

const ReturnAddressForm = ({ values, setFieldValue, isSubmitting }: ReturnAddressFormProps) => {
  const { setShowLoader } = useGlobal();
  const { setReturnAddressScene } = useReturnAddressHandler();
  const [selectedReturnAddress, setSelectedReturnAddress] = useState<ReturnAddress>(null);

  useEffect(() => {
    if (isSubmitting) setShowLoader(isSubmitting);
  }, [isSubmitting, setShowLoader]);

  const handleFieldChange = (field: string, value: string) => {
    if (selectedReturnAddress !== null) setSelectedReturnAddress(null);
    setReturnAddressScene({ ...values, [field]: value });
    setFieldValue(field, value);
  };

  return (
    <Form>
      <Stack gap={1}>
        <Box display={'flex'} gap={1}>
          <Field name="returnFirstName">
            {({ field, meta }: FieldProps) => (
              <Stack flex={1} gap={1}>
                <Typography variant="body2" fontWeight={'bold'}>
                  First name
                </Typography>
                <Box>
                  <MDInput
                    {...field}
                    fullWidth
                    placeholder={'First name'}
                    disabledWithoutOpacity
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleFieldChange('returnFirstName', e.target.value)
                    }
                  />
                  <FormikErrorMessage meta={meta} />
                </Box>
              </Stack>
            )}
          </Field>
          <Field name="returnLastName">
            {({ field, meta }: FieldProps) => (
              <Stack flex={1} gap={1}>
                <Typography variant="body2" fontWeight={'bold'}>
                  Last name
                </Typography>
                <Box>
                  <MDInput
                    {...field}
                    fullWidth
                    placeholder={'Last name'}
                    disabledWithoutOpacity
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleFieldChange('returnLastName', e.target.value)
                    }
                  />
                  <FormikErrorMessage meta={meta} />
                </Box>
              </Stack>
            )}
          </Field>
        </Box>
        <Field name="returnOrganization">
          {({ field, meta }: FieldProps) => (
            <Stack gap={1}>
              <Typography variant="body2" fontWeight={'bold'}>
                Organization
              </Typography>
              <Box>
                <MDInput
                  {...field}
                  fullWidth
                  placeholder={'Organization'}
                  disabledWithoutOpacity
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange('returnOrganization', e.target.value)
                  }
                />
                <FormikErrorMessage meta={meta} />
              </Box>
            </Stack>
          )}
        </Field>
        <Typography variant="body2" fontWeight={'bold'}>
          Address
        </Typography>
        <Field name="returnAddress1">
          {({ field, meta }: FieldProps) => (
            <Box>
              <MDInput
                {...field}
                fullWidth
                placeholder={'Address 1'}
                disabledWithoutOpacity
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleFieldChange('returnAddress1', e.target.value)
                }
              />
              <FormikErrorMessage meta={meta} />
            </Box>
          )}
        </Field>
        <Field name="returnAddress2">
          {({ field, meta }: FieldProps) => (
            <Box>
              <MDInput
                {...field}
                fullWidth
                placeholder={'Address 2'}
                disabledWithoutOpacity
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleFieldChange('returnAddress2', e.target.value)
                }
              />
              <FormikErrorMessage meta={meta} />
            </Box>
          )}
        </Field>
        <Box display={'flex'} gap={1}>
          <Field name="returnCity">
            {({ field, meta }: FieldProps) => (
              <Box flex={1}>
                <MDInput
                  {...field}
                  fullWidth
                  placeholder={'City'}
                  disabledWithoutOpacity
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFieldChange('returnCity', e.target.value)}
                />
                <FormikErrorMessage meta={meta} />
              </Box>
            )}
          </Field>
          <Field name="returnState">
            {({ field, meta }: FieldProps) => (
              <Box width={130}>
                <Autocomplete
                  {...field}
                  fullWidth
                  options={states}
                  renderInput={(props) => <TextField {...props} placeholder={'State'} />}
                  onChange={(_, value) => handleFieldChange('returnState', value)}
                />
                <FormikErrorMessage meta={meta} />
              </Box>
            )}
          </Field>
          <Field name="returnZip">
            {({ field, meta }: FieldProps) => (
              <Box width={80}>
                <MDInput
                  {...field}
                  placeholder={'Zip'}
                  disabledWithoutOpacity
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFieldChange('returnZip', e.target.value)}
                />
                <FormikErrorMessage meta={meta} />
              </Box>
            )}
          </Field>
        </Box>
      </Stack>
    </Form>
  );
};

export default ReturnAddressForm;
