import React, {useState} from "react";
import {useEditor} from "context/editor-context";

import MDCard from "material-ui/components/MDCard";
import MDBox from "material-ui/components/MDBox";
import {Box, Divider, Grid, Tooltip} from "@mui/material";
import UploadIcon from "assets/icons/upload";
import BrandArtworkIcon from "assets/icons/brand-artwork";
import DeleteIcon from "assets/icons/delete";
import {alignBlock, removeBlock, stretchImageToFullBleed} from "helpers/ImgLyHelper";
import MDButton from "material-ui/components/MDButton";
import AlignTopLeftIcon from "assets/icons/align-top-left";
import AlignBottomRightIcon from "assets/icons/align-bottom-right";
import AlignBottomCenterIcon from "assets/icons/align-bottom-center";
import AlignBottomLeftIcon from "assets/icons/align-bottom-left";
import AlignTopRightIcon from "assets/icons/align-top-right";
import AlignTopCenterIcon from "assets/icons/align-top-center";
import PastImagesIcon from "assets/icons/past-images";
import {useQuery} from "@tanstack/react-query";
import SelectImageModal from "../select-image-modal/select-image-modal";
import ProfileArtifactService from "services/profile-artifact";
import ArtifactService from "services/artifact-service";
import {Order} from "models/order";
import InfoModal from "components/info-modal";
import AddImageIcon from "assets/icons/add-image";
import AddPremadeDesignModal from "../add-premade-design-modal";
import {orderProductIsPrintedCard, ProductTypes} from "models/enums/ProductTypes";
import StretchFullIcon from "assets/icons/stretch-full";
import MDTypography from "material-ui/components/MDTypography";
import LayerManagementBar from "../layer-management-bar";
import MDIconButton from "material-ui/components/MDIconButton";

interface ImageBarProps{
    order: Order
}

function ImageBar({order}: ImageBarProps) {
    const {selectedBlocks, uploadNewImageArtifact, creativeEngine, checkImageQuality, checkForBlocksOnGivenSide, resizeImageAsLogoSize, setSelectedBlocks} = useEditor()

    const {getProfileArtifacts} = ProfileArtifactService()
    const {getPastOrderArtifacts} = ArtifactService()

    const getBrandArtworkQuery = useQuery({
        queryKey: ["profileArtifacts"],
        queryFn: getProfileArtifacts
    })

    const getPastOrderArtifactsQuery = useQuery({
        queryKey: ["pastOrderArtifacts"],
        queryFn: getPastOrderArtifacts
    })

    const [showBrandArtworkModal, setShowBrandArtworkModal] = useState<boolean>(false)
    const [showPastImagesModal, setShowPastImagesModal] = useState<boolean>(false)

    const [showNoBrandArtworkModal, setShowNoBrandArtworkModal] = useState<boolean>(false)
    const [showNoPastImagesModal, setShowNoPastImagesModal] = useState<boolean>(false)

    const [showCardDesignsModal, setShowCardDesignsModal] = useState<boolean>(false)

    return <MDCard sx={{width: "100%"}} boxShadow={false} border={true} borderColor={"light"} borderRadiusSize={"md"}>
        <MDBox p={1.5}>
            <Grid container alignItems={"stretch"} gap={1}>
                <Grid item>
                    <Tooltip title="Upload Image">
                        <MDIconButton sx={{ py: 2 }} backgroundColor={"light"} onClick={async () => {
                            await uploadNewImageArtifact()

                            if(order?.product === ProductTypes.HandwrittenBiFoldCard) checkForBlocksOnGivenSide(0)
                            else checkForBlocksOnGivenSide(1)
                        }}>
                            <UploadIcon/>
                        </MDIconButton>
                    </Tooltip>
                </Grid>

                <Grid px={1} item>
                    <Divider light={true} orientation={"vertical"}/>
                </Grid>

                <Grid item>
                    <Tooltip title="Brand Artwork">
                        <Box>
                            <MDIconButton
                                disabled={getBrandArtworkQuery.isLoading}
                                sx={{ py: 2 }}
                                backgroundColor={"light"}
                                onClick={() => {
                                    if(!getBrandArtworkQuery.data?.payload.length) {
                                        setShowNoBrandArtworkModal(true)
                                    }
                                    else {
                                        setShowBrandArtworkModal(true)
                                    }
                                }}
                            >
                                <BrandArtworkIcon/>
                            </MDIconButton>
                        </Box>
                    </Tooltip>
                </Grid>

                <Grid item>
                    <Tooltip title="Past Images">
                        <Box>
                            <MDIconButton 
                                disabled={getPastOrderArtifactsQuery.isLoading}
                                sx={{ py: 2 }}                                backgroundColor={"light"} 
                                onClick={() => {
                                    if(!getPastOrderArtifactsQuery.data?.length) {
                                        setShowNoPastImagesModal(true)
                                    }
                                    else {
                                        setShowPastImagesModal(true)
                                    }
                                }}
                            >
                                <PastImagesIcon/>
                            </MDIconButton>
                        </Box>
                    </Tooltip>
                </Grid>

                {!selectedBlocks?.length && !orderProductIsPrintedCard(order.product)?
                    <Grid item>
                        <Tooltip title="Card Designs">
                            <MDIconButton sx={{ py: 2 }} backgroundColor={"light"} onClick={() => {
                                setShowCardDesignsModal(true)
                            }}>
                                <AddImageIcon/>
                            </MDIconButton>
                        </Tooltip>
                    </Grid>
                    :
                    null
                }

                {selectedBlocks?.length ?
                    <>
                        <Grid px={1} item>
                            <Divider light={true} orientation={"vertical"}/>
                        </Grid>

                        <Grid item>
                            <Tooltip title="Delete">
                                <MDIconButton sx={{ py: 2 }} backgroundColor={"light"} onClick={async () => {
                                    removeBlock(creativeEngine, selectedBlocks[0].id)

                                    await checkImageQuality()

                                    if(order?.product === ProductTypes.HandwrittenBiFoldCard) checkForBlocksOnGivenSide(0)
                                    else checkForBlocksOnGivenSide(1)
                                    setSelectedBlocks(null)
                                }}>
                                    <DeleteIcon color={"error"}/>
                                </MDIconButton>
                            </Tooltip>
                        </Grid>
                    </>
                    :
                    null
                }
            </Grid>

            {selectedBlocks?.length?
                <>
                    <Divider light={true}/>

                    <MDTypography sx={{fontSize: 16}} fontWeight={"bold"}>Image Placement</MDTypography>

                    <Grid container alignItems={"center"} gap={1} mt={1}>
                        <Grid item>
                            <Tooltip title={"Align top left"}>
                                <MDButton color={"light"} sx={{padding: "20px", flexDirection: "column", alignItems: "center"}} onClick={() => {
                                    resizeImageAsLogoSize(selectedBlocks[0].id)
                                    alignBlock(creativeEngine, selectedBlocks[0].id, "Top", "Left")
                                }}>
                                    <AlignTopLeftIcon sx={{display: "block"}} color={"error"}/>
                                </MDButton>
                            </Tooltip>
                        </Grid>

                        <Grid item>
                            <Tooltip title={"Align top center"}>
                                <MDButton color={"light"} sx={{padding: "20px", flexDirection: "column", alignItems: "center"}} onClick={() => {
                                    resizeImageAsLogoSize(selectedBlocks[0].id)
                                    alignBlock(creativeEngine, selectedBlocks[0].id, "Top", "Center")
                                }}>
                                    <AlignTopCenterIcon sx={{display: "block"}} color={"error"}/>
                                </MDButton>
                            </Tooltip>
                        </Grid>

                        <Grid item>
                            <Tooltip title={"Align top right"}>
                                <MDButton color={"light"} sx={{padding: "20px", flexDirection: "column", alignItems: "center"}} onClick={() => alignBlock(creativeEngine, selectedBlocks[0].id, "Top", "Right")}>
                                    <AlignTopRightIcon sx={{display: "block"}} color={"error"}/>
                                </MDButton>
                            </Tooltip>
                        </Grid>

                        <Grid item>
                            <Tooltip title={"Align bottom left"}>
                                <MDButton color={"light"} sx={{padding: "20px", flexDirection: "column", alignItems: "center"}} onClick={() => alignBlock(creativeEngine, selectedBlocks[0].id, "Bottom", "Left")}>
                                    <AlignBottomLeftIcon sx={{display: "block"}} color={"error"}/>
                                </MDButton>
                            </Tooltip>
                        </Grid>

                        <Grid item>
                            <Tooltip title={"Align bottom center"}>
                                <MDButton color={"light"} sx={{padding: "20px", flexDirection: "column", alignItems: "center"}} onClick={() => alignBlock(creativeEngine, selectedBlocks[0].id, "Bottom", "Center")}>
                                    <AlignBottomCenterIcon sx={{display: "block"}} color={"error"}/>
                                </MDButton>
                            </Tooltip>
                        </Grid>

                        <Grid item>
                            <Tooltip title={"Align bottom right"}>
                                <MDButton color={"light"} sx={{padding: "20px", flexDirection: "column", alignItems: "center"}} onClick={() => alignBlock(creativeEngine, selectedBlocks[0].id, "Bottom", "Right")}>
                                    <AlignBottomRightIcon sx={{display: "block"}} color={"error"}/>
                                </MDButton>
                            </Tooltip>
                        </Grid>

                        <Grid item>
                            <Tooltip title={"Full Bleed"}>
                                <MDButton color={"light"} sx={{padding: "20px", flexDirection: "column", alignItems: "center"}} onClick={() => stretchImageToFullBleed(creativeEngine, selectedBlocks[0].id, order?.product)}>
                                    <StretchFullIcon sx={{display: "block"}}/>
                                </MDButton>
                            </Tooltip>
                        </Grid>
                    </Grid>

                   <LayerManagementBar/>
                </>
                :
                null
            }
        </MDBox>

        <SelectImageModal show={showBrandArtworkModal} setShow={setShowBrandArtworkModal} type={"Brand Artwork"} order={order}/>
        <SelectImageModal show={showPastImagesModal} setShow={setShowPastImagesModal} type={"Past Images"} order={order}/>

        <InfoModal
            show={showNoBrandArtworkModal}
            setShow={setShowNoBrandArtworkModal}
            headerText={"You haven't uploaded any profile artwork yet. You can do that from your Profile page"}
            showCancelButton={false}
            showConfirmButton={true}
            confirmButtonOnClick={() => {
                setShowNoBrandArtworkModal(false)
            }}
        />

        <InfoModal
            show={showNoPastImagesModal}
            setShow={setShowNoPastImagesModal}
            headerText={"You haven't uploaded images to any orders yet."}
            showCancelButton={false}
            showConfirmButton={true}
            confirmButtonOnClick={() => {
                setShowNoPastImagesModal(false)
            }}
        />

        <AddPremadeDesignModal
            show={showCardDesignsModal}
            setShow={setShowCardDesignsModal}
            order={order}
        />
    </MDCard>
}

export default ImageBar