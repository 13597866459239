import { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { AnyObjectSchema } from 'yup';

// Usage example for simple array:
// frontend\src\pages\smart-automation-upsert\components\smart-automation-form.tsx

const useFormikValidation = <T, K extends AnyObjectSchema>(schema: K, path: string) => {
	const formikProps = useFormikContext<T>();

	if (!formikProps) {
		throw new Error('useFormikValidation must be used inside of a Formik component');
	}

	const { values = null } = formikProps
	const [isValid, setIsValid] = useState(false);

	useEffect(() => {
		const validateField = async () => {
			try {
				await schema.validateAt(path, values, { abortEarly: false });
				setIsValid(true);
			} catch (error) {
				setIsValid(false);
			}
		};

		if (values) validateField();
	}, [values, path, schema]);

	return [isValid];
};

export default useFormikValidation;
