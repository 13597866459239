// @ts-ignore
import Retool from 'react-retool';
import { Box } from '@mui/material';

export default function AdminOrderTracking() {
  return (
    <Box height="90vh">
      <Retool url="https://lettrlabs.retool.com/p/order-tracking" />
    </Box>
  );
}