import MDCard from "material-ui/components/MDCard";
import MDBox from "material-ui/components/MDBox";
import {PaymentMethod} from "@stripe/stripe-js";
import {Grid} from "@mui/material";
import MDTypography from "material-ui/components/MDTypography";
import MDRadio from "material-ui/components/MDRadio";

interface PaymentCardProps{
    card: PaymentMethod
    selected?: boolean
    onClick?: Function
    variant?: "small" | "large"
    isDefault?: boolean
}

function PaymentCard({card, selected = false, isDefault = false, onClick = () => {}, variant="large"}: PaymentCardProps){
    return <MDCard color={"primary"} borderRadiusSize={"xl"} border={false} boxShadow={false} onClick={() => onClick()} sx={variant === "large"? {height: 180}: {}}>
        <MDBox p={2} height={"100%"}>
            <Grid container justifyContent={"space-between"} flexDirection={"column"} height={"100%"}>
                <Grid item>
                    <Grid container alignItems={"center"} gap={0.5}>
                        <Grid item>
                            <MDRadio checked={selected} color={"white"}/>
                        </Grid>

                        {isDefault?
                            <Grid item>
                                <MDTypography sx={{fontSize: 16}} fontWeight={"regular"} color={"white"}>Default</MDTypography>
                            </Grid>
                            :
                            null
                        }
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid container alignItems={"flex-end"} justifyContent={"space-between"}>
                        <Grid item>
                            <MDTypography variant={"h4"} fontWeight={"regular"} color={"white"}>**** **** **** {card.card.last4}</MDTypography>
                            <MDTypography variant={"h5"} fontWeight={"regular"} color={"white"}>{card.billing_details.name}</MDTypography>
                            <MDTypography variant={"h5"} fontWeight={"regular"} color={"white"}>EXP {card.card.exp_month}/{card.card.exp_year}</MDTypography>
                        </Grid>

                        <Grid item display={"flex"}>
                            {card.card.brand === "amex" &&
                                <img style={{width: "50px", height: "auto"}} alt={""} src={"https://js.stripe.com/v3/fingerprinted/img/visa-729c05c240c4bdb47b03ac81d9945bfe.svg"}/>
                            }
                            {card.card.brand === "visa" &&
                                <img style={{width: "50px", height: "auto"}} alt={""} src={"https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg"}/>
                            }
                            {card.card.brand === "amex" &&
                                <img style={{width: "50px", height: "auto"}} alt={""} src={"https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg"}/>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MDBox>
    </MDCard>
}


export default PaymentCard