import Dialog from "@mui/material/Dialog"
import { DialogContent } from "@mui/material"
import { useGlobal } from "context/global-context"
import Loader from "components/loader";
import './index.scss';

const LoadingModal = () => {
    const { showLoader } = useGlobal()

    return (
        <Dialog open={showLoader} sx={{borderRadius: 0, ".MuiPaper-root": {borderRadius: "20px"}}}>
            <DialogContent sx={{padding: 1}} >
                <Loader/>
            </DialogContent>
        </Dialog>
    )
}

export default LoadingModal
