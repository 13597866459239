/**
 =========================================================
 * Material Dashboard 2 PRO React - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { FC, ReactNode, forwardRef } from "react";

// @mui material components
import {ButtonProps} from "@mui/material";
import MDIconButtonRoot from "./MDIconButtonRoot";

// Material Dashboard 2 PRO React TS contexts

// Declaring props types for MDIconButton
interface Props extends Omit<ButtonProps, "color" | "variant"> {
    color?:
        | "white"
        | "primary"
        | "secondary"
        | "info"
        | "success"
        | "warning"
        | "error"
        | "light"
        | "dark"
        | "default";
    variant?: "primary";
    border?: boolean;
    children?: ReactNode;
    borderRadiusSize?:
        | "xs"
        | "sm"
        | "md"
        | "lg"
        | "xl"
        | "xxl"
        | "xxxl";
    square?: boolean
    backgroundColor?: "white" | "light" | "primary"
    size?: "small" | "medium" | "large"
    active?: boolean
    [key: string]: any;
}

const MDIconButton: FC<Props> = forwardRef(
    ({ color, variant, border, borderRadiusSize, backgroundColor, active, square, size, children, ...rest }, ref) => {
        return (
            <MDIconButtonRoot
                {...rest}
                ref={ref}
                ownerState={{ color, variant, border, borderRadiusSize, square, size, backgroundColor, active }}
            >
                {children}
            </MDIconButtonRoot>
        );
    }
);

// Declaring default props for MDIconButton
MDIconButton.defaultProps = {
    square: false,
    size: "medium",
    color: "secondary",
    variant: "primary",
    border: false,
    borderRadiusSize: "xl",
    backgroundColor: "white",
    active: false
};

export default MDIconButton;