export enum OrderStatus {
  Draft = "Draft",
  EditsNeeded = "Edits Needed",
  PaymentNeeded = "Payment Needed",
  Paid = "Paid",
  ReadyForProduction = "Ready For Production",
  InProduction = "In Production",
  InShipping = "InShipping",
  InQa = "In QA",
  Mailed = "Mailed",
  Cancelled = "Cancelled",
  Deleted = "Deleted"
}
