import {
    Checkbox,
    CircularProgress,
    DialogContent,
    Grid,
    TextField, Tooltip,
} from "@mui/material"
import {useState} from "react"
import MDButton from "material-ui/components/MDButton"
import {Dialog, Autocomplete} from "@mui/material"
import MDBox from "material-ui/components/MDBox"
import MDTypography from "material-ui/components/MDTypography"
import {IProfileTag} from "models/profileTag"
import {useMutation, useQueryClient, useQuery} from "@tanstack/react-query";
import ProfileTagService from "services/profile-tag";
import ProfileRecipientService from "services/profile-recipient";
import AddTagIcon from "assets/icons/add-tag";
import {formatNumber} from "helpers/formatters";
import {GridSearchParams} from "models/gridSearchParams";
import { useGlobal } from "context/global-context"

interface GroupRecipientsByTagsModalProps {
    show: boolean
    setShow: Function
    selectedRecipients: number[]
    showManageTagsModal: boolean
    setShowManageTagsModal: Function
    lastSearchParams: GridSearchParams;
    selectedAll: boolean
    totalCount: number
}

function GroupRecipientsByTagsModal({show, setShow, selectedRecipients, showManageTagsModal, setShowManageTagsModal, lastSearchParams, totalCount, selectedAll}: GroupRecipientsByTagsModalProps) {
    const queryClient = useQueryClient()
    const {setShowLoader} = useGlobal()

    const {addTagsToProfileRecipients, removeTagsFromProfileRecipients} = ProfileRecipientService()
    const {getProfileTags} = ProfileTagService()

    let [selectedTagsToAdd, setSelectedTagsToAdd] = useState<number[]>([])
    let [selectedTagsToRemove, setSelectedTagsToRemove] = useState<number[]>([])

    const getProfileTagsQuery = useQuery<IProfileTag[]>({
        queryKey: ["profileTags"],
        queryFn: getProfileTags
    })

    const addTagsToProfileRecipientsQuery = useMutation({
        mutationFn: (variables) => {
            return addTagsToProfileRecipients(selectedRecipients, selectedTagsToAdd, lastSearchParams, selectedAll)
        },
        onMutate: function (){
            setShowLoader(true)
        },
        onSuccess: (data: any, variables: any, context: any) => {
            queryClient.invalidateQueries({queryKey: ["profileRecipients"]})
            setShow(false)
            setShowLoader(false)
        }
    })

    const removeTagsFromProfileRecipientsMutation = useMutation({
        mutationFn: (variables) => {
            return removeTagsFromProfileRecipients(selectedRecipients, selectedTagsToRemove, lastSearchParams, selectedAll)
        },
        onMutate: function (){
            setShowLoader(true)
        },
        onSuccess: (data: any, variables: any, context: any) => {
            queryClient.invalidateQueries({queryKey: ["profileRecipients"]})
            setShow(false)
            setShowLoader(false)
        }
    })

    return (
        <Dialog open={show} onClose={() => setShow(false)} maxWidth={false}>
            <DialogContent>
                <MDBox>
                    <Grid container alignItems={"center"} justifyContent={"space-between"} mb={3}>
                        <Grid item>
                            <MDTypography>
                            Manage Tags for {
                                selectedAll?
                                    `${formatNumber(totalCount)} recipient${totalCount > 1 ? 's' : ''}`
                                    :
                                    `${formatNumber(selectedRecipients.length)} Recipient${selectedRecipients.length > 1 ? 's' : ''}`
                                }
                            </MDTypography>
                        </Grid>

                        <Grid item>
                            <Tooltip title={"Manage Tags"}>
                                <MDBox display={"flex"} onClick={() => {
                                    setShowManageTagsModal(true)
                                }}>
                                    <AddTagIcon/>
                                </MDBox>
                            </Tooltip>
                        </Grid>
                    </Grid>

                    {!getProfileTagsQuery.isLoading ? (
                        <MDBox>
                            <Grid
                                container
                                flexDirection={"row"}
                                alignItems={"center"}
                                columnSpacing={"30px"}
                            >
                                <Grid item flexGrow={1} width={"400px"}>
                                    <Autocomplete
                                        fullWidth={true}
                                        multiple={true}
                                        options={getProfileTagsQuery.data}
                                        onChange={(event, value: any) => {
                                            setSelectedTagsToAdd(value.map((a: IProfileTag) => a.id))
                                        }}
                                        disableCloseOnSelect
                                        getOptionLabel={(option: any) =>
                                            option.tagName
                                        }
                                        renderOption={(
                                            props,
                                            option,
                                            {selected}
                                        ) => (
                                            <li {...props}>
                                                <Checkbox checked={selected}/>
                                                {option.tagName}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Tags"
                                                placeholder="Select Tags"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item>
                                    <MDButton
                                        sx={{width: "120px"}}
                                        onClick={() => addTagsToProfileRecipientsQuery.mutate({})}
                                    >
                                        Add
                                    </MDButton>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                flexDirection={"row"}
                                alignItems={"center"}
                                columnSpacing={"30px"}
                                mt={"30px"}
                            >
                                <Grid item flexGrow={1} width={"400px"}>
                                    <Autocomplete
                                        fullWidth={true}
                                        multiple={true}
                                        options={getProfileTagsQuery.data}
                                        disableCloseOnSelect
                                        onChange={(event, value: any) => {
                                            setSelectedTagsToRemove(value.map((a: IProfileTag) => a.id))
                                        }}
                                        getOptionLabel={(option: any) =>
                                            option.tagName
                                        }
                                        renderOption={(
                                            props,
                                            option,
                                            {selected}
                                        ) => (
                                            <li {...props}>
                                                <Checkbox checked={selected}/>
                                                {option.tagName}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Tags"
                                                placeholder="Select Tags"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item>
                                    <MDButton
                                        sx={{width: "120px"}}
                                        onClick={() => removeTagsFromProfileRecipientsMutation.mutate({})}
                                    >
                                        Remove
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </MDBox>
                    ) : (
                        <MDBox sx={{textAlign: "center"}}>
                            <CircularProgress color="info"/>
                        </MDBox>
                    )}
                </MDBox>
            </DialogContent>
        </Dialog>
    )
}

export default GroupRecipientsByTagsModal
