// @ts-ignore
import Retool from "react-retool"
import MDBox from "material-ui/components/MDBox";

function AdminOrdersPage(){
    return <MDBox style={{ height: "90vh" }}>
        <Retool url="https://lettrlabs.retool.com/embedded/public/7139d541-049d-4065-86f3-055839076d21" />
    </MDBox>
}

export default AdminOrdersPage