import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const AddTagIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-outside-1_3550_149" maskUnits="userSpaceOnUse" x="9.73047" y="9.73022" width="12" height="12" fill="black">
                    <rect fill="white" x="9.73047" y="9.73022" width="12" height="12"/>
                    <path d="M15.8892 21.0477C13.0448 21.0477 10.7305 18.7334 10.7305 15.889C10.7305 13.0445 13.0448 10.7302 15.8892 10.7302C18.7336 10.7302 21.0479 13.0445 21.0479 15.889C21.0479 18.7334 18.7336 21.0477 15.8892 21.0477ZM15.8892 11.5239C13.4821 11.5239 11.5241 13.4818 11.5241 15.889C11.5241 18.2961 13.4821 20.254 15.8892 20.254C18.2963 20.254 20.2543 18.2961 20.2543 15.889C20.2543 13.4818 18.2963 11.5239 15.8892 11.5239Z"/>
                </mask>
                <path d="M15.8892 21.0477C13.0448 21.0477 10.7305 18.7334 10.7305 15.889C10.7305 13.0445 13.0448 10.7302 15.8892 10.7302C18.7336 10.7302 21.0479 13.0445 21.0479 15.889C21.0479 18.7334 18.7336 21.0477 15.8892 21.0477ZM15.8892 11.5239C13.4821 11.5239 11.5241 13.4818 11.5241 15.889C11.5241 18.2961 13.4821 20.254 15.8892 20.254C18.2963 20.254 20.2543 18.2961 20.2543 15.889C20.2543 13.4818 18.2963 11.5239 15.8892 11.5239Z" fill="#130B46"/>
                <path d="M15.8892 21.0477C13.0448 21.0477 10.7305 18.7334 10.7305 15.889C10.7305 13.0445 13.0448 10.7302 15.8892 10.7302C18.7336 10.7302 21.0479 13.0445 21.0479 15.889C21.0479 18.7334 18.7336 21.0477 15.8892 21.0477ZM15.8892 11.5239C13.4821 11.5239 11.5241 13.4818 11.5241 15.889C11.5241 18.2961 13.4821 20.254 15.8892 20.254C18.2963 20.254 20.2543 18.2961 20.2543 15.889C20.2543 13.4818 18.2963 11.5239 15.8892 11.5239Z" stroke="#130B46" strokeWidth="0.4" mask="url(#path-1-outside-1_3550_149)"/>
                <mask id="path-2-outside-2_3550_149" maskUnits="userSpaceOnUse" x="14.4922" y="12.1111" width="2" height="7" fill="black">
                    <rect fill="white" x="14.4922" y="12.1111" width="2" height="7"/>
                    <path d="M15.889 18.6666C15.67 18.6666 15.4922 18.4889 15.4922 18.2698V13.5079C15.4922 13.2889 15.67 13.1111 15.889 13.1111C16.1081 13.1111 16.2858 13.2889 16.2858 13.5079V18.2698C16.2858 18.4889 16.1081 18.6666 15.889 18.6666Z"/>
                </mask>
                <path d="M15.889 18.6666C15.67 18.6666 15.4922 18.4889 15.4922 18.2698V13.5079C15.4922 13.2889 15.67 13.1111 15.889 13.1111C16.1081 13.1111 16.2858 13.2889 16.2858 13.5079V18.2698C16.2858 18.4889 16.1081 18.6666 15.889 18.6666Z" fill="#130B46"/>
                <path d="M15.889 18.6666C15.67 18.6666 15.4922 18.4889 15.4922 18.2698V13.5079C15.4922 13.2889 15.67 13.1111 15.889 13.1111C16.1081 13.1111 16.2858 13.2889 16.2858 13.5079V18.2698C16.2858 18.4889 16.1081 18.6666 15.889 18.6666Z" stroke="#130B46" strokeWidth="0.4" mask="url(#path-2-outside-2_3550_149)"/>
                <mask id="path-3-outside-3_3550_149" maskUnits="userSpaceOnUse" x="12.1113" y="14.4919" width="7" height="2" fill="black">
                    <rect fill="white" x="12.1113" y="14.4919" width="7" height="2"/>
                    <path d="M18.2701 16.2856H13.5082C13.2891 16.2856 13.1113 16.1078 13.1113 15.8888C13.1113 15.6697 13.2891 15.4919 13.5082 15.4919H18.2701C18.4891 15.4919 18.6669 15.6697 18.6669 15.8888C18.6669 16.1078 18.4891 16.2856 18.2701 16.2856Z"/>
                </mask>
                <path d="M18.2701 16.2856H13.5082C13.2891 16.2856 13.1113 16.1078 13.1113 15.8888C13.1113 15.6697 13.2891 15.4919 13.5082 15.4919H18.2701C18.4891 15.4919 18.6669 15.6697 18.6669 15.8888C18.6669 16.1078 18.4891 16.2856 18.2701 16.2856Z" fill="#130B46"/>
                <path d="M18.2701 16.2856H13.5082C13.2891 16.2856 13.1113 16.1078 13.1113 15.8888C13.1113 15.6697 13.2891 15.4919 13.5082 15.4919H18.2701C18.4891 15.4919 18.6669 15.6697 18.6669 15.8888C18.6669 16.1078 18.4891 16.2856 18.2701 16.2856Z" stroke="#130B46" strokeWidth="0.4" mask="url(#path-3-outside-3_3550_149)"/>
                <mask id="path-4-outside-4_3550_149" maskUnits="userSpaceOnUse" x="1" y="1" width="21" height="21" fill="black">
                    <rect fill="white" x="1" y="1" width="21" height="21"/>
                    <path d="M9.53968 21.0476C9.00476 21.0476 8.50635 20.8389 8.13651 20.4595L2.58413 14.9071C2.20873 14.5413 2 14.0429 2 13.5079C2 13 2.2 12.5032 2.54841 12.1444L11.4921 2.86746C12.0214 2.30873 12.7389 2 13.5079 2H19.0635C20.1571 2 21.0476 2.89048 21.0476 3.98413V9.53968C21.0476 9.88333 20.9849 10.219 20.8563 10.5667C20.7802 10.7722 20.5516 10.8786 20.346 10.8008C20.1405 10.7246 20.0357 10.496 20.1119 10.2905C20.2071 10.0333 20.254 9.7881 20.254 9.53968V3.98413C20.254 3.32778 19.7198 2.79365 19.0635 2.79365H13.5079C12.9587 2.79365 12.446 3.01429 12.0659 3.41587L3.11905 12.696C2.9119 12.9095 2.79365 13.2048 2.79365 13.5079C2.79365 13.827 2.91746 14.1238 3.14206 14.3429L8.70079 19.9016C9.07619 20.2865 9.70556 20.3619 10.1524 20.077C10.3381 19.9595 10.5825 20.0143 10.7 20.2C10.8175 20.3849 10.7627 20.6302 10.577 20.7476C10.2683 20.9444 9.90952 21.0476 9.53968 21.0476Z"/>
                </mask>
                <path d="M9.53968 21.0476C9.00476 21.0476 8.50635 20.8389 8.13651 20.4595L2.58413 14.9071C2.20873 14.5413 2 14.0429 2 13.5079C2 13 2.2 12.5032 2.54841 12.1444L11.4921 2.86746C12.0214 2.30873 12.7389 2 13.5079 2H19.0635C20.1571 2 21.0476 2.89048 21.0476 3.98413V9.53968C21.0476 9.88333 20.9849 10.219 20.8563 10.5667C20.7802 10.7722 20.5516 10.8786 20.346 10.8008C20.1405 10.7246 20.0357 10.496 20.1119 10.2905C20.2071 10.0333 20.254 9.7881 20.254 9.53968V3.98413C20.254 3.32778 19.7198 2.79365 19.0635 2.79365H13.5079C12.9587 2.79365 12.446 3.01429 12.0659 3.41587L3.11905 12.696C2.9119 12.9095 2.79365 13.2048 2.79365 13.5079C2.79365 13.827 2.91746 14.1238 3.14206 14.3429L8.70079 19.9016C9.07619 20.2865 9.70556 20.3619 10.1524 20.077C10.3381 19.9595 10.5825 20.0143 10.7 20.2C10.8175 20.3849 10.7627 20.6302 10.577 20.7476C10.2683 20.9444 9.90952 21.0476 9.53968 21.0476Z" fill="#130B46"/>
                <path d="M9.53968 21.0476C9.00476 21.0476 8.50635 20.8389 8.13651 20.4595L2.58413 14.9071C2.20873 14.5413 2 14.0429 2 13.5079C2 13 2.2 12.5032 2.54841 12.1444L11.4921 2.86746C12.0214 2.30873 12.7389 2 13.5079 2H19.0635C20.1571 2 21.0476 2.89048 21.0476 3.98413V9.53968C21.0476 9.88333 20.9849 10.219 20.8563 10.5667C20.7802 10.7722 20.5516 10.8786 20.346 10.8008C20.1405 10.7246 20.0357 10.496 20.1119 10.2905C20.2071 10.0333 20.254 9.7881 20.254 9.53968V3.98413C20.254 3.32778 19.7198 2.79365 19.0635 2.79365H13.5079C12.9587 2.79365 12.446 3.01429 12.0659 3.41587L3.11905 12.696C2.9119 12.9095 2.79365 13.2048 2.79365 13.5079C2.79365 13.827 2.91746 14.1238 3.14206 14.3429L8.70079 19.9016C9.07619 20.2865 9.70556 20.3619 10.1524 20.077C10.3381 19.9595 10.5825 20.0143 10.7 20.2C10.8175 20.3849 10.7627 20.6302 10.577 20.7476C10.2683 20.9444 9.90952 21.0476 9.53968 21.0476Z" stroke="#130B46" strokeWidth="0.4" mask="url(#path-4-outside-4_3550_149)"/>
                <mask id="path-5-outside-5_3550_149" maskUnits="userSpaceOnUse" x="14.4922" y="3.38086" width="5" height="5" fill="black">
                    <rect fill="white" x="14.4922" y="3.38086" width="5" height="5"/>
                    <path d="M17.0795 7.55546C16.2041 7.55546 15.4922 6.84356 15.4922 5.96816C15.4922 5.09276 16.2041 4.38086 17.0795 4.38086C17.9549 4.38086 18.6668 5.09276 18.6668 5.96816C18.6668 6.84356 17.9549 7.55546 17.0795 7.55546ZM17.0795 5.17451C16.6422 5.17451 16.2858 5.53086 16.2858 5.96816C16.2858 6.40546 16.6422 6.76181 17.0795 6.76181C17.5168 6.76181 17.8731 6.40546 17.8731 5.96816C17.8731 5.53086 17.5168 5.17451 17.0795 5.17451Z"/>
                </mask>
                <path d="M17.0795 7.55546C16.2041 7.55546 15.4922 6.84356 15.4922 5.96816C15.4922 5.09276 16.2041 4.38086 17.0795 4.38086C17.9549 4.38086 18.6668 5.09276 18.6668 5.96816C18.6668 6.84356 17.9549 7.55546 17.0795 7.55546ZM17.0795 5.17451C16.6422 5.17451 16.2858 5.53086 16.2858 5.96816C16.2858 6.40546 16.6422 6.76181 17.0795 6.76181C17.5168 6.76181 17.8731 6.40546 17.8731 5.96816C17.8731 5.53086 17.5168 5.17451 17.0795 5.17451Z" fill="#130B46"/>
                <path d="M17.0795 7.55546C16.2041 7.55546 15.4922 6.84356 15.4922 5.96816C15.4922 5.09276 16.2041 4.38086 17.0795 4.38086C17.9549 4.38086 18.6668 5.09276 18.6668 5.96816C18.6668 6.84356 17.9549 7.55546 17.0795 7.55546ZM17.0795 5.17451C16.6422 5.17451 16.2858 5.53086 16.2858 5.96816C16.2858 6.40546 16.6422 6.76181 17.0795 6.76181C17.5168 6.76181 17.8731 6.40546 17.8731 5.96816C17.8731 5.53086 17.5168 5.17451 17.0795 5.17451Z" stroke="#130B46" strokeWidth="0.4" mask="url(#path-5-outside-5_3550_149)"/>
            </svg>

        </SvgIcon>
    )
}

export default AddTagIcon;