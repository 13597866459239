import {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import RadioSelectWrapper from "components/radio-select-wrapper";
import MDBox from "material-ui/components/MDBox";
import { productCategories } from "helpers/products";

interface ProductImagesCarouselProps {
    productId: number
}

function ProductImagesCarousel({productId}: ProductImagesCarouselProps) {
    const [selectedImage, setSelectedImage] = useState(null)
    const productCategory = productCategories.find(a => a.products.includes(productId))
    const isPrintedPostcard = productCategory.products.length > 1
    const index = productCategory.products.findIndex(el => el === productId) 
    const imgList: string[] = isPrintedPostcard ? productCategory?.images[index] : productCategory?.images

    useEffect(() => {
        setSelectedImage(imgList[0])
    }, [imgList]);

    return <MDBox>
        <MDBox>
            <RadioSelectWrapper>
                <img alt={""} style={{width: "100%", height: "400px", objectFit: "contain"}} src={selectedImage}/>
            </RadioSelectWrapper>
        </MDBox>

        <MDBox mt={1}>
            <Grid container columns={10} gap={1} flexWrap={"nowrap"}>
                {imgList?.map((a, index: number) => {
                    return <Grid item key={index} xs={2}>
                        <RadioSelectWrapper selected={selectedImage === a} onClick={() => {
                            setSelectedImage(a)
                        }}>
                            <img alt={""} style={{width: "100%", height: "auto"}} src={a}/>
                        </RadioSelectWrapper>
                    </Grid>
                })}
            </Grid>
        </MDBox>
    </MDBox>
}

export default ProductImagesCarousel