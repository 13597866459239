/* eslint-disable prefer-destructuring */
/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import {styled, Theme} from "@mui/material/styles";
import {Button} from "@mui/material";
import pxToRem from "../../theme/functions/pxToRem";

export default styled(Button)(({theme, ownerState}: { theme?: Theme | any; ownerState: any }) => {
    const {palette} = theme;
    const {color, border, borderRadiusSize, square, size, backgroundColor, active} = ownerState;

    const {primary, white, grey} = palette;

    const borderStyles = () => ({
        border: `1px solid ${grey[400]}`,
    });

    const paddingStyles = () => {
        if(square){
            if (size === "small"){
                return {
                    padding: `${pxToRem(4)} ${pxToRem(4)}`
                }
            }
            else{
                return {
                    padding: `${pxToRem(8)} ${pxToRem(8)}`
                }
            }
        }
        else{
            return {
                padding: `${pxToRem(8)} ${pxToRem(14)}`
            }
        }
    }

    const backgroundColorValue = palette[backgroundColor].main

    return {
        ...(paddingStyles()),
        ...(border && borderStyles()),
        borderRadius: square? "50%" : theme.borders.borderRadius[borderRadiusSize],
        backgroundColor: active? primary.main : backgroundColorValue,
        color: active? white.main : palette[color].main,
        ...(square && {minWidth: 0, minHeight: 0}),

        "svg": {
            fontSize: "50px"
        },

        "&:hover": {
            backgroundColor: primary.main,

            "svg": {
                color: white.main
            }
        }
    };
});