import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const DocumentIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.166 14.25V11.625C20.166 10.7299 19.8104 9.87145 19.1775 9.23851C18.5446 8.60558 17.6861 8.25 16.791 8.25H15.291C14.9926 8.25 14.7065 8.13147 14.4955 7.9205C14.2845 7.70952 14.166 7.42337 14.166 7.125V5.625C14.166 4.72989 13.8104 3.87145 13.1775 3.23851C12.5446 2.60558 11.6861 2.25 10.791 2.25H8.91602M8.91602 15H16.416M8.91602 18H12.666M11.166 2.25H6.29102C5.67002 2.25 5.16602 2.754 5.16602 3.375V20.625C5.16602 21.246 5.67002 21.75 6.29102 21.75H19.041C19.662 21.75 20.166 21.246 20.166 20.625V11.25C20.166 8.86305 19.2178 6.57387 17.53 4.88604C15.8421 3.19821 13.553 2.25 11.166 2.25Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export default DocumentIcon;