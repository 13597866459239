import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FieldMetaProps } from 'formik';
import { Box, Dialog, IconButton } from '@mui/material';
import MDCard from 'material-ui/components/MDCard';
import MDButton from 'material-ui/components/MDButton';
import FormikErrorMessage from 'components/formik-error-message';
import DeleteIcon from 'assets/icons/delete';
import { ITemplate } from 'models/template';
import EditPencilIcon from 'assets/icons/edit-pencil';
import TemplateScenePreview from 'components/template-scene-preview';
import { useGlobal } from 'context/global-context';

interface IOrderDefault {
  template?: { id: number; hasDiscount?: boolean };
  templateId?: number;
  multiUseCouponId?: number | null;
  couponListId?: number | null;
}

interface SelectTemplateProps<T extends IOrderDefault> {
  order: T;
  meta?: FieldMetaProps<T>;
  template?: ITemplate;
  disabled?: boolean;
  disableEditOption?: boolean;
  onDelete: () => void;
  onTemplateChange: (template: ITemplate) => void;
}

const SelectTemplate = <T extends IOrderDefault>({
  order,
  meta,
  template,
  disabled,
  disableEditOption,
  onDelete,
  onTemplateChange,
}: SelectTemplateProps<T>) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { setTemplateFlow } = useGlobal();
  const [showTemplateZoom, setShowTemplateZoom] = useState<boolean>(false);
  const templateId = order?.templateId || order?.template?.id || '';

  const handleTemplateChange = (isEdit?: boolean) => {
    setTemplateFlow({ onTemplateChange, order, showModal: !isEdit, originalRoute: `${pathname}${search}` });
    if (isEdit) navigate(`/template/${templateId}`);
  };

  if (templateId && template) {
    return (
      <Box>
        <MDCard border borderColor="dark" color="light" sx={{ width: 'fit-content', maxWidth: '500px' }}>
          <Box role="button" onClick={() => setShowTemplateZoom(true)} p={2} sx={{ cursor: 'pointer' }}>
            <TemplateScenePreview {...template} />
          </Box>
          {!disabled && (
            <Box position="absolute" top="20px" right="20px">
              {!disableEditOption && (
                <IconButton onClick={() => handleTemplateChange(true)}>
                  <EditPencilIcon />
                </IconButton>
              )}
              <IconButton color="error" onClick={() => onDelete()}>
                <DeleteIcon />
              </IconButton>
            </Box>
          )}
        </MDCard>
        <Dialog
          fullWidth
          open={showTemplateZoom}
          maxWidth={false}
          sx={{ maxWidth: 2000, marginX: 'auto' }}
          onClose={() => setShowTemplateZoom(false)}
        >
          <TemplateScenePreview {...template} sx={{ padding: 2 }} />
        </Dialog>
      </Box>
    );
  }

  return (
    <>
      <MDButton
        disabled={disabled}
        onClick={() => handleTemplateChange()}
        sx={meta?.error && meta?.touched && { border: '1px solid red' }}
      >
        Select Card Template
      </MDButton>
      {meta && <FormikErrorMessage meta={meta} />}
    </>
  );
};

export default SelectTemplate;
