import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GridRowModelUpdate } from '@mui/x-data-grid-pro';

import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { IntegrationOrderType, IVM_GridOrder } from 'models/views/gridOrderVm';
import { IntegrationOrderStatus } from 'models/enums/IntegrationOrderStatus';
import IntegrationOrderService from 'services/integration-order';
import MDButton from 'material-ui/components/MDButton';
import MDDatePicker from 'material-ui/components/MDDatePicker';
import { format, subDays } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';

interface ConfirmStatusChangeProps {
  order: IVM_GridOrder;
  status: IntegrationOrderStatus;
  open?: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updateRows?: (updates: GridRowModelUpdate[]) => void;
}

const REFRESH_MAX_DAYS = 90;

export default function ConfirmStatusChange({ order, status, open, setOpen, updateRows }: ConfirmStatusChangeProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [keepRecipients, setKeepRecipients] = useState<boolean>(false);
  const [refreshDate, setRefreshDate] = useState<string>(format(new Date(), 'MM/dd/yyyy'));

  const isKlaviyoActivation =
    IntegrationOrderType.KLAVIYO === order?.integrationOrderType && status === IntegrationOrderStatus.Active;

  const showCheckbox =
    [IntegrationOrderType.NORMAL].includes(order?.integrationOrderType) &&
    !!order?.queueCount &&
    order?.status === IntegrationOrderStatus.Testing;

  const { updateIntegrationOrderStatus } = IntegrationOrderService();
  const { mutate: updateStatus, isPending } = useMutation({
    mutationFn: () => updateIntegrationOrderStatus(order?.id, status, !keepRecipients, refreshDate),
    onSuccess: (response) => {
      if (response.Message) {
        setErrorMessage(response.Message);
        if (
          IntegrationOrderType.MOVER === order?.integrationOrderType &&
          order?.status === IntegrationOrderStatus.Inactive &&
          status === IntegrationOrderStatus.Active
        ) {
          navigate(`/smart-automation/${order?.id}`);
        }
      } else {
        setOpen(false);
        navigate(`${pathname}?id=${order.id}`, { replace: true });
        queryClient.invalidateQueries({ queryKey: ['orderRecipients', order?.id] });
        queryClient.invalidateQueries({ queryKey: ['allSmartAutomations'] });
        order.status = status;
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
    setErrorMessage('');
  };

  const subtitle = () => {
    if (IntegrationOrderType.SHOPIFY === order?.integrationOrderType && status === IntegrationOrderStatus.Testing) {
      return 'In testing status, collected recipients will not receive any letters, and filters will not be applied. The recipients gathered for testing do not adhere to the filters and will not receive letters. Would you like to proceed with testing the integration?';
    }
    let text = `In ${status?.toLowerCase()} status,`;
    if (status === IntegrationOrderStatus.Inactive) return `${text} no recipients are collected`;
    text += ' collected recipients will';
    if (status === IntegrationOrderStatus.Active) return `${text} be mailed out and you'll be charged`;
    return `${text} not be mailed out`;
  };

  const submitText = {
    [IntegrationOrderStatus.Active]: 'Activate',
    [IntegrationOrderStatus.Inactive]: 'Inactivate',
    [IntegrationOrderStatus.Testing]: 'Test',
  };

  const handleSubmit = () => {
    if (
      IntegrationOrderType.MOVER === order?.integrationOrderType &&
      order.status === IntegrationOrderStatus.Testing &&
      status === IntegrationOrderStatus.Active
    ) {
      navigate(`/smart-automation/${order?.id}`);
    } else updateStatus();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogContent>
        {errorMessage ? (
          <Typography>{errorMessage}</Typography>
        ) : (
          <>
            <Typography variant="h4" mb={2}>
              {submitText[status]} Automation?
            </Typography>
            {isKlaviyoActivation && order.status === IntegrationOrderStatus.Testing ? (
              <Box component={'ul'} ml={3}>
                <Typography component={'li'} fontSize={16}>
                  {subtitle()}
                </Typography>
                <Typography component={'li'} fontSize={16}>
                  All recipients received in “Testing” Status will be removed
                </Typography>
              </Box>
            ) : (
              <Typography fontSize={16}>{subtitle()}</Typography>
            )}
            {isKlaviyoActivation && (
              <>
                <Typography variant="h4" fontSize={18} mt={2} mb={1}>
                  Select how far back to add recipients to your segment
                </Typography>
                <Typography variant="h5">Lookback Period</Typography>
                <MDDatePicker
                  disabled={isPending}
                  options={{ minDate: subDays(new Date(), REFRESH_MAX_DAYS), maxDate: new Date() }}
                  onChange={(_: Date, dateStr: string) => setRefreshDate(dateStr)}
                  input={{
                    value: refreshDate,
                    placeholder: 'MM/DD/YYYY',
                    helperText: `Max ${REFRESH_MAX_DAYS} days in the past.`,
                  }}
                />
              </>
            )}
            {showCheckbox && (
              <FormControlLabel
                onChange={() => setKeepRecipients(!keepRecipients)}
                control={<Checkbox checked={keepRecipients} />}
                label={`Keep ${order?.queueCount} recipients when switching?${
                  order?.mockedQueueCount > 0 ? ` (${order?.mockedQueueCount} test recipients)` : ''
                }`}
              />
            )}
          </>
        )}
        <Stack direction={'row'} spacing={2} mt={2}>
          <MDButton fullWidth disabled={isPending} onClick={() => (errorMessage ? handleClose() : handleSubmit())}>
            {isPending ? <CircularProgress color="inherit" size={18} /> : errorMessage ? 'Okay' : submitText[status]}
          </MDButton>
          {!errorMessage && (
            <MDButton fullWidth disabled={isPending} color="light" onClick={handleClose}>
              Cancel
            </MDButton>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
