import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DialogContent, Grid, DialogTitle, Dialog, Box } from '@mui/material';
import MDButton from 'material-ui/components/MDButton';
import MDTypography from 'material-ui/components/MDTypography';
import Loader from 'components/loader';
import TemplateCard from 'components/template-card';
import TemplateService from 'services/template';
import { ITemplate, Template } from 'models/template';
import AddTemplateCard from 'components/add-template-card';
import { useGlobal } from 'context/global-context';

const SelectTemplateModal = () => {
  const navigate = useNavigate();
  const { getTemplates, postTemplate } = TemplateService();
  const { templateFlow, setTemplateFlow, setShowLoader, allowDiscount } = useGlobal();
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplate>(templateFlow.order?.template);

  const { data: templates, isLoading } = useQuery({
    queryKey: ['templates'],
    queryFn: getTemplates,
    refetchOnWindowFocus: false,
  });

  const { mutate: createTemplate } = useMutation({
    mutationFn: () => postTemplate({ ...new Template(0), isIntegrationOrderTemplate: true }),
    onMutate: () => setShowLoader(true),
    onSuccess: (data) => {
      setTemplateFlow((prev) => ({ ...prev, showModal: false }));
      navigate(`/template/${data.id}`);
      setShowLoader(false);
    },
  });

  const handleTemplateConfirm = (newTemplate?: ITemplate) => {
    const auxTemplate = newTemplate || selectedTemplate;
    if (auxTemplate) {
      templateFlow.onTemplateChange(auxTemplate);
      setTemplateFlow({});
    } else if (templateFlow.customCreateCallback) {
      setTemplateFlow({ ...templateFlow, showModal: false });
      templateFlow.customCreateCallback();
    } else createTemplate();
    setSelectedTemplate(null);
  };

  const handleModalClose = () => {
    setSelectedTemplate(null);
    setTemplateFlow({});
  };

  return (
    <Dialog open={templateFlow.showModal} onClose={() => handleModalClose()} maxWidth={false}>
      <DialogContent sx={{ width: '1000px' }}>
        {isLoading ? (
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Loader />
          </Box>
        ) : templates?.length ? (
          <Box>
            <DialogTitle>Select one of your templates</DialogTitle>

            <Box maxHeight={'64vh'} sx={{ overflowY: 'auto' }}>
              <Grid container spacing={'20px'} alignItems="stretch">
                <Grid item xs={4}>
                  <AddTemplateCard
                    minHeight="auto"
                    selectOnly
                    isSelected={!selectedTemplate}
                    setIsSelected={() => setSelectedTemplate(null)}
                    onDoubleClick={() => handleTemplateConfirm()}
                  />
                </Grid>
                {templates.map((template: Template, index: number) => (
                  <Grid item key={index} xs={4}>
                    <TemplateCard
                      template={template}
                      disabled={template.hasDiscount && !allowDiscount}
                      hideButtons
                      onClick={() => setSelectedTemplate(template)}
                      onDoubleClick={() => handleTemplateConfirm(template)}
                      pointerCursor
                      selected={selectedTemplate?.id === template.id}
                      showPreviewButton
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        ) : (
          <DialogTitle mb={0}>
            You don't have any templates yet. Please create one by clicking
            <MDTypography
              color={'primary'}
              isLink
              onClick={() => createTemplate()}
              display={'inline-block'}
              fontWeight={'bold'}
              ml={0.5}
            >
              here
            </MDTypography>
          </DialogTitle>
        )}

        {!isLoading && !!templates?.length && (
          <Box display="flex" justifyContent="flex-end" gap={1}>
            <MDButton circular color="light" onClick={() => handleModalClose()}>
              Close
            </MDButton>
            <MDButton circular onClick={() => handleTemplateConfirm()}>
              Select
            </MDButton>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SelectTemplateModal;
