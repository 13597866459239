import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SidebarPhoneIcon = (props: any) => {
    return (
        <SvgIcon {...props} viewBox={"0 0 32 32"}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M2 6.00391C2 4.94304 2.42143 3.92562 3.17157 3.17548C3.92172 2.42533 4.93913 2.00391 6 2.00391H7.82933C8.976 2.00391 9.976 2.78524 10.2547 3.89724L11.728 9.79457C11.8474 10.2719 11.8233 10.7739 11.6587 11.2375C11.4941 11.7012 11.1963 12.106 10.8027 12.4012L9.07867 13.6946C8.89867 13.8292 8.86 14.0266 8.91067 14.1639C9.66317 16.2104 10.8515 18.0688 12.3933 19.6106C13.9351 21.1524 15.7935 22.3407 17.84 23.0932C17.9773 23.1439 18.1733 23.1052 18.3093 22.9252L19.6027 21.2012C19.8979 20.8076 20.3027 20.5098 20.7664 20.3452C21.2301 20.1806 21.732 20.1565 22.2093 20.2759L28.1067 21.7492C29.2187 22.0279 30 23.0279 30 24.1759V26.0039C30 27.0648 29.5786 28.0822 28.8284 28.8323C28.0783 29.5825 27.0609 30.0039 26 30.0039H23C11.4027 30.0039 2 20.6012 2 9.00391V6.00391Z" fill="white"/>
            </svg>
        </SvgIcon>
    )
}

export default SidebarPhoneIcon;