import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ColumnIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.5 5.75V18.25M14.5 5.75V18.25M5.4375 18.25H18.5625C19.08 18.25 19.5 17.83 19.5 17.3125V6.6875C19.5 6.17 19.08 5.75 18.5625 5.75H5.4375C4.92 5.75 4.5 6.17 4.5 6.6875V17.3125C4.5 17.83 4.92 18.25 5.4375 18.25Z"
                    stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export default ColumnIcon;