import SvgIcon from '@mui/material/SvgIcon';

const EyeIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.19645 10.2683C2.13894 10.0955 2.13894 9.90867 2.19645 9.73583C3.35229 6.25833 6.63312 3.75 10.4998 3.75C14.3648 3.75 17.644 6.25583 18.8023 9.73167C18.8606 9.90417 18.8606 10.0908 18.8023 10.2642C17.6473 13.7417 14.3665 16.25 10.4998 16.25C6.63479 16.25 3.35479 13.7442 2.19645 10.2683Z" stroke="#2C2D36" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13 10C13 10.663 12.7366 11.2989 12.2678 11.7678C11.7989 12.2366 11.163 12.5 10.5 12.5C9.83696 12.5 9.20107 12.2366 8.73223 11.7678C8.26339 11.2989 8 10.663 8 10C8 9.33696 8.26339 8.70107 8.73223 8.23223C9.20107 7.76339 9.83696 7.5 10.5 7.5C11.163 7.5 11.7989 7.76339 12.2678 8.23223C12.7366 8.70107 13 9.33696 13 10Z" stroke="#2C2D36" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export default EyeIcon