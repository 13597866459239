import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const HomeShareIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 18.0007V13.0007C7.5 12.5586 7.6756 12.1347 7.98816 11.8221C8.30072 11.5096 8.72464 11.334 9.16667 11.334H10.8333C11.0392 11.334 11.2367 11.3715 11.4183 11.4398" stroke="#130B46" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M15.8333 10.5H17.5L10 3L2.5 10.5H4.16667V16.3333C4.16667 16.7754 4.34226 17.1993 4.65482 17.5118C4.96738 17.8244 5.39131 18 5.83333 18H10M13.3333 18.8333L17.5 14.6667M17.5 14.6667V18.4167M17.5 14.6667H13.75" stroke="#130B46" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export default HomeShareIcon;