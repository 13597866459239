export enum SceneBlockNames {
    //Added image for all scenes
    Image = "Image",
    //Added image for all scenes
    PrintedText = "PrintedText",
    //Border for all scenes
    Border = "Rectangle",
    //Front logo for all scenes
    FrontLogo = "Front_Logo",
    //Read logo for all scenes
    RearImage = "Rear_Image",
    //Outside Front Image (REAR image for Bi-Fold Cards)
    OutsideFrontImage = "Outside_Front_Image",
    //Outside Rear Bottom Keep Out Area (for Bi-Fold Cards)
    OutsideKeepOutArea = "Outside_Keep_Out_Area",
    //Outside Rear Keep Out Area Text (for Bi-Fold Cards)
    OutsideKeepOutAreaText = "Outside_Keep_Out_Area_Text",
    //Handwritten text
    HandwrittenText = "Greeting",
    //Handwritten text
    HandwrittenText2 = "Greeting_2",
    //Return address on envelope
    ReturnAddress = "ReturnAddress",
    //QR code block
    QRCode = "QR_Code",
    //Tracking Phone Number block
    TrackingPhoneNumber = "TrackingPhoneNumber",
    //To address on envelope
    Envelope_ToAddress = "ToAddress",
    //Postcard  return address
    Postcard_ToAddress = "Static Greeting",
    //Postcard bar code
    Postcard_USPSBarCode = "IMB",
    //Postcard stamp (Needs to be renamed from 'image' on envelopes if we want to target it)
    Postcard_Stamp = "Stamp",
    //Usps barcode on envelope
    USPSBarCode = "USPS_BARCODE",
    //USPS address
    USPSAddress = "USPS_ADDRESS",
    //Bleed lines
    SafeZone = "SafeZone",
    //Bleed text
    SafeZoneText = "SafeZoneText",
    //Version
    Version="Version",
    FullBleedBigText = "FullBleedBigText",
    FullBleedSmallText = "FullBleedSmallText",
    SafeZoneBigText = "SafeZoneBigText",
    SafeZoneSmallText = "SafeZoneSmallText",
    UspsStamp = "USPS_STAMP",
    UspsStampText ="STAMP_TEXT",
    UspsStampElement = "Element",
    HWPCKeepOutZone = "HandwrittenPostCardKeepoutZone"
}