/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS Base Styles
import borders from "material-ui/theme/base/borders";
import colors from "material-ui/theme/base/colors";

const { primary } = colors;
const { borderWidth } = borders;

// types
type Types = any;

const stepConnector: Types = {
  styleOverrides: {
    root: {
      color: "#E4E6F1",
      transition: "all 200ms linear",

      "&.Mui-active, &.Mui-completed": {
        color: primary.main,
      },

      "&.Mui-completed": {

      },
    },

    alternativeLabel: {
      top: "20%",
      left: "-50%",
      right: "50%",
    },

    line: {
      borderWidth: `${borderWidth[4]} !important`,
      borderColor: "currentColor",
    },
  },
};

export default stepConnector;
