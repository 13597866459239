import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const WarningIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0003 8.99993V12.7499M2.69731 16.1259C1.83131 17.6259 2.91431 19.4999 4.64531 19.4999H19.3553C21.0853 19.4999 22.1683 17.6259 21.3033 16.1259L13.9493 3.37793C13.0833 1.87793 10.9173 1.87793 10.0513 3.37793L2.69731 16.1259ZM12.0003 15.7499H12.0073V15.7579H12.0003V15.7499Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export default WarningIcon