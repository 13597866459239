import {Grid, Tooltip} from "@mui/material";
import MDButton from "material-ui/components/MDButton";
import BringForwardIcon from "assets/icons/bring-forward";
import BringBackwardIcon from "assets/icons/bring-backward";
import BringToFrontIcon from "assets/icons/bring-to-front";
import BringToBackIcon from "assets/icons/bring-to-back";
import React from "react";
import {useEditor} from "context/editor-context";

function LayerManagementBar(){
    const {creativeEngine, selectedBlocks} = useEditor()

    return  <Grid container alignItems={"center"} gap={1} mt={1}>
        <Grid item>
            <Tooltip title={"Move Forward"}>
                <MDButton color={"light"} sx={{padding: "20px", flexDirection: "column", alignItems: "center"}} onClick={() => {
                    creativeEngine.block.bringForward(selectedBlocks[0].id)
                }}>
                    <BringForwardIcon sx={{display: "block"}} color={"error"}/>
                </MDButton>
            </Tooltip>
        </Grid>

        <Grid item>
            <Tooltip title={"Move Backward"}>
                <MDButton color={"light"} sx={{padding: "20px", flexDirection: "column", alignItems: "center"}} onClick={() => {
                    creativeEngine.block.sendBackward(selectedBlocks[0].id)
                }}>
                    <BringBackwardIcon sx={{display: "block"}} color={"error"}/>
                </MDButton>
            </Tooltip>
        </Grid>

        <Grid item>
            <Tooltip title={"Bring to Front"}>
                <MDButton color={"light"} sx={{padding: "20px", flexDirection: "column", alignItems: "center"}} onClick={() => {
                    creativeEngine.block.bringToFront(selectedBlocks[0].id)
                }}>
                    <BringToFrontIcon sx={{display: "block"}} color={"error"}/>
                </MDButton>
            </Tooltip>
        </Grid>

        <Grid item>
            <Tooltip title={"Bring to Back"}>
                <MDButton color={"light"} sx={{padding: "20px", flexDirection: "column", alignItems: "center"}} onClick={() => {
                    creativeEngine.block.sendToBack(selectedBlocks[0].id)
                }}>
                    <BringToBackIcon sx={{display: "block"}} color={"error"}/>
                </MDButton>
            </Tooltip>
        </Grid>
    </Grid>
}

export default LayerManagementBar