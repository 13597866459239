// @ts-ignore
import Retool from "react-retool"
import MDBox from "material-ui/components/MDBox";

function AdminSubscriptionPricing(){
    return <MDBox style={{ height: "90vh" }}>
        <Retool url="https://lettrlabs.retool.com/embedded/public/c51dc26e-3b9b-42ca-9930-55e719e14268" />
    </MDBox>
}

export default AdminSubscriptionPricing