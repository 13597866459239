import { Order } from "models/order";
import MDCard from "material-ui/components/MDCard";
import React, { FormEvent, useEffect, useState } from "react";
import { Chip, Divider, Grid, Radio, Tooltip } from "@mui/material";
import MDBox from "material-ui/components/MDBox";
import MDTypography from "material-ui/components/MDTypography";
import RadioSelectWrapper from "components/radio-select-wrapper";
import { PostageTypes } from "models/enums/PostageTypes"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import OrderService from "services/order";
import Info2Icon from "assets/icons/info-2";
import MDDatePicker from "material-ui/components/MDDatePicker";
import { formatDate, formatNumber, formatPrice } from "helpers/formatters";
import MDButton from "material-ui/components/MDButton";
import Spinner2 from "components/loader-2";
import { OrderSteps } from "models/orderSteps";
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe, PaymentMethod } from "@stripe/stripe-js";
import { StripePaymentForm } from "components/stripe-payment-container";
import { ICheckout } from "models/checkout";
import InfoModal from "components/info-modal";
import { OrderStatus } from "models/enums/orderStatus";
import PaymentCard from "components/payment-card";
import ProfileService from "services/profile";
import { ProductionSpeed } from "models/enums/ProductionSpeeds";
import moment from "moment";
import { orderProductIsPrintedCard, orderProducUsesLetterUSPSPricing } from "models/enums/ProductTypes";
import { useNavigate } from "react-router-dom";
import { useGlobal } from "context/global-context";

interface CheckoutSubpageProps {
    order: Order
    setOrder: Function
    setActiveStep: Function
    onBack: Function
}

interface CheckoutSubpageContentProps {
    order: Order
    setOrder: Function
    setActiveStep: Function
    checkout: ICheckout
    isFetchingCheckout: boolean
    onBack: Function
    defaultPaymentMethodId: string
}

enum MailingScheduleTypes {
    MailAsReady,
    ExpeditedNormal,
    ExpeditedFaster,
    HoldUntil
}

enum PaymentMethodTypes {
    ChargeCardOnFile = 1,
    NewPayment = 2
}

function CheckoutSubpageContent({
    order,
    setOrder,
    setActiveStep,
    checkout,
    isFetchingCheckout,
    onBack,
    defaultPaymentMethodId
}: CheckoutSubpageContentProps) {
    const queryClient = useQueryClient()
    const { postOrder } = OrderService()
    const navigate = useNavigate()
    const { setShowLoader } = useGlobal()

    const [paymentMethod, setPaymentMethod] = useState<PaymentMethodTypes>(null)

    const [postageType, setPostageType] = useState<PostageTypes>(null)
    const [mailingScheduleType, setMailingScheduleType] = useState<MailingScheduleTypes>(null)

    const [savedCards, setSavedCards] = useState<PaymentMethod[]>([])

    const [selectedCard, setSelectedCard] = useState<PaymentMethod>(null)

    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [errorModalText, setErrorModalText] = useState<string>("")

    const [isFetchingOrder, setIsFetchingOrder] = useState<boolean>(false)

    const postOrderMutation = useMutation({
        mutationFn: () => {
            return postOrder(order)
        },
        onMutate: () => {
            setIsFetchingOrder(true)
        },
        onSuccess: () => {
            setIsFetchingOrder(false)
            queryClient.invalidateQueries({ queryKey: ["checkout", "order", order.id] })
        }
    })

    useEffect(() => {
        // Set the default selected postage type option
        if (order.postageType) {
            if (order.postageType === PostageTypes.Standard) {
                setPostageType(PostageTypes.Standard)
            }
            if (order.postageType === PostageTypes.FirstClass) {
                setPostageType(PostageTypes.FirstClass)
            }
        } else {
            order.postageType = PostageTypes.Standard
            setPostageType(PostageTypes.Standard)
        }

        // Set the default selected mailing schedule option
        if (order.productionSpeed !== ProductionSpeed.Normal) {
            if (order.productionSpeed === ProductionSpeed.ExpeditedNormal) {
                setMailingScheduleType(MailingScheduleTypes.ExpeditedNormal)
            } else if (order.productionSpeed === ProductionSpeed.ExpeditedFaster) {
                setMailingScheduleType(MailingScheduleTypes.ExpeditedFaster)
            }
        } else {
            if (order.holdUntilDate) {
                setMailingScheduleType(MailingScheduleTypes.HoldUntil)
            } else {
                setMailingScheduleType(MailingScheduleTypes.MailAsReady)
            }
        }

        if (checkout?.savedPaymentMethods?.length) {
            setSavedCards(checkout.savedPaymentMethods)
        } else {
            setPaymentMethod(PaymentMethodTypes.NewPayment)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (paymentMethod !== PaymentMethodTypes.ChargeCardOnFile || !checkout || !checkout.savedPaymentMethods || !checkout.savedPaymentMethods.length || !defaultPaymentMethodId) {
            return
        }

        setSelectedCard(checkout.savedPaymentMethods.find((a) => a.id === defaultPaymentMethodId))
    }, [paymentMethod, checkout, defaultPaymentMethodId]);

    function handlePaymentCallback(response: any) {
        if (response.error) {
            let error = response.error
            let message

            if (error.type === "card_error") {
                if (error.code === "card_declined") {
                    message = "You card has been declined. Please contact your bank for additional information"
                } else if (error.code === "expired_card") {
                    message = "You card has expired"
                } else if (error.code === "incorrect_cvc" || error.code === "incorrect_number") {
                    message = "Invalid cvc or number. Please verify your credentials and try again"
                } else {
                    message = "An unexpected error occurred. Please try again or contact billing@lettrlabs.com"
                }
            } else {
                message = "An unexpected error occurred. Please try again or contact billing@lettrlabs.com"
            }

            setShowErrorModal(true)
            setErrorModalText(message)
        } else {
            let paymentIntent = response.paymentIntent;

            if (paymentIntent.status === "succeeded") {
                // setActiveStep(OrderSteps.SuccessSubpage)

                navigate(`/order/${order.id}/success`)
            }
        }

        setShowLoader(false)
    }

    function handleFreeCheckoutCallback(response: any) {
        setShowLoader(false)

        if (response.paymentStatus === "success") {
            setActiveStep(OrderSteps.SuccessSubpage)
            setShowLoader(false)
        } else {
            setErrorModalText('An unexpected error occurred. Please try again or contact billing@lettrlabs.com')
            setShowErrorModal(true)
        }
    }

    return <MDBox display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Grid container gap={3} flexWrap={"nowrap"} sx={{ maxWidth: "1530px" }}>
            <Grid item flex={1}>
                <MDCard color={"white"} borderRadiusSize={"xxxl"}>
                    <MDBox p={5} style={{
                        opacity: isFetchingCheckout || isFetchingOrder || order.orderStatus === OrderStatus.Paid ? 0.5 : 1,
                        pointerEvents: isFetchingCheckout || isFetchingOrder || order.orderStatus === OrderStatus.Paid ? "none" : "all"
                    }}>
                        <MDBox>
                            <Grid container alignItems={"center"} gap={1}>
                                <Grid item>
                                    <MDTypography variant={"h4"} color={"text"}>Production Speed</MDTypography>
                                </Grid>
                                <Grid item>
                                    <Tooltip title={"Select how fast you'd like your order produced."}>
                                        <MDBox display={"flex"}>
                                            <Info2Icon color={"light2"} />
                                        </MDBox>
                                    </Tooltip>
                                </Grid>
                            </Grid>

                            <MDBox mt={2}>
                                <RadioSelectWrapper
                                    selected={mailingScheduleType === MailingScheduleTypes.ExpeditedFaster}
                                    onClick={() => {
                                        setOrder((prevState: Order) => {
                                            return {
                                                ...prevState,
                                                productionSpeed: ProductionSpeed.ExpeditedFaster,
                                                holdUntilDate: null as Date
                                            }
                                        })

                                        postOrderMutation.mutate()

                                        setMailingScheduleType(MailingScheduleTypes.ExpeditedFaster)
                                    }}>
                                    <MDBox pl={2}>
                                        <Grid container gap={0.5} alignItems={"center"}>
                                            <Grid item>
                                                <Radio
                                                    checked={mailingScheduleType === MailingScheduleTypes.ExpeditedFaster}
                                                />
                                            </Grid>

                                            <Grid item>
                                                <Grid container alignItems={"center"} gap={1}>
                                                    <Grid item>
                                                        <MDTypography sx={{ fontSize: 16 }}
                                                            fontWeight={"bold"}>{moment(checkout?.productionSpeeds.ExpeditedFaster).format("dddd, MMM DD")}</MDTypography>
                                                    </Grid>

                                                    <Grid item>
                                                        <Grid container alignItems={"center"} gap={1}>
                                                            <Grid item>
                                                                <MDTypography sx={{ fontSize: 16 }}
                                                                    fontWeight={"normal"}>(Fastest)</MDTypography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Chip label={orderProductIsPrintedCard(order.product) ? "+$0.15" : "+$0.50"} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                </RadioSelectWrapper>
                            </MDBox>

                            <MDBox mt={1}>
                                <RadioSelectWrapper
                                    selected={mailingScheduleType === MailingScheduleTypes.ExpeditedNormal}
                                    onClick={() => {
                                        setOrder((prevState: Order) => {
                                            return {
                                                ...prevState,
                                                productionSpeed: ProductionSpeed.ExpeditedNormal,
                                                holdUntilDate: null as Date
                                            }
                                        })

                                        postOrderMutation.mutate()

                                        setMailingScheduleType(MailingScheduleTypes.ExpeditedNormal)
                                    }}>
                                    <MDBox pl={2}>
                                        <Grid container gap={0.5} alignItems={"center"}>
                                            <Grid item>
                                                <Radio
                                                    checked={mailingScheduleType === MailingScheduleTypes.ExpeditedNormal}
                                                />
                                            </Grid>

                                            <Grid item>
                                                <Grid container alignItems={"center"} gap={1}>
                                                    <Grid item>
                                                        <MDTypography sx={{ fontSize: 16 }}
                                                            fontWeight={"bold"}>{moment(checkout?.productionSpeeds.ExpeditedNormal).format("dddd, MMM DD")}</MDTypography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container alignItems={"center"} gap={1}>
                                                            <Grid item>
                                                                <MDTypography sx={{ fontSize: 16 }}
                                                                    fontWeight={"normal"}>(Faster)</MDTypography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Chip label={orderProductIsPrintedCard(order.product) ? "+$0.07" : "+$0.25"} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                </RadioSelectWrapper>
                            </MDBox>

                            <MDBox mt={1}>
                                <RadioSelectWrapper
                                    selected={mailingScheduleType === MailingScheduleTypes.MailAsReady}
                                    onClick={() => {
                                        setOrder((prevState: Order) => {
                                            return {
                                                ...prevState,
                                                productionSpeed: ProductionSpeed.Normal,
                                                holdUntilDate: null as Date
                                            }
                                        })

                                        postOrderMutation.mutate()

                                        setMailingScheduleType(MailingScheduleTypes.MailAsReady)
                                    }}>
                                    <MDBox pl={2}>
                                        <Grid container gap={0.5} alignItems={"center"}>
                                            <Grid item>
                                                <Radio
                                                    checked={mailingScheduleType === MailingScheduleTypes.MailAsReady}
                                                />
                                            </Grid>

                                            <Grid item>
                                                <MDTypography sx={{ fontSize: 16 }}
                                                    fontWeight={"bold"}>{moment(checkout?.productionSpeeds.Normal).format("dddd, MMM DD")}</MDTypography>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                </RadioSelectWrapper>
                            </MDBox>

                            <MDBox mt={1}>
                                <RadioSelectWrapper
                                    selected={mailingScheduleType === MailingScheduleTypes.HoldUntil}
                                    onClick={() => {
                                        setOrder((prevState: Order) => {
                                            return {
                                                ...prevState,
                                                productionSpeed: ProductionSpeed.Normal,
                                                holdUntilDate: null as Date
                                            }
                                        })

                                        setMailingScheduleType(MailingScheduleTypes.HoldUntil)
                                    }}>
                                    <MDBox pl={2}>
                                        <Grid container gap={0.5} alignItems={"center"}>
                                            <Grid item>
                                                <Radio
                                                    checked={mailingScheduleType === MailingScheduleTypes.HoldUntil}
                                                />
                                            </Grid>

                                            <Grid item>
                                                <MDTypography sx={{ fontSize: 16 }} fontWeight={"bold"}>Delay
                                                    Production</MDTypography>
                                            </Grid>
                                        </Grid>

                                        {mailingScheduleType === MailingScheduleTypes.HoldUntil ?
                                            <MDBox mt={2}>
                                                <MDDatePicker
                                                    options={{
                                                        minDate: formatDate(checkout?.estimatedMailDate)
                                                    }}
                                                    onChange={(dates: Date, dateStr: string) => {
                                                        order.holdUntilDate = dateStr
                                                        postOrderMutation.mutate()
                                                    }}
                                                    input={{
                                                        backgroundColor: "white",
                                                        fullWidth: true,
                                                        placeholder: "MM/DD/YYYY",
                                                        helperText: "Your order will be mailed no sooner than the date specified.",
                                                        value: order.holdUntilDate ? moment(order.holdUntilDate).format("MM/DD/YYYY") : null
                                                    }} />
                                            </MDBox>
                                            :
                                            null
                                        }
                                    </MDBox>
                                </RadioSelectWrapper>
                            </MDBox>
                        </MDBox>

                        <MDBox my={4}>
                            <Divider light={true} />
                        </MDBox>

                        {checkout ?
                            <MDBox>
                                <MDBox>
                                    <MDTypography variant={"h4"} color={"text"}>Delivery Option</MDTypography>

                                    <MDBox mt={2}>
                                        <RadioSelectWrapper
                                            selected={postageType === PostageTypes.FirstClass}
                                            onClick={() => {
                                                setOrder((prevState: Order) => {
                                                    return {
                                                        ...prevState,
                                                        postageType: PostageTypes.FirstClass
                                                    }
                                                })

                                                postOrderMutation.mutate()

                                                setPostageType(PostageTypes.FirstClass)
                                            }}>
                                            <MDBox pl={2}>
                                                <Grid container alignItems={"center"} gap={1}>
                                                    <Grid item>
                                                        <Radio checked={order.postageType === PostageTypes.FirstClass} />
                                                    </Grid>

                                                    <Grid item>
                                                        <MDTypography sx={{ fontSize: 16 }} fontWeight={"bold"}>First Class Postage</MDTypography>
                                                    </Grid>

                                                    <Grid item>
                                                        <MDTypography sx={{ fontSize: 16 }} color={"secondary"}>
                                                            (3-5 day USPS estimated delivery)
                                                        </MDTypography>
                                                    </Grid>

                                                    <Grid item>
                                                        {orderProducUsesLetterUSPSPricing(order.product) ?
                                                            <Chip label={"+$0.15"} />
                                                            :
                                                            <Chip label={"+$0.05"} />
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </MDBox>
                                        </RadioSelectWrapper>
                                    </MDBox>

                                    <MDBox mt={1}>
                                        <RadioSelectWrapper
                                            selected={postageType === PostageTypes.Standard}
                                            onClick={() => {
                                                setOrder((prevState: Order) => {
                                                    return {
                                                        ...prevState,
                                                        postageType: PostageTypes.Standard
                                                    }
                                                })

                                                postOrderMutation.mutate()

                                                setPostageType(PostageTypes.Standard)
                                            }}>
                                            <MDBox pl={2}>
                                                <Grid container alignItems={"center"} gap={1}>
                                                    <Grid item>
                                                        <Radio checked={order.postageType === PostageTypes.Standard} />
                                                    </Grid>

                                                    <Grid item>
                                                        <MDTypography sx={{ fontSize: 16 }} fontWeight={"bold"}>
                                                            Standard Postage
                                                        </MDTypography>
                                                    </Grid>

                                                    <Grid item>
                                                        <MDTypography sx={{ fontSize: 16 }}>
                                                            (10-14 day USPS estimated delivery)
                                                        </MDTypography>
                                                    </Grid>
                                                </Grid>
                                            </MDBox>
                                        </RadioSelectWrapper>
                                    </MDBox>
                                </MDBox>

                                <MDBox my={4}>
                                    <Divider light={true} />
                                </MDBox>
                            </MDBox>
                            :
                            null
                        }

                        {checkout?.stripeToken ?
                            <MDBox>
                                <MDTypography variant={"h4"} color={"text"}>Payment Method</MDTypography>

                                {savedCards?.length ?
                                    <Grid container mt={2} gap={2} flexWrap={"nowrap"}>
                                        <Grid item xs={6}>
                                            <RadioSelectWrapper
                                                selected={paymentMethod === PaymentMethodTypes.ChargeCardOnFile}
                                                disabled={!savedCards.length}
                                                onClick={() => {
                                                    setSelectedCard(null)
                                                    setPaymentMethod(PaymentMethodTypes.ChargeCardOnFile)
                                                }}
                                            >
                                                <MDBox pl={2}>
                                                    <Grid container alignItems={"center"} gap={1}>
                                                        <Grid item>
                                                            <Radio
                                                                checked={paymentMethod === PaymentMethodTypes.ChargeCardOnFile}
                                                            />
                                                        </Grid>

                                                        <Grid item display={"flex"} alignItems={"center"}>
                                                            <MDTypography fontWeight={"bold"} sx={{ fontSize: 16 }}>
                                                                Charge card on file
                                                            </MDTypography>
                                                        </Grid>
                                                    </Grid>
                                                </MDBox>
                                            </RadioSelectWrapper>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <RadioSelectWrapper
                                                selected={paymentMethod === PaymentMethodTypes.NewPayment}
                                                onClick={() => {
                                                    setPaymentMethod(PaymentMethodTypes.NewPayment)
                                                }}
                                            >
                                                <MDBox pl={2}>
                                                    <Grid container alignItems={"center"} gap={1}>
                                                        <Grid item>
                                                            <Radio
                                                                checked={paymentMethod === PaymentMethodTypes.NewPayment}
                                                            />
                                                        </Grid>

                                                        <Grid item display={"flex"} alignItems={"center"}>
                                                            <MDTypography fontWeight={"bold"} sx={{ fontSize: 16 }}>
                                                                New payment info
                                                            </MDTypography>
                                                        </Grid>
                                                    </Grid>
                                                </MDBox>
                                            </RadioSelectWrapper>
                                        </Grid>
                                    </Grid>
                                    :
                                    null
                                }

                                {paymentMethod === PaymentMethodTypes.ChargeCardOnFile ?
                                    <MDBox mt={4}>
                                        <MDTypography variant={"h5"} color={"text"}>Select your card</MDTypography>

                                        <Grid container mt={1} spacing={2}>
                                            {savedCards.map((card, index) => {
                                                return <Grid item xs={4} key={index} sx={{ cursor: "pointer" }}>
                                                    <PaymentCard card={card} selected={selectedCard?.id === card.id}
                                                        isDefault={defaultPaymentMethodId === card.id}
                                                        onClick={() => {
                                                            setSelectedCard(card)
                                                        }} />
                                                </Grid>
                                            })}
                                        </Grid>
                                    </MDBox>
                                    :
                                    null
                                }

                                {paymentMethod === PaymentMethodTypes.NewPayment ?
                                    <StripePaymentForm />
                                    :
                                    null
                                }

                                <MDBox my={4}>
                                    <Divider light={true} />
                                </MDBox>
                            </MDBox>
                            :
                            null
                        }

                        <MDBox textAlign={"center"} mt={2} width={'100%'}>
                            <MDTypography variant={"h5"} fontWeight={"normal"} color={"secondary"}>
                                If you have any questions or encountered any issues you can contact us at:
                            </MDTypography>

                            <MDTypography variant={"h5"} fontWeight={"normal"} color={"primary"}>
                                <a href={"mailto: billing@lettrlabs.com"}
                                    style={{ color: "inherit" }}>billing@lettrlabs.com</a>
                            </MDTypography>
                        </MDBox>

                        <MDButton color={"light"} circular={true} onClick={() => onBack()}>
                            Back
                        </MDButton>
                    </MDBox>
                </MDCard>
            </Grid>

            <Grid item minWidth={600} xs={4}>
                <MDCard color={"white"} borderRadiusSize={"xxxl"}>
                    <MDBox p={5} position={"relative"}>
                        <MDBox style={{
                            opacity: isFetchingCheckout || isFetchingOrder || order.orderStatus === OrderStatus.Paid ? 0.5 : 1,
                            pointerEvents: isFetchingCheckout || isFetchingOrder || order.orderStatus === OrderStatus.Paid ? "none" : "all"
                        }}>
                            <MDTypography variant={"h4"} color={"text"}>Order Summary</MDTypography>

                            <Grid container mt={4} alignItems={"center"}>
                                <Grid item xs={6}></Grid>

                                <Grid item xs={2}>
                                    <MDTypography variant={"h5"} color={"text"}>Cost</MDTypography>
                                </Grid>

                                <Grid item xs={2}>
                                    <MDTypography variant={"h5"} color={"text"}>Qty</MDTypography>
                                </Grid>

                                <Grid item xs={2}>
                                    <MDTypography variant={"h5"} color={"text"} textAlign={"right"}>Amount</MDTypography>
                                </Grid>
                            </Grid>

                            <Divider light={true} />

                            {checkout?.lineItems?.map((lineItem, index: number) => {
                                return <MDBox key={index}>
                                    <Grid container mt={2} alignItems={"center"}>
                                        <Grid item xs={6}>
                                            <Tooltip title={lineItem.name}>
                                                <MDTypography variant={"h5"} color={"text"} sx={{
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis"
                                                }}>{lineItem.name}</MDTypography>
                                            </Tooltip>
                                        </Grid>

                                        <Grid item xs={2}>
                                            <MDTypography variant={"h5"} color={"text"}>
                                                {formatPrice(lineItem.rate)}
                                            </MDTypography>
                                        </Grid>

                                        <Grid item xs={2}>
                                            <MDTypography variant={"h5"} color={"text"}>
                                                {lineItem.quantity ? formatNumber(lineItem.quantity) : ""}
                                            </MDTypography>
                                        </Grid>

                                        <Grid item xs={2}>
                                            <MDTypography variant={"h5"} color={"text"} textAlign={"right"}>
                                                {formatPrice(lineItem.quantity * lineItem.rate)}
                                            </MDTypography>
                                        </Grid>
                                    </Grid>

                                    <MDBox mt={2}>
                                        {lineItem.subItems.map((subLineItem, index) => {
                                            return <Grid container key={index} mt={1} alignItems={"center"}>
                                                <Grid item xs={6}>
                                                    <MDTypography variant={"h5"} color={"text"} fontWeight={"normal"}>
                                                        {subLineItem.name}
                                                    </MDTypography>
                                                </Grid>

                                                <Grid item xs={2}>
                                                    {subLineItem.isAddon ?
                                                        <Chip label={formatPrice(subLineItem.cost)} />
                                                        :
                                                        <MDTypography variant={"h5"} color={"text"}>
                                                            {formatPrice(subLineItem.cost)}
                                                        </MDTypography>
                                                    }
                                                </Grid>

                                                <Grid item xs={2}></Grid>
                                                <Grid item xs={2}></Grid>
                                            </Grid>
                                        })}
                                    </MDBox>
                                </MDBox>
                            })}

                            <Divider light={true} />

                            <Grid container alignItems={"center"}>
                                <Grid item xs={6}></Grid>

                                <Grid item xs={2} display={"flex"}>
                                    <MDTypography variant={"h5"} color={"text"}>
                                        Total Tax:
                                    </MDTypography>
                                </Grid>

                                <Grid item xs={2}></Grid>
                                <Grid item xs={2}>
                                    <MDTypography variant={"h5"} color={"text"} textAlign={"right"}>
                                        {formatPrice(0)}
                                    </MDTypography>
                                </Grid>
                            </Grid>

                            <Grid container mt={2} alignItems={"center"}>
                                <Grid item xs={6}></Grid>

                                <Grid item xs={2} display={"flex"}>
                                    <MDTypography variant={"h5"} color={"text"}>Credits:</MDTypography>
                                </Grid>

                                <Grid item xs={2}></Grid>
                                <Grid item xs={2}>
                                    <MDTypography variant={"h5"} color={"text"} textAlign={"right"}>
                                        {formatPrice(checkout?.credits?.reduce((a, b) => a + b.total, 0) ?? 0)}
                                    </MDTypography>
                                </Grid>
                            </Grid>

                            <Grid container mt={2} alignItems={"center"}>
                                <Grid item xs={6}></Grid>

                                <Grid item xs={2} display={"flex"}>
                                    <MDTypography variant={"h4"} color={"primary"} sx={{ whiteSpace: "nowrap" }}>
                                        Total Due:
                                    </MDTypography>
                                </Grid>

                                <Grid item xs={4}>
                                    <MDTypography variant={"h4"} color={"primary"} textAlign={"right"}>
                                        {formatPrice(checkout?.balance ?? 0)}
                                    </MDTypography>
                                </Grid>
                            </Grid>

                            <MDBox mt={4}>
                                <Divider light={true} />
                            </MDBox>

                            <MDBox mt={2}>
                                <MDCard color={"light"} boxShadow={false} borderRadiusSize={"lg"}>
                                    <MDBox p={2}>
                                        <Grid container alignItems={"center"} gap={1}>
                                            <Grid item>
                                                <MDTypography fontWeight={"bold"} color={"text"} sx={{ fontSize: "16px" }}>
                                                    USPS Est. Delivery:
                                                </MDTypography>
                                            </Grid>
                                            <Grid item>
                                                <MDTypography color={"text"} sx={{ fontSize: "16px" }} fontWeight={"normal"}>
                                                    {checkout?.estimatedDeliveryDateMin && checkout?.estimatedDeliveryDateMax ?
                                                        `${moment(checkout.estimatedDeliveryDateMin).format("ddd, MMM. Do")} - ${moment(checkout.estimatedDeliveryDateMax).format("ddd, MMM. Do")}`
                                                        :
                                                        null
                                                    }
                                                </MDTypography>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                </MDCard>

                                <MDTypography mt={1} ml={2} variant={"h6"} fontWeight={'normal'}>
                                    *USPS Delivery estimates not guaranteed by LettrLabs
                                </MDTypography>
                            </MDBox>

                            <MDBox>
                                <Divider light={true} />
                            </MDBox>

                            <MDBox mt={2}>
                                {order.orderStatus !== OrderStatus.Paid ?
                                    checkout?.stripeToken ?
                                        <CheckoutButtonStripe order={order} paymentIntent={checkout?.stripeToken}
                                            paymentMethod={paymentMethod} selectedCard={selectedCard}
                                            paymentCallback={handlePaymentCallback}
                                            mailingScheduleType={mailingScheduleType} />
                                        :
                                        <CheckoutButtonFree order={order} paymentCallback={handleFreeCheckoutCallback} />
                                    :
                                    null
                                }
                            </MDBox>

                            <MDTypography mt={1} align={"center"} variant={"h6"} fontWeight={'normal'} color={"secondary"}>
                                *By placing your order, you agree to LettrLabs terms and conditions
                            </MDTypography>
                        </MDBox>

                        <MDBox sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            display: (isFetchingCheckout || isFetchingOrder) ? "flex" : "none"
                        }}>
                            <Spinner2 />
                        </MDBox>
                    </MDBox>
                </MDCard>
            </Grid>
        </Grid>

        <InfoModal
            show={showErrorModal}
            setShow={setShowErrorModal}
            headerText={errorModalText}
            showConfirmButton={true}
            showCancelButton={false}
            confirmButtonOnClick={() => {
                setShowErrorModal(false)
            }
            } />
    </MDBox>
}

function CheckoutSubpage({ order, setOrder, setActiveStep, onBack }: CheckoutSubpageProps) {
    const { setShowLoader } = useGlobal()

    const { getOrderCheckout } = OrderService()
    const { getProfile } = ProfileService()

    const [stripePromise, setStripePromise] = useState(null)
    const [paymentIntent, setPaymentIntent] = useState<string>("")

    const [checkout, setCheckout] = useState<ICheckout>(null)

    const getCheckoutQuery = useQuery({
        queryKey: ["checkout", "order", order.id],
        queryFn: () => {
            return getOrderCheckout(order.id)
        },
        refetchOnWindowFocus: false
    })

    const getProfileQuery = useQuery({
        queryKey: ["profile"],
        queryFn: () => {
            return getProfile()
        },
        refetchOnWindowFocus: false
    })

    useEffect(() => {
        setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY))
    }, []);

    useEffect(() => {
        if (getCheckoutQuery.isLoading || getProfileQuery.isLoading) {
            setShowLoader(true)
        }
        else {
            setShowLoader(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCheckoutQuery.isLoading, getProfileQuery.isLoading]);

    useEffect(() => {
        if (getCheckoutQuery.data) {
            setCheckout(getCheckoutQuery.data.payload)

            setPaymentIntent(getCheckoutQuery.data.payload.stripeToken)
        }
    }, [getCheckoutQuery.data]);

    return !getCheckoutQuery.isLoading && !getProfileQuery.isLoading ?
        paymentIntent ?
            <Elements key={paymentIntent} stripe={stripePromise} options={{ clientSecret: paymentIntent }}>
                <CheckoutSubpageContent order={order}
                    defaultPaymentMethodId={getProfileQuery.data.defaultPaymentMethodId}
                    setOrder={setOrder} setActiveStep={setActiveStep} onBack={onBack}
                    checkout={checkout} isFetchingCheckout={getCheckoutQuery.isFetching} />
            </Elements>
            :
            <CheckoutSubpageContent order={order} defaultPaymentMethodId={getProfileQuery.data.defaultPaymentMethodId}
                setOrder={setOrder} setActiveStep={setActiveStep} onBack={onBack}
                checkout={checkout} isFetchingCheckout={getCheckoutQuery.isFetching} />
        :
        null
}

interface CheckoutButtonProps {
    order: Order
    paymentIntent: string
    selectedCard: PaymentMethod
    paymentMethod: PaymentMethodTypes
    paymentCallback: Function
    mailingScheduleType: MailingScheduleTypes
}

function CheckoutButtonStripe({
    order,
    paymentIntent,
    paymentMethod,
    selectedCard,
    paymentCallback,
    mailingScheduleType
}: CheckoutButtonProps) {
    const stripe = useStripe();
    const elements = useElements();
    const { setShowLoader } = useGlobal()

    function checkout(event: FormEvent) {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        //Paying with an already existing card
        if (selectedCard) {
            stripe.confirmCardPayment(paymentIntent, {
                payment_method: selectedCard.id
            }).then((result) => {
                paymentCallback(result)
            })
        } else {
            elements.submit()
                .then((result1) => {
                    if (result1.error) {
                        return;
                    }

                    setShowLoader(true)

                    //stripe.confirmPayment will only redirect if the user chooses a redirect-based payment method
                    stripe.confirmPayment({
                        elements,
                        redirect: "if_required"
                    }).then((result2: any) => {
                        paymentCallback(result2)
                    })
                })
        }
    }

    return <MDButton
        fullWidth={true}
        color="primary"
        circular={true}
        disabled={!order.postageType || !paymentMethod || (paymentMethod === PaymentMethodTypes.ChargeCardOnFile && !selectedCard) || (mailingScheduleType === MailingScheduleTypes.HoldUntil && !order.holdUntilDate)}
        onClick={checkout}
    >
        Place your order
    </MDButton>
}

interface CheckoutButtonFreeProps {
    order: Order
    paymentCallback: Function
}

function CheckoutButtonFree({ order, paymentCallback }: CheckoutButtonFreeProps) {
    const { postOrderCheckout } = OrderService()
    const { setShowLoader } = useGlobal()

    function checkout() {
        setShowLoader(true)

        postOrderCheckout(order.id).then((response) => {
            paymentCallback(response)
        })
    }

    return <MDButton
        fullWidth={true}
        color="primary"
        circular={true}
        disabled={!order.postageType}
        onClick={checkout}
    >
        Place your order
    </MDButton>
}

export default CheckoutSubpage