export const replaceAllCaseInsensitive = (original, from, to) => {
  //eslint-disable-next-line
  var esc = from.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&")
  var reg = new RegExp(esc, "ig")
  return original.replace(reg, to)
}

export function toCamelCase(inputString) {
  const words = inputString.toLowerCase().split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  return words.join(' ');
}
