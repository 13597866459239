import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
const AddressBookIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M19.5 2.25H6C5.60218 2.25 5.22064 2.40804 4.93934 2.68934C4.65804 2.97064 4.5 3.35218 4.5 3.75V6H3C2.80109 6 2.61032 6.07902 2.46967 6.21967C2.32902 6.36032 2.25 6.55109 2.25 6.75C2.25 6.94891 2.32902 7.13968 2.46967 7.28033C2.61032 7.42098 2.80109 7.5 3 7.5H4.5V11.25H3C2.80109 11.25 2.61032 11.329 2.46967 11.4697C2.32902 11.6103 2.25 11.8011 2.25 12C2.25 12.1989 2.32902 12.3897 2.46967 12.5303C2.61032 12.671 2.80109 12.75 3 12.75H4.5V16.5H3C2.80109 16.5 2.61032 16.579 2.46967 16.7197C2.32902 16.8603 2.25 17.0511 2.25 17.25C2.25 17.4489 2.32902 17.6397 2.46967 17.7803C2.61032 17.921 2.80109 18 3 18H4.5V20.25C4.5 20.6478 4.65804 21.0294 4.93934 21.3107C5.22064 21.592 5.60218 21.75 6 21.75H19.5C19.8978 21.75 20.2794 21.592 20.5607 21.3107C20.842 21.0294 21 20.6478 21 20.25V3.75C21 3.35218 20.842 2.97064 20.5607 2.68934C20.2794 2.40804 19.8978 2.25 19.5 2.25ZM19.5 20.25H6V3.75H19.5V20.25Z"
                    fill="currentColor"/>
                <path
                    d="M15 11C15 11.5304 14.7893 12.0391 14.4142 12.4142C14.0391 12.7893 13.5304 13 13 13C12.4696 13 11.9609 12.7893 11.5858 12.4142C11.2107 12.0391 11 11.5304 11 11C11 10.4696 11.2107 9.96086 11.5858 9.58579C11.9609 9.21071 12.4696 9 13 9C13.5304 9 14.0391 9.21071 14.4142 9.58579C14.7893 9.96086 15 10.4696 15 11Z"
                    stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                    d="M17 11C17 14.8091 13 17 13 17C13 17 9 14.8091 9 11C9 9.93913 9.42143 8.92172 10.1716 8.17157C10.9217 7.42143 11.9391 7 13 7C14.0609 7 15.0783 7.42143 15.8284 8.17157C16.5786 8.92172 17 9.93913 17 11Z"
                    stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export default AddressBookIcon;