import { useEffect } from 'react';
import { AccountCircleOutlined } from '@mui/icons-material';
import { Autocomplete, ListItem, TextField } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { IProfile } from 'models/profile';
import ProfileService from 'services/profile';
import { useGlobal } from 'context/global-context';

import { StyledSidebarDivider } from './StyledSidebar';
import SidebarItem from './SidebarItem';

export default function SidebarItemActingAs() {
  const queryClient = useQueryClient();
  const { setShowLoader, isSidebarOpen } = useGlobal();
  const { getLoggedInProfile, postProfile } = ProfileService();

  const {
    data: profile,
    refetch: refetchLoggedInProfile,
    isRefetching,
    isFetched,
  } = useQuery({
    queryKey: ['loggedInProfile'],
    queryFn: getLoggedInProfile,
    refetchOnWindowFocus: false,
  });

  const { mutate: updateProfile } = useMutation({
    mutationFn: postProfile,
    onMutate: () => setShowLoader(true),
    onSuccess: (updatedProfile: IProfile) => {
      sessionStorage.setItem(
        'actingAsUserId',
        updatedProfile?.actingAsUserId ? updatedProfile?.actingAsUserId.toString() : null
      );
      queryClient.invalidateQueries();
      refetchLoggedInProfile();
    },
  });

  useEffect(() => {
    const actingAsUserId = sessionStorage.getItem('actingAsUserId');
    if (!actingAsUserId && profile?.actingAsUserId) updateProfile({ ...profile, actingAsUserId: null });
  }, [profile, setShowLoader, updateProfile]);

  useEffect(() => {
    if (!isRefetching && isFetched) setShowLoader(false);
  }, [isRefetching, isFetched, setShowLoader]);

  const currentActingAs = profile?.availableProfiles.find((option) => option.id === profile?.actingAsUserId) || null;
  const firstName = currentActingAs ? currentActingAs.firstName : profile?.firstName;
  const lastName = currentActingAs ? currentActingAs.lastName : profile?.lastName;
  const email = currentActingAs ? currentActingAs.email : profile?.email;
  const actingAsLabel = `${profile?.actingAsUserId ? 'As' : 'Hi'} ${firstName}`;
  const actingAsMiniTooltip = `${actingAsLabel}, open the sidebar to change user`;
  const actingAsFullTooltip = `${firstName} ${lastName}, ${email}`;

  if (!(profile?.canActAsUser || profile?.isActingAsUser)) return null;

  return (
    <>
      <SidebarItem
        persistentTooltip
        name={actingAsLabel}
        icon={<AccountCircleOutlined />}
        tooltipText={isSidebarOpen ? actingAsFullTooltip : actingAsMiniTooltip}
        collapse={
          isSidebarOpen && (
            <ListItem sx={{ py: 1, px: 2.5 }}>
              <Autocomplete
                fullWidth
                blurOnSelect
                options={profile.availableProfiles}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.email)}
                renderInput={(params) => <TextField {...params} placeholder="Act as ..." />}
                value={currentActingAs}
                onChange={(_, newValue) =>
                  updateProfile({
                    ...profile,
                    actingAsUserId: newValue && typeof newValue !== 'string' ? newValue.id : null,
                  })
                }
              />
            </ListItem>
          )
        }
      />
      <StyledSidebarDivider />
    </>
  );
}
