export enum SceneDefaultArtifacts {
  QrCode = "https://lettrlabsappstorage.blob.core.windows.net/scenes/images/QR_Code.png",
  Blank = "https://lettrlabsappstorage.blob.core.windows.net/scenes/images/QR_Code.png",
  Rear = "https://lettrlabsappstorage.blob.core.windows.net/scenes/images/Rear_Image.png",
  OutsideFront = "https://lettrlabsappstorage.blob.core.windows.net/scenes/images/Outside_Front_Image.png",
  OutsideKeepOut = "https://lettrlabsappstorage.blob.core.windows.net/scenes/images/Outside_KeepOut_Image.png",
  Front = "https://lettrlabsappstorage.blob.core.windows.net/scenes/images/Front_Logo.png",
  A8Card = "https://lettrlabsappstorage.blob.core.windows.net/scenes/large_handwritten_card(A8).scene",
  A8PostCard = "https://lettrlabsappstorage.blob.core.windows.net/scenes/handwritten_post_card.scene",
  PrintedPostCardFrontImage = "https://lettrlabsappstorage.blob.core.windows.net/scenes/images/Front_Image_High.png"
}
