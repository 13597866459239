import MDButton from "material-ui/components/MDButton";
import MDTypography from "material-ui/components/MDTypography";
import {Grid} from "@mui/material";
import MDBox from "material-ui/components/MDBox";
import MDCard from "material-ui/components/MDCard";
import MailboxIcon from "assets/icons/mailbox";
import CreateOrderIcon from "assets/icons/create-order";
import {useMutation, useQuery} from "@tanstack/react-query";
import TemplateService from "services/template";
import {Template} from "models/template";
import TemplateCard from "components/template-card";
import AddTemplateCard from "components/add-template-card";
import PremadeDesignsBlock from "components/premade-designs-block";
import PremadeDesignService from "services/premade-design";
import CreateOrderFlow from "components/create-order-flow";
import {useEffect, useState} from "react";
import {PremadeDesign} from "models/premadeDesign";
import {CreateOrderFlowSteps} from "models/order-creation-flow/create-order-flow-steps";
import TwoButtonWithOptionalFormFieldModal from "components/modal-with-two-buttons-and-textfield";
import {Link, useNavigate} from "react-router-dom";
import StyledCarousel from "components/styled-carousel/styled-carousel";
import {Order} from "models/order";
import OrderService from "services/order";
import { useGlobal } from "context/global-context";

function HomePage() {
    const {setShowLoader} = useGlobal()
    const {getTemplates, postTemplate} = TemplateService()
    const {getPremadeDesigns} = PremadeDesignService()
    const {postOrder} = OrderService()

    const navigate = useNavigate()

    const [premadeDesigns, setPremadeDesigns] = useState(null)

    const [showCreateOrderFromPremadeDesignModal, setShowCreateOrderFromPremadeDesignModal] = useState<boolean>(false)
    const [showCreateNewTemplateModal, setShowCreateNewTemplateModal] = useState<boolean>(false)
    const [newTemplateName, setNewTemplateName] = useState<string>("")

    const [selectedDesign, setSelectedDesign] = useState<PremadeDesign>(null)

    const createOrderMutation = useMutation({
        mutationFn: () => {
            let o: Order = new Order(0)

            return postOrder(o)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: (data: any) => {
            setShowLoader(false)
            navigate(`/order/${data.id}`)
        }
    })

    const getTemplatesQuery = useQuery({
        queryKey: ["templates"],
        queryFn: getTemplates
    })

    const createNewTemplateMutation = useMutation({
        mutationFn: () => {
            let template = new Template(0);
            template.name = newTemplateName

            return postTemplate(template)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: (data) => {
            setShowLoader(false)
            setShowCreateNewTemplateModal(false)
            navigate(`/template/${data.id}`)
        }
    })

    const responsiveTemplates = {
        desktopLarge: {
            breakpoint: {max: 3000, min: 1900},
            items: 5
        },
        desktopMedium: {
            breakpoint: {max: 1900, min: 1500},
            items: 4
        },
        desktopSmall: {
            breakpoint: {max: 1500, min: 1200},
            items: 3
        },
        tablet: {
            breakpoint: {max: 1200, min: 900},
            items: 2
        },
        mobile: {
            breakpoint: {max: 900, min: 0},
            items: 1
        }
    };

    const responsivePremadeDesigns = {
        desktopExtraLarge: {
            breakpoint: {max: 10000, min: 2200},
            items: 6
        },
        desktopLarge: {
            breakpoint: {max: 2200, min: 1900},
            items: 6
        },
        desktopMedium: {
            breakpoint: {max: 1900, min: 1700},
            items: 5
        },
        desktopSmall: {
            breakpoint: {max: 1700, min: 1024},
            items: 4
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1
        }
    };

    useEffect(function () {
        getPremadeDesigns().then((result) => {
            setPremadeDesigns(result)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <MDBox>
        <MDCard color={"white"}>
            <MDBox p={3}>
                <Grid container alignItems={"center"} justifyContent={"space-between"}>
                    <Grid item>
                        <Link to={"/my-templates"}>
                            <MDTypography fontWeight={"bold"} sx={{fontSize: "26px"}} isLink={true} linkHoverColor={"primary"}>My Templates</MDTypography>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Grid container gap={2}>
                            <Grid item>
                                <MDButton
                                    color={"light"}
                                    onClick={() => {
                                        createOrderMutation.mutate()
                                    }}
                                >
                                    <Grid container alignItems={"center"} gap={1}>
                                        <Grid item display={"flex"}>
                                            <MailboxIcon/>
                                        </Grid>
                                        <Grid item>
                                            New Order
                                        </Grid>
                                    </Grid>
                                </MDButton>
                            </Grid>
                            <Grid item>
                                <MDButton
                                    color={"primary"}
                                    onClick={() => {
                                        setShowCreateNewTemplateModal(true)
                                    }}
                                >
                                    <Grid container alignItems={"center"} gap={1}>
                                        <Grid item display={"flex"}>
                                            <CreateOrderIcon/>
                                        </Grid>
                                        <Grid item>
                                            New Template
                                        </Grid>
                                    </Grid>
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <MDBox mt={3}>
                    {!getTemplatesQuery.data?.length?
                        <StyledCarousel
                            responsive={responsiveTemplates}
                            showDots={false}
                            renderArrowsWhenDisabled={true}
                            slidesToSlide={2}
                            swipeable={false}
                            draggable={false}
                            arrows={true}
                        >
                            <MDBox p={1} width={"100%"} height={"100%"}>
                                <AddTemplateCard/>
                            </MDBox>
                        </StyledCarousel>
                        :
                        null
                    }

                    {getTemplatesQuery.data?.length?
                        <StyledCarousel
                            className={"arrows-blue"}
                            responsive={responsiveTemplates}
                            showDots={false}
                            renderArrowsWhenDisabled={true}
                            slidesToSlide={2}
                            swipeable={false}
                            draggable={false}
                            arrows={true}
                        >
                            <MDBox p={1} width={"100%"} height={"100%"}>
                                <AddTemplateCard/>
                            </MDBox>

                            {getTemplatesQuery.data?.map((template: Template, index: number) => {
                                return <MDBox p={1} key={index}>
                                    <TemplateCard key={index} template={template}/>
                                </MDBox>
                            })}
                        </StyledCarousel>
                        :
                        null
                    }
                </MDBox>
            </MDBox>
        </MDCard>

        <MDBox mt={3}>
            <MDCard color={"white"}>
                <MDBox p={3} pt={4}>
                    <MDTypography fontWeight={"bold"} sx={{fontSize: "26px"}}>Free Card Designs</MDTypography>

                    <MDBox mt={3}>
                        <PremadeDesignsBlock
                            showFilter={false}
                            data={premadeDesigns}
                            designOnClick={(design: PremadeDesign) => {
                                setSelectedDesign(design)
                                setShowCreateOrderFromPremadeDesignModal(true)
                            }}
                            responsiveSteps={responsivePremadeDesigns}
                        />
                    </MDBox>
                </MDBox>
            </MDCard>
        </MDBox>

        <CreateOrderFlow
            show={showCreateOrderFromPremadeDesignModal}
            setShow={setShowCreateOrderFromPremadeDesignModal}
            startAtStep={CreateOrderFlowSteps.Step3}
            design={selectedDesign}
        />

        <TwoButtonWithOptionalFormFieldModal
            open={showCreateNewTemplateModal}
            onClose={() => setShowCreateNewTemplateModal(false)}
            formFieldLabel="Template Name"
            formFieldHelperText="Please name your template, this will help you identify it later"
            formFieldValue={newTemplateName}
            formFieldOnChange={setNewTemplateName}
            primaryButtonText="Create"
            primaryButtonOnClick={() => createNewTemplateMutation.mutate()}
            secondaryButtonText="Cancel"
            secondaryButtonOnClick={() => setShowCreateNewTemplateModal(false)}
        />
    </MDBox>
}

export default HomePage