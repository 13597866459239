import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const PeriodRangeSendIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_6724_57143)">
                    <path
                        d="M5.80273 2.5V4.5M14.1973 2.5V4.5M2.80469 16.5V6.5C2.80469 5.96957 2.99421 5.46086 3.33155 5.08579C3.6689 4.71071 4.12644 4.5 4.60352 4.5H15.3965C15.8736 4.5 16.3311 4.71071 16.6684 5.08579C17.0058 5.46086 17.1953 5.96957 17.1953 6.5V16.5M2.80469 16.5C2.80469 17.0304 2.99421 17.5391 3.33155 17.9142C3.6689 18.2893 4.12644 18.5 4.60352 18.5H15.3965C15.8736 18.5 16.3311 18.2893 16.6684 17.9142C17.0058 17.5391 17.1953 17.0304 17.1953 16.5M2.80469 16.5V9.83333C2.80469 9.3029 2.99421 8.79419 3.33155 8.41912C3.6689 8.04405 4.12644 7.83333 4.60352 7.83333H15.3965C15.8736 7.83333 16.3311 8.04405 16.6684 8.41912C17.0058 8.79419 17.1953 9.3029 17.1953 9.83333V16.5"
                        stroke="#130B46" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <circle cx="14.4531" cy="14.9141" r="4.75" fill="white" stroke="#130B46" strokeWidth="1.5"/>
                    <path
                        d="M13.7903 15.4638L14.8459 14.4082M16.1116 12.9137L11.7586 14.1397C11.7216 14.1498 11.6886 14.1711 11.6641 14.2007C11.6395 14.2303 11.6248 14.2667 11.6217 14.305C11.6186 14.3433 11.6274 14.3816 11.6469 14.4147C11.6664 14.4478 11.6957 14.4741 11.7306 14.49L13.7296 15.4358C13.7688 15.4539 13.8002 15.4853 13.8184 15.5245L14.7641 17.5235C14.78 17.5585 14.8063 17.5877 14.8394 17.6072C14.8725 17.6267 14.9108 17.6355 14.9491 17.6324C14.9874 17.6294 15.0238 17.6146 15.0534 17.5901C15.083 17.5655 15.1043 17.5325 15.1144 17.4955L16.3404 13.1425C16.3497 13.1108 16.3503 13.0771 16.3421 13.045C16.3339 13.013 16.3172 12.9837 16.2938 12.9603C16.2704 12.9369 16.2412 12.9202 16.2091 12.912C16.177 12.9038 16.1433 12.9044 16.1116 12.9137Z"
                        stroke="#130B46" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_6724_57143">
                        <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
                    </clipPath>
                </defs>
            </svg>

        </SvgIcon>
    )
}

export default PeriodRangeSendIcon;