/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { forwardRef, FC } from "react";

// Custom styles for MDRadio
import MDRadioRoot from "material-ui/components/MDRadio/MDRadioRoot";

// declaring props types for MDRadio
interface Props {
    color?: "primary" | "secondary" | "error" | "info" | "success" | "warning" | "default" | "white";
    [key: string]: any;
}

const MDRadio: FC<Props> = forwardRef(({ color, ...rest }, ref) => (
        <MDRadioRoot
            {...rest}
            ownerState={{ color }}
        />
    )
);

// Declaring default props for MDRadio
MDRadio.defaultProps = {
    color: "primary",
};

export default MDRadio;
