import { useState } from 'react';
import { FieldProps } from 'formik';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Accordion, AccordionActions, AccordionDetails, CircularProgress, Typography } from '@mui/material';
import FormikErrorMessage from 'components/formik-error-message';
import MDButton from 'material-ui/components/MDButton';
import MDCard from 'material-ui/components/MDCard';
import ProfileService from 'services/profile';
import AccordionTitle from '../accordion-title';

interface LeadRevealPaymentAccordionProps extends FieldProps {
  expanded: string;
  setExpanded: (expanded: string) => void;
}

export default function LeadRevealPaymentAccordion({
  field,
  meta,
  form,
  expanded,
  setExpanded,
}: LeadRevealPaymentAccordionProps) {
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();
  const { addProfilePaymentMethod } = ProfileService();
  const [canSubmitPayment, setCanSubmitPayment] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSubmitingPayment, setIsSubmitingPayment] = useState<boolean>(false);
  const hasErrors = meta?.touched && meta?.error;

  const { mutate: addPaymentMethod } = useMutation({
    mutationFn: addProfilePaymentMethod,
    onSuccess: (result) => {
      if (result.hasErrors) setErrorMessage(result.errors[0]);
      else {
        queryClient.invalidateQueries({ queryKey: ['getLeadRevealSource'] });
        form.setFieldValue('hasDefaultCreditCard', true);
        setExpanded('');
      }
      setIsSubmitingPayment(false);
    },
  });

  const handlePaymentMethodSubmit = async () => {
    if (!stripe || !elements) setErrorMessage('System error. Please try again later.');
    else {
      setIsSubmitingPayment(true);
      const card = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({ type: 'card', card });
      if (error) {
        setErrorMessage(error.message);
        setIsSubmitingPayment(false);
      } else addPaymentMethod(paymentMethod.id);
    }
  };

  return (
    <Accordion
      elevation={0}
      disableGutters
      disabled={field.value}
      expanded={expanded === 'DefaultPayment'}
      onChange={() => setExpanded(expanded === 'DefaultPayment' ? '' : 'DefaultPayment')}
      sx={{ my: '1px', border: `1px solid ${hasErrors ? 'red' : '#344767'}` }}
    >
      <AccordionTitle checked={field.value} meta={meta}>
        Default payment method set
      </AccordionTitle>
      <AccordionDetails>
        <Typography variant="h5">To proceed, please set a default payment method for future automations.</Typography>
        <Typography variant="h5">
          Note:{' '}
          <Typography variant="h5" component={'span'} fontWeight={'normal'}>
            You won't be charged now; charges will only apply after you create and enable a LeadReveal automation.
          </Typography>
        </Typography>
        <MDCard border boxShadow borderRadiusSize={'md'} boxShadowSize={'small'} sx={{ p: 1, mt: 1 }}>
          <CardElement onChange={(e) => setCanSubmitPayment(e.complete)} />
        </MDCard>
        <FormikErrorMessage meta={{ touched: true, error: errorMessage }} />
      </AccordionDetails>
      <AccordionActions>
        <MDButton
          color="secondary"
          disabled={!canSubmitPayment || isSubmitingPayment}
          onClick={handlePaymentMethodSubmit}
        >
          {isSubmitingPayment ? <CircularProgress size={20} /> : 'Add payment method'}
        </MDButton>
      </AccordionActions>
    </Accordion>
  );
}
