/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS Base Styles
import typography from "material-ui/theme/base/typography";

// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from "material-ui/theme/functions/pxToRem";

const { size, fontWeightBold } = typography;

// types
type Types = any;

const stepLabel: Types = {
  styleOverrides: {
    label: {
      marginTop: `${pxToRem(8)} !important`,
      fontWeight: fontWeightBold,
      fontSize: size.md,
      color: "black",

      "&:hover": {

      },

      "&.Mui-active": {
        fontWeight: fontWeightBold,
      },

      "&.Mui-completed": {
        fontWeight: fontWeightBold,
      },
    },
  },
};

export default stepLabel;
