import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT from "../endpoint";

function FontService(){
    const {execute} = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/fonts`

    // Creates a search integration order from a template
    function getFonts(){
        return execute({
            endpoint: API_PATH,
            method: "GET"
        })
    }

    // Gets a font table that contains the glyphs for every character in the font
    function getFontGlyphs(id: number) {
        return execute({
            endpoint: `${API_PATH}/${id}/font-glyphs`,
            method: "GET"
        })
    }
    
    return {
        getFonts,
        getFontGlyphs
    }
}

export default FontService