import * as Yup from "yup"

export const PaymentValidationCallTracking = Yup.object({
    cardNumber: Yup.string().required("Required"),
    cardholderName: Yup.string().required("Required"),
    expirationDate: Yup.string().length(5, "Invalid date").required("Required"),
    cvv: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    zip: Yup.string().length(5).required("Required"),
})

export const PaymentValidationStripe = Yup.object({
    cardNumber: Yup.string().required("Required"),
    expirationDate: Yup.string().length(5, "Invalid date").required("Required"),
    cvv: Yup.string().required("Required"),
})