import MDCard from "material-ui/components/MDCard";
import MDBox from "material-ui/components/MDBox";
import MDTypography from "material-ui/components/MDTypography";
import React, {useEffect, useState} from "react";
import {Divider, Grid, Switch, Tooltip} from "@mui/material";
import ProfileService from "services/profile";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {IProfile} from "models/profile";
import {formatNumber, formatPrice} from "helpers/formatters";
import MDButton from "material-ui/components/MDButton";
import PaymentCard from "components/payment-card";
import {PaymentMethod} from "@stripe/stripe-js";
import DeleteIcon from "assets/icons/delete";
import InfoModal from "components/info-modal";
import AddPaymentMethodModal from "./components/add-payment-method-modal";
import { useGlobal } from "context/global-context";

function Billing(){
    const queryClient = useQueryClient()

    const {setShowLoader} = useGlobal()
    const {getProfile, postProfile, getOrderTransactions, getCreditTransactions, getProfilePaymentMethods, deleteProfilePaymentMethod} = ProfileService()

    const [profile, setProfile] = useState<IProfile>(null)

    const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = useState<boolean>(false)

    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [errorModalText, setErrorModalText] = useState<string>("")

    const [showDeleteCardModal, setShowDeleteCardModal] = useState<boolean>(false)
    const [paymentMethodToDelete, setPaymentMethodToDelete] = useState<PaymentMethod>(null)

    const getProfileQuery = useQuery({
        queryKey: ["profile"],
        queryFn: () => {
            return getProfile()
        },
        refetchOnWindowFocus: false
    })

    const getProfilePaymentMethodsQuery = useQuery({
        queryKey: ["profilePaymentMethods"],
        queryFn: () => {
            return getProfilePaymentMethods()
        },
        refetchOnWindowFocus: false
    })

    function _deleteProfilePaymentMethod(){
        setShowLoader(true)

        deleteProfilePaymentMethod(paymentMethodToDelete.id).then((response1) => {
            setShowDeleteCardModal(false)

            if(response1.hasErrors){
                setShowLoader(false)
                setErrorModalText(response1.errors[0])
                setShowErrorModal(true)
                return
            }

            getProfile().then((response2) => {
                setShowLoader(false)

                setProfile((prevState) => {
                    return {...prevState, defaultPaymentMethodId: response2.defaultPaymentMethodId}
                })
            })

            queryClient.invalidateQueries({queryKey: ["profilePaymentMethods"]})
        })
    }

    useEffect(() => {
        if(getProfilePaymentMethodsQuery.data && getProfileQuery.data){
            setShowLoader(false)
        }
        else{
            setShowLoader(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getProfileQuery.data, getProfilePaymentMethodsQuery.data]);

    useEffect(function () {
        if(getProfileQuery.data){
            setProfile(getProfileQuery.data)
        }
    }, [getProfileQuery.data])

    function updateAutobill(e: React.MouseEvent) {
        let checked = !profile.autobill

        if(checked){
            if(!profile.defaultPaymentMethodId){
                setErrorModalText("You need to have a default payment method to be able to auto-billing")
                setShowErrorModal(true)
                e.preventDefault()
                return
            }
        }

        setShowLoader(true)

        let updatedProfile = profile
        updatedProfile.autobill = checked

        postProfile(updatedProfile).then(() => {
            setProfile(updatedProfile)

            setShowLoader(false)
        })
    }

    function downloadOrderSummary(){
        setShowLoader(true)

        getOrderTransactions().then(() => {
            setShowLoader(false)
        })
    }

    function downloadCreditTransactions(){
        setShowLoader(true)

        getCreditTransactions().then(() => {
            setShowLoader(false)
        })
    }

    return <MDBox>
        <MDCard color={"white"} boxShadow={false} border={false}>
            <MDBox p={4}>
                <MDBox>
                    <MDTypography variant={"h4"} fontWeight={"bold"}>Subscription</MDTypography>

                    <Grid container alignItems={"center"} mt={2} gap={0.5}>
                        <Grid item>
                            <MDTypography variant={"h5"} fontWeight={"bold"}>Subscription Name: </MDTypography>
                        </Grid>
                        <Grid item>
                            <MDTypography variant={"h5"}>{profile? (profile.customerSubscription.subscription.name === "Default Pricing"? "N/A" : profile.customerSubscription.subscription.name) : ""}</MDTypography>
                        </Grid>
                    </Grid>

                    <Grid container alignItems={"center"} mt={2} gap={0.5}>
                        <Grid item>
                            <MDTypography variant={"h5"} fontWeight={"bold"}>Price: </MDTypography>
                        </Grid>
                        <Grid item>
                            <MDTypography variant={"h5"} fontWeight={"bold"}>{profile? profile.customerSubscription.subscription.name === "Default Pricing"? "N/A" : `$${formatNumber(profile.customerSubscription.subscription.price)}` : ""}</MDTypography>
                        </Grid>
                    </Grid>

                    {profile && profile.customerSubscription.subscription.name !== "Default Pricing" && 
                        <>
                            <Grid container alignItems={"center"} mt={2} gap={0.5}>
                                <Grid item>
                                    <MDTypography variant={"h5"} fontWeight={"bold"}>Status:</MDTypography>
                                </Grid>
                                <Grid item>
                                    <MDTypography variant={"h5"} fontWeight={"bold"}>Active</MDTypography>
                                </Grid>
                            </Grid>
                            <Grid container alignItems={"center"} mt={2} gap={0.5}>
                                <Grid item>
                                    <MDTypography variant={"h5"} fontWeight={"bold"}>To cancel your subscription please contact <a href="mailto:Billing@LettrLabs.com">Billing@LettrLabs.com</a> </MDTypography>
                                </Grid>
                            </Grid>

                        </>  
                    }
                </MDBox>


                <MDBox my={3}>
                    <Divider light={true}/>
                </MDBox>

                <MDBox>
                    <MDTypography variant={"h4"} fontWeight={"bold"}>Available Credits</MDTypography>
                    <Grid container alignItems={"center"} mt={2} gap={0.5}>
                        <Grid item>
                            <MDTypography variant={"h5"} fontWeight={"bold"}>Balance: </MDTypography>
                        </Grid>
                        <Grid item>
                            <MDTypography variant={"h5"} fontWeight={"bold"}>{profile? formatPrice(profile.customerSubscription.balance) : ""}</MDTypography>
                        </Grid>
                    </Grid>
                    <Grid container alignItems={"center"} mt={2} gap={0.5}>
                        <Grid item>
                            <MDTypography variant={"h5"} fontWeight={"bold"}>Bonus Balance: </MDTypography>
                        </Grid>
                        <Grid item>
                            <MDTypography variant={"h5"} fontWeight={"bold"}>{profile? formatPrice(profile.balance) : ""}</MDTypography>
                        </Grid>
                    </Grid>
                </MDBox>

                <MDBox my={3}>
                    <Divider light={true}/>
                </MDBox>

                <MDBox>
                    <MDTypography variant={"h4"} fontWeight={"bold"}>Transaction Reports</MDTypography>

                    <Grid container mt={3} alignItems={"center"} gap={1}>
                        <Grid item>
                            <MDButton color={"primary"} circular={true} onClick={downloadOrderSummary}>Order Summary</MDButton>
                        </Grid>

                        <Grid item>
                            <MDButton color={"primary"} circular={true} onClick={downloadCreditTransactions}>Transaction Summary</MDButton>
                        </Grid>
                    </Grid>
                </MDBox>

                <MDBox my={3}>
                    <Divider light={true}/>
                </MDBox>

                <MDBox>
                    <Grid container alignItems={"center"} gap={2}>
                        <Grid item>
                            <MDTypography variant={"h4"} fontWeight={"bold"}>Auto-Refill Balance for Integration Orders: </MDTypography>
                        </Grid>
                        <Grid item>
                            <Switch color={"primary"} checked={profile?.autobill} onClick={(e) => {
                                updateAutobill(e)
                            }}/>
                        </Grid>
                    </Grid>

                    <MDTypography sx={{fontSize: 18}} fontWeight={"regular"} mt={2}>If your account has insufficient funds to process an automated order we will automatically bill your default credit card with the amount that's needed to pay for the order, or $100, whichever is greater</MDTypography>
                </MDBox>

                <MDBox my={3}>
                    <Divider light={true}/>
                </MDBox>

                <MDBox>
                    <Grid container alignItems={"center"} gap={"20px"}>
                        <Grid item>
                            <MDTypography variant={"h4"} fontWeight={"bold"}>Payment Methods: </MDTypography>
                        </Grid>

                        <Grid item>
                            <MDButton
                                color="primary"
                                onClick={() => setShowAddPaymentMethodModal(true)}
                            >
                                Add new Payment Method
                            </MDButton>
                        </Grid>
                    </Grid>

                    <Grid container mt={2} spacing={2}>
                        {getProfilePaymentMethodsQuery.data?.payload.map((card: PaymentMethod, index: number) => {
                            return <Grid item width={"340px"} key={index}>
                                <Grid container>
                                    <Grid item flex={1}>
                                        <Grid container justifyContent={"flex-end"} mb={2}>
                                            <Grid item>
                                                <Tooltip title={card.id === profile?.defaultPaymentMethodId? "You can't delete your default payment method as you may have active subscriptions, automations, or auto-billing enabled. Please contact support@lettrlabs.com if you want to remove it" : ""}>
                                                    <MDBox>
                                                        <MDButton
                                                            color={"light"}
                                                            onClick={() => {
                                                                setPaymentMethodToDelete(card)
                                                                setShowDeleteCardModal(true)
                                                            }}
                                                            disabled={card.id === profile?.defaultPaymentMethodId}
                                                        >
                                                            <Grid container alignItems={"center"} justifyContent={"center"} gap={1}>
                                                                <Grid item display={"flex"}>
                                                                    <DeleteIcon color={"error"}/>
                                                                </Grid>
                                                                <Grid item>
                                                                    Delete
                                                                </Grid>
                                                            </Grid>
                                                        </MDButton>
                                                    </MDBox>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>

                                        <PaymentCard
                                            card={card}
                                            selected={profile?.defaultPaymentMethodId === card.id}
                                            isDefault={profile?.defaultPaymentMethodId === card.id}
                                            onClick={() => {
                                                if(profile.defaultPaymentMethodId === card.id) {
                                                    return
                                                }

                                                setShowLoader(true)

                                                profile.defaultPaymentMethodId = card.id

                                                postProfile(profile).then(() => {
                                                    setProfile(profile)
                                                    setShowLoader(false)
                                                })
                                            }}/>
                                    </Grid>

                                    <Grid item ml={2}>
                                        <Divider orientation={"vertical"} light={true}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        })}
                    </Grid>
                </MDBox>
            </MDBox>
        </MDCard>

        <InfoModal
            show={showDeleteCardModal}
            setShow={setShowDeleteCardModal}
            headerText={`Are you sure you want to delete card ending with ${paymentMethodToDelete?.card.last4}?`}
            showCancelButton={true}
            showConfirmButton={true}
            cancelButtonOnClick={() => {
                setShowDeleteCardModal(false)
            }}
            confirmButtonOnClick={() => {
                _deleteProfilePaymentMethod()
            }}
        />

        <InfoModal
            show={showErrorModal}
            setShow={setShowErrorModal}
            headerText={errorModalText}
            showCancelButton={false}
            showConfirmButton={true}
            confirmButtonOnClick={() => {
                setShowErrorModal(false)
            }}
        />

        <AddPaymentMethodModal
            show={showAddPaymentMethodModal}
            setShow={setShowAddPaymentMethodModal}
            setProfile={setProfile}
        />
    </MDBox>
}

export default Billing