import { useState } from 'react';
import { Form, Formik } from 'formik';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Alert, Box, CircularProgress, Dialog, DialogContent, Typography } from '@mui/material';

import MDButton from 'material-ui/components/MDButton';
import { IObjectResult } from 'services/endpoint';
import { doNotMailValidation } from 'newStandard/src/services/doNotMail/schema';
import useDoNotMailService from 'newStandard/src/services/doNotMail';
import { IDoNotMailRecipient } from 'newStandard/src/services/doNotMail/types';
import AddressFormikFields from 'newStandard/src/components/AddressFormikFields';

export interface DoNotMailUpsertModalProps {
  recipient?: IDoNotMailRecipient;
  open?: boolean;
  onClose: () => void;
}

export default function DoNotMailUpsertModal({ onClose, open, recipient }: DoNotMailUpsertModalProps) {
  const [responseError, setResponseError] = useState<string>('');

  const queryClient = useQueryClient();
  const { createDoNotMailRecipient, updateDoNotMailRecipient } = useDoNotMailService();

  const onSuccess = (result: IObjectResult<{}>) => {
    if (result.hasErrors) return setResponseError(result.errors[0]);
    queryClient.invalidateQueries({ queryKey: ['getAllDoNotMailRecipients'] });
    onClose();
  };

  const { mutate: addRecipient, isPending: isAddPending } = useMutation({
    mutationFn: createDoNotMailRecipient,
    onSuccess,
  });
  const { mutate: updateRecipient, isPending: isUpdatePending } = useMutation({
    mutationFn: updateDoNotMailRecipient,
    onSuccess,
  });
  const isPending = isAddPending || isUpdatePending;

  const handleSubmit = (values: IDoNotMailRecipient) => {
    if (recipient) updateRecipient(values);
    else addRecipient(values);
  };

  const handleCustonValidate = (values: IDoNotMailRecipient) => {
    const isAnyFieldFilled = Object.values(values).some((value) => value);
    if (isAnyFieldFilled) return {};
    setResponseError('At least one of the fields must be filled');
    return { error: true };
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent>
        <Typography variant="h4" mb={2}>
          {recipient ? 'Edit' : 'Add new'} recipient to "Do Not Mail" list
        </Typography>
        <Formik
          initialValues={recipient || { id: null }}
          validationSchema={doNotMailValidation}
          validate={handleCustonValidate}
          onSubmit={handleSubmit}
        >
          <Form>
            <AddressFormikFields />
            {responseError && (
              <Alert severity="error" onClose={() => setResponseError('')} sx={{ my: 1, py: 0 }}>
                {responseError}
              </Alert>
            )}
            <Box display={'flex'} gap={2} mt={2}>
              <MDButton fullWidth type="submit" disabled={isPending}>
                {isPending ? <CircularProgress size={20} color="secondary" /> : 'Save'}
              </MDButton>
              <MDButton color="light" onClick={onClose} disabled={isPending}>
                Cancel
              </MDButton>
            </Box>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
