import {
    Grid,
    Icon,
    IconButton,
} from "@mui/material"
import {Template} from "models/template"
import {useNavigate} from "react-router-dom"
import {useState} from "react"
import TwoButtonWithOptionalFormFieldModal from "components/modal-with-two-buttons-and-textfield"
import {useMutation} from "@tanstack/react-query";
import TemplateService from "services/template";
import MDCard from "material-ui/components/MDCard";
import { useGlobal } from "context/global-context"

type SelectType =
    | { selectOnly: true; isSelected?: boolean; setIsSelected: () => void }
    | { selectOnly?: false; isSelected?: never; setIsSelected?: never };

type AddTemplateCardProps = {
    minHeight?: string;
    onDoubleClick?: () => void
} & SelectType;

// Data
function AddTemplateCard({minHeight = "300px", selectOnly, isSelected, setIsSelected, onDoubleClick}: AddTemplateCardProps) {
    const navigate = useNavigate()
    const {setShowLoader} = useGlobal()
    const {postTemplate} = TemplateService()

    const [showCreateNewTemplateModal, setShowCreateNewTemplateModal] = useState<boolean>(false)
    const [newTemplateName, setNewTemplateName] = useState<string>("")

    const createNewTemplateMutation = useMutation({
        mutationFn: () => {
            let template = new Template(0);
            template.name = newTemplateName

            return postTemplate(template)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: (data) => {
            setShowLoader(false)
            setShowCreateNewTemplateModal(false)
            navigate(`/template/${data.id}`)
        }
    })

    return (
        <>
            <MDCard
                boxShadow={false}
                border={true}
                borderSize={isSelected? 2 : 1}
                color={"light"}
                onDoubleClick={onDoubleClick ? () => onDoubleClick() : null}
                onClick={() => selectOnly ? setIsSelected() : setShowCreateNewTemplateModal(true)}
                sx={{cursor: "pointer", height: "100%", minHeight, borderColor: isSelected ? "red" : "black"}}
            >
                <Grid container p={2} alignItems={"center"} justifyContent={"center"} width={"100%"} height={"100%"}>
                    <Grid item>
                        <IconButton color={"primary"} sx={{fontSize: "80px"}}>
                            <Icon>add_circle</Icon>
                        </IconButton>
                    </Grid>
                </Grid>
            </MDCard>

            <TwoButtonWithOptionalFormFieldModal
                open={showCreateNewTemplateModal}
                onClose={() => setShowCreateNewTemplateModal(false)}
                formFieldLabel="Template Name"
                formFieldHelperText="Please name your template, this will help you identify it later"
                formFieldValue={newTemplateName}
                formFieldOnChange={setNewTemplateName}
                primaryButtonText="Create"
                primaryButtonOnClick={() => createNewTemplateMutation.mutate()}
                secondaryButtonText="Cancel"
                secondaryButtonOnClick={() => setShowCreateNewTemplateModal(false)}
            />
        </>
    )
}

export default AddTemplateCard