import MinusIcon from "assets/icons/minus";
import PlusIcon from "assets/icons/plus";
import MDIconButton from "material-ui/components/MDIconButton";

interface IncrementDecrementButtonProps {
  type: "minus" | "plus";
  value: number
  minValue: number
  maxValue: number
  setValue: (value: number) => void;
}

const IncrementDecrementButton = ({ type, value, minValue, maxValue, setValue }: IncrementDecrementButtonProps) => {
  const isMinus = type === "minus"
  const isDisabled = (isMinus ? minValue : maxValue) === value
  const Icon = isMinus ? MinusIcon : PlusIcon

  return (
    <MDIconButton
      square
      size={"small"}
      color={"white"}
      onClick={() => setValue(value + (isMinus ? -1 : 1))}
      disabled={isDisabled}
      sx={{ aspectRatio: 1 }}
    >
      <Icon color={"secondary"} />
    </MDIconButton>
  )
};

export default IncrementDecrementButton
