import * as Yup from 'yup';

export const dicountCodeValidation = (isNameOptional: boolean) =>
  Yup.object({
    name: Yup.string().when({
      is: isNameOptional,
      then: Yup.string().nullable(),
      otherwise: Yup.string().required('Required'),
    }),
    expirationDate: Yup.date(),
  });
