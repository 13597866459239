import { FieldArray, FieldArrayRenderProps } from "formik";
import { Box, Chip, Grid, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import SmartAutomationFilterItem from "./smart-automation-filter-item";
import { emptyFilter } from "./smart-automation-form";
import { IUserCreatedFilter } from "services/smart-automation/smart-automation.types";

interface SmartAutomationFilterGroupProps extends FieldArrayRenderProps {
  orGroup: { integrationOrderFilters: IUserCreatedFilter[] }
  orIndex: number
  disabled?: boolean
}

const SmartAutomationFilterGroup = ({ orGroup, orIndex, disabled, ...arrayHelpers }: SmartAutomationFilterGroupProps) => {
  const orList = arrayHelpers.form.values.userCreatedFilters;
  const hasManyOrGroups = orList.length > 1
  const hasManyAndGroups = orGroup.integrationOrderFilters.length > 1

  const defineButtonPadding = () => {
    if (!hasManyOrGroups) return 0
    if (hasManyAndGroups) return 15
    return 7
  }

  const handleAddAnd = () => {
    arrayHelpers.replace(orIndex, { integrationOrderFilters: [...orGroup.integrationOrderFilters, emptyFilter] })
  }

  return (
    <Box py={1.5} pl={hasManyOrGroups ? 0 : 1} border={1} borderColor={"ButtonShadow"} borderRadius={2}>
      <Grid container gap={1}>
        {hasManyOrGroups && (
          <Grid item width={44}>
            {orIndex !== 0 && <Chip label="OR" color="secondary" size="medium" sx={{ marginTop: -9, fontWeight: 700 }} />}
          </Grid>
        )}

        <Grid item flex={1}>
          <Grid container gap={1} display={{ xs: "none", lg: "flex" }}>
            {orGroup.integrationOrderFilters.length > 1 && <Grid item width={54} />}
            <Grid item lg={3} maxWidth={350} minWidth={200}>
              <Typography variant="h6">Filter</Typography>
            </Grid>
            <Grid item lg={3} maxWidth={350} minWidth={200}>
              <Typography variant="h6">Operator</Typography>
            </Grid>
            <Grid item flex={1} maxWidth={350} minWidth={200}>
              <Typography variant="h6">Value</Typography>
            </Grid>
          </Grid>

          <FieldArray
            name={`userCreatedFilters.${orIndex}.integrationOrderFilters`}
            render={innerArrayHelpers => orGroup.integrationOrderFilters?.map((filter, andIndex) => (
              <SmartAutomationFilterItem
                key={`filter${orIndex}${andIndex}`}
                orIndex={orIndex}
                andIndex={andIndex}
                filter={filter}
                deleteOrGroup={() => arrayHelpers.remove(orIndex)}
                disabled={disabled}
                {...innerArrayHelpers}
              />
            ))}
          />
        </Grid>
      </Grid>
      <Box display={"flex"} gap={1} pl={defineButtonPadding()} mt={1}>
        <Chip label="AND" color="primary" variant="filled" component="button" clickable sx={{ fontWeight: 700 }}
          icon={<AddIcon style={{ width: 20, height: 20 }} />}
          onClick={handleAddAnd}
          disabled={disabled}
        />
        <Chip label="OR" color="primary" variant="filled" component="button" clickable sx={{ fontWeight: 700 }}
          icon={<AddIcon style={{ width: 20, height: 20 }} />}
          onClick={() => arrayHelpers.push({ integrationOrderFilters: [emptyFilter] })}
          disabled={disabled}
        />
      </Box>
    </Box>
  )
}

export default SmartAutomationFilterGroup