import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Alert, Box, Grid } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import MDCard from 'material-ui/components/MDCard';
import MDTypography from 'material-ui/components/MDTypography';
import MDButton from 'material-ui/components/MDButton';
import SmartAutomationService from 'services/smart-automation';
import SmartAutomationGrid from './components/smart-automation-grid';
import NewSmartAutomationModal from 'components/new-smart-automation-modal';
import PlusIcon from 'assets/icons/plus';
import Loader from 'components/loader';

const SmartAutomationPage = () => {
  const navigate = useNavigate();
  const { getAllSmartAutomations } = SmartAutomationService();
  const [showNewAutomationModal, setShowNewAutomationModal] = useState<boolean>(false);

  const {
    data: automationsData,
    isLoading: isLoadingAutomations,
    isFetching,
    isRefetching,
    isError,
  } = useQuery({
    queryFn: () => getAllSmartAutomations(),
    queryKey: ['allSmartAutomations'],
    refetchOnWindowFocus: false,
  });

  const hasErrors = isError || automationsData?.hasErrors;
  const smartAutomations = automationsData?.payload.integrationOrders;

  const renderContent = () => {
    if (isLoadingAutomations) {
      return (
        <Box display="flex" justifyContent="center">
          <Loader />
        </Box>
      );
    }
    if (hasErrors) {
      return (
        <Alert severity="error" style={{ marginBottom: 12 }}>
          <MDTypography sx={{ fontSize: 16 }} fontWeight={'bold'}>
            Something went wrong, please try again later
          </MDTypography>
        </Alert>
      );
    }
    if (!smartAutomations?.length) {
      return (
        <Alert severity="success" style={{ marginBottom: 12 }}>
          <MDTypography sx={{ fontSize: 16 }} fontWeight={'bold'}>
            You have data sources available.
            <MDTypography
              component="span"
              color={'primary'}
              fontWeight={'bold'}
              sx={{ fontSize: 16 }}
              isLink={true}
              linkHoverColor={'primary'}
              onClick={() => setShowNewAutomationModal(true)}
            >
              Click here to create your first smart automation!
            </MDTypography>
          </MDTypography>
        </Alert>
      );
    }
    return <SmartAutomationGrid data={smartAutomations} isLoading={isFetching || isRefetching} />;
  };

  return (
    <MDCard>
      <Box p={3}>
        <Grid container alignItems={'center'} justifyContent={'space-between'} mb={3}>
          <MDTypography sx={{ fontSize: 26 }} fontWeight={'bold'}>
            Smart Automations
          </MDTypography>
          <Box display={'flex'} gap={1}>
            <MDButton color="light" disabled={hasErrors} onClick={() => navigate('/settings/smart-automation-setup')}>
              <SettingsIcon style={{ marginRight: 8 }} /> Setup Sources
            </MDButton>
            <MDButton onClick={() => setShowNewAutomationModal(true)}>
              <PlusIcon style={{ marginRight: 8 }} /> New Automation
            </MDButton>
          </Box>
        </Grid>

        {renderContent()}
      </Box>
      <NewSmartAutomationModal open={showNewAutomationModal} setOpen={setShowNewAutomationModal} />
    </MDCard>
  );
};

export default SmartAutomationPage;
