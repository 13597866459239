import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT from "../endpoint";
import {IOrderRecipient} from "models/orderRecipient";

function OrderRecipientService() {
    const {execute} = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/orderRecipient`

    // Gets all order recipients for an order
    function getOrderRecipients(id: number) {
        return execute({
            endpoint: `${API_PATH}/${id}`,
            method: "GET"
        })
    }

    // Upserts an order recipient
    function postOrderRecipient(orderRecipient: IOrderRecipient) {
        return execute({
            endpoint: `${API_PATH}`,
            method: "POST",
            data: orderRecipient
        })
    }

    // Deletes order recipients
    function deleteOrderRecipientsFromAnOrder(id: number, recipientIds: number[]) {
        return execute({
            endpoint: `${API_PATH}/deletefromorder/${id}`,
            method: "DELETE",
            data: recipientIds
        })
    }

    function addFromAddressBook(orderId: number, recipientIds: number[], lastSearchParams: object, selectedAll: boolean) {
        return execute({
            endpoint: `${API_PATH}/addfromaddressbook/${orderId}`,
            method: "POST",
            data: {
                recipientIds,
                lastSearchParams,
                selectedAll
            }
        })
    }

    function addFromRecipientSearch(orderId: number, recipientSearchId: number[]) {
        return execute({
            endpoint: `${API_PATH}/addfromradiussearch/${orderId}`,
            method: "POST",
            data: recipientSearchId
        })
    }

    function postProcessOrderRecipients(orderId: number){
        return execute({
            endpoint: `${API_PATH}/postprocess/${orderId}`,
            method: "POST"
        })
    }

    function getPostProcessStatus(orderId: number){
        return execute({
            endpoint: `${API_PATH}/GetPostProcessingStatus/${orderId}`,
            method: "GET"
        })
    }

    return {getOrderRecipients, postOrderRecipient, deleteOrderRecipientsFromAnOrder, addFromAddressBook, addFromRecipientSearch, postProcessOrderRecipients, getPostProcessStatus}
}

export default OrderRecipientService