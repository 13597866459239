import * as Yup from "yup"

export const ProfileRecipientValidation = Yup.object({
    id: Yup.number().nullable(),
    salutation: Yup.string().nullable(),
    firstName: Yup.string().nullable(),
    lastName: Yup.string().nullable(),
    toOrganization: Yup.string().nullable(),
    address1: Yup.string().nullable(),
    address2: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(),
    zipCode: Yup.string().length(5, 'Zip must be 5 digits long').nullable(),
    anniversary: Yup.date().nullable(),
    birthday: Yup.date().nullable(),
})

export function isRequired(schema: any, field: string): boolean {
    return schema.fields[field].exclusiveTests.required
}
