import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {OrderSteps, TemplateSteps} from "models/orderSteps";


interface OrderStepperProps{
    steps: {
        key: number
        label: string
    }[]
    activeStep: OrderSteps | TemplateSteps
    maxStep: OrderSteps | TemplateSteps
    onBack: Function
    onNext: Function
}

function OrderStepper({steps, activeStep, maxStep, onNext, onBack}: OrderStepperProps) {
    function changeStep(newStep: number){
        if(newStep > maxStep || newStep === activeStep){
            return
        }

        if(newStep > activeStep){
            onNext(newStep)
        }
        else{
            onBack(newStep)
        }
    }

    return <Stepper activeStep={activeStep} alternativeLabel={true}>
        {steps.map((step, index) => (
            <Step key={index}>
                <StepLabel onClick={() => changeStep(step.key)} sx={{"*": {cursor: step.key <= maxStep? "pointer !important" : "auto !important"}}}>{step.label}</StepLabel>
            </Step>
        ))}
    </Stepper>
}

export default OrderStepper