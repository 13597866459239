// @ts-ignore
import Retool from "react-retool"
import MDBox from "material-ui/components/MDBox";

function AdminCustomerSuccessDashboardPage(){
    return <MDBox style={{ height: "90vh" }}>
        <Retool url="https://lettrlabs.retool.com/embedded/public/1cdd122c-9ae2-4f43-a3e3-6b022d8af450" />
    </MDBox>
}

export default AdminCustomerSuccessDashboardPage