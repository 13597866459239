import {SelectFrom} from "models/order-creation-flow/select-from";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "material-ui/components/MDButton";
import {DialogContent, Grid, Stack} from "@mui/material";
import MDBox from "material-ui/components/MDBox";
import Dialog from "@mui/material/Dialog";
import {useQuery} from "@tanstack/react-query";
import TemplateService from "services/template";
import {ITemplate, Template} from "models/template";
import TemplateCard from "components/template-card";
import MDTypography from "material-ui/components/MDTypography";

interface NewOrderStep2ModalProps {
    show: boolean
    setShow: Function
    selectFrom: SelectFrom
    onNext: Function
    onBack: Function
    selectedTemplate: ITemplate
    setSelectedTemplate: Function
    onClose: Function
}

function NewOrderStep2TemplatesModal({show, setShow, selectFrom, onNext, onBack, selectedTemplate, setSelectedTemplate, onClose}: NewOrderStep2ModalProps) {
    const {getTemplates} = TemplateService()

    const getTemplatesQuery = useQuery({
        queryKey: ["templates"],
        queryFn: getTemplates
    })

    return <Dialog open={show} onClose={() => onClose()} maxWidth={false}>
        <DialogContent sx={{width: "1000px"}}>
            <DialogTitle>
                {selectFrom === SelectFrom.MyTemplates && "Select one of your templates"}
                {selectFrom === SelectFrom.PremadeDesigns && "Select from our Free Card Designs"}
            </DialogTitle>

            <MDBox height={"72vh"} sx={{overflowY: "scroll"}}>
                <Grid container spacing={"20px"}>
                    {getTemplatesQuery.data?.length?
                        getTemplatesQuery.data.map((template: Template, index: number) => (
                            <Grid item key={index} xs={4}>
                                <TemplateCard template={template} hideButtons={true} onClick={() => setSelectedTemplate(template)} pointerCursor={true} selected={selectedTemplate?.id === template.id}/>
                            </Grid>
                        ))
                        :
                        <Grid item xs={12}>
                            <MDTypography>You don't have any templates</MDTypography>
                        </Grid>
                    }
                </Grid>
            </MDBox>

            <Stack
                direction="row"
                spacing={1}
                marginTop={2}
                justifyContent="flex-end"
            >
                <MDButton
                    color={"light"}
                    circular={true}
                    onClick={() => onBack()}
                >
                    Back
                </MDButton>

                <MDButton
                    onClick={() => onNext()}
                    circular={true}
                    disabled={!selectedTemplate}
                >
                    Next
                </MDButton>
            </Stack>
        </DialogContent>
    </Dialog>
}

export default NewOrderStep2TemplatesModal