/**
 =========================================================
 * Material Dashboard 2 PRO React - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { FC, ReactNode, forwardRef } from "react";

// @mui material components
import {CardProps} from "@mui/material";

// Material Dashboard 2 PRO React TS contexts
import MDCardRoot from "./MDCardRoot";

// Declaring props types for MDCard
interface Props extends Omit<CardProps, "color" | "variant"> {
    color?:
        | "white"
        | "primary"
        | "secondary"
        | "info"
        | "success"
        | "warning"
        | "error"
        | "light"
        | "dark"
        | "default";
    variant?: "primary";
    border?: boolean;
    children?: ReactNode;
    boxShadow?: boolean;
    boxShadowSize?: "small" | "large";
    borderColor?: "light" | "dark" | "darkest"
    borderRadiusSize?:
        | 0
        | "xs"
        | "sm"
        | "md"
        | "lg"
        | "xl"
        | "xxl"
        | "xxxl"
    customColor?: string
    borderSize?: number
    [key: string]: any;
}

const MDCard: FC<Props> = forwardRef(
    ({ color, variant, border, borderColor, borderSize, boxShadowSize, customColor, boxShadow, borderRadiusSize, children, ...rest }, ref) => {
        return (
            <MDCardRoot
                {...rest}
                ref={ref}
                ownerState={{ color, variant, border, boxShadow, borderColor, borderSize, borderRadiusSize, customColor, boxShadowSize}}
            >
                {children}
            </MDCardRoot>
        );
    }
);

// Declaring default props for MDCard
MDCard.defaultProps = {
    color: "white",
    variant: "primary",
    border: false,
    boxShadow: true,
    padding: "40px",
    borderRadiusSize: "xxxl",
    borderColor: "dark",
    borderSize: 1,
    boxShadowSize: "small"
};

export default MDCard;
