import React, {useState} from "react";
import MDBox from "material-ui/components/MDBox";
import MDButton from "material-ui/components/MDButton";
import MDInput from "material-ui/components/MDInput";
import {Order} from "models/order";
import {GridColDef, GridValueGetterParams} from "@mui/x-data-grid-pro";
import {useQuery} from "@tanstack/react-query";
import AdminService from "services/admin";
import {Grid, Tooltip} from "@mui/material";
import StyledDataGrid from "components/styled-data-grid";
import {filterRows} from "helpers/filter-grid";
import MDCard from "material-ui/components/MDCard";
import BulkUpdateOrdersModal from "./components/bulk-update-orders-modal";
import UpdateOrderStatusModal from "./components/update-order-status";
import EditPencilIcon from "assets/icons/edit-pencil";

function AdminChangeOrderStatusPage() {
    const {getOrders} = AdminService()

    const [selectedRows, setSelectedRows] = useState<number[]>([])

    const [search, setSearch] = useState<string>("")

    const [orderToUpdate, setOrderToUpdate] = useState<Order>(null)
    const [showUpdateOrderStatusModal, setShowUpdateOrderStatusModal] = useState<boolean>(false)
    const [showBulkUpdateOrderStatusModal, setShowBulkUpdateOrderStatusModal] = useState<boolean>(false)

    const columns: GridColDef[] = [
        {
            type: "actions",
            field: "actions",
            align: "center",
            width: 50,
            getActions: (params) => {
                let actions = [
                    <Tooltip title={"Edit"}>
                        <MDBox display={"flex"} onClick={() => {
                            setOrderToUpdate(params.row)
                            setShowUpdateOrderStatusModal(true)
                        }}>
                            <EditPencilIcon color={"secondary"}/>
                        </MDBox>
                    </Tooltip>
                ]
                return actions
            }
        },
        {
            headerName: "Order ID",
            field: "id",
            width: 120
        },
        {
            headerName: "Email",
            field: "email",
            width: 250
        },
        {
            headerName: "First Name",
            field: "firstName",
            width: 150
        },
        {
            headerName: "Last Name",
            field: "lastName",
            width: 150
        },
        {
            headerName: "Company Name",
            field: "companyName",
            width: 180
        },
        {
            headerName: "Order Name",
            field: "name",
            width: 350
        },
        {
            headerName: "Product Type",
            field: "product",
            width: 260
        },
        {
            headerName: "Volume",
            field: "orderVolume",
            width: 120
        },
        {
            headerName: "Status",
            field: "orderStatus",
            width: 150
        },
        {
            headerName: "Last Updated",
            field: "updatedDate",
            valueGetter: function (params: GridValueGetterParams) {
                return params.row.updatedDate
                    ? new Date(params.row.updatedDate)
                    : null
            },
            width: 350
        },
        {
            headerName: "Submitted On",
            field: "submittedDate",
            valueGetter: function (params: GridValueGetterParams) {
                return params.row.submittedDate
                    ? new Date(params.row.submittedDate)
                    : null
            },
            width: 350
        },
        {
            headerName: "Paid On",
            field: "paidDate",
            valueGetter: function (params: GridValueGetterParams) {
                return params.row.paidDate
                    ? new Date(params.row.paidDate)
                    : null
            },
            width: 350
        }
    ]

    const getOrdersQuery = useQuery({
        queryFn: getOrders,
        queryKey: ["adminOrders"],
        refetchOnWindowFocus: false
    })

    return <MDCard boxShadow={false} color={"white"}>
        <MDBox p={4}>
            <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                width={"100%"}
            >
                <Grid item>
                    <Grid
                        container
                        alignItems={"center"}
                        gap={1}
                    >
                        <Grid item mr={2}>
                            <MDButton
                                color="primary"
                                disabled={!selectedRows.length}
                                onClick={() => setShowBulkUpdateOrderStatusModal(true)}
                            >
                                Bulk Mark Orders as Mailed
                            </MDButton>
                        </Grid>

                        <Grid item width={"240px"}>
                            <MDInput
                                fullWidth={true}
                                placeholder="Search..."
                                value={search}
                                onChange={({currentTarget}: any) => {
                                    setSearch(currentTarget.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <MDBox height={"70vh"} mt={4}>
                <StyledDataGrid
                    initialState={{
                        pagination: {paginationModel: {pageSize: 25}},
                    }}
                    pagination={true}
                    pageSizeOptions={[25, 50, 100]}
                    loading={getOrdersQuery.isFetching}
                    rows={filterRows(getOrdersQuery.data ?? [], search)}
                    columns={columns}
                    checkboxSelection={true}
                    onRowSelectionModelChange={(selectionModel: any) => {
                        setSelectedRows(selectionModel)
                    }}
                    rowSelectionModel={selectedRows}
                    disableRowSelectionOnClick={true}
                />
            </MDBox>

            <BulkUpdateOrdersModal
                show={showBulkUpdateOrderStatusModal}
                setShow={setShowBulkUpdateOrderStatusModal}
                orders={selectedRows}
            />

            <UpdateOrderStatusModal
                show={showUpdateOrderStatusModal}
                setShow={setShowUpdateOrderStatusModal}
                order={orderToUpdate}
            />
        </MDBox>
    </MDCard>
}

export default AdminChangeOrderStatusPage;