import useFetchWithMsal from 'hooks/useFetch';
import API_ENDPOINT from '../endpoint';
import { PostageTypes } from 'models/enums/PostageTypes';
import { IntegrationOrderStatus } from 'models/enums/IntegrationOrderStatus';
import { IVM_GridOrder } from '../../models/views/gridOrderVm';

function IntegrationOrderService() {
  const { execute } = useFetchWithMsal();

  const API_PATH = `${API_ENDPOINT}/trickleOrder`;

  // Creates a search integration order from a template
  function createIntegrationOrderFromTemplate(name: string, templateId: number, postageType: PostageTypes) {
    return execute({
      endpoint: API_PATH,
      method: 'POST',
      data: {
        id: 0,
        name,
        templateId,
        postageType,
      },
    });
  }

  function copyIntegrationOrder(order: IVM_GridOrder) {
    return execute({
      endpoint: `${API_PATH}/copy`,
      method: 'POST',
      data: order,
    });
  }

  function updateIntegrationOrderStatus(
    id: number,
    status: IntegrationOrderStatus,
    clearTestRecipients: boolean,
    retroactiveProcessingDate?: string
  ) {
    return execute({
      endpoint: `${API_ENDPOINT}/integrationorder/changestatus`,
      method: 'PUT',
      data: { id, status, clearTestRecipients, retroactiveProcessingDate },
    });
  }

  function deleteIntegrationOrder(id: number) {
    return execute({
      endpoint: `${API_PATH}/delete/${id}`,
      method: 'POST',
    });
  }

  function getIntegrationOrderRecipients(id: number) {
    return execute({
      endpoint: `${API_ENDPOINT}/integrationorderRecipient/${id}`,
      method: 'GET',
    });
  }

  function getIntegrationOrder(id: number) {
    return execute({
      endpoint: `${API_ENDPOINT}/integrationOrders/${id}`,
      method: 'GET',
    });
  }

  function getAllIntegrationOrders() {
    return execute({
      endpoint: `${API_ENDPOINT}/integration-order/get-all`,
      method: 'GET',
    });
  }

  return {
    createIntegrationOrderFromTemplate,
    updateIntegrationOrderStatus,
    deleteIntegrationOrder,
    getIntegrationOrderRecipients,
    copyIntegrationOrder,
    getAllIntegrationOrders,
    getIntegrationOrder,
  };
}

export default IntegrationOrderService;
