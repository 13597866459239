import { Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import MDBox from "material-ui/components/MDBox";
import MDCard from "material-ui/components/MDCard";
import OrderStepper from "../components/stepper";
import { useParams } from "react-router-dom";
import OrderService from "services/order";
import { OrderSteps } from "models/orderSteps";
import SuccessSubpage from "../components/subpages/7-success-subpage";

const steps = [
  { key: 0, label: "Product" },
  { key: 1, label: "Design" },
  { key: 2, label: "Return Address" },
  { key: 3, label: "Recipients" },
  { key: 4, label: "Review" },
  { key: 5, label: "Checkout" },
]

const OrderSuccessPage = () => {
  const params = useParams()
  const id = parseInt(params.id)

  const { getOrder } = OrderService()
  const { data } = useQuery({
    queryKey: ["order", id],
    queryFn: () => getOrder(id),
    refetchOnWindowFocus: false
  })

  const order = data[0]

  return (
    <Grid container flexDirection={"column"} height={"100%"} gap={2} flexWrap={"nowrap"}>
      <Grid item>
        <MDCard color={"white"} borderRadiusSize={"xxxl"} boxShadow={false}>
          <MDBox py={2}>
            <OrderStepper steps={steps} activeStep={OrderSteps.SuccessSubpage} maxStep={OrderSteps.SuccessSubpage} onBack={null} onNext={null} />
          </MDBox>
        </MDCard>
      </Grid>

      <Grid item flex={1}>
        <SuccessSubpage order={order} />
      </Grid>
    </Grid>
  )
}

export default OrderSuccessPage