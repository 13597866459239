/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Dashboard 2 PRO React TS Base Styles
import colors from "material-ui/theme/base/colors";

// Material Dashboard 2 PRO React TS Helper Functions
import rgba from "material-ui/theme/functions/rgba";
import pxToRem from "material-ui/theme/functions/pxToRem";

const {dark, white} = colors;

// types
type Types = any;

const divider: Types = {
    styleOverrides: {
        root: {
            backgroundColor: rgba(dark.main, 0.4),
            height: "1px",
            margin: `${pxToRem(16)} 0`,
            borderBottom: "none",

            "&.blurred": {
                backgroundImage: `linear-gradient(to right, ${rgba(white.main, 0)}, ${rgba(white.main, 0.4)}, ${rgba(white.main,0)}) !important`,
                backgroundColor: "transparent"
            }
        },

        vertical: {
            backgroundColor: rgba(dark.main, 0.4),
            width: "1px",
            height: "100%",
            margin: `0`,
            borderRight: "none",
        },

        light: {
            backgroundImage: `linear-gradient(to right, #EDEFF5, #EDEFF5, #EDEFF5) !important`,
        }
    },
};

export default divider;
