import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const RadiusIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2756_13427)">
                    <g clipPath="url(#clip1_2756_13427)">
                        <path
                            d="M12.0001 20.3333C7.40508 20.3333 3.66675 16.595 3.66675 12C3.66675 7.40499 7.40508 3.66666 12.0001 3.66666C16.5951 3.66666 20.3334 7.40499 20.3334 12C20.3334 16.595 16.5951 20.3333 12.0001 20.3333ZM12.0001 4.91666C8.09425 4.91666 4.91675 8.09416 4.91675 12C4.91675 15.9058 8.09425 19.0833 12.0001 19.0833C15.9059 19.0833 19.0834 15.9058 19.0834 12C19.0834 8.09416 15.9059 4.91666 12.0001 4.91666Z"
                            fill="currentColor"/>
                        <path
                            d="M12 6.16667C11.655 6.16667 11.375 5.88667 11.375 5.54167V2.625C11.375 2.28 11.655 2 12 2C12.345 2 12.625 2.28 12.625 2.625V5.54167C12.625 5.88667 12.345 6.16667 12 6.16667Z"
                            fill="currentColor"/>
                        <path
                            d="M21.3749 12.625H18.4583C18.1133 12.625 17.8333 12.345 17.8333 12C17.8333 11.655 18.1133 11.375 18.4583 11.375H21.3749C21.7199 11.375 21.9999 11.655 21.9999 12C21.9999 12.345 21.7199 12.625 21.3749 12.625Z"
                            fill="currentColor"/>
                        <path
                            d="M12 22C11.655 22 11.375 21.72 11.375 21.375V18.4583C11.375 18.1133 11.655 17.8333 12 17.8333C12.345 17.8333 12.625 18.1133 12.625 18.4583V21.375C12.625 21.72 12.345 22 12 22Z"
                            fill="currentColor"/>
                        <path
                            d="M5.54167 12.625H2.625C2.28 12.625 2 12.345 2 12C2 11.655 2.28 11.375 2.625 11.375H5.54167C5.88667 11.375 6.16667 11.655 6.16667 12C6.16667 12.345 5.88667 12.625 5.54167 12.625Z"
                            fill="currentColor"/>
                        <path
                            d="M12 14.5C10.6217 14.5 9.5 13.3783 9.5 12C9.5 10.6217 10.6217 9.5 12 9.5C13.3783 9.5 14.5 10.6217 14.5 12C14.5 13.3783 13.3783 14.5 12 14.5ZM12 10.75C11.3108 10.75 10.75 11.3108 10.75 12C10.75 12.6892 11.3108 13.25 12 13.25C12.6892 13.25 13.25 12.6892 13.25 12C13.25 11.3108 12.6892 10.75 12 10.75Z"
                            fill="currentColor"/>
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_2756_13427">
                        <rect width="24" height="24" fill="white"/>
                    </clipPath>
                    <clipPath id="clip1_2756_13427">
                        <rect width="20" height="20" fill="white" transform="translate(2 2)"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    )
}

export default RadiusIcon;