import {Order} from "models/order";
import MDBox from "material-ui/components/MDBox";
import MDCard from "material-ui/components/MDCard";
import React, {MouseEventHandler, useEffect, useState} from "react";
import MDTypography from "material-ui/components/MDTypography";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import OrderRecipientService from "services/order-recipient";
import {IOrderRecipient, IOrderRecipients, OrderRecipientError, OrderRecipientWarning} from "models/orderRecipient";
import { Alert, Box, Divider, Grid, IconButton, Snackbar, Tooltip } from "@mui/material";
import {GridColDef} from "@mui/x-data-grid-pro";
import AddRecipientModal from "./components/add-recipient-modal";
import MDButton from "material-ui/components/MDButton";
// @ts-ignore~
import { CSVBoxButton } from "@csvbox/react"
import {filterRows} from "helpers/filter-grid";
import EditPencilIcon from "assets/icons/edit-pencil";
import InfoModal from "components/info-modal";
import WarningIcon from "assets/icons/warning";
import AddressBookIcon from "assets/icons/address-book";
import SingleRecipientIcon from "assets/icons/single-recipient";
import MailingListAddIcon from "assets/icons/mailing-list-add";
import SheetAddIcon from "assets/icons/sheet-add";
import DeleteIcon from "assets/icons/delete";
import AddRecipientsFromAddressBookModal from "./components/add-recipients-from-address-book-modal";
import EyeIcon from "assets/icons/eye";
import EyeHiddenIcon from "assets/icons/eye-hidden";
import AddRecipientsFromRecipientSearchModal from "./components/add-recipients-from-recipient-search-modal";
import OrderService from "services/order";
import RecipientProcessModal from "./components/recipient-process-modal";
import LayoutMain from "pages/order/components/layout-main";
import {formatNumber} from "helpers/formatters";
import RecipientUploadHelpModal from "./components/recipient-upload-help";
import { useGlobal } from "context/global-context";
import Table from "components/table";
import { GridSearchParams } from "models/gridSearchParams";
import SelectDiscountCodes, { ISelectedDiscountCode } from "components/select-discount-codes";
import useDiscountCodesService from "services/discount-codes";
import { IObjectResult } from "services/endpoint";

interface RecipientsSubpageProps {
    order: Order
    setOrder: Function
    onBack: Function
    onNext: Function
    recipients: IOrderRecipient[]
    usedColumns: string[]
    isRecipientsLoading: boolean
    hasUnproccessedRecipients: boolean
    undeliverableRows: IOrderRecipient[]
    potentiallyUndeliverableRows: IOrderRecipient[]
    missingMailMergeRows: IOrderRecipient[]
    missingAddressFieldRows: IOrderRecipient[]
    showErrorModal: boolean
    setShowErrorModal: Function
}
const defaultSearchParams: GridSearchParams = { page: 1, pageSize: 100, sortBy: "", sortDirection: "asc", search: "", filters: "" }

function RecipientsSubpage({order, onBack, onNext, recipients, usedColumns, isRecipientsLoading, hasUnproccessedRecipients, undeliverableRows, potentiallyUndeliverableRows, missingMailMergeRows, missingAddressFieldRows, showErrorModal, setShowErrorModal, setOrder}: RecipientsSubpageProps) {
    const {setShowLoader} = useGlobal()
    const queryClient = useQueryClient()
    const CSVBOX_LICENCE = process.env.REACT_APP_CSVBOX_LICENCE

    const {deleteOrderRecipientsFromAnOrder, postProcessOrderRecipients} = OrderRecipientService()
    const {postOrder} = OrderService()
    const {assignCouponToOrder} = useDiscountCodesService()

    const [selectedRows, setSelectedRows] = useState<number[]>([])

    const [showAddRecipientModal, setShowAddRecipientModal] = useState(false)
    const [recipientToManage, setRecipientToManage] = useState<IOrderRecipient>(null)

    const [showDeleteRecipientModal, setShowDeleteRecipientModal] = useState<boolean>(false)
    const [showAddRecipientsFromAddressBookModal, setShowAddRecipientsFromAddressBookModal] = useState<boolean>(false)
    const [showAddRecipientsFromRecipientSearchModal, setShowAddRecipientsFromRecipientSearchModal] = useState<boolean>(false)
    const [showRecipientProcessModal, setShowRecipientProcessModal] = useState<boolean>(false)
    const [searchParams, setSearchParams] = useState<GridSearchParams>(defaultSearchParams);

    const [isLookingAtUndeliverableRows, setIsLookingAtUndeliverableRows] = useState<boolean>(false)
    const [isLookingAtPotentiallyUndeliverableRows, setIsLookingAtPotentiallyUndeliverableRows] = useState<boolean>(false)
    const [isLookingAtMissingMailMergeRows, setIsLookingAtMissingMailMergeRows] = useState<boolean>(false)
    const [isLookingAtMissingAddressRows, setIsLookingAtMissingAddressRows] = useState<boolean>(false)

    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)

    const [showRecipientUploadHelpModal, setShowRecipientUploadHelpModal] = useState<boolean>(false)

    const [showGrid, setShowGrid] = useState<boolean>(false)
    const [hasDiscountError, setHasDiscountError] = useState<boolean>(false);
    const [selectedDiscount, setSelectedDiscount] = useState<ISelectedDiscountCode>(order)
    const [deleteError, setDeleteError] = useState<string>('');

    useEffect(() => {
        setSelectedDiscount(order)
    }, [order])

    const columns: GridColDef[] = [
        {
            type: "actions",
            field: "actions",
            align: "center",
            width: 55,
            getActions: (params) => {
                let actions = [
                    <Tooltip title={"Edit"} arrow={false}>
                        <IconButton onClick={() => {
                            setRecipientToManage(params.row)
                            setShowAddRecipientModal(true)
                        }}>
                            <EditPencilIcon fontSize={"medium"} htmlColor={"#646367"}/>
                        </IconButton>
                    </Tooltip>,
                ]

                return actions
            }
        },
        {
            headerName: "First Name",
            field: "firstName",
            width: 180,
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => {
                if(!params.value && params.row.missingMailMergeFields?.includes("firstName")){
                    return 'Missing Mail Merge Text'
                }

                return params.value || " - "
            },
            cellClassName: (params) => {
                if(!params.row.firstName && params.row.missingMailMergeFields?.includes("firstName")){
                    return 'warning'
                }

                return ""
            }
        },
        {
            headerName: "Last Name",
            field: "lastName",
            width: 200,
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => {
                if(!params.value && params.row.missingMailMergeFields?.includes("lastName")){
                    return 'Missing Mail Merge Text'
                }

                return params.value || " - "
            },
            cellClassName: (params) => {
                if(!params.row.lastName && params.row.missingMailMergeFields?.includes("lastName")){
                    return 'warning'
                }

                return ""
            }
        },
        {
            headerName: "Salutation",
            field: "salutation",
            width: 120,
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => {
                if(!params.value && params.row.missingMailMergeFields?.includes("salutation")){
                    return 'Missing Mail Merge Text'
                }

                return params.value || " - "
            },
            cellClassName: (params) => {
                if(!params.row.salutation && params.row.missingMailMergeFields?.includes("salutation")){
                    return 'warning'
                }

                return ""
            }
        },
        {
            headerName: "To Organization",
            field: "toOrganization",
            width: 180,
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => {
                if(!params.value && params.row.missingMailMergeFields?.includes("toOrganization")){
                    return 'Missing Mail Merge Text'
                }

                return params.value || " - "
            },
            cellClassName: (params) => {
                if(!params.row.toOrganization && params.row.missingMailMergeFields?.includes("toOrganization")){
                    return 'warning'
                }

                return ""
            }
        },
        {
            headerName: "Address 1",
            field: "address1",
            minWidth: 260,
            maxWidth: 500,
            flex: 1,
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => {
                if(!params.value && params.row.missingMailMergeFields?.includes("address1")){
                    return 'Missing Mail Merge Text'
                }

                return params.value || " - "
            },
            cellClassName: (params) => {
                if(!params.row.address1 && params.row.missingMailMergeFields?.includes("address1")){
                    return 'warning'
                }

                return ""
            }
        },
        {
            headerName: "Address 2",
            field: "address2",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => {
                if(!params.value && params.row.missingMailMergeFields?.includes("address2")){
                    return 'Missing Mail Merge Text'
                }

                return params.value || " - "
            },
            cellClassName: (params) => {
                if(!params.row.address2 && params.row.missingMailMergeFields?.includes("address2")){
                    return 'warning'
                }

                return ""
            }
        },
        {
            headerName: "City",
            field: "city",
            width: 150,
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => {
                if(!params.value && params.row.missingMailMergeFields?.includes("city")){
                    return 'Missing Mail Merge Text'
                }

                return params.value || " - "
            },
            cellClassName: (params) => {
                if(!params.row.city && params.row.missingMailMergeFields?.includes("city")){
                    return 'warning'
                }

                return ""
            }
        },
        {
            headerName: "State",
            field: "state",
            width: 100,
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => {
                if(!params.value && params.row.missingMailMergeFields?.includes("state")){
                    return 'Missing Mail Merge Text'
                }

                return params.value || " - "
            },
            cellClassName: (params) => {
                if(!params.row.state && params.row.missingMailMergeFields?.includes("state")){
                    return 'warning'
                }

                return ""
            }
        },
        {
            headerName: "Zip Code",
            field: "zipCode",
            width: 150,
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => {
                if(!params.value && params.row.missingMailMergeFields?.includes("zipCode")){
                    return 'Missing Mail Merge Text'
                }

                return params.value || " - "
            },
            cellClassName: (params) => {
                if(!params.row.zipCode && params.row.missingMailMergeFields?.includes("zipCode")){
                    return 'warning'
                }

                return ""
            }
        },
        {
            headerName: "Custom 1",
            field: "custom1",
            headerAlign: "center",
            align: "center",
            minWidth: 200,
            valueGetter: (params) => {
                if(!params.value && params.row.missingMailMergeFields?.includes("custom1")){
                    return 'Missing Mail Merge Text'
                }

                return params.value || " - "
            },
            cellClassName: (params) => {
                if(!params.row.custom1 && params.row.missingMailMergeFields?.includes("custom1")){
                    return 'warning'
                }

                return ""
            }
        },
        {
            headerName: "Custom 2",
            field: "custom2",
            headerAlign: "center",
            align: "center",
            minWidth: 200,
            valueGetter: (params) => {
                if(!params.value && params.row.missingMailMergeFields?.includes("custom2")){
                    return 'Missing Mail Merge Text'
                }

                return params.value || " - "
            },
            cellClassName: (params) => {
                if(!params.row.custom2 && params.row.missingMailMergeFields?.includes("custom2")){
                    return 'warning'
                }

                return ""
            }
        },
        {
            headerName: "Custom 3",
            field: "custom3",
            headerAlign: "center",
            align: "center",
            minWidth: 200,
            valueGetter: (params) => {
                if(!params.value && params.row.missingMailMergeFields?.includes("custom3")){
                    return 'Missing Mail Merge Text'
                }

                return params.value || " - "
            },
            cellClassName: (params) => {
                if(!params.row.custom3 && params.row.missingMailMergeFields?.includes("custom3")){
                    return 'warning'
                }

                return ""
            }
        },
        {
            headerName: "Custom 4",
            field: "custom4",
            headerAlign: "center",
            align: "center",
            minWidth: 200,
            valueGetter: (params) => {
                if(!params.value && params.row.missingMailMergeFields?.includes("custom4")){
                    return 'Missing Mail Merge Text'
                }

                return params.value || " - "
            },
            cellClassName: (params) => {
                if(!params.row.custom4 && params.row.missingMailMergeFields?.includes("custom4")){
                    return 'warning'
                }

                return ""
            }
        },
        {
            headerName: "Custom 5",
            field: "custom5",
            headerAlign: "center",
            align: "center",
            minWidth: 200,
            valueGetter: (params) => {
                if(!params.value && params.row.missingMailMergeFields?.includes("custom5")){
                    return 'Missing Mail Merge Text'
                }

                return params.value || " - "
            },
            cellClassName: (params) => {
                if(!params.row.custom5 && params.row.missingMailMergeFields?.includes("custom5")){
                    return 'warning'
                }

                return ""
            }
        },
        {
            headerName: "Custom 6",
            field: "custom6",
            headerAlign: "center",
            align: "center",
            minWidth: 200,
            valueGetter: (params) => {
                if(!params.value && params.row.missingMailMergeFields?.includes("custom6")){
                    return 'Missing Mail Merge Text'
                }

                return params.value || " - "
            },
            cellClassName: (params) => {
                if(!params.row.custom6 && params.row.missingMailMergeFields?.includes("custom6")){
                    return 'warning'
                }

                return ""
            }
        },
        {
            headerName: "Radius Pin Address",
            field: "radiusPinAddress",
            minWidth: 260,
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => {
                if(!params.value && params.row.missingMailMergeFields?.includes("radiusPinAddress")){
                    return 'Missing Mail Merge Text'
                }

                return params.value || " - "
            }
        },
        {
            headerName: "Radius Pin Street",
            field: "radiusPinStreet",
            minWidth: 260,
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => {
                if(!params.value && params.row.missingMailMergeFields?.includes("radiusPinStreet")){
                    return 'Missing Mail Merge Text'
                }

                return params.value || " - "
            }
        },
        {
            headerName: "Warnings",
            field: "warnings",
            flex: 1,
            headerAlign: "center",
            align: "center",
            minWidth: 150,
            renderCell: (params: any) => {
                if (params.row.warnings?.length) {
                    let warnings = []

                    if(params.row.warnings.includes(OrderRecipientWarning.PotentiallyUndeliverable)){
                        warnings.push(
                            <Tooltip title={"Potentially undeliverable"}>
                                <MDBox display={"flex"}>
                                    <WarningIcon color={"warning"}/>
                                </MDBox>
                            </Tooltip>
                        )
                    }

                    if(params.row.warnings.includes(OrderRecipientWarning.Undeliverable)){
                        warnings.push(
                            <Tooltip title={"Undeliverable"}>
                                <MDBox display={"flex"}>
                                    <WarningIcon color={"warning"}/>
                                </MDBox>
                            </Tooltip>
                        )
                    }

                    if(params.row.warnings.includes(OrderRecipientWarning.MissingMailMergeField)){
                        warnings.push(
                            <Tooltip title={"Missing mail merge fields"}>
                                <MDBox display={"flex"}>
                                    <WarningIcon color={"warning"}/>
                                </MDBox>
                            </Tooltip>
                        )
                    }

                    return warnings
                }

                return " - "
            }
        },
        {
            headerName: "Errors",
            field: "errors",
            editable: false,
            flex: 1,
            headerAlign: "center",
            align: "center",
            minWidth: 150,
            renderCell: (params: any) => {
                if (params.row.errors?.length) {
                    let errors = []

                    if (params.row.errors.includes(OrderRecipientError.MissingAddressFields)){
                        errors.push(
                            <Tooltip title={"Missing required address field(s) (Address 1, City, State, Zip)"}>
                                <MDBox display={"flex"}>
                                    <WarningIcon color={"error"}/>
                                </MDBox>
                            </Tooltip>
                        )
                    }

                    if (params.row.errors.includes(OrderRecipientError.MissingNameFields)){
                        errors.push(
                            <Tooltip title={"Missing at least one required name field (First Name, Last Name, Organization)"}>
                                <MDBox display={"flex"}>
                                    <WarningIcon color={"error"}/>
                                </MDBox>
                            </Tooltip>
                        )
                    }

                    if (params.row.errors.includes(OrderRecipientError.NonUsAddress)){
                        errors.push(
                            <Tooltip title={"Non US Address"}>
                                <MDBox display={"flex"}>
                                    <WarningIcon color={"error"}/>
                                </MDBox>
                            </Tooltip>
                        )
                    }

                    return errors
                }

                return " - "
            }
        }
    ]

    const {mutate: assignCoupon} = useMutation({
        mutationFn: assignCouponToOrder,
        onMutate: () => setShowLoader(true),
        onSuccess:  (response: IObjectResult<{}>) => {
            if (response?.hasErrors) {
                setDeleteError(response.errors[0])
            } else onNext()
            setShowLoader(false)
        }
    })
    
    const deleteOrderRecipientsMutation = useMutation({
        mutationFn: () => {
            return deleteOrderRecipientsFromAnOrder(order.id, selectedRows)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: () => {
            setShowLoader(false)
            setShowDeleteRecipientModal(false)
            queryClient.invalidateQueries({queryKey: ["orderRecipients", order.id]})
            if(selectedRows.length === rows.length){
                setIsLookingAtMissingAddressRows(false)
                setIsLookingAtPotentiallyUndeliverableRows(false)
                setIsLookingAtUndeliverableRows(false)
                setIsLookingAtMissingMailMergeRows(false)
            }
        }
    })

    useEffect(function () {
        if (recipients) {
            if(hasUnproccessedRecipients){
                startRecipientPostProcess()
            }
        }

        setIsFirstLoad(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipients, hasUnproccessedRecipients, isFirstLoad])

    useEffect(function () {
        if(isFirstLoad){
            setShowLoader(true)
        }
        else{
            setShowLoader(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFirstLoad])

    function startRecipientPostProcess(){
        postProcessOrderRecipients(order.id)
        setShowRecipientProcessModal(true)
    }

    const getRows = () => {
        if (isLookingAtPotentiallyUndeliverableRows) return filterRows(potentiallyUndeliverableRows, searchParams.search)
        if (isLookingAtUndeliverableRows) return filterRows(undeliverableRows, searchParams.search)
        if (isLookingAtMissingMailMergeRows) return filterRows(missingMailMergeRows, searchParams.search)
        if (isLookingAtMissingAddressRows) return filterRows(missingAddressFieldRows, searchParams.search)
        return filterRows(recipients, searchParams.search)
    }
    const rows = getRows()
    const allSelected = selectedRows?.length && selectedRows.length === rows?.length

    const handleNextClick = () => {
        if(order.hasDiscount) {
            if(!selectedDiscount.couponListId && !selectedDiscount.multiUseCouponId) setHasDiscountError(true)
            else {
                setOrder({...order, multiUseCouponId: selectedDiscount.multiUseCouponId, couponListId: selectedDiscount.couponListId})
                assignCoupon({...selectedDiscount, orderId: order.id})
            }
        } else onNext()
    }

    return <LayoutMain maxWidth={false} showBackButton={true} showNextButton={true} backButtonOnClick={onBack} nextButtonOnClick={handleNextClick} nextButtonDisabled={!recipients.length}>
        <Grid container gap={3}>
            <Grid item justifyContent={"space-between"}>
                <MDBox>
                    <MDTypography fontWeight={"bold"} sx={{fontSize: 24}}>Add Recipients</MDTypography>

                    {!recipients?.length?
                        <MDBox mt={1}>
                            <MDTypography isLink={true} linkHoverColor={"primary"} color={"primary"} fontWeight={"medium"} onClick={() => {setShowRecipientUploadHelpModal(true)}}>Not sure what to do? Click here for help uploading your recipients</MDTypography>
                        </MDBox>
                        :
                        null
                    }

                    <MDBox mt={2}>
                        <MDTypography variant={"h5"} fontWeight={"normal"}>Add recipients by clicking the buttons below, multiple options can be used per order.</MDTypography>
                    </MDBox>
                </MDBox>

                <Grid container gap={1} mt={3}>
                    <Grid item>
                        <MDButton color="primary" onClick={() => {
                            setShowAddRecipientsFromAddressBookModal(true)
                        }}>
                            <Grid container alignItems={"center"} gap={1}>
                                <Grid item display={"flex"}>
                                    <AddressBookIcon/>
                                </Grid>

                                <Grid item>Address Book</Grid>
                            </Grid>
                        </MDButton>
                    </Grid>

                    <Grid item>
                        <CSVBoxButton
                            licenseKey={CSVBOX_LICENCE}
                            user={{
                                user_id: "LettrLabsCsvBoxAdmin",
                                order_id: order.id,
                            }}
                            onImport={async function (
                                result: boolean,
                                data: {
                                    row_success: string
                                    rows: IOrderRecipients
                                    raw_file: string
                                    row_count: number
                                }
                            ) {
                                if (result) {
                                    setShowLoader(true)
                                    setShowGrid(true)

                                    startRecipientPostProcess()

                                    order.recipientsUploaded = true
                                    order.recipientsCsvBoxUrl = data.raw_file
                                    order.orderVolume = data.row_count
                                    postOrder(order)
                                } else {
                                    alert("Upload failed. Please try again.")
                                }
                            }}
                            render={(
                                launch: MouseEventHandler<HTMLButtonElement>,
                                isLoading: boolean
                            ) => {
                                return (
                                    <MDButton
                                        color="primary"
                                        disabled={isLoading}
                                        onClick={launch}
                                    >
                                        <Grid container alignItems={"center"} gap={1}>
                                            <Grid item display={"flex"}>
                                                <SheetAddIcon/>
                                            </Grid>

                                            <Grid item>Upload Spreadsheet</Grid>
                                        </Grid>
                                    </MDButton>
                                )
                            }}
                        >
                            Import
                        </CSVBoxButton>
                    </Grid>

                    <Grid item>
                        <MDButton
                            color="primary"
                            onClick={() => {
                                setShowAddRecipientsFromRecipientSearchModal(true)
                            }}
                        >
                            <Grid container alignItems={"center"} gap={1}>
                                <Grid item display={"flex"}>
                                    <MailingListAddIcon/>
                                </Grid>

                                <Grid item>Mailing List</Grid>
                            </Grid>
                        </MDButton>
                    </Grid>

                    <Grid item>
                        <MDButton
                            color="primary"
                            onClick={() => setShowAddRecipientModal(true)}
                        >
                            <Grid container alignItems={"center"} gap={1}>
                                <Grid item display={"flex"}>
                                    <SingleRecipientIcon/>
                                </Grid>

                                <Grid item>Single Recipient</Grid>
                            </Grid>
                        </MDButton>
                    </Grid>
                </Grid>
            </Grid>

            {recipients?.length?
                <>
                    <Grid item>
                        <Divider orientation={"vertical"} light={true}/>
                    </Grid>

                    <Grid item display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                        <Grid container gap={1} alignItems={"center"}>
                            <Grid item>
                                <MDTypography fontWeight={"bold"} sx={{fontSize: 24}}>Total Recipients: </MDTypography>
                            </Grid>

                            <Grid item>
                                <MDTypography fontWeight={"normal"} sx={{fontSize: 24}}>{formatNumber(recipients.length)}</MDTypography>
                            </Grid>
                        </Grid>

                        {(undeliverableRows.length || potentiallyUndeliverableRows.length || missingMailMergeRows.length || missingAddressFieldRows.length)?
                            <MDBox mt={3} width={"fit-content"}>
                                <MDCard boxShadow={false} border={false} color={"light"} borderRadiusSize={"xl"}>
                                    <MDBox p={2}>
                                        <Grid item width={"350px"}>
                                            {undeliverableRows.length?
                                            <Grid container alignItems={"center"} justifyContent={"space-between"}>
                                                <Grid item>
                                                    <Grid container alignItems={"center"}>
                                                        <Grid item display={"flex"}>
                                                            <WarningIcon color={"warning"}/>
                                                        </Grid>
                                                        <Grid item ml={1}>
                                                            <MDTypography variant={"h5"}>Undeliverable:</MDTypography>
                                                        </Grid>
                                                        <Grid item ml={0.5}>
                                                            <MDTypography variant={"h5"} fontWeight={"normal"}>{formatNumber(undeliverableRows.length)}</MDTypography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item>
                                                    <Tooltip title={"Show all with potentially undeliverable addresses"}>
                                                        <MDBox display={"flex"}>
                                                            {isLookingAtUndeliverableRows ?
                                                                <EyeIcon onClick={() => {
                                                                    setIsLookingAtUndeliverableRows(false)
                                                                }}/>
                                                                :
                                                                <EyeHiddenIcon onClick={() => {
                                                                    setIsLookingAtPotentiallyUndeliverableRows(false)
                                                                    setIsLookingAtMissingMailMergeRows(false)
                                                                    setIsLookingAtMissingAddressRows(false)

                                                                    setIsLookingAtUndeliverableRows(true)
                                                                }}/>
                                                            }
                                                        </MDBox>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                                :
                                                null
                                            }

                                            {potentiallyUndeliverableRows.length?
                                            <Grid container mt={1} alignItems={"center"} justifyContent={"space-between"}>
                                                <Grid item>
                                                    <Grid container alignItems={"center"}>
                                                        <Grid item display={"flex"}>
                                                            <WarningIcon color={"warning"}/>
                                                        </Grid>
                                                        <Grid item ml={1}>
                                                            <MDTypography variant={"h5"}>Potentially undeliverable addresses:</MDTypography>
                                                        </Grid>
                                                        <Grid item ml={0.5}>
                                                            <MDTypography variant={"h5"} fontWeight={"normal"}>{formatNumber(potentiallyUndeliverableRows.length)}</MDTypography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item>
                                                    <Tooltip title={"Show all with potentially undeliverable addresses"}>
                                                        <MDBox display={"flex"}>
                                                            {isLookingAtPotentiallyUndeliverableRows ?
                                                                <EyeIcon onClick={() => {
                                                                    setIsLookingAtPotentiallyUndeliverableRows(false)
                                                                }}/>
                                                                :
                                                                <EyeHiddenIcon onClick={() => {
                                                                    setIsLookingAtUndeliverableRows(false)
                                                                    setIsLookingAtMissingMailMergeRows(false)
                                                                    setIsLookingAtMissingAddressRows(false)

                                                                    setIsLookingAtPotentiallyUndeliverableRows(true)
                                                                }}/>
                                                            }
                                                        </MDBox>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                                :
                                                null
                                            }

                                            {missingMailMergeRows.length?
                                            <Grid container mt={1} alignItems={"center"} justifyContent={"space-between"}>
                                                <Grid item>
                                                    <Grid container alignItems={"center"}>
                                                        <Grid item display={"flex"}>
                                                            <WarningIcon color={"warning"}/>
                                                        </Grid>
                                                        <Grid item ml={1}>
                                                            <MDTypography variant={"h5"}>Missing mail merge text:</MDTypography>
                                                        </Grid>
                                                        <Grid item ml={0.5}>
                                                            <MDTypography variant={"h5"} fontWeight={"normal"}>
                                                                {formatNumber(missingMailMergeRows.length)}
                                                            </MDTypography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item>
                                                    <Tooltip title={"Show all not deliverable"}>
                                                        <MDBox display={"flex"}>
                                                            {isLookingAtMissingMailMergeRows ?
                                                                <EyeIcon onClick={() => {
                                                                    setIsLookingAtMissingMailMergeRows(false)
                                                                }}/>
                                                                :
                                                                <EyeHiddenIcon onClick={() => {
                                                                    setIsLookingAtUndeliverableRows(false)
                                                                    setIsLookingAtPotentiallyUndeliverableRows(false)
                                                                    setIsLookingAtMissingAddressRows(false)

                                                                    setIsLookingAtMissingMailMergeRows(true)
                                                                }}/>
                                                            }
                                                        </MDBox>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                                :
                                                null
                                            }

                                            {missingAddressFieldRows.length?
                                            <Grid container mt={1} alignItems={"center"} justifyContent={"space-between"}>
                                                <Grid item>
                                                    <Grid container alignItems={"center"}>
                                                        <Grid item display={"flex"}>
                                                            <WarningIcon color={"error"}/>
                                                        </Grid>
                                                        <Grid item ml={1}>
                                                            <MDTypography variant={"h5"}>Undeliverable Addresses:</MDTypography>
                                                        </Grid>
                                                        <Grid item ml={0.5}>
                                                            <MDTypography variant={"h5"} fontWeight={"normal"}>
                                                                {formatNumber(missingAddressFieldRows.length)}
                                                            </MDTypography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item>
                                                    <Tooltip title={"Show all not deliverable"}>
                                                        <MDBox display={"flex"}>
                                                            {isLookingAtMissingAddressRows ?
                                                                <EyeIcon onClick={() => {
                                                                    setIsLookingAtMissingAddressRows(false)
                                                                }}/>
                                                                :
                                                                <EyeHiddenIcon onClick={() => {
                                                                    setIsLookingAtMissingMailMergeRows(false)
                                                                    setIsLookingAtPotentiallyUndeliverableRows(false)
                                                                    setIsLookingAtUndeliverableRows(false)

                                                                    setIsLookingAtMissingAddressRows(true)
                                                                }}/>
                                                            }
                                                        </MDBox>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                                :
                                                null
                                            }
                                        </Grid>
                                    </MDBox>
                                </MDCard>
                            </MDBox>
                            :
                            null
                        }
                    </Grid>
                </>
                :
                null
            }
        </Grid>

        {showGrid || recipients?.length?
            <MDBox>
                <MDBox my={4}>
                    <Divider light={true}/>
                </MDBox>

                <Table
                    rows={rows}
                    columns={columns.filter((value) => ["actions", "warnings", "errors", ...usedColumns].includes(value.field.toLowerCase()))}
                    isLoading={isRecipientsLoading}
                    tableHeight={"45vh"}
                    totalRowCount={rows.length}
                    selectedRows={selectedRows}
                    allSelected={allSelected}
                    setAllSelected={() => {}}
                    setSearchParams={setSearchParams}
                    setSelectedRows={setSelectedRows}
                    renderToolbarFilterLeft={() => (
                        <Grid item display={'flex'} alignItems={"center"} gap={2}>
                            <Tooltip title={!selectedRows.length ? "Select an address to remove from the list" : `Remove ${allSelected ? "All" : selectedRows.length} Recipient${selectedRows.length ? (selectedRows.length > 1 ? 's' : '') : 's'}`}>
                                <Box>
                                    <IconButton color="error" disabled={!selectedRows.length} onClick={() => setShowDeleteRecipientModal(true)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </Tooltip>
                            {order.hasDiscount && (
                                <SelectDiscountCodes 
                                    error={hasDiscountError}
                                    setError={setHasDiscountError}
                                    selectedDiscount={selectedDiscount} 
                                    setSelectedDiscount={setSelectedDiscount}
                                    recipientsAmount={rows.length}
                                />
                            )}
                        </Grid>
                    )}
                />
            </MDBox>
            :
            null
        }

        <InfoModal
            show={showErrorModal}
            setShow={setShowErrorModal}
            headerText={`Please address the errors before continuing`}
            showCancelButton={false}
            showConfirmButton={true}
            confirmButtonOnClick={() => {
                setShowErrorModal(false)
            }}
        />

        <AddRecipientModal
            show={showAddRecipientModal}
            setShow={setShowAddRecipientModal}
            orderId={order.id}
            recipientToManage={recipientToManage}
            setRecipientToManage={setRecipientToManage}
            setShowGrid={setShowGrid}
        />

        <InfoModal
            show={showDeleteRecipientModal}
            setShow={setShowDeleteRecipientModal}
            headerText={`Are you sure you want to remove ${selectedRows.length ? formatNumber(selectedRows.length) : "all"} recipients from your list?`}
            showCancelButton={true}
            showConfirmButton={true}
            cancelButtonOnClick={() => {
                setShowDeleteRecipientModal(false)
            }}
            confirmButtonOnClick={() => {
                deleteOrderRecipientsMutation.mutate()
            }}
        />

        <AddRecipientsFromAddressBookModal
            key={`AddRecipientsFromAddressBookModal${showAddRecipientsFromAddressBookModal}`}
            show={showAddRecipientsFromAddressBookModal}
            setShow={setShowAddRecipientsFromAddressBookModal}
            orderId={order.id}
            setShowGrid={setShowGrid}
        />

        <AddRecipientsFromRecipientSearchModal
            show={showAddRecipientsFromRecipientSearchModal}
            setShow={setShowAddRecipientsFromRecipientSearchModal}
            orderId={order.id}
            setShowGrid={setShowGrid}
        />

        <RecipientProcessModal
            orderId={order.id}
            show={showRecipientProcessModal}
            setShow={setShowRecipientProcessModal}
        />

        <RecipientUploadHelpModal
            show={showRecipientUploadHelpModal}
            setShow={setShowRecipientUploadHelpModal}
        />
        
      <Snackbar
        open={!!deleteError}
        autoHideDuration={6000}
        onClose={() => setDeleteError('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setDeleteError('')} severity="error" variant="standard" sx={{ py: 0 }}>
          {deleteError}
        </Alert>
      </Snackbar>
    </LayoutMain>
}

export default RecipientsSubpage