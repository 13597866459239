import DialogTitle from "@mui/material/DialogTitle";
import {CircularProgress, Dialog, DialogContent, Grid} from "@mui/material";
import {useEditor} from "context/editor-context";
import {useEffect, useState} from "react";
import ProfileArtifactService from "services/profile-artifact";
import ArtifactService from "services/artifact-service";
import {Order} from "models/order";
import {OrderArtifactType} from "models/enums/orderArtifactType";
import {OrderArtifact} from "models/orderArtifact";
import {ProfileArtifact} from "models/profileArtifact";
import MDBox from "material-ui/components/MDBox";
import { useGlobal } from "context/global-context";
import { ProductTypes } from "models/enums/ProductTypes";

interface SelectImageModalProps {
    show: boolean
    setShow: Function
    type: "Brand Artwork" | "Past Images"
    order: Order
}

function SelectImageModal({show, setShow, type, order}: SelectImageModalProps) {
    const {setShowLoader} = useGlobal()

    const {addOrReplaceImageToScene, checkForBlocksOnGivenSide} = useEditor()

    const {getProfileArtifacts} = ProfileArtifactService()
    const {getPastOrderArtifacts} = ArtifactService()

    const [images, setImages] = useState<string[]>([])

    const [isLoaded, setIsLoaded] = useState<boolean>(false)

    useEffect(function () {
        if(show){
            if(type === "Brand Artwork"){
                getProfileArtifacts().then((result) => {
                    setImages(result.payload?.map((a: ProfileArtifact) => a.blobUri))
                    setIsLoaded(true)
                })
            }
            else if(type === "Past Images"){
                getPastOrderArtifacts().then((result) => {
                    setImages(result.filter(
                        (artifact: OrderArtifact) =>
                            artifact.artifactType === OrderArtifactType.Image ||
                            artifact.artifactType === OrderArtifactType.FrontLogo ||
                            artifact.artifactType === OrderArtifactType.RearImage
                    ).map((a: OrderArtifact) => a.blobUri))
                    setIsLoaded(true)
                })
            }
        }
        else{
            setImages([])
            setIsLoaded(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    return <Dialog open={show} onClose={() => setShow(false)} maxWidth={false}>
        <DialogContent sx={{width: "800px"}}>
            <DialogTitle>Select image</DialogTitle>

            {isLoaded?
                <Grid container alignItems={"center"} gap={1}>
                    {images.map((image: string, index: number) => {
                        return <Grid item key={index}>
                            <img alt={""} style={{height: "120px", cursor: "pointer"}} src={image} onClick={async () => {
                                setShowLoader(true)

                                await addOrReplaceImageToScene(order, image)

                                if(order?.product === ProductTypes.HandwrittenBiFoldCard) checkForBlocksOnGivenSide(0)
                                else checkForBlocksOnGivenSide(1)

                                setShowLoader(false)
                                setShow(false)
                            }}/>
                        </Grid>
                    })}
                </Grid>
                :
                <MDBox sx={{textAlign: "center"}}>
                    <CircularProgress color="primary"/>
                </MDBox>
            }

        </DialogContent>
    </Dialog>
}

export default SelectImageModal