// @ts-ignore
import Retool from "react-retool"
import MDBox from "material-ui/components/MDBox";

function AdminFeedbackPage(){
    return <MDBox style={{ height: "90vh" }}>
        <Retool url="https://lettrlabs.retool.com/embedded/public/b78d3f26-516f-493a-a9ab-08c8e7ada34d" />
    </MDBox>
}

export default AdminFeedbackPage