import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SidebarTemplatesIcon = (props: any) => {
    return (
        <SvgIcon {...props} viewBox={"0 0 32 32"}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M2 8.00391C2 7.20826 2.31607 6.4452 2.87868 5.88259C3.44129 5.31998 4.20435 5.00391 5 5.00391H27C27.7957 5.00391 28.5587 5.31998 29.1213 5.88259C29.6839 6.4452 30 7.20826 30 8.00391V24.0039C30 24.7996 29.6839 25.5626 29.1213 26.1252C28.5587 26.6878 27.7957 27.0039 27 27.0039H5C4.20435 27.0039 3.44129 26.6878 2.87868 26.1252C2.31607 25.5626 2 24.7996 2 24.0039V8.00391ZM4 21.4172V24.0039C4 24.5559 4.448 25.0039 5 25.0039H27C27.2652 25.0039 27.5196 24.8986 27.7071 24.711C27.8946 24.5235 28 24.2691 28 24.0039V21.4172L24.4133 17.8319C24.0383 17.4574 23.53 17.247 23 17.247C22.47 17.247 21.9617 17.4574 21.5867 17.8319L20.4133 19.0039L21.7067 20.2972C21.8049 20.3888 21.8837 20.4992 21.9384 20.6219C21.993 20.7445 22.0224 20.8769 22.0248 21.0112C22.0272 21.1455 22.0025 21.2789 21.9522 21.4034C21.9019 21.5279 21.827 21.641 21.7321 21.736C21.6371 21.8309 21.524 21.9058 21.3995 21.9561C21.2749 22.0064 21.1416 22.0311 21.0073 22.0287C20.873 22.0263 20.7406 21.9969 20.618 21.9423C20.4953 21.8876 20.3849 21.8088 20.2933 21.7106L13.4133 14.8319C13.0383 14.4574 12.53 14.247 12 14.247C11.47 14.247 10.9617 14.4574 10.5867 14.8319L4 21.4186V21.4172ZM17.5 11.0039C17.5 10.6061 17.658 10.2246 17.9393 9.94325C18.2206 9.66194 18.6022 9.50391 19 9.50391C19.3978 9.50391 19.7794 9.66194 20.0607 9.94325C20.342 10.2246 20.5 10.6061 20.5 11.0039C20.5 11.4017 20.342 11.7833 20.0607 12.0646C19.7794 12.3459 19.3978 12.5039 19 12.5039C18.6022 12.5039 18.2206 12.3459 17.9393 12.0646C17.658 11.7833 17.5 11.4017 17.5 11.0039Z" fill="white"/>
            </svg>
        </SvgIcon>
    )
}

export default SidebarTemplatesIcon;