import { IAllDiscountCodes, ICoupon } from './discount-codes.types';

export const mockAllDiscountCodes: IAllDiscountCodes = {
  multiUseCoupon: [
    { id: 1, name: 'FREESHIP1', expirationDate: '2024-07-09T15:20:30Z', canDelete: true },
    { id: 2, name: 'FREESHIP2', canDelete: true },
    { id: 3, name: 'FREESHIP3', canDelete: true },
    { id: 4, name: 'FREESHIP4', canDelete: false },
  ],
  singleUseCouponList: [
    { id: 1, name: 'List1', availableCoupons: 0, totalCoupons: 10, usedCoupons: 10, canDelete: true },
    { id: 2, name: 'List2', availableCoupons: 10, totalCoupons: 10, usedCoupons: 0, canDelete: true },
    { id: 3, name: 'List3', availableCoupons: 5, totalCoupons: 10, usedCoupons: 5, canDelete: true },
    {
      id: 4,
      name: 'List4',
      availableCoupons: 10,
      totalCoupons: 10,
      usedCoupons: 0,
      expirationDate: '2024-07-09T15:20:30Z',
      canDelete: false,
    },
  ],
};

export const mockCoupons: ICoupon[] = [
  { id: 1, name: 'RANDOM1', isUsed: false },
  { id: 2, name: 'RANDOM2', isUsed: true },
  { id: 3, name: 'RANDOM3', isUsed: false },
];
