/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS Base Styles
import colors from "material-ui/theme/base/colors";

// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from "material-ui/theme/functions/pxToRem";

const { white, primary } = colors;

// types
type Types = any;

const stepIcon: Types = {
  styleOverrides: {
    root: {
      fill: "#E4E6F1",
      strokeWidth: pxToRem(10),
      width: pxToRem(30),
      height: pxToRem(30),
      borderRadius: "50%",
      zIndex: 99,
      transition: "all 200ms linear",

      "&:hover": {

      },

      "&.Mui-active": {
        fill: primary.main,

        text: {
          fill: "white"
        }
      },

      "&.Mui-completed": {
        fill: primary.main,
        background: white.main
      },

      text: {
        fill: "#2C2D36",
        fontWeight: "bold"
      }
    },
  },
};

export default stepIcon;
