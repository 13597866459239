import { useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { GridColDef, GridRowModelUpdate } from '@mui/x-data-grid-pro';
import { formatDate, formatNumber } from 'helpers/formatters';
import StyledDataGrid from 'components/styled-data-grid';
import { useQuery } from '@tanstack/react-query';
import MDTypography from 'material-ui/components/MDTypography';
import IntegrationOrderService from 'services/integration-order';
import MDCard from 'material-ui/components/MDCard';
import { orderProductHasNoEnvelope } from 'models/enums/ProductTypes';
import PreviewCardImagesModal from 'components/preview-card-images-modal';
import Loader from 'components/loader';
import { getColumns } from 'helpers/integrationOrderDetailColumns';
import { IntegrationOrderStatus } from 'models/enums/IntegrationOrderStatus';
import { IntegrationOrderType, IVM_GridOrder } from 'models/views/gridOrderVm';

interface SmartAutomationDetailProps {
  orderId: number;
  status: IntegrationOrderStatus;
  updateRows: (updates: GridRowModelUpdate[]) => void;
}

const SmartAutomationDetail = ({ orderId, status, updateRows }: SmartAutomationDetailProps) => {
  const { getIntegrationOrder } = IntegrationOrderService();
  const { getIntegrationOrderRecipients } = IntegrationOrderService();

  const [columns, setColumns] = useState<GridColDef[]>([]);

  const [showPreviewImagesModal, setShowPreviewImagesModal] = useState<boolean>(false);

  const refetchInterval = (order: IVM_GridOrder) => {
    if (
      (order?.integrationOrderType === IntegrationOrderType.KLAVIYO && status === IntegrationOrderStatus.Testing) ||
      order?.inProcessing
    ) {
      return 10000;
    }
    return false;
  };

  const { data: { payload: order } = {} } = useQuery({
    queryKey: ['getIntegrationOrder', orderId],
    queryFn: () => getIntegrationOrder(orderId),
    refetchInterval: (query) => refetchInterval(query.state.data?.payload),
  });

  const { data: { payload: { recipients = [], usedColumns = [] } = {} } = {}, isLoading } = useQuery({
    queryKey: ['orderRecipients', orderId],
    queryFn: () => getIntegrationOrderRecipients(orderId),
    refetchInterval: refetchInterval(order),
  });

  useEffect(() => {
    if (!order) return;
    setColumns(getColumns(order));
    updateRows?.([
      {
        id: orderId,
        inProcessing: order.inProcessing,
        queueCount: order.queueCount,
        inProductionCount: order.inProductionCount,
        mailedCount: order.mailedCount,
        status: order.status
      },
    ]);
  }, [order, orderId, updateRows]);

  const renderList = (title: string, list: { hideCondition?: boolean; label: string; content: string | number }[]) => (
    <Box minWidth={200}>
      <MDTypography color="secondary" fontWeight="bold" mb={2}>
        {title}:
      </MDTypography>
      {list.map((item) => {
        if (item.hideCondition) return null;
        return (
          <MDTypography variant="h5" mt={1}>
            {item.label}:{' '}
            <MDTypography component="span" fontWeight="regular" variant="h5">
              {item.content}
            </MDTypography>
          </MDTypography>
        );
      })}
    </Box>
  );

  const renderImage = (title: string, src: string) => (
    <Box flex={1}>
      <MDTypography mb={1} fontWeight="bold" color="secondary">
        {title}:
      </MDTypography>
      <MDCard borderRadiusSize={0} boxShadow={false} border>
        <img alt="" src={src} style={{ width: '100%', height: '100%' }} />
      </MDCard>
    </Box>
  );

  if (!order) {
    return (
      <Box p={2} display="flex" justifyContent="center">
        <Loader />
      </Box>
    );
  }

  return (
    <Box p={1}>
      <MDCard border boxShadow={false} borderRadiusSize="lg">
        <Box display={'flex'} px={2} py={1} gap={2}>
          {renderList('Details', [
            { label: 'Id', content: order.id },
            { label: 'Name', content: order.name },
            { label: 'Status', content: order.status },
            { label: 'Queued', content: formatNumber(order.queueCount) },
            { label: 'In Production', content: formatNumber(order.inProductionCount) },
            { label: 'Mailed', content: formatNumber(order.mailedCount) },
            { label: 'Mailed Date', content: formatDate(order.mailedDate), hideCondition: !order.mailedDate },
          ])}

          <Box>
            <Divider orientation="vertical" light />
          </Box>

          {renderList('Analytics', [
            {
              label: 'Qr Scans',
              content: recipients?.reduce(
                (total: number, orderRecipient: { qrScanCount: number }) => total + orderRecipient.qrScanCount,
                0
              ),
            },
            { label: 'Calls Received', content: order.callsReceivedCount ? formatNumber(order.callsReceivedCount) : 0 },
            {
              label: 'Total Call Time',
              content: `${order.totalCallTime ? formatNumber(order.totalCallTime) : 0} minutes`,
            },
          ])}

          <Box>
            <Divider orientation="vertical" light />
          </Box>

          <Box
            display={'flex'}
            gap={2}
            flexWrap="nowrap"
            sx={{ width: '800px', cursor: 'pointer' }}
            onClick={() => setShowPreviewImagesModal(true)}
          >
            {renderImage('Card Front', order.sceneFrontFull)}
            {renderImage('Card Rear', order.sceneBackFull)}
            {!orderProductHasNoEnvelope(order.product) && renderImage('Envelope', order.envelopeFull)}
          </Box>
        </Box>
      </MDCard>

      <Box maxHeight="600px" height={'100%'} mt={1} overflow={'auto'}>
        {columns.length ? (
          <StyledDataGrid
            pagination
            disableRowSelectionOnClick
            loading={isLoading || order.inProcessing}
            columns={[...columns.filter((value) => usedColumns.includes(value.field.toLowerCase()))]}
            initialState={{ pagination: { paginationModel: { pageSize: 100 } } }}
            pageSizeOptions={[100]}
            rows={recipients ?? []}
          />
        ) : null}
      </Box>

      <PreviewCardImagesModal
        show={showPreviewImagesModal}
        setShow={setShowPreviewImagesModal}
        images={[order.sceneFrontFull, order.sceneBackFull, order.envelopeFull]}
      />
    </Box>
  );
};

export default SmartAutomationDetail;
