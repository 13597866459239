import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Info2Icon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 19.25C15.5563 19.25 19.25 15.5563 19.25 11C19.25 6.44365 15.5563 2.75 11 2.75C6.44365 2.75 2.75 6.44365 2.75 11C2.75 15.5563 6.44365 19.25 11 19.25Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M11 6.875V11.6875" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M11 15.8125C11.5695 15.8125 12.0312 15.3508 12.0312 14.7812C12.0312 14.2117 11.5695 13.75 11 13.75C10.4305 13.75 9.96875 14.2117 9.96875 14.7812C9.96875 15.3508 10.4305 15.8125 11 15.8125Z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    )
}

export default Info2Icon;