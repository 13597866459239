import { Grid, Paper } from "@mui/material";
import MDBox from "material-ui/components/MDBox";
import MDTypography from "material-ui/components/MDTypography";
import { ITableRow } from "../helper/subscriptionsUtils";

interface SubscriptionsComparisonProps {
  title: string;
  table: ITableRow[];
  firstColumnSize: number;
}

const SubscriptionsComparison = ({ title, table, firstColumnSize }: SubscriptionsComparisonProps) => {

  return (
    <>
      <MDTypography mb={2} fontWeight="bold" sx={{ fontSize: "36px" }} color={'secondary'}>
        {title}
      </MDTypography>

      <Paper elevation={8} sx={{ borderRadius: 4 }}>
        {table.map((row, i) => {
          const styles = {
            backgroundColor: i % 2 !== 0 ? "#F9F9FB" : "white",
            borderBottom: i !== table.length - 1 ? "1px solid #E4E6F1" : "",
            borderRadius: i !== table.length - 1 ? "none" : 4,
          }
          const columnSizeLeft = 12 - firstColumnSize;
          const otherColumnsSize = columnSizeLeft / 2;
          const cellPadding = row.isHeader ? "16px 26px" : "12px 26px"

          return <Grid key={i} container width={"100%"} alignItems={"center"} sx={styles}>
            <Grid item xs={firstColumnSize}>
              <MDBox sx={{ padding: cellPadding, height: "100%" }}>
                {row.col1Content}
              </MDBox>
            </Grid>

            <Grid item xs={row.col3Content ? otherColumnsSize : columnSizeLeft} sx={{ borderLeft: "1px solid #E4E6F1" }}>
              <MDBox sx={{ padding: cellPadding }}>
                {row.col2Content}
              </MDBox>
            </Grid>

            {row.col3Content &&
              <Grid item xs={otherColumnsSize} sx={{ borderLeft: "1px solid #E4E6F1" }}>
                <MDBox sx={{ padding: cellPadding }}>
                  {row.col3Content}
                </MDBox>
              </Grid>
            }
          </Grid>
        })}
      </Paper>
    </>
  )
}

export default SubscriptionsComparison
