import { styled, Theme, CSSObject } from '@mui/material/styles';
import {
  Divider,
  Drawer,
  DrawerProps,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemIconProps,
} from '@mui/material';

export const sidebarFullWidth = 280;

interface StyledDrawerProps extends DrawerProps {
  actingAsUser: boolean;
}

interface StyledListItemPartialProps {
  isSidebarOpen: boolean;
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: sidebarFullWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })<StyledDrawerProps>(
  ({ theme, open, actingAsUser }) => ({
    width: sidebarFullWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiDrawer-paper': {
      ...(open ? openedMixin(theme) : closedMixin(theme)),
      backgroundColor: actingAsUser ? 'hsl(15, 87%, 35%)' : 'auto',
    },
  })
);

export const StyledSidebarDivider = styled(Divider, { shouldForwardProp: (prop) => prop !== 'open' })({
  height: '1px',
  margin: `8px 0`,
  borderBottom: 'none',
  backgroundImage: `linear-gradient(to right, transparent, rgba(255,255,255, 0.4), transparent) !important`,
  backgroundColor: 'transparent',
});

export const StyledListItemButton = styled(ListItemButton)<ListItemButtonProps & StyledListItemPartialProps>(
  ({ isSidebarOpen }) => ({
    '&.Mui-selected': { backgroundColor: 'rgba(255, 255, 255, 0.2)' },
    justifyContent: isSidebarOpen ? 'initial' : 'center',
    color: 'whitesmoke',
    minHeight: 48,
    padding: '0 20px',
  })
);

export const StyledListItemIcon = styled(ListItemIcon)<ListItemIconProps & StyledListItemPartialProps>(
  ({ isSidebarOpen }) => ({
    minWidth: 0,
    marginRight: isSidebarOpen ? 24 : 'auto',
    justifyContent: 'center',
    color: 'inherit',
  })
);
