import {ResponsiveType} from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import MDBox from "material-ui/components/MDBox";
import MDTypography from "material-ui/components/MDTypography";
import {DesignCategory} from "models/designCategory";
import {PremadeDesign} from "models/premadeDesign";
import {Grid} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import StyledCarousel from "../styled-carousel/styled-carousel";

interface PremadeDesignsListProps {
    designCategory: DesignCategory
    selectedPremadeDesign: PremadeDesign
    designOnClick: Function
    viewMode?: "carousel" | "grid"
    responsiveSteps: ResponsiveType
}

interface PremadeDesignImageProps{
    image: string
    selected: boolean
}

function PremadeDesignImage({ image, selected }: PremadeDesignImageProps) {
    const [height, setHeight] = useState<number>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const ref = useRef(null)

    const loadingStyles = {
        background: "linear-gradient( 100deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, 0) 60% ) #ededed",
        backgroundSize: "200% 100%",
        backgroundPositionX: "180%",
        borderRadius: '10px',
        animation: "1s loading ease-in-out infinite"
    }

    useEffect(() => {
        if(ref && ref.current){
            setHeight(ref.current.offsetWidth * 0.71)
        }
    }, [ref]);

    return <MDBox width={"100%"} ref={ref}>
        {isLoading?
            <MDBox width={"100%"} height={height} sx={loadingStyles}/>
            :
            null
        }

        <img
            alt={""}
            src={image}
            style={{
                width: "100%",
                height: "auto",
                border: selected? `2px solid #F05A2A` : '',
                borderRadius: '10px',
                display: isLoading ? "none" : "block",
                boxShadow: "rgb(209, 209, 209) 0px 0 10px 0"
            }}
            onLoad={() => setIsLoading(false)}
        />
    </MDBox>
}

function PremadeDesignsList({designCategory, selectedPremadeDesign, designOnClick, viewMode="carousel", responsiveSteps}: PremadeDesignsListProps) {
    return <MDBox>
        <MDTypography mb={1} fontWeight={"bold"}>
            {designCategory.name}
        </MDTypography>

        {viewMode === "carousel"?
            <StyledCarousel
                responsive={responsiveSteps}
                showDots={false}
                renderArrowsWhenDisabled={true}
                slidesToSlide={4}
                swipeable={false}
                draggable={false}
                infinite={true}
                arrows={true}
            >
                {designCategory.files.map((design, index) => {
                    return <MDBox key={index} p={1} sx={{cursor: "pointer"}} onClick={() => designOnClick(design)}>
                        <PremadeDesignImage
                            selected={selectedPremadeDesign && selectedPremadeDesign.fullSizeUrl === design.fullSizeUrl}
                            image={design.thumbUrl}
                        />
                    </MDBox>
                })}
            </StyledCarousel>
            :
            <Grid container columns={10}>
                {designCategory.files.map((design, index) => {
                    return <Grid key={index} item p={1} xs={2} sx={{cursor: "pointer"}} onClick={() => designOnClick(design)}>
                        <PremadeDesignImage
                            image={design.thumbUrl}
                            selected={selectedPremadeDesign && selectedPremadeDesign.fullSizeUrl === design.fullSizeUrl}
                        />
                    </Grid>
                })}
            </Grid>
        }
    </MDBox>
}

export default PremadeDesignsList