/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-flatpickr components
import Flatpickr from "react-flatpickr";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";

// Material Dashboard 2 PRO React TS components
import MDInput from "material-ui/components/MDInput";
import CalendarIcon from "assets/icons/calendar";
import React from "react";

// types
interface Props {
    input?: {
        [key: string]: any;
    };
    [key: string]: any;
}

function MDDatePicker({input, ...rest}: Props): JSX.Element {
    const flatpickrRef = React.useRef<Flatpickr>(null);

    return (
        <Flatpickr
            {...rest}
            options={{
                dateFormat: "m/d/Y",
                ...rest.options
            }}
            ref={flatpickrRef}
            render={({defaultValue}: any, ref: any) => (
                <MDInput
                    {...input}
                    disabled={rest.disabled}
                    InputProps={{
                        endAdornment: <CalendarIcon onClick={() => flatpickrRef?.current?.flatpickr.open()} />,
                    }}
                    defaultValue={defaultValue}
                    inputRef={ref}/>
            )}
        />
    );
}

MDDatePicker.defaultProps = {
    input: {},
};

export default MDDatePicker;
