function formatUSPhoneNumber(number: string) {
    if(!number){
        return ''
    }

    const phoneNumberString = number.toString();
    const areaCode = phoneNumberString.substring(2, 5);
    const firstPart = phoneNumberString.substring(5, 8);
    const secondPart = phoneNumberString.substring(8, 12);

    return `+1(${areaCode}) ${firstPart}-${secondPart}`;
}

function removeUSPhoneNumberFormat(number: string){
    return number.replace(/\D/g, '')
}

export {formatUSPhoneNumber, removeUSPhoneNumberFormat}