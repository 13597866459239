import { Field, FieldProps } from 'formik';
import { Grid, MenuItem, Select, Typography } from '@mui/material';

import FormikErrorMessage from 'components/formik-error-message';

export default function MonthlyLeadsField() {
  return (
    <Field name="monthlyLeads">
      {({ field, meta }: FieldProps) => (
        <Grid item xs={12} sm={3} md>
          <Typography variant="h5">Maximum Monthly Leads</Typography>
          <Select {...field} value={field.value ?? 0} displayEmpty fullWidth error={!!meta.touched && !!meta.error}>
            <MenuItem value={0} disabled>
              Select an option
            </MenuItem>
            {[250, 500, 1000, 5000, 10000, 50000, 100000, 500000, 1000000].map((leads) => (
              <MenuItem key={leads} value={leads}>
                {leads}
              </MenuItem>
            ))}
          </Select>
          <FormikErrorMessage meta={meta} />
        </Grid>
      )}
    </Field>
  );
}
