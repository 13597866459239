import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT from "../endpoint";
import {OrderArtifactType} from "models/enums/orderArtifactType";

function SceneService(){
    const {execute} = useFetchWithMsal()

    function clearScene(orderId: number, productId: number){
        return execute({
            endpoint: `${API_ENDPOINT}/clearScene/${orderId}/${productId}`,
            method: "POST"
        })
    }

    function uploadScene(orderId: number, sceneType: OrderArtifactType.CreativeEditorScene | OrderArtifactType.EnvelopeScene, formData: FormData){
        return execute({
            endpoint: `${API_ENDPOINT}/scene/${orderId}/${sceneType}`,
            method: "POST",
            formData: formData
        })
    }

    return {clearScene, uploadScene}
}

export default SceneService