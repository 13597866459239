import { ReactNode } from "react";
import { Grid, Tooltip } from "@mui/material";
import MDBox from "material-ui/components/MDBox";
import MDTypography from "material-ui/components/MDTypography";
import Info2Icon from "assets/icons/info-2";
import Check4Icon from "assets/icons/check-4";
import HandwrittenCardEnvelop from "assets/icons/handwritten-card-envelop.svg";
import HandwrittenPostcard from "assets/icons/handwritten-postcard.svg";
import PrintedPostcard from "assets/icons/printed-postcard.svg";

export interface ITableRow {
  isHeader?: boolean;
  col1Content: JSX.Element;
  col2Content: JSX.Element;
  col3Content?: JSX.Element;
}

const renderBoldCell = (header: string, textAlign = "center") => (
  <MDTypography sx={{ fontSize: 18 }} fontWeight={"bold"} color={"secondary"} textAlign={textAlign}>{header}</MDTypography>
)

const renderRegularCell = (subject: string | JSX.Element) => (
  <MDTypography sx={{ fontSize: 16 }} fontWeight={"bold"} color={"text"} textAlign="center">{subject}</MDTypography>
)

const renderTooltipCell = (subject: string, tooltip?: ReactNode) => (
  <MDBox>
    <Grid container alignItems={"center"} justifyContent={"space-between"}>
      <Grid item>
        <MDTypography sx={{ fontSize: 16 }} fontWeight={"bold"} color={"text"}>
          {subject}
        </MDTypography>
      </Grid>
      {tooltip && <Grid item>
        <Tooltip componentsProps={{ tooltip: { sx: { width: "300px", maxWidth: "unset" } } }} title={tooltip}>
          <MDBox display={"flex"}>
            <Info2Icon color={"light2"} />
          </MDBox>
        </Tooltip>
      </Grid>}
    </Grid>
  </MDBox>
)

const headerRow = {
  isHeader: true,
  col1Content: <MDTypography>&nbsp;</MDTypography>,
  col2Content: renderBoldCell("One Off Orders"),
  col3Content: renderBoldCell("Monthly Pre-Pay")
}

export const pricingPlansTable: ITableRow[] = [
  headerRow,
  {
    col1Content: renderTooltipCell("No Minimum Order Quantity"),
    col2Content: <MDBox display={"flex"} alignItems={"center"} justifyContent={"center"}><Check4Icon /></MDBox>,
    col3Content: <MDBox display={"flex"} alignItems={"center"} justifyContent={"center"}><Check4Icon /></MDBox>,
  },
  {
    col1Content: renderTooltipCell("Postage is Included",
      <MDBox>
        <MDTypography color={"white"} sx={{ fontSize: 14 }}>Standard postage is included, First Class postage available at a small uncharge.</MDTypography>
        <MDBox mt={2}>
          <MDTypography color={"white"} sx={{ fontSize: 14 }}>Standard Postage: 1-2 week delivery</MDTypography>
          <MDTypography color={"white"} sx={{ fontSize: 14 }}>First Class Postage: 3-5 day delivery</MDTypography>
        </MDBox>
      </MDBox>
    ),
    col2Content: <MDBox display={"flex"} alignItems={"center"} justifyContent={"center"}><Check4Icon /></MDBox>,
    col3Content: <MDBox display={"flex"} alignItems={"center"} justifyContent={"center"}><Check4Icon /></MDBox>,
  },
  {
    col1Content: renderTooltipCell("Discounted Cards", "Pay a discounted flat rate price per card, the lowest in the industry"),
    col2Content: <MDTypography sx={{ fontSize: 16 }}>&nbsp;</MDTypography>,
    col3Content: <MDBox display={"flex"} alignItems={"center"} justifyContent={"center"}><Check4Icon /></MDBox>,
  },
  {
    col1Content: renderTooltipCell("Commitment", "Monthly pre-pay commitment allows you to stop or pause your subscription every 90 days"),
    col2Content: renderRegularCell("None"),
    col3Content: renderRegularCell("Quarterly"),
  }
]


export const addOnsTable: ITableRow[] = [
  headerRow,
  {
    col1Content: renderTooltipCell("Double Sided Printing", "Add double sided printing to our handwritten card. Postcards include double sided printing at no cost"),
    col2Content: renderRegularCell("$0.20 ea"),
    col3Content: renderRegularCell("$0.10 ea"),
  },
  {
    col1Content: renderTooltipCell("Upgrade to First Class Postage", `Upgrade to First Class Postage for faster delivery. 6"x11" Printed postcard is $0.15 ea (USPS considers this size postcard a letter)`),
    col2Content: renderRegularCell(<>$0.05 for Postcards<br />$0.15 ea for Cards</>),
  },
  {
    col1Content: renderTooltipCell("Mailing List Recipients", "Create custom mailing lists based on dozens of search parameters with our self serve platform!"),
    col2Content: renderRegularCell("$0.11 ea"),
  },
  {
    col1Content: renderTooltipCell("Additional Handwritten Text", "Additional text not available for handwritten postcards, up to 350 characters included"),
    col2Content: renderRegularCell(<>500 Characters Included, 800 max<br />Additional 150 Characters for $0.25 ea</>),
  },
  {
    col1Content: renderTooltipCell("Expedited Production / Weekend Production", "We provide two levels of expedited service reducing our quick production times by up to 50%!"),
    col2Content: renderRegularCell("$0.25 - $0.50 ea"),
  }
]

export const subscriptionOptions = [
  { title: <>Handwritten Card<br />+ Envelope</>, image: HandwrittenCardEnvelop },
  { title: "Printed Postcard", image: PrintedPostcard },
  { title: "Handwritten Postcard", image: HandwrittenPostcard },
]