import {Dialog, DialogContent, List, ListItem, ListItemText, Table, TableBody, TableRow} from "@mui/material";
import React from "react";
import MDTypography from "material-ui/components/MDTypography";
import {Link} from "react-router-dom";
import MDBox from "material-ui/components/MDBox";

interface RecipientUploadHelpProps{
    show: boolean
    setShow: Function
}

function RecipientUploadHelpModal({show, setShow}: RecipientUploadHelpProps){
    return <Dialog
        onClose={() => {
            setShow(false)
        }}
        open={show}
        maxWidth={false}
    >
        <DialogContent>
            <MDBox mt={0.5}>
                <MDTypography variant={"h4"} sx={{width: "fit-content"}}>
                    Follow the tips below, so we can seamlessly upload your spreadsheet.{" "}
                </MDTypography>
            </MDBox>

            <MDBox mt={0.5}>
                <Link to={"/assets/LettrLabsUploadTemplate.csv"} target={"_blank"} style={{width: "fit-content", display: "block"}}>
                    <MDTypography fontWeight={"bold"} variant={"h4"} color={"primary"} isLink={true} linkHoverColor={"primary"}>
                        Download the Template to simplify the process.
                    </MDTypography>
                </Link>
            </MDBox>

            <MDBox mt={2}>
                <List>
                    <ListItem></ListItem>
                    <ListItem>
                        <ListItemText>
                            <MDTypography sx={{fontSize: 18}} fontWeight={"normal"}>
                                - Enter recipients, their addresses and mail merge fields on the same row.
                            </MDTypography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <MDTypography sx={{fontSize: 18}} fontWeight={"normal"}>
                                - Split addresses into five columns: Address 1,
                                Address 2, City, State, Zip Code.
                            </MDTypography>
                        </ListItemText>
                    </ListItem>
                    <ListItemText>
                        <MDTypography sx={{fontSize: 18}} fontWeight={"normal"}>
                            - If you're creating your own file, enter column
                            headers (e.g. Salutation, First Name, Last Name,
                            etc. ) in the first row.
                        </MDTypography>
                    </ListItemText>
                </List>
            </MDBox>

            <MDBox mt={3} mb={3}>
                <Table border={1}>
                    <TableRow sx={{height: "2rem"}}>
                        <MDBox
                            component="th"
                            sx={{backgroundColor: "#C4C4C4"}}
                        >
                            <MDTypography
                                variant="h6"
                            >
                                Salutation
                            </MDTypography>
                        </MDBox>
                        <MDBox
                            component="th"
                            sx={{backgroundColor: "#C4C4C4"}}
                        >
                            <MDTypography
                                variant="h6"
                            >
                                First Name
                            </MDTypography>
                        </MDBox>
                        <MDBox
                            component="th"
                            sx={{backgroundColor: "#C4C4C4"}}
                        >
                            <MDTypography
                                variant="h6"
                            >
                                Last Name
                            </MDTypography>
                        </MDBox>
                        <MDBox
                            component="th"
                            sx={{backgroundColor: "#C4C4C4"}}
                        >
                            <MDTypography
                                variant="h6"
                            >
                                To Organization
                            </MDTypography>
                        </MDBox>
                        <MDBox
                            component="th"
                            sx={{backgroundColor: "#C4C4C4"}}
                        >
                            <MDTypography
                                variant="h6"
                            >
                                Address 1
                            </MDTypography>
                        </MDBox>
                        <MDBox
                            component="th"
                            sx={{backgroundColor: "#C4C4C4"}}
                        >
                            <MDTypography
                                variant="h6"
                            >
                                Address 2
                            </MDTypography>
                        </MDBox>
                        <MDBox
                            component="th"
                            sx={{backgroundColor: "#C4C4C4"}}
                        >
                            <MDTypography
                                variant="h6"
                            >
                                City
                            </MDTypography>
                        </MDBox>
                        <MDBox
                            component="th"
                            sx={{backgroundColor: "#C4C4C4"}}
                        >
                            <MDTypography
                                variant="h6"
                            >
                                State
                            </MDTypography>
                        </MDBox>
                        <MDBox
                            component="th"
                            sx={{backgroundColor: "#C4C4C4"}}
                        >
                            <MDTypography
                                variant="h6"
                            >
                                Zip Code
                            </MDTypography>
                        </MDBox>
                        <MDBox
                            component="th"
                            sx={{backgroundColor: "#C4C4C4"}}
                        >
                            <MDTypography
                                variant="h6"
                                sx={{color: "#00000"}}
                            >
                                Custom 1
                            </MDTypography>
                        </MDBox>
                    </TableRow>
                    <TableBody>
                        <TableRow>
                            <MDBox component="th">
                                <MDTypography variant="button">
                                    Johnny
                                </MDTypography>
                            </MDBox>
                            <MDBox component="th">
                                <MDTypography variant="button">
                                    John
                                </MDTypography>
                            </MDBox>
                            <MDBox component="th">
                                <MDTypography variant="button">
                                    Smith
                                </MDTypography>
                            </MDBox>
                            <MDBox component="th">
                                <MDTypography variant="button"></MDTypography>
                            </MDBox>
                            <MDBox component="th">
                                <MDTypography variant="button">
                                    392 Alpine St.
                                </MDTypography>
                            </MDBox>
                            <MDBox component="th">
                                <MDTypography variant="button">
                                    APT 2233
                                </MDTypography>
                            </MDBox>
                            <MDBox component="th">
                                <MDTypography variant="button">
                                    New York
                                </MDTypography>
                            </MDBox>
                            <MDBox component="th">
                                <MDTypography variant="button">
                                    NY
                                </MDTypography>
                            </MDBox>
                            <MDBox component="th">
                                <MDTypography variant="button">
                                    10045
                                </MDTypography>
                            </MDBox>
                            <MDBox component="th">
                                <MDTypography variant="button"></MDTypography>
                            </MDBox>
                        </TableRow>
                        <TableRow>
                            <MDBox component="th">
                                <MDTypography variant="button"></MDTypography>
                            </MDBox>
                            <MDBox component="th">
                                <MDTypography variant="button"></MDTypography>
                            </MDBox>
                            <MDBox component="th">
                                <MDTypography variant="button"></MDTypography>
                            </MDBox>
                            <MDBox component="th">
                                <MDTypography variant="button">
                                    Fight for the Cure
                                </MDTypography>
                            </MDBox>
                            <MDBox component="th">
                                <MDTypography variant="button">
                                    425 Donation Way
                                </MDTypography>
                            </MDBox>
                            <MDBox component="th">
                                <MDTypography variant="button"></MDTypography>
                            </MDBox>
                            <MDBox component="th">
                                <MDTypography variant="button">
                                    Los Angeles
                                </MDTypography>
                            </MDBox>
                            <MDBox component="th">
                                <MDTypography variant="button">
                                    CA
                                </MDTypography>
                            </MDBox>
                            <MDBox component="th">
                                <MDTypography variant="button">
                                    90210
                                </MDTypography>
                            </MDBox>
                            <MDBox component="th">
                                <MDTypography variant="button"></MDTypography>
                            </MDBox>
                        </TableRow>
                    </TableBody>
                </Table>
            </MDBox>
        </DialogContent>
    </Dialog>
}

export default RecipientUploadHelpModal