import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const WindSpeedIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2.51953 7.33555L10.2052 7.33545V7.33545C14.1604 7.33545 12.2887 1.7236 9.49191 4.52037C9.30884 4.70343 9.11621 4.89699 8.91352 5.10142"
                    stroke="#130B46" strokeWidth="1.5" strokeLinecap="round"/>
                <path
                    d="M3.32812 11.3207L13.4313 11.3208V11.3208C17.3866 11.3208 15.5149 16.9327 12.7181 14.1359C12.535 13.9528 12.3424 13.7593 12.1397 13.5548"
                    stroke="#130B46" strokeWidth="1.5" strokeLinecap="round"/>
                <path
                    d="M2.07422 13.6645H4.15743L7.29036 13.6645V13.6645C11.2456 13.6645 9.3739 19.2764 6.57709 16.4796C6.39402 16.2966 6.2014 16.103 5.99871 15.8986"
                    stroke="#130B46" strokeWidth="1.5" strokeLinecap="round"/>
                <path
                    d="M12.832 9.16016H16.3519V9.16016C20.3137 9.16016 18.4661 3.68261 15.602 6.41993C15.4198 6.59404 15.2284 6.77779 15.0274 6.9715"
                    stroke="#130B46" strokeWidth="1.5" strokeLinecap="round"/>
            </svg>
        </SvgIcon>
    )
}

export default WindSpeedIcon;