import {
    createContext, PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';
import {useEditor} from 'context/editor-context';
import logger from 'loglevel'
import {OrderArtifactType} from "models/enums/orderArtifactType"
import {SceneDefaultArtifacts} from "models/enums/placeholderImage"
import {orderProductHasNoEnvelope, orderProductIsPrintedCard, ProductTypes} from "models/enums/ProductTypes";
import {SceneBlockNames} from "models/enums/SceneBlockNames"
import {
    setTextSizeByBlockName,
    setTextFontByBlockName,
    setTextByBlockName,
    showBlockByName,
    setYPositionByName,
    setHeightByName,
    setParagraphSpacingByBlockName,
} from "helpers/ImgLyHelper"
import {addGlyphs} from "helpers/glyphHelper"
import {YesNo} from 'models/enums/yesNo';
import {IOrder} from "models/order";
import { OrderFontDefaults } from 'models/enums/OrderDefaults';

const PageSettingsContext = createContext(null);

declare type EditorProviderProps = PropsWithChildren<{
    order: IOrder
}>;

export const PageSettingsProvider = ({children, order}: EditorProviderProps) => {
    const {creativeEngine, sceneIsLoaded, currentlyLoadedEditor, selectedFont, glyphsForCurrentlySelectedFont, checkForHandwrittenTextOutOfBounds} = useEditor();

    // const [handwrittenText] = useState<string>(order.text);
    const [backGreetingsTextSize, setBackGreetingsTextSize] = useState(OrderFontDefaults.MinHandWrittenTextFontSize);
    const [returnAddresssTextSize, setReturnAddresssTextSize] = useState(OrderFontDefaults.MinHandWrittenTextFontSize);

    const clearUploadLogoIfNotSet = useCallback(() => {
        logger.debug("clearUploadLogoIfNotSet")
        if (!sceneIsLoaded) {
            return null;
        }
        showBlockByName(creativeEngine, OrderArtifactType.FrontLogo, false, SceneDefaultArtifacts.Front)
    }, [creativeEngine, sceneIsLoaded])

    const clearRearImageLogoIfNotSet = useCallback(() => {
        logger.debug("clearRearImageLogoIfNotSet")
        if (!sceneIsLoaded) {
            return null;
        }
        showBlockByName(creativeEngine, OrderArtifactType.RearImage, false, SceneDefaultArtifacts.Rear)
    }, [creativeEngine, sceneIsLoaded])

    useEffect(() => {
        if (sceneIsLoaded) {
            logger.info("Setting font to", selectedFont, currentlyLoadedEditor)
            if (currentlyLoadedEditor === 'Card') {
                setTextFontByBlockName(creativeEngine, SceneBlockNames.HandwrittenText, selectedFont.url)
                setTextFontByBlockName(creativeEngine, SceneBlockNames.HandwrittenText2, selectedFont.url)
                //TODO use db configuration for all these - this is a hack to get the printed card to work
                //if(order.product !== ProductTypes.PrintedPostcard6x11){
                if (!orderProductIsPrintedCard(order.product)) {
                    setTextFontByBlockName(creativeEngine, SceneBlockNames.Postcard_ToAddress, selectedFont.url);
                }
            } else {
                //Do no set the font for the return address on post cards - it's printed, only set it for products that use envelopes
                //if (order.product !== ProductTypes.HandwrittenPostCardA8) {
                if (!orderProductHasNoEnvelope(order.product)) {
                    setTextFontByBlockName(creativeEngine, 'ReturnAddress', selectedFont.url)
                }
                setTextFontByBlockName(creativeEngine, 'ToAddress', selectedFont.url)
            }
        }
    }, [selectedFont, currentlyLoadedEditor, order.product, sceneIsLoaded, creativeEngine]);

    useEffect(() => {
        if (!sceneIsLoaded || !backGreetingsTextSize)
            return
        setTextSizeByBlockName(creativeEngine, SceneBlockNames.HandwrittenText, backGreetingsTextSize)
        setTextSizeByBlockName(creativeEngine, SceneBlockNames.HandwrittenText2, backGreetingsTextSize)
    }, [backGreetingsTextSize, sceneIsLoaded, creativeEngine]);

    useEffect(() => {
        if (!sceneIsLoaded)
            return
        setBackGreetingsTextSize(order.fontSize)
    }, [order.fontSize, currentlyLoadedEditor, sceneIsLoaded]);
    //---------------------------
    //Sets the initial load of the text for the greeting card
    //-----------------------------
    useEffect(() => {
        if (!sceneIsLoaded)
            return
        // setTextByBlockName(creativeEngine, SceneBlockNames.HandwrittenText, handwrittenText)
        setParagraphSpacingByBlockName(creativeEngine, SceneBlockNames.HandwrittenText, 0.0)
        setParagraphSpacingByBlockName(creativeEngine, SceneBlockNames.HandwrittenText2, 0.0)
        // Waiting for IMG.LY to explain how they calculate line height
        // setLineHeightByBlockName(creativeEngine, SceneBlockNames.HandwrittenText, 1)
    }, [sceneIsLoaded, creativeEngine]);

    useEffect(() => {
        if (!sceneIsLoaded || !glyphsForCurrentlySelectedFont)
            return

        setTextByBlockName(creativeEngine, SceneBlockNames.HandwrittenText, addGlyphs(order.text, glyphsForCurrentlySelectedFont))
        setTextByBlockName(creativeEngine, SceneBlockNames.HandwrittenText2, addGlyphs(order.text2, glyphsForCurrentlySelectedFont))
    }, [order.text, order.text2, sceneIsLoaded, creativeEngine, glyphsForCurrentlySelectedFont]);

    useEffect(() => {
        logger.info("Changed font to", order.font)
    }, [order.font]);

    const handleFrontLogoToggle = useCallback(() => {
        let defaultNoLogoMargin = 0.35
        let defaultNoLogoExtraHeight = 0.65
        if(order?.product === ProductTypes.HandwrittenBiFoldCard) {
            order.noFrontLogo = YesNo.Yes
            defaultNoLogoMargin = 0.5
            defaultNoLogoExtraHeight = 0
        }
        logger.info("Toggling no front logo", order.noFrontLogo)

        var showFrontLogo = true;
        var height = 4.2

        if (order.noFrontLogo === YesNo.Yes) {
            showFrontLogo = false;
            height += defaultNoLogoExtraHeight
        }

        showBlockByName(creativeEngine, SceneBlockNames.FrontLogo, showFrontLogo)
        setYPositionByName(creativeEngine, SceneBlockNames.HandwrittenText, showFrontLogo ? 1 : defaultNoLogoMargin)
        setHeightByName(creativeEngine, SceneBlockNames.HandwrittenText, height)

        // Wait a bit for the components to change their properties before checking again
        setTimeout(() => {
            checkForHandwrittenTextOutOfBounds()
        }, 100)
    }, [creativeEngine, checkForHandwrittenTextOutOfBounds, order])

    useEffect(function () {
        if (sceneIsLoaded) {
            handleFrontLogoToggle();
        }
    }, [sceneIsLoaded, handleFrontLogoToggle])

    useEffect(() => {
        handleFrontLogoToggle();
    }, [creativeEngine, order.noFrontLogo, order.product, handleFrontLogoToggle]);

    //-----------------------------Envelope changes--------------------------------

    useEffect(
        () => {
            logger.info("Setting return address fon size to ", order.fontSizeReturnAddress)
            setReturnAddresssTextSize(order.fontSizeReturnAddress)
        },
        [setReturnAddresssTextSize, order.fontSizeReturnAddress]
    );

    useEffect(
        () => {
            if (!sceneIsLoaded) {
                return
            }
            ///TODO: Hack to get printed cards to work - don't change size for the printed card
            //as it's super small
            //if(order.product !== ProductTypes.PrintedPostcard6x11){
            if (!orderProductIsPrintedCard(order.product)) {
                setTextSizeByBlockName(creativeEngine, 'ReturnAddress', returnAddresssTextSize)
                setTextSizeByBlockName(creativeEngine, 'ToAddress', returnAddresssTextSize)
            }
        },
        [sceneIsLoaded, returnAddresssTextSize, creativeEngine, order.product]
    );

    const value = {
        setBackGreetingsTextSize,
        clearUploadLogoIfNotSet,
        clearRearImageLogoIfNotSet
    };
    return (
        <PageSettingsContext.Provider value={value}>
            {children}
        </PageSettingsContext.Provider>
    );
};

export const usePageSettings = () => {
    const context = useContext(PageSettingsContext);
    if (context === undefined) {
        throw new Error(
            'usePageSettings must be used within a PageSettingsProvider'
        );
    }
    return context;
};
