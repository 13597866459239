import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const MailingListAddIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.875 14.25L9.089 16.192C9.29119 16.5157 9.57241 16.7826 9.90619 16.9677C10.24 17.1528 10.6153 17.2499 10.997 17.25H13.003C13.779 17.25 14.5 16.85 14.911 16.192L16.125 14.25M2.41 9H7.046C7.4164 9.00003 7.78107 9.0915 8.10763 9.26629C8.43419 9.44109 8.71255 9.6938 8.918 10.002L9.082 10.248C9.28745 10.5562 9.56581 10.8089 9.89237 10.9837C10.2189 11.1585 10.5836 11.25 10.954 11.25H13.046C13.4164 11.25 13.7811 11.1585 14.1076 10.9837C14.4342 10.8089 14.7126 10.5562 14.918 10.248L15.082 10.002C15.2874 9.6938 15.5658 9.44109 15.8924 9.26629C16.2189 9.0915 16.5836 9.00003 16.954 9H21.59M2.41 9C2.30446 9.2647 2.25017 9.54704 2.25 9.832V12C2.25 12.5967 2.48705 13.169 2.90901 13.591C3.33097 14.0129 3.90326 14.25 4.5 14.25H19.5C20.0967 14.25 20.669 14.0129 21.091 13.591C21.5129 13.169 21.75 12.5967 21.75 12V9.832C21.75 9.545 21.695 9.262 21.59 9M2.41 9C2.50166 8.76973 2.63073 8.55619 2.792 8.368L6.077 4.536C6.28817 4.28956 6.55015 4.09171 6.84496 3.95604C7.13978 3.82037 7.46046 3.75008 7.785 3.75H16.215C16.872 3.75 17.496 4.037 17.924 4.536L21.208 8.368C21.371 8.558 21.499 8.772 21.59 9M4.5 20.25H19.5C20.0967 20.25 20.669 20.0129 21.091 19.591C21.5129 19.169 21.75 18.5967 21.75 18V15.375C21.75 14.754 21.246 14.25 20.625 14.25H3.375C2.754 14.25 2.25 14.754 2.25 15.375V18C2.25 18.5967 2.48705 19.169 2.90901 19.591C3.33097 20.0129 3.90326 20.25 4.5 20.25Z"
                    stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                    d="M5 3.66667V6.33333V3.66667ZM6.33333 5H3.66667H6.33333ZM9 5C9 5.52529 8.89654 6.04543 8.69552 6.53073C8.4945 7.01604 8.19986 7.45699 7.82843 7.82843C7.45699 8.19986 7.01604 8.4945 6.53073 8.69552C6.04543 8.89654 5.52529 9 5 9C4.47471 9 3.95457 8.89654 3.46927 8.69552C2.98396 8.4945 2.54301 8.19986 2.17157 7.82843C1.80014 7.45699 1.5055 7.01604 1.30448 6.53073C1.10346 6.04543 1 5.52529 1 5C1 3.93913 1.42143 2.92172 2.17157 2.17157C2.92172 1.42143 3.93913 1 5 1C6.06087 1 7.07828 1.42143 7.82843 2.17157C8.57857 2.92172 9 3.93913 9 5Z"
                    fill="black"/>
                <path
                    d="M5 3.66667V6.33333M6.33333 5H3.66667M9 5C9 5.52529 8.89654 6.04543 8.69552 6.53073C8.4945 7.01604 8.19986 7.45699 7.82843 7.82843C7.45699 8.19986 7.01604 8.4945 6.53073 8.69552C6.04543 8.89654 5.52529 9 5 9C4.47471 9 3.95457 8.89654 3.46927 8.69552C2.98396 8.4945 2.54301 8.19986 2.17157 7.82843C1.80014 7.45699 1.5055 7.01604 1.30448 6.53073C1.10346 6.04543 1 5.52529 1 5C1 3.93913 1.42143 2.92172 2.17157 2.17157C2.92172 1.42143 3.93913 1 5 1C6.06087 1 7.07828 1.42143 7.82843 2.17157C8.57857 2.92172 9 3.93913 9 5Z"
                    stroke="#F3F5FB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

        </SvgIcon>
    )
}

export default MailingListAddIcon