import * as Yup from 'yup';

export const doNotMailValidation = Yup.object().shape({
  email: Yup.string(),
  firstName: Yup.string(),
  lastName: Yup.string(),
  address1: Yup.string(),
  address2: Yup.string().nullable(),
  city: Yup.string().when('address1', {
    is: (address1: string) => !!address1,
    then: Yup.string().required('City is required'),
    otherwise: Yup.string().notRequired(),
  }),
  state: Yup.string().when('address1', {
    is: (address1: string) => !!address1,
    then: Yup.string().nullable().required('State is required'),
    otherwise: Yup.string().nullable().notRequired(),
  }),
  zipCode: Yup.string().when('address1', {
    is: (address1: string) => !!address1,
    then: Yup.string().required('Zip Code is required').length(5, 'Zip must be 5 digits long'),
    otherwise: Yup.string().length(5, 'Zip must be 5 digits long').notRequired(),
  }),
});
