import useFetchWithMsal from 'hooks/useFetch';
import API_ENDPOINT, { IObjectResult } from 'services/endpoint';
import { mockDoNotMailRecipients } from './mocks';
import { IDoNotMailRecipient } from './types';

const useDoNotMailService = () => {
  const { execute } = useFetchWithMsal();
  const API_PATH = `${API_ENDPOINT}/do-not-mail`;
  const shouldMock = false;

  const getAllDoNotMailRecipients = (): Promise<IObjectResult<IDoNotMailRecipient[]>> => {
    if (shouldMock) return new Promise((resolve) => resolve({ payload: mockDoNotMailRecipients }));
    return execute({ endpoint: API_PATH, method: 'GET' });
  };

  const createDoNotMailRecipient = (recipient: IDoNotMailRecipient): Promise<IObjectResult<{}>> => {
    if (shouldMock) return new Promise((resolve) => resolve(null));
    return execute({ endpoint: API_PATH, method: 'POST', data: recipient });
  };

  const updateDoNotMailRecipient = (recipient: IDoNotMailRecipient): Promise<IObjectResult<{}>> => {
    if (shouldMock) return new Promise((resolve) => resolve(null));
    return execute({ endpoint: `${API_PATH}`, method: 'PUT', data: recipient });
  };

  const deleteDoNotMailRecipients = (recipientIds: number[]): Promise<IObjectResult<{}>> => {
    if (shouldMock) return new Promise((resolve) => resolve(null));
    return execute({ endpoint: API_PATH, method: 'DELETE', data: { recipientIds } });
  };

  return {
    getAllDoNotMailRecipients,
    createDoNotMailRecipient,
    updateDoNotMailRecipient,
    deleteDoNotMailRecipients,
  };
};

export default useDoNotMailService;
