import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ImportIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.5 15.75V17.625C4.5 18.1223 4.69754 18.5992 5.04917 18.9508C5.40081 19.3025 5.87772 19.5 6.375 19.5H17.625C18.1223 19.5 18.5992 19.3025 18.9508 18.9508C19.3025 18.5992 19.5 18.1223 19.5 17.625V15.75M15.75 8.25L12 4.5M12 4.5L8.25 8.25M12 4.5V15.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export default ImportIcon;