import CreativeEngine, {Configuration, DesignBlockId} from "@cesdk/engine";
import {isEqual} from "lodash";
import {createContext, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState} from "react";
import {useSinglePageFocus} from "./lib/UseSinglePageFocus";
import logger from "loglevel";
import {lettrLabsCaseAssetPath} from "./util";
import {OrderArtifactType} from "models/enums/orderArtifactType";
import {orderProductIsPrintedCard, ProductTypes} from "models/enums/ProductTypes";
import {uploadFile} from "./lib/upload";
import {SUPPORTED_IMAGE_MIME_TYPES} from "models/enums/supportedMimeTypes";
import {SceneBlockNames} from "models/enums/SceneBlockNames";
import {IOrder, Order} from "models/order";
import {
    addBorderToPage,
    addImage, addImagePlaceholder, addKeepOutArea, createBleedLine, createBleedText, createKeepOutText, createQRCode, disableBlocksByName,
    getBlockHeightByName,
    getBlockRotationByName,
    getBlockWidthByName,
    getBlockXPositionByName,
    getBlockYPositionByName,
    getImageDpi, getVersion,
    moveBlockToBottomRightByName,
    printNamesOfAllBlocks,
    recreateImageBlockByName, removeBlock,
    removeBlockByName,
    reshapeBlockToImage,
    setBlockHeightByName,
    setBlockToImageById,
    setBlockToImageByName,
    setCanBeMovedAroundByName,
    setHeightByName,
    setIsAlwaysOnTopByName, setVersion,
    setWidthByName,
    showBlockByName, stretchImageToFullBleed
} from "helpers/ImgLyHelper";
import {replaceGlyphs} from "helpers/glyphHelper";
import {YesNo} from "models/enums/yesNo";
import {getMaxCharacterCountForProduct, mailMergeFields} from "helpers/OrderHelper";
import {IOrderArtifact, IOrderArtifacts} from "models/orderArtifact";
import SceneService from "services/scene-service";
import ArtifactService from "services/artifact-service";
import {useQuery} from "@tanstack/react-query";
import {Font, IFont} from "models/font";
import FontService from "services/font-service";
import {products} from "helpers/products";
import {versions} from "helpers/imgly-versions";
import {setOrderDefaults} from "../../models/enums/OrderDefaults";
import {SceneDefaultArtifacts} from "../../models/enums/placeholderImage";
import { useGlobal } from "context/global-context";

interface Props {
    canRedo: boolean;
    canUndo: boolean;
    creativeEngine: CreativeEngine;
    currentPageBlockId: string;
    currentStep: string;
    currentlyLoadedEditor: "Card" | "Envelope";
    editMode: string;
    engineIsLoaded: boolean;
    loadCurrentScene: (editorType: "Card" | "Envelope") => void;
    resetEditor: () => void;
    saveCurrentScene: (
        editorType: "Card" | "Envelope"
    ) => Promise<void>;
    sceneIsLoaded: boolean;
    selectedBlocks: any;
    setSelectedBlocks: Function;
    setCurrentStep: (value: string) => void;
    setCurrentlyLoadedEditor: (value: "Card" | "Envelope") => void;
    setEnvelopeSceneString: (value: string) => void;
    setCardSceneString: (value: string) => void;
    uploadNewImageArtifact: (selectedBlock?: any) => void;
    setEditorDoesNotUpdateOrder: (value: boolean) => void;
    resetProduct: (productId: number, productName: string) => Promise<void>;
    generateQrCodeFn: () => Promise<void>;
    removeQrCodeFn: () => Promise<void>;
    generatedQrCodeArtifact: IOrderArtifact
    orderArtifacts: IOrderArtifact[]
    addOrReplaceImageToScene: (order: Order, image: string) => void
    updateOrderTextFromCreativeEngine: () => void
    checkImageQuality: () => Promise<void>
    checkForBlocksOnGivenSide: (side: number) => void
    resizeImageAsLogoSize: (blockId: number) => void
    fonts: IFont[]
    selectedFont: IFont
    glyphsForCurrentlySelectedFont: string
    textSelectRange: TextSelectRange
    setImageBlockDimensions: (imageElementId: number, blockName: string) => void
    isThereImageBetweenSafeZoneAndBleedZone: boolean
    processOrderArtifact: (orderArtifact: IOrderArtifact) => void
    isRearImageLowResolution: boolean
    lowestFoundResolution: number
    hasInvalidMailMergePlaceholders: boolean
    hasInvalidImageSize: boolean
    hasTextOutsideOfHandwrittenTextArea: boolean
    hasMissingRearImage: boolean
    hasMissingOutsideFrontImage: boolean
    checkForHandwrittenTextOutOfBounds: () => void
    order: IOrder
    setKeepOutWhite: () => void
}

export declare type EditorProviderProps = PropsWithChildren<{
    order: IOrder
    setOrder: Function
}>;

interface TextSelectRange{
    from: number
    to: number
}

const EditorContext = createContext<Props>(null);

const whiteOutBlockId = 1011880354

export const ALL_STEPS = ["Style", "Design", "Write"];

function EditorProvider({order, setOrder, children}: EditorProviderProps) {
    const {setShowLoader} = useGlobal()

    /**
     * @type {[import("@cesdk/engine").default, Function]} CreativeEngine
     */
    const [orderArtifacts, setOrderArtifacts] = useState<IOrderArtifact[]>([])
    const [selectedOrderArtifact, setSelectedOrderArtifact] = useState<IOrderArtifact>();
    const [creativeEngine, setCreativeEngine] = useState<CreativeEngine>(null);
    const [engineIsLoaded, setEngineIsLoaded] = useState(false);
    const [sceneIsLoaded, setSceneIsLoaded] = useState(false);
    const [currentlyLoadedEditor, setCurrentlyLoadedEditor] = useState<"Card" | "Envelope">(null);
    const [cardSceneString, setCardSceneString] = useState(null);
    const [envelopeSceneString, setEnvelopeSceneString] = useState(null);
    const [currentStep, setCurrentStep] = useState(ALL_STEPS[0]);
    const [editMode, setEditMode] = useState("Transform");
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    //When set to true, the editor will not update the order
    const [editorDoesNotUpdateOrder, setEditorDoesNotUpdateOrder] = useState(false);
    const [generatedQrCodeArtifact, setGeneratedQrCodeArtifact] = useState<IOrderArtifact>(null);

    const {uploadScene, clearScene} = SceneService()
    const {uploadOrderArtifact, updateArtifactType, generateQrCode, removeArtifact, copyOrderArtifact} = ArtifactService()
    const {getFonts, getFontGlyphs} = FontService()

    const [fonts, setFonts] = useState<IFont[]>(null)
    const [selectedFont, setSelectedFont] = useState<IFont>(null);
    const [glyphsForCurrentlySelectedFont, setGlyphsForCurrentlySelectedFont] = useState<string>(null)

    const [isThereImageBetweenSafeZoneAndBleedZone, setIsThereImageBetweenSafeZoneAndBleedZone] = useState<boolean>(false)

    const [lowestFoundResolution, setLowestFoundResolution] = useState(300);
    const [isRearImageLowResolution, setIsRearImageLowResolution] = useState<boolean>(false)

    const [hasInvalidMailMergePlaceholders, setHasInvalidMailMergePlaceholders] = useState<boolean>(false)
    const [hasInvalidImageSize, setHasInvalidImageSize] = useState<boolean>(false)
    const [hasMissingRearImage, setHasMissingRearImage] = useState<boolean>(false)
    const [hasMissingOutsideFrontImage, setHasMissingOutsideFrontImage] = useState<boolean>(false)

    const [hasTextOutsideOfHandwrittenTextArea, setHasTextOutsideOfHandwrittenTextArea] = useState<boolean>(false)

    // There is a bug with MaterialUI and imgly, where if the editor is focused, and i try to open a <Select>, the console would go into an infinite loop and freeze the UI
    // This just saves the cursor in the handwritten text at all times, so that i can unfocus the imgly editor when opening a <Select>
    const [textSelectRange, setTextSelectRange] = useState<TextSelectRange>({from: 0, to: 0})

    const {
        setEnabled: setFocusEnabled,
        setEngine: setFocusEngine,
        currentPageBlockId,
        setCurrentPageIndex,
        setZoomPaddingLeft,
        setZoomPaddingRight,
        setZoomPaddingBottom
    } = useSinglePageFocus({
        verticalTextScrollEnabledDefault: true,
        refocusCropModeEnabledDefault: true,
        zoomPaddingBottomDefault: 0,
        zoomPaddingLeftDefault: 0,
        zoomPaddingRightDefault: 0,
        zoomPaddingTopDefault: 0,
    });
    const editorUpdateCallbackRef = useRef(() => {
    });
    const engineEventCallbackRef = useRef((events: string | any) => {
    });
    const [selectedBlocks, setSelectedBlocks] = useState(null);

    const delay = (wait: number = 200) => {
        return new Promise((resolve) => setTimeout(resolve, wait));
    };

    const getFontsQuery = useQuery<Font[]>({
        queryKey: ["fonts"],
        queryFn: getFonts,
        refetchOnWindowFocus: false
    })

    const getFontsGlyphsQuery = useQuery({
        queryKey: ["fonts", selectedFont?.id, "font-glyphs"],
        queryFn: () => {
            if (!selectedFont)
                return null
            logger.debug("Getting glyphs for font", selectedFont)
            return getFontGlyphs(selectedFont.id)
        },
        refetchOnWindowFocus: false
    })

    useEffect(() => {
        if (order?.orderArtifacts?.length) {
            setOrderArtifacts(order.orderArtifacts)

            setGeneratedQrCodeArtifact(order.orderArtifacts.find((x) => x.artifactType === OrderArtifactType.QRCode)?? null);
        }
    }, [order?.orderArtifacts]);

    useEffect(function () {
        if(!getFontsQuery.data){
            return
        }

        setFonts(getFontsQuery.data)
    }, [getFontsQuery.data])

    useEffect(() => {
        if(!order){
            return
        }

        if(orderProductIsPrintedCard(order.product)){
            setZoomPaddingLeft(20)
            setZoomPaddingRight(20)
        } else if(order.product === ProductTypes.HandwrittenBiFoldCard){
            setZoomPaddingBottom(10)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    useEffect(function () {
        if(!order?.font || !fonts){
            return
        }

        const auxSelectedFont = fonts.find((font: Font) => font.label === order.font)
        setSelectedFont(auxSelectedFont ? auxSelectedFont : fonts[0])
    }, [order?.font, fonts])

    useEffect(function(){
        if(!selectedFont || getFontsGlyphsQuery.isFetching){
            return
        }
        logger.debug("Setting glyphs for currently selected font", selectedFont)
        setGlyphsForCurrentlySelectedFont(getFontsGlyphsQuery.data.value)
    }, [selectedFont, getFontsGlyphsQuery])

    const setWhiteOutBlockColor = useCallback(async(color: "red" | "white") => {
        const rgba: [number, number, number, number] = color === "red" ? [1, 0, 0, 0.25] : [ 1, 1, 1, 0]
        const blocks = await creativeEngine?.block.findByName(SceneBlockNames.USPSBarCode)
        blocks?.forEach(async (block) => {
            await creativeEngine.block.setFillSolidColor(block, ...rgba)
        })
    }, [creativeEngine?.block])

    const setKeepOutBlockColor = useCallback(async (color: "red" | "white", blockName = SceneBlockNames.HWPCKeepOutZone) => {
        const rgba: [number, number, number, number] = color === "red" ? [1, 0, 0, 0.25] : [ 1, 1, 1, 1]
        const blocks = await creativeEngine?.block.findByName(blockName)
        blocks?.forEach(async (block) => {
            await creativeEngine.block.setFillSolidColor(block, ...rgba)
            await creativeEngine.block.setScopeEnabled(block, "editor/select", false)
            if(blockName === SceneBlockNames.OutsideKeepOutArea) creativeEngine.block.bringToFront(block)
        })
        const barcodeIds = await creativeEngine.block.findByName(SceneBlockNames.Postcard_USPSBarCode);
        if(barcodeIds?.length) {
            if(color === "red") await creativeEngine.block.sendToBack(barcodeIds[0]);
            else await creativeEngine.block.bringToFront(barcodeIds[0]);
        }
    }, [creativeEngine?.block])

    async function generateQrCodeFn(){
        let response = await generateQrCode(order.id, order.qrUrl)

        setGeneratedQrCodeArtifact(response);
        processOrderArtifact(response)

        order.useQr = true;
        setOrder(order);
    }

    async function removeQrCodeFn() {
        var qrArtifact = orderArtifacts.find((x) => x.artifactType === OrderArtifactType.QRCode);

        if (qrArtifact) {
            await removeArtifactFn(qrArtifact);
            setGeneratedQrCodeArtifact(null)

            var blockId = creativeEngine.block.findByName(SceneBlockNames.QRCode)[0]
            var fill = creativeEngine.block.getFill(blockId)
            creativeEngine.block.setString(fill, "fill/image/imageFileURI", SceneDefaultArtifacts.QrCode)

            setShowLoader(true)

            await saveCurrentScene("Card")

            setShowLoader(false)
        }
        else {
            logger.debug("No QR code artifact found");
        }
    }

    async function removeArtifactFn(orderArtifact: IOrderArtifact) {
        await removeArtifact(order.id, orderArtifact.id).then((response) => {
            logger.debug("Artifact deleted, removing from local artifacts", response);

            var index = orderArtifacts.indexOf(orderArtifact);
            if (index > -1) {
                orderArtifacts.splice(index, 1);
                setOrderArtifacts([...orderArtifacts]);
            }
        });
    }

    async function resetProduct(productId: number, productName: string) {
        await clearScene(order.id, productId)

        setCardSceneString(null);
        setEnvelopeSceneString(null);
        setCurrentlyLoadedEditor(null);
        removeItemAll(order.orderArtifacts, OrderArtifactType.EnvelopeScene);
        removeItemAll(order.orderArtifacts, OrderArtifactType.CreativeEditorScene);
        removeItemAll(order.orderArtifacts, OrderArtifactType.QRCode);

        setOrderDefaults(order, productName)

        order.useQr = false;
        order.qrUrl = "";
        //if this is not a template order, then we need to reset the double sided and no front logo
        //We don't do this for template orders currently as the UI does not render these two options
        if (order.templateId) {
            order.doubleSided = YesNo.Yes;
            order.noFrontLogo = productName === ProductTypes.HandwrittenPostCardA8 ? YesNo.No : YesNo.Yes;
        } else {
            order.doubleSided = YesNo.No;
            order.noFrontLogo = YesNo.No;
        }

        if (orderProductIsPrintedCard(order.product)) {
            order.doubleSided = YesNo.Yes;
            order.text = '';
            order.text2 = '';
        }

        setOrder(order)
    }

    function removeItemAll(arr: IOrderArtifacts, artifactType: string) {
        var i = 0;
        while (i < arr.length) {
            if (arr[i].artifactType === artifactType) {
                arr.splice(i, 1);
            } else {
                ++i;
            }
        }
        return arr;
    }

    //Adds the response as order artifact if it doesn't already exist, or updates it if it does
    const processOrderArtifact = useCallback(
        (response: any) => {
            var savedArtifact = response as IOrderArtifact;
            if (orderArtifacts) {
                var existingArtifact = orderArtifacts.find((artifact) => artifact.id === savedArtifact.id);
                //if artifact exists - remove it
                if (existingArtifact) {
                    logger.debug("Removing existing artifact", existingArtifact);
                    var index = orderArtifacts.indexOf(existingArtifact);
                    orderArtifacts.splice(index, 1);
                }
                //And insert it again to trigger a change
                logger.debug("Adding search artifact", savedArtifact);
                orderArtifacts.push(savedArtifact);
                setOrderArtifacts([...orderArtifacts]);
            } else {
                setOrderArtifacts([savedArtifact]);
            }
            return savedArtifact;
        },
        [orderArtifacts, setOrderArtifacts]
    );

    const uploadSceneFn = useCallback(async (scene: string, sceneType: OrderArtifactType.CreativeEditorScene | OrderArtifactType.EnvelopeScene) => {
        logger.debug("Uploading scene", sceneType, scene);
        const formData = new FormData();
        formData.append("scene", scene);

        await uploadScene(order.id, sceneType, formData).then((response) => {
            var savedArtifact = response as IOrderArtifact;
            processOrderArtifact(savedArtifact);
            logger.debug("Scene saved", savedArtifact);
        })

    }, [order, uploadScene, processOrderArtifact]);

    async function uploadOrderArtifactFn(file: File, artifactType: string) {
        if (artifactType === OrderArtifactType.Image) {
            var type = file.type;
            if (SUPPORTED_IMAGE_MIME_TYPES.indexOf(type) <= -1) {
                alert(
                    "File type: " +
                    type +
                    " is not supported. Please use PNG, JPEG, or SVG files"
                );
                return;
            }
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("artifactType", artifactType);
        logger.debug("Uploading", artifactType);

        return await uploadOrderArtifact(order.id, formData)
    }

    function updateArtifactTypeFn(orderArtifact: IOrderArtifact) {
        updateArtifactType(orderArtifact).then((response) => {
            var artifact = response as IOrderArtifact;
            processOrderArtifact(artifact);
            logger.debug("Artifact type updated", artifact);
        });
    }

    const createCreativeEngine = useCallback(async () => {
        logger.debug("Creating creative engine");
        const config = {
            role: "Adopter",
            featureFlags: {
                preventScrolling: true,
            },
            assetSources: {},
            page: {
                title: {
                    show: false,
                },
            },
            license: process.env.REACT_APP_IMGLYLICENCE,
        } as Partial<Configuration>;
        logger.debug("Calling CreativeEngine.init");
        const creativeEngine = await CreativeEngine.init(config);
        creativeEngine.editor.setSettingBool("doubleClickToCropEnabled", false);
        creativeEngine.editor.setSettingBool('mouse/enableScroll', false);
        creativeEngine.editor.setSettingBool('mouse/enableZoom', false);
        //ignore the error - it's a bug in the library, but it works anyways
        // @ts-ignore
        creativeEngine.editor.setSettingColor('page/marginFillColor', { r: 0, g: 0, b: 0, a: 0});
        return creativeEngine;
    }, []);
    
    const createHWPCKeepoutZone = () => {
        if(!creativeEngine) return;
        // Create block
        const frontPage = creativeEngine.scene.getPages()[0]
        const positionX = 3.97;
        const positionY = 1.85;
        const width = 3.82;
        const height = 3.44;
        const blockName = SceneBlockNames.HWPCKeepOutZone
        addKeepOutArea(creativeEngine, frontPage, positionX, positionY, width, height, blockName)
        // Bring barcode and address to front
        const addressIds = creativeEngine.block.findByName(SceneBlockNames.Postcard_ToAddress);
        const barcodeIds = creativeEngine.block.findByName(SceneBlockNames.Postcard_USPSBarCode);
        if(addressIds.length && barcodeIds.length) {
            creativeEngine.block.setAlwaysOnTop(addressIds[0], true)
            creativeEngine.block.setAlwaysOnTop(barcodeIds[0], true)
            creativeEngine.block.bringToFront(addressIds[0]);
            creativeEngine.block.bringToFront(barcodeIds[0]);
        }
    }

    // Checks if there's a block on the given page of the scene
    const checkForBlocksOnGivenSide = useCallback((side: number) => {
        if(!creativeEngine) return;
        try {
            let pages = creativeEngine.scene.getPages();
            // Check if given side is valid
            if (side >= pages.length) {
                console.error('Invalid side index');
                return;
            }

            let givenPage = pages[side]

            // The reason why i'm doing this is because there's a lot of blocks on the page,
            // and we only need to check for images and printed texts

            // Check if there's an image on the given side
            let allImageIds = creativeEngine.block.findByKind("image");

            for(let blockId of allImageIds){
                let parentId = creativeEngine.block.getParent(blockId)

                if(parentId === givenPage){
                    let imageFill = creativeEngine.block.getFill(blockId)
                    let imageUrl = creativeEngine.block.getString(imageFill, "fill/image/imageFileURI")

                    // If the image comes from the backend
                    if (imageUrl && !imageUrl.includes("/scenes/")) {
                        setOrder((prevState: Order) => {
                            return {...prevState, doubleSided: YesNo.Yes}
                        })
                        return
                    }
                }
            }

            // Check if there's a printed text on the given side
            let allTextIds = creativeEngine.block.findByName(SceneBlockNames.PrintedText);
            for(let blockId of allTextIds){
                let parentId = creativeEngine.block.getParent(blockId)

                if(parentId === givenPage){
                    setOrder((prevState: Order) => {
                        return {...prevState, doubleSided: YesNo.Yes}
                    })
                    return
                }
            }

            // If bifold, check if there's a qr code on the given side
            if(order?.product === ProductTypes.HandwrittenBiFoldCard) {
                let qrCodes = creativeEngine.block.findByName(SceneBlockNames.QRCode);
                for(let blockId of qrCodes){
                    let parentId = creativeEngine.block.getParent(blockId)
                    if(parentId === givenPage){
                        setOrder((prevState: Order) => {
                            return {...prevState, doubleSided: YesNo.Yes}
                        })
                        return
                    }
                }
            }
            
            setOrder((prevState: Order) => {
                return {...prevState, doubleSided: YesNo.No}
            })
        } catch (error) {
            console.error('Error checking for blocks on given side:', error);
        }
    }, [creativeEngine, setOrder, order?.product])

    function checkIfImageIsBetweenSafeZoneAndBleedZone(){
        let arr1 = creativeEngine.block.findByName(SceneBlockNames.Image);
        let arr2 = creativeEngine.block.findByName(SceneBlockNames.RearImage);
        let arr3 = creativeEngine.block.findByName(SceneBlockNames.QRCode);
        let arr4 = creativeEngine.block.findByName(SceneBlockNames.FrontLogo);
        let arr5 = creativeEngine.block.findByName(SceneBlockNames.PrintedText);
        let arr6 = creativeEngine.block.findByName(SceneBlockNames.OutsideFrontImage);

        let allImageIds = [...arr1, ...arr2, ...arr4, ...arr6]
        let allIds = [...arr1, ...arr2, ...arr3, ...arr4, ...arr5, ...arr6]

        let check = false
        
        const isBifold = order?.product === ProductTypes.HandwrittenBiFoldCard
        for(let blockId of allIds){
            if(arr4.includes(blockId) && order.noFrontLogo === "Yes") break;
            const parent = creativeEngine.block.getParent(blockId)

            const pageWidth = parseFloat(creativeEngine.block.getWidth(parent).toFixed(2))
            let pageHeight = parseFloat(creativeEngine.block.getHeight(parent).toFixed(2))
            const imageWidth = creativeEngine.block.getWidth(blockId)
            const imageHeight = creativeEngine.block.getHeight(blockId)

            let imageX1 = parseFloat(creativeEngine.block.getPositionX(blockId).toFixed(2))
            let imageX2 = parseFloat((creativeEngine.block.getPositionX(blockId) + imageWidth).toFixed(2))
            let imageY1 = parseFloat(creativeEngine.block.getPositionY(blockId).toFixed(2))
            let imageY2 = parseFloat((creativeEngine.block.getPositionY(blockId) + imageHeight).toFixed(2))

            if(imageX1 < 0.1 && imageX1 > -0.1){
                check = true
                break
            }
            if(imageX2 > pageWidth - 0.1 && imageX2 < pageWidth + 0.1){
                check = true
                break
            }
            if(imageY1 < (isBifold ? 5.5 : 0.1) && imageY1 > (isBifold ? 5.25 : -0.1)){
                check = true
                break
            }
            if(imageY2 > pageHeight - 0.1 && imageY2 < pageHeight + 0.1){
                check = true
                break
            }
            if(allImageIds.includes(blockId)) {
                creativeEngine.block.setScopeEnabled(blockId, "layer/crop", false)
                if (imageX1 === 0.1 || imageX2 === pageWidth - 0.1 || imageY1 === 0.1 || imageY2 === pageHeight - 0.1) {
                    check = true
                    break
                }
            }
        }

        setIsThereImageBetweenSafeZoneAndBleedZone(check)
    }

    function resetEditor(){
        if (!creativeEngine) {
            return
        }

        setSceneIsLoaded(false)
    }

    async function addOrReplaceImageToScene(order: IOrder, image: string){
        let blockToPlace;
        await copyOrderArtifact(order.id, image)

        if(selectedBlocks?.length){
            blockToPlace = selectedBlocks[0].id
        }
        else{
            blockToPlace = addImage(creativeEngine, currentPageBlockId)
        }

        setBlockToImageById(
            creativeEngine,
            blockToPlace,
            image
        );

        await setImageBlockDimensions(
            blockToPlace,
            OrderArtifactType.Image
        );
    }

    const loadCurrentScene = useCallback(async (editorTypeIn: "Card" | "Envelope") => {
        logger.debug("Loading current scene");
        setSceneIsLoaded(false);

        //For old scenes, we need to also set the front logo and rear image if they exists in the orderartifacts
        //Find order artifact for front logo
        const LoadImagesIntoSceneFromArtifacts = async () => {
            if (orderArtifacts) {
                var frontLogo = orderArtifacts.find(
                    (artifact) =>
                        artifact.artifactType ===
                        OrderArtifactType.FrontLogo
                );
                if (frontLogo) {
                    logger.debug(
                        "Setting front logo to ",
                        frontLogo.blobUri
                    );
                    setBlockToImageByName(
                        creativeEngine,
                        SceneBlockNames.FrontLogo,
                        frontLogo.blobUri
                    );
                }
                //Find order artifact for rear image
                if (order.doubleSided === YesNo.Yes || order?.product === ProductTypes.HandwrittenBiFoldCard) {
                    let rearImageName = order?.product === ProductTypes.HandwrittenBiFoldCard ? SceneBlockNames.OutsideFrontImage : SceneBlockNames.RearImage;
                    var rearImage = orderArtifacts.find(
                        (artifact) =>
                            artifact.artifactType ===
                            OrderArtifactType.RearImage
                    );
                    if (rearImage) {
                        logger.debug(
                            "Setting rear image to ",
                            rearImage.blobUri
                        );
                        setBlockToImageByName(
                            creativeEngine,
                            rearImageName,
                            rearImage.blobUri
                        );
                    }
                }
            }
        };

        const SetQRCodeVisibility = async () => {
            logger.debug(
                "Recreating QR block to for backwards compatibility"
            );
            recreateImageBlockByName(
                creativeEngine,
                SceneBlockNames.QRCode
            );
            if (!order.useQr) {
                showBlockByName(
                    creativeEngine,
                    SceneBlockNames.QRCode,
                    false
                );
                if (orderProductIsPrintedCard(order.product)) {
                    moveBlockToBottomRightByName(
                        creativeEngine,
                        SceneBlockNames.QRCode
                    );
                    setIsAlwaysOnTopByName(
                        creativeEngine,
                        SceneBlockNames.QRCode,
                        true
                    );
                }
            }
        };

       /* const UpdateAllImagesToBeAssociatedWithCurrentOrder = async () => {
            var allImageIds = creativeEngine.block.findByType("graphic");

            allImageIds.forEach((imageElementId: DesignBlockId) => {
                let imageFill = creativeEngine.block.getFill(imageElementId)
                let imageUrl = creativeEngine.block.getString(imageFill, "fill/image/imageFileURI")

                var tmp = imageUrl.replace(
                    /\/orderartifacts\/\d+\//,
                    "/orderartifacts/" + order.id + "/"
                );
                const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
                var newImageUrl = tmp;
                if (ENVIRONMENT === "qat") {
                    newImageUrl = tmp.replace(
                        "lettrlabsappstorage",
                        "lettrlabsappqatstorage"
                    );
                } else if (ENVIRONMENT === "uat") {
                    newImageUrl = tmp.replace(
                        "lettrlabsappstorage",
                        "lettrlabsappuatstorage"
                    );
                }

                if (newImageUrl !== imageUrl) {
                    logger.debug(
                        "Updating image url from ",
                        imageUrl,
                        newImageUrl
                    );
                    creativeEngine.block.setString(
                        imageFill,
                        "fill/image/imageFileURI",
                        newImageUrl
                    );
                }
            });
        };*/

        //Always hide the border - we're no longer using it - The border was included in scenes pre march 2023
        const HideBorder = async () => {
            //showBlockByName(creativeEngine, SceneBlockNames.Border, false);
        };

        const fixBackwardsCompatibilityForOldScenes = async () => {
            if (editorTypeIn === "Card") {
                await LoadImagesIntoSceneFromArtifacts();
                await HideBorder();
                if(order?.product !== ProductTypes.HandwrittenBiFoldCard) await SetQRCodeVisibility();
                //await UpdateAllImagesToBeAssociatedWithCurrentOrder();
            }
        };

        // This function sets the front and back images of printed products to be the full size of the card
        function setPrintedProductPlaceholderImagesToBeFullStretch(){
            let frontImage = creativeEngine.block.findByName(SceneBlockNames.FrontLogo)[0]
            let backImage = creativeEngine.block.findByName(SceneBlockNames.RearImage)[0]

            if (frontImage){
                let imageFill = creativeEngine.block.getFill(frontImage)
                let imageUrl = creativeEngine.block.getString(imageFill, "fill/image/imageFileURI")

                // Only make it full stretch if it's the placeholder image - otherwise don't, since we don't want to overwrite the size of the user's selected image
                if (imageUrl && imageUrl.includes("/scenes/")) {
                    stretchImageToFullBleed(creativeEngine, frontImage)
                }
            }
            if (backImage){
                let imageFill = creativeEngine.block.getFill(backImage)
                let imageUrl = creativeEngine.block.getString(imageFill, "fill/image/imageFileURI")

                // Only make it full stretch if it's the placeholder image - otherwise don't, since we don't want to overwrite the size of the user's selected image
                if (imageUrl && imageUrl.includes("/scenes/")) {
                    stretchImageToFullBleed(creativeEngine, backImage)
                }
            }
        }

        const addBleedMarginToPrintedProducts = () => {
            var pages = creativeEngine.scene.getPages()

            for(let p of pages){
                creativeEngine.block.setBool(p, "page/marginEnabled", true)
                creativeEngine.block.setFloat(p, "page/margin/left", 0.1)
                creativeEngine.block.setFloat(p, "page/margin/right", 0.1)
                creativeEngine.block.setFloat(p, "page/margin/top", 0.1)
                creativeEngine.block.setFloat(p, "page/margin/bottom", 0.1)
            }
        }

        const removeStampFromPrintedProducts = () => {
            logger.debug("Removing stamp from printed products")
            removeBlockByName(creativeEngine, SceneBlockNames.UspsStamp)
            removeBlockByName(creativeEngine, SceneBlockNames.UspsStampText)
            removeBlockByName(creativeEngine, SceneBlockNames.UspsStampElement)
            //Unfortunately the Stamp Element is not named, so we have to hard code remove it 
            removeBlock(creativeEngine, 100667854)
            removeBlock(creativeEngine, 69210520)

            // var pages = creativeEngine.scene.getPages()

            // for(let p of pages){
            //     creativeEngine.block.setBool(p, "page/marginEnabled", true)
            //     creativeEngine.block.setFloat(p, "page/margin/left", 0.1)
            //     creativeEngine.block.setFloat(p, "page/margin/right", 0.1)
            //     creativeEngine.block.setFloat(p, "page/margin/top", 0.1)
            //     creativeEngine.block.setFloat(p, "page/margin/bottom", 0.1)
            // }
        }

        const resetBleeds = () => {
            let bleedBlocks: number[] = []
            let bleedTexts: number[] = []

            // This removes the lines and text that come from the premade scene
            if(order.product === ProductTypes.LargeHandwrittenCardA8){
                //Remove bleeds
                bleedBlocks = [283120005, 674238882, 1819283914]

                //Remove bleed texts
                bleedTexts = [16781712, 846205310, 1383073566, 1447039419, 1515196807, 2248151492, 2348814764, 2790265285, 3745518008]
            }
            else if(order.product === ProductTypes.PrintedPostcard4x6 || order.product === ProductTypes.PrintedPostcard6x9 || order.product === ProductTypes.PrintedPostcard6x11){
                //Remove bleeds
                bleedBlocks = [260051411, 1456476561]

                //Remove bleed texts
                bleedTexts = [63967621, 1162875335, 2026901895, 3353350590, 1567625603, 1963987375, 2345668993, 475009402, 62919120, 349180287, 762319261, 1608520110, 2145391001]
            }
            else if(order.product === ProductTypes.HandwrittenPostCardA8){
                //Remove bleeds
                bleedBlocks = [141562264, 260051411, 1530925510]

                //Remove bleed texts
                bleedTexts = [1622151590, 2026901895, 3353350590, 586154539, 762319261, 1608520110]
            }

            bleedBlocks.push(...creativeEngine.block.findByName(SceneBlockNames.SafeZone))
            bleedTexts.push(...creativeEngine.block.findByName(SceneBlockNames.SafeZoneText))
            bleedTexts.push(...creativeEngine.block.findByName(SceneBlockNames.SafeZoneSmallText))
            bleedTexts.push(...creativeEngine.block.findByName(SceneBlockNames.SafeZoneBigText))
            bleedTexts.push(...creativeEngine.block.findByName(SceneBlockNames.FullBleedSmallText))
            bleedTexts.push(...creativeEngine.block.findByName(SceneBlockNames.FullBleedBigText))

            for(let id of bleedBlocks){
                removeBlock(creativeEngine, id)
            }

            for(let id of bleedTexts){
                removeBlock(creativeEngine, id)
            }

            let pages = creativeEngine.scene.getPages();

            const currentProduct = products.find(a => a.name === order.product)
            const isBifold = order.product === ProductTypes.HandwrittenBiFoldCard

            pages.forEach((p, index) => {
                const isBifoldBack = isBifold && index === 1

                createBleedLine(creativeEngine, p, {r: 0, g: 1, b: 0, a: 0.7}, -0.2, isBifoldBack ? -5.5 : -0.2, 0.1, isBifoldBack ? 5.45 : 0.1)
                createBleedLine(creativeEngine, p, {r: 0, g: 0, b: 1, a: 0.7}, 0.2, isBifoldBack ? -5.1 : 0.2, -0.1, isBifoldBack ? 5.2 : -0.1)

                createBleedText(creativeEngine, p, `Full Bleed ${currentProduct.fullBleedArea}`, {r: 0, g: 0, b: 0.6, a: 1}, 8, -0.1, 0.13, SceneBlockNames.FullBleedBigText, true)
                createBleedText(creativeEngine, p, 'For edge-to-edge designs, extend your background graphics to this border.', {r: 0, g: 0, b: 0, a: 1}, 6, -0.1, 0.25, SceneBlockNames.FullBleedSmallText, false)

                createBleedText(creativeEngine, p, `Safe Zone ${currentProduct.safeZoneArea}`, {r: 0, g: 0.6, b: 0, a: 1}, 8, 3, 0.13, SceneBlockNames.SafeZoneBigText, false)
                createBleedText(creativeEngine, p, 'Keep all text and important graphics within this region.', {r: 0, g: 0, b: 0, a: 1}, 6, 3, 0.25, SceneBlockNames.SafeZoneSmallText, false)
            })
        }

        const checkSceneDimensions = async () => {
            var height = creativeEngine.block.getHeight(creativeEngine.scene.getPages()[0]);
            var width = creativeEngine.block.getWidth(creativeEngine.scene.getPages()[0])
            height = Math.round((height + Number.EPSILON) * 100) / 100
            width = Math.round((width + Number.EPSILON) * 100) / 100
            var orderIsInError = false;
            setWhiteOutBlockColor("white")

            const postcardBarCodeBlocks = creativeEngine?.block.findByName(SceneBlockNames.Postcard_USPSBarCode)
            postcardBarCodeBlocks?.forEach((block) => creativeEngine.block.setAlwaysOnTop(block, true))

            if (order.product === ProductTypes.HandwrittenPostCardA8) {
                if(!creativeEngine.block.findByName(SceneBlockNames.HWPCKeepOutZone).length) createHWPCKeepoutZone()
                else setKeepOutBlockColor("white")
                if (height !== 5.3 || width !== 7.8)
                    orderIsInError = true
            }else if (order.product === ProductTypes.LargeHandwrittenCardA8) {
                if (height !== 5.3 || width !== 7.5)
                    orderIsInError = true
            } else if (order.product === ProductTypes.PrintedPostcard4x6) {
                if (height !== 4 || width !== 6){
                    creativeEngine.block.resizeContentAware(
                        creativeEngine.scene.getPages(), 6, 4
                    );
                    //Set witeout area correctly
                    const whiteOutBlockHeight = 2.76
                    const whiteOutBlockWidth = 4.06
                    setHeightByName(creativeEngine, SceneBlockNames.USPSBarCode, whiteOutBlockHeight)
                    setWidthByName(creativeEngine, SceneBlockNames.USPSBarCode, whiteOutBlockWidth)
                    creativeEngine.block.setHeight(whiteOutBlockId, whiteOutBlockHeight)
                    creativeEngine.block.setWidth(whiteOutBlockId, whiteOutBlockWidth)
                }
            } else if (order.product === ProductTypes.PrintedPostcard6x11) {
                if (height !== 6 || width !== 11){
                    creativeEngine.block.resizeContentAware(
                        creativeEngine.scene.getPages(), 11, 6
                    );
                    //Set witeout area correctly
                    const whiteOutBlockHeight = 2.78
                    const whiteOutBlockWidth = 4.15
                    setHeightByName(creativeEngine, SceneBlockNames.USPSBarCode, whiteOutBlockHeight)
                    setWidthByName(creativeEngine, SceneBlockNames.USPSBarCode, whiteOutBlockWidth)
                    creativeEngine.block.setHeight(whiteOutBlockId, whiteOutBlockHeight)
                    creativeEngine.block.setWidth(whiteOutBlockId, whiteOutBlockWidth)
                }
            } else if (order.product === ProductTypes.PrintedPostcard6x9) {
                if (height !== 6 || width !== 9){
                    creativeEngine.block.resizeContentAware(
                        creativeEngine.scene.getPages(), 9, 6
                    );
                    //Set witeout area correctly
                    const whiteOutBlockHeight = 2.77
                    const whiteOutBlockWidth = 4.12
                    setHeightByName(creativeEngine, SceneBlockNames.USPSBarCode, whiteOutBlockHeight)
                    setWidthByName(creativeEngine, SceneBlockNames.USPSBarCode, whiteOutBlockWidth)
                    creativeEngine.block.setHeight(whiteOutBlockId, whiteOutBlockHeight)
                    creativeEngine.block.setWidth(whiteOutBlockId, whiteOutBlockWidth)
                }
            }
            if (orderIsInError) {
                window.alert("The scene you are trying to load is not the correct size for this product. Please contact support.")
                throw new Error("Scene is not the correct size for")
            }
        };

        if (engineIsLoaded) {
            creativeEngine.block.onSelectionChanged(handleOnSelectionChange)

            //If we already have the card scene in memeroy
            if (editorTypeIn === "Card" && cardSceneString !== null) {
                logger.debug("Loading postcard (or printed card) template from memory");
                await creativeEngine.scene.loadFromString(cardSceneString);
                //If we already have the envelope scene in memory
            }
            else if (editorTypeIn === "Envelope" && envelopeSceneString !== null) {
                logger.debug("Loading envelope template from memory");
                await creativeEngine.scene.loadFromString(envelopeSceneString);
                //Otherwise, load it from the back end
            }
            else {
                var sceneUrl = lettrLabsCaseAssetPath(order.id, editorTypeIn);
                logger.debug("Loading postcard template from order:" + order.id, sceneUrl);

                await creativeEngine.scene.loadFromURL(sceneUrl);

                if (editorTypeIn === "Card") {
                    await checkSceneDimensions();
                    
                    if(order?.product === ProductTypes.HandwrittenBiFoldCard) {
                        var imageBlock = creativeEngine.block.findByName(SceneBlockNames.OutsideFrontImage)
                        const width = 7.48;
                        const height = 5.2;
                        const positionX = 0.01;
                        const topHalfPositionY = 0.01;
                        const bottomHalfPositionY = 5.18;
                        const pageId = creativeEngine.block.findByType("page")[0];
                        if(imageBlock.length === 0) addImagePlaceholder(creativeEngine, pageId, positionX, bottomHalfPositionY, width, height, SceneBlockNames.OutsideFrontImage, SceneDefaultArtifacts.OutsideFront);
                        addKeepOutArea(creativeEngine, pageId, positionX, topHalfPositionY, width, height, SceneBlockNames.OutsideKeepOutArea);
                        
                        const hasKeepOutText = creativeEngine.block.findByName(SceneBlockNames.OutsideKeepOutAreaText)
                        if(!hasKeepOutText.length) createKeepOutText(creativeEngine)
                    }
                }
                await fixBackwardsCompatibilityForOldScenes();
            }

            let version = getVersion(creativeEngine)

            if (editorTypeIn === "Card"){
                resetBleeds()

                if(orderProductIsPrintedCard(order.product)){
                    setPrintedProductPlaceholderImagesToBeFullStretch()
                } else if(order?.product === ProductTypes.HandwrittenBiFoldCard ) {
                    // This function sets the front and back images of printed products to be the full size of the card
                    const backImage = creativeEngine.block.findByName(SceneBlockNames.OutsideFrontImage)[0]

                    if (backImage) stretchImageToFullBleed(creativeEngine, backImage, order.product)
                    if( !order.qrUrl) {
                        removeBlockByName(creativeEngine, SceneBlockNames.QRCode)
                        setOrder({...order, 
                            qrAngle: null,
                            qrCodeHeight: null,
                            qrCodeSide: null,
                            qrCodeWidth: null,
                            qrCodeX: null,
                            qrCodeY: null,
                            qrUrl: null,
                            useQr: false
                        })
                    }
                }
            }

            disableBlocksByName(creativeEngine, SceneBlockNames.SafeZone)
            disableBlocksByName(creativeEngine, SceneBlockNames.SafeZoneText)
            disableBlocksByName(creativeEngine, SceneBlockNames.SafeZoneSmallText)
            disableBlocksByName(creativeEngine, SceneBlockNames.SafeZoneBigText)
            disableBlocksByName(creativeEngine, SceneBlockNames.FullBleedSmallText)
            disableBlocksByName(creativeEngine, SceneBlockNames.FullBleedBigText)
            disableBlocksByName(creativeEngine, SceneBlockNames.OutsideKeepOutAreaText)

            if(version && versions[version].hasBackSideBorder){
                disableBlocksByName(creativeEngine, SceneBlockNames.Border)
            }
            else{
                if(editorTypeIn === "Card")
                {
                    const pages = creativeEngine.scene.getPages()
                    if(order.product === ProductTypes.LargeHandwrittenCardA8 || order.product === ProductTypes.HandwrittenBiFoldCard || order.product === ProductTypes.HandwrittenPostCardA8){
                        addBorderToPage(creativeEngine, pages[1])
                    }
                    else if(order.product === ProductTypes.PrintedPostcard4x6 || order.product === ProductTypes.PrintedPostcard6x9 || order.product === ProductTypes.PrintedPostcard6x11){
                        addBorderToPage(creativeEngine, pages[0])
                        addBorderToPage(creativeEngine, pages[1])
                    }
                }
            }

            let arr = creativeEngine.block.findByName(SceneBlockNames.HandwrittenText)

            if(arr && arr.length){
                let handwrittenBlockId = arr[0]

                // Not sure what this does as imgly's documentation is awful but it basically enables the usage of
                // text/hasClippedLines, which sets the hasTextOutsideOfHandwrittenTextArea state
                creativeEngine.block.setBool(handwrittenBlockId, "text/clipLinesOutsideOfFrame", true)
            }

            if (orderProductIsPrintedCard(order.product)){
                addBleedMarginToPrintedProducts()
                removeStampFromPrintedProducts()
            }

            if(editorTypeIn === "Card"){
                if(order?.product === ProductTypes.HandwrittenBiFoldCard) checkForBlocksOnGivenSide(0)
                else checkForBlocksOnGivenSide(1)
            }

            // TODO: the if and else bellow are old news, but when I added a type to the engine the bellow failed on build
            if(editorTypeIn === "Envelope"){
                //@ts-ignore
                creativeEngine.editor.setSettingEnum('role', 'Viewer');
            }
            else{
                //@ts-ignore
                creativeEngine.editor.setSettingEnum('role', 'Adopter');
            }

            setCurrentlyLoadedEditor(editorTypeIn);

            setCurrentPageIndex(0);
            setSceneIsLoaded(true);
            //For cards, we want to be able to drag the images around
            if (editorTypeIn === "Card") {
                setCanBeMovedAroundByName(
                    creativeEngine,
                    SceneBlockNames.FrontLogo,
                    true
                );
                setCanBeMovedAroundByName(
                    creativeEngine,
                    SceneBlockNames.RearImage,
                    true
                );
                setCanBeMovedAroundByName(
                    creativeEngine,
                    SceneBlockNames.OutsideFrontImage,
                    true
                );
            }
            else{
                setCanBeMovedAroundByName(
                    creativeEngine,
                    SceneBlockNames.FrontLogo,
                    false
                );
                setCanBeMovedAroundByName(
                    creativeEngine,
                    SceneBlockNames.RearImage,
                    false
                );
            }

            setCurrentStep("Write");
            
            printNamesOfAllBlocks(creativeEngine);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderArtifacts, creativeEngine, engineIsLoaded, cardSceneString, envelopeSceneString, order, setCurrentPageIndex, checkForBlocksOnGivenSide]);

    const saveCurrentScene = async (editorTypeIn: "Card" | "Envelope") => {
        logger.debug(
            "Saving current scene for editor type: ",
            editorTypeIn
        );
        if (creativeEngine) {
            setVersion(creativeEngine, "1")

            //If we have a qr code, we want to save the properties of it to be able to recreate it later
            if (order.qrUrl) {
                //Refactor this, we don't want to set the parameters if the qr code block does not exist in the scene
                if (getBlockHeightByName(creativeEngine, SceneBlockNames.QRCode))
                    order.qrCodeHeight = getBlockHeightByName(creativeEngine, SceneBlockNames.QRCode)
                if (getBlockWidthByName(creativeEngine, SceneBlockNames.QRCode))
                    order.qrCodeWidth = getBlockWidthByName(creativeEngine, SceneBlockNames.QRCode)
                if (getBlockRotationByName(creativeEngine, SceneBlockNames.QRCode))
                    order.qrAngle = getBlockRotationByName(creativeEngine, SceneBlockNames.QRCode)
                if (getBlockXPositionByName(creativeEngine, SceneBlockNames.QRCode))
                    order.qrCodeX = getBlockXPositionByName(creativeEngine, SceneBlockNames.QRCode)
                if (getBlockYPositionByName(creativeEngine, SceneBlockNames.QRCode))
                    order.qrCodeY = getBlockYPositionByName(creativeEngine, SceneBlockNames.QRCode)

                //Make sure qr code is a square
                if (order.qrCodeHeight !== order.qrCodeWidth) {
                    setBlockHeightByName(creativeEngine, SceneBlockNames.QRCode, order.qrCodeWidth)
                    order.qrCodeHeight = order.qrCodeWidth
                }
                // TODO: CHECK IF FRONT OR REAR (Normal Cards: Front is the front. Bi-Fold Cards: Inside is the front)
                if(order.product === ProductTypes.HandwrittenBiFoldCard) {
                    const backPage = creativeEngine.scene.getPages()[1]
                    const qrCode = creativeEngine.block.findByName(SceneBlockNames.QRCode)[0]
                    if(qrCode) {
                        const qrCodeParent = creativeEngine.block.getParent(qrCode)
                        order.qrCodeSide = backPage === qrCodeParent ? "Rear" : "Front"
                    }
                } else order.qrCodeSide = "Front"
            }

            var sceneString = await creativeEngine.scene.saveToString();

            //Make images not changeable during save
            setCanBeMovedAroundByName(
                creativeEngine,
                OrderArtifactType.FrontLogo,
                false
            );
            setCanBeMovedAroundByName(
                creativeEngine,
                OrderArtifactType.RearImage,
                false
            );

            let sceneType: OrderArtifactType.CreativeEditorScene | OrderArtifactType.EnvelopeScene;

            if (editorTypeIn === "Card") {
                sceneType = OrderArtifactType.CreativeEditorScene
            } else {
                sceneType = OrderArtifactType.EnvelopeScene
            }

            logger.debug("Saving scene");
            await uploadSceneFn(sceneString, sceneType);

            if (sceneType === OrderArtifactType.CreativeEditorScene) {
                logger.debug("Saving card scene to memory");
                setCardSceneString(sceneString);
            } else {
                logger.debug("Saving envelope scene to memory");
                setEnvelopeSceneString(sceneString);
            }
        }
    }

    function updateOrderTextFromCreativeEngine() {
        if (creativeEngine && !editorDoesNotUpdateOrder) {
            const updateTextFromBlock = (blockName:string, orderField:string) => {
                var greetingBlocks = creativeEngine.block.findByName(blockName);
                if (greetingBlocks.length > 0) {
                    var greetingBlock = greetingBlocks[0];
                    var text = creativeEngine.block.getString(greetingBlock, "text/text");
    
                    text = replaceGlyphs(text, glyphsForCurrentlySelectedFont);
    
                    if (order[orderField] !== text) {
                        let maxChars = getMaxCharacterCountForProduct(order.product);
                        if(order?.product === ProductTypes.HandwrittenBiFoldCard) maxChars = maxChars / 2
                        logger.debug("Updating order text from creative engine to " + text);
    
                        if (text.length > maxChars) {
                            text = text.substring(0, maxChars);
                            creativeEngine.block.setString(greetingBlock, "text/text", text);
                        }
    
                        order[orderField] = text;
                        setOrder(order);
                    }
                }
            };
    
            updateTextFromBlock(SceneBlockNames.HandwrittenText, 'text');
            updateTextFromBlock(SceneBlockNames.HandwrittenText2, 'text2');
        }
    }
    
    //--------------------------------------------------------------------------------
    //USE EFFECTS
    //--------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------
    //Changes the page index when changing the current step
    //--------------------------------------------------------------------------------
    useEffect(() => {
        var newPageIndex = currentStep === "Write" ? 0 : 1;
        logger.debug(
            "Setting editor scene page index to",
            newPageIndex,
            currentStep
        );
        setCurrentPageIndex(newPageIndex);
    }, [setCurrentPageIndex, currentStep]);


    //--------------------------------------------------------------------------------
    //Initializes the creative engine with the configuration, disposes it on navigating away
    //--------------------------------------------------------------------------------
    useEffect(() => {
        logger.debug("Initializing the creative engine");
        const loadEditor = async () => {
            const creativeEngine = await createCreativeEngine();

            setCreativeEngine(creativeEngine);
            creativeEngine.editor.onStateChanged(() =>
                editorUpdateCallbackRef.current()
        );
        creativeEngine.event.subscribe([], (events) => {
            engineEventCallbackRef.current(events);
        });
        setEngineIsLoaded(true);
        setFocusEngine(creativeEngine);
        setFocusEnabled(true);
    };
    
    loadEditor();

        return () => {
            logger.debug("checking for disposing creative engine");
            //TODO: this is not working - creativeEngine is not being disposed so we'll definitely have memoryleaks[Priority:High]
            if (creativeEngine) {
                logger.debug("disposing creative engine");
                creativeEngine.dispose();
            }
            else {
                logger.info(
                    "CreativeEngine is undefined and cannot be disposed"
                );
            }
            setEngineIsLoaded(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createCreativeEngine]);

    const checkImageQuality = async () => {
        let allImageIds = creativeEngine.block.findByKind("image");

        let lowestResolution = 300;
        setLowestFoundResolution(300)

        allImageIds?.forEach(async (imageElementId) => {
            let imageFill = creativeEngine.block.getFill(imageElementId)

            let imageUrl = creativeEngine.block.getString(imageFill, "fill/image/imageFileURI")

            if (imageUrl && !imageUrl.includes("/scenes/")) {
                let imageResolution = await getImageDpi(creativeEngine, imageElementId)

                if (imageResolution < lowestResolution) {
                    lowestResolution = imageResolution;
                    setLowestFoundResolution(lowestResolution);
                }
            }
        });

        setIsRearImageLowResolution(false)

        let rearImageName = order?.product === ProductTypes.HandwrittenBiFoldCard ? SceneBlockNames.OutsideFrontImage : SceneBlockNames.RearImage;
        let rearImage = creativeEngine.block.findByName(rearImageName)[0]
        if(rearImage){
            let rearImageResolution = await getImageDpi(creativeEngine, rearImage)

            if(rearImageResolution < 200){
                setIsRearImageLowResolution(true)
            }
        }
    }

    function resizeImageAsLogoSize(blockId: number){
        if (!creativeEngine) return;

        var frameHeight = 0.85;
        var frameWidth = creativeEngine.block.getWidth(blockId)

        if (order.product === ProductTypes.HandwrittenPostCardA8) {
            frameHeight = 0.8;
        }

        creativeEngine.block.setHeight(blockId, frameHeight);

        reshapeBlockToImage(
            creativeEngine,
            frameWidth,
            frameHeight,
            blockId
        );
    }

    function checkForIncorrectMailMergePlaceholders() {
        const checkTextForPlaceholders = (text: string | undefined) => {
            if (!text) return false;
    
            let matches = text.match(/{{(.*?)}}/g);
            if (!matches) return false;
    
            for (let match of matches) {
                match = match.replaceAll('{', '').replaceAll('}', '');
                if (!mailMergeFields.includes(match)) {
                    return true;
                }
            }
    
            return false;
        };
    
        const hasInvalidMailMergePlaceholders = checkTextForPlaceholders(order.text) || checkTextForPlaceholders(order.text2);
    
        setHasInvalidMailMergePlaceholders(hasInvalidMailMergePlaceholders);
    }

    useEffect(() => {
        if(!order){
            return
        }

        checkForIncorrectMailMergePlaceholders()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order?.text]);

    //When loading the front logo - we need to set the default dimensions
    const setImageBlockDimensions = useCallback(async (imageElementId: number, blockName: string) => {
        if (!creativeEngine || !order) return;
        //Start off with the existing height, width, and postion of the block
        var frameHeight = creativeEngine.block.getHeight(imageElementId);
        var frameWidth = creativeEngine.block.getWidth(imageElementId);
        var positionX = creativeEngine.block.getPositionX(imageElementId);
        var positionY = creativeEngine.block.getPositionY(imageElementId);

        //Then, if we have a special block - use it's defaults
        //TODO, load this from an external config object on load
        //Defaults for handwritten cards
        //For HandwrittenPostCardA8
        if (blockName === OrderArtifactType.FrontLogo) {
            frameHeight = 0.85;
            frameWidth = 6.5;
            positionX = 0.5;
            positionY = 0.15;

            //Defaults for post cards
            if (order.product === ProductTypes.HandwrittenPostCardA8) {
                frameHeight = 0.8;
                frameWidth = 3;
                positionX = 2.4;
            }

            creativeEngine.block.setHeight(imageElementId, frameHeight);
            creativeEngine.block.setWidth(imageElementId, frameWidth);
            creativeEngine.block.setPositionX(imageElementId, positionX);
            creativeEngine.block.setPositionY(imageElementId, positionY);
        }
        else if (blockName === OrderArtifactType.RearImage) {
            frameHeight = 5.5;
            frameWidth = 8;
            positionX = -0.1;
            positionY = -0.1;

            //Defaults for a8 cards
            if (order.product === ProductTypes.LargeHandwrittenCardA8) {
                frameWidth = 7.7;
            }
            creativeEngine.block.setHeight(imageElementId, frameHeight);
            creativeEngine.block.setWidth(imageElementId, frameWidth);
            creativeEngine.block.setPositionX(imageElementId, positionX);
            creativeEngine.block.setPositionY(imageElementId, positionY);
        }

        await reshapeBlockToImage(
            creativeEngine,
            frameWidth,
            frameHeight,
            imageElementId
        );
    }, [creativeEngine, order]);
    //--------------------------------------------------------------------------------
    //when selected artifact changed, update all the selected blocks with the search image
    //Then reset the selected image to make it selectable again
    //--------------------------------------------------------------------------------
    useEffect(() => {
        if (creativeEngine && selectedOrderArtifact) {
            logger.debug(
                "HEY",
                selectedOrderArtifact,
                "Selected order artifact changed - updating all selected blocks",
                selectedOrderArtifact.blobUri
            );
            const allSelectedImageElements = creativeEngine.block
                .findAllSelected()
                .filter((elementId) =>
                    creativeEngine.block.getType(elementId).includes("image")
                );

            if (allSelectedImageElements.length > 0) {
                allSelectedImageElements.forEach(async (blockId) => {
                    var blockName = creativeEngine.block.getName(blockId);
                    //Do not replace QR code image
                    if (blockName !== SceneBlockNames.QRCode) {


                        //Only change block names for front and rear images, or if we have a different block name
                        if (
                            blockName !== SceneBlockNames.Image &&
                            blockName !== selectedOrderArtifact.artifactType
                        ) {
                            logger.debug(
                                "Changing artifact type to",
                                blockName
                            );
                            selectedOrderArtifact.artifactType = blockName;
                            updateArtifactTypeFn(selectedOrderArtifact);
                        }
                        delay(500).then(() => {
                            logger.debug("Checking image quality");
                            checkImageQuality();
                        });
                    }
                });

                creativeEngine.editor.addUndoStep();
            }
            setSelectedOrderArtifact(null);
        }
        //disable exhaustive deps, they are triggering the use effect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrderArtifact]);

    //--------------------------------------------------------------------------------
    //When the generated qr code changes - add it to the scene
    //--------------------------------------------------------------------------------
    useEffect(() => {
        logger.debug("Trying to update qr block");
        if (creativeEngine) {
            //If we have an image - set it on the ui
            if (generatedQrCodeArtifact && generatedQrCodeArtifact.blobUri) {
                logger.debug("Updating QR block with qr image", generatedQrCodeArtifact.blobUri);
                var rnd = Math.random();
               
                if(currentlyLoadedEditor === "Card" && currentPageBlockId && order?.product === ProductTypes.HandwrittenBiFoldCard) {
                    createQRCode(creativeEngine, currentPageBlockId)
                    const pages = creativeEngine.scene.getPages();
                    const backPage = pages[1];
                    setOrder({...order, doubleSided: backPage === currentPageBlockId ?  YesNo.No : YesNo.Yes})
                }

                setBlockToImageByName(
                    creativeEngine,
                    SceneBlockNames.QRCode,
                    generatedQrCodeArtifact.blobUri + "?" + rnd
                );
                showBlockByName(creativeEngine, SceneBlockNames.QRCode, true);
                //Otherwise set it to the placeholder

                const qrCodes = creativeEngine.block.findByName(SceneBlockNames.QRCode)
                if(qrCodes?.length) {
                    creativeEngine.block.setScopeEnabled(qrCodes[0], "layer/crop", false)
                    if(orderProductIsPrintedCard(order.product)) {
                        creativeEngine.block.sendToBack(qrCodes[0])
                    } else {
                        creativeEngine.block.setAlwaysOnTop(qrCodes[0], true)
                        if (order?.product !== ProductTypes.HandwrittenBiFoldCard) {
                            creativeEngine.block.sendBackward(qrCodes[0])
                        }
                    }
                }
            } else {
                logger.debug("Removing qr image from block");
                showBlockByName(creativeEngine, SceneBlockNames.QRCode, false);
                // setOrder({...order, 
                //     qrAngle: null,
                //     qrCodeHeight: null,
                //     qrCodeSide: null,
                //     qrCodeWidth: null,
                //     qrCodeX: null,
                //     qrCodeY: null,
                //     qrUrl: null,
                //     useQr: false
                //  })
                if(order?.product === ProductTypes.HandwrittenBiFoldCard) removeBlockByName(creativeEngine, SceneBlockNames.QRCode)
            }
        }
        //The dependencies outside of the generqtedQrCodeArtifact are triggering this - so I'm removing them
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generatedQrCodeArtifact]);

    //--------------------------------------------------------------------------------
    //CALLBACK REFS
    //--------------------------------------------------------------------------------
    editorUpdateCallbackRef.current = () => {
        updateOrderTextFromCreativeEngine();
        const newEditMode = creativeEngine.editor.getEditMode();
        if (newEditMode !== editMode) {
            logger.debug("Editor edit mode changed", newEditMode);
            setEditMode(newEditMode);
        }

        if(newEditMode === "Text"){
            setTextSelectRange(creativeEngine.block.getTextCursorRange())
        }
    };

    const uploadNewImageArtifact = async (selectedBlockId?: any) => {
        var files = await uploadFile({
            supportedMimeTypes: SUPPORTED_IMAGE_MIME_TYPES,
            multiple: false,
        });

        if(!files || !files.length){
            return
        }

        const maxSizeBytes = 25 * 1024 * 1024; // 25 MB in bytes
        if(files[0].size >= maxSizeBytes) {
            setHasInvalidImageSize(true)
            return
        }

        setHasInvalidImageSize(false)
        setShowLoader(true)

        logger.debug("uploading file as image artifact", files);
        //This will upload an artifact and once it's done, set the selected artifact to the one that was just uploaded
        //Which in its turn will trigger an useEffect in EditorContext.tsx which will update the selected image in the editor
        let artifact = await uploadOrderArtifactFn(files[0], OrderArtifactType.Image);

        let blockToPlaceImage

        if(selectedBlockId && creativeEngine.block.getType(selectedBlockId) === "//ly.img.ubq/graphic"){
            blockToPlaceImage = selectedBlockId
        }
        else{
            blockToPlaceImage = addImage(creativeEngine, currentPageBlockId)
        }

        setBlockToImageById(
            creativeEngine,
            blockToPlaceImage,
            artifact.blobUri
        );

        await setImageBlockDimensions(
            blockToPlaceImage,
            OrderArtifactType.Image
        );

        setShowLoader(false)
    }

    const showUploadFileIfPlaceholderBlockWasCalled = async (blockId: DesignBlockId) => {
        if (blockId) {
            if (creativeEngine.block.getType(blockId) === "//ly.img.ubq/graphic") {
                // Stupid fix for a bug where stretching an image would make it disappear
                creativeEngine.block.resetCrop(blockId);

                let imageFill = creativeEngine.block.getFill(blockId)
                let imgUrl = creativeEngine.block.getString(imageFill, "fill/image/imageFileURI")

                /*
                if (imgUrl === SceneDefaultArtifacts.Rear ||
                    imgUrl === SceneDefaultArtifacts.Front ||
                    imgUrl === SceneDefaultArtifacts.PrintedPostCardFrontImage
                    )
                */

                if (imgUrl.includes('/scenes/')) {
                    logger.debug("Image is placeholder - showing file upload");

                    // This fixes a bug where when you click on a placeholder and upload an image, the image drags afterwards
                    setTimeout(async function () {
                        await uploadNewImageArtifact(blockId)

                        if(order?.product === ProductTypes.HandwrittenBiFoldCard) checkForBlocksOnGivenSide(0)
                        else checkForBlocksOnGivenSide(1)
                    }, 200)
                }
            }
        }
    }

    const checkOutsideFrontImage = () => {
        if(!creativeEngine) return;
        logger.debug("Validating Outside Front Image")
        const pages = creativeEngine.scene.getPages();
        const backPage = pages[1];
        const imagePlaceholders = creativeEngine.block.findByKind("image/placeholder");
        const images = creativeEngine.block.findByKind("image");
        const allImageIds = imagePlaceholders.concat(images);

        for(const blockId of allImageIds){
            const parentId = creativeEngine.block.getParent(blockId)
            if(parentId === backPage){
                let imageFill = creativeEngine.block.getFill(blockId)
                let imageUrl = creativeEngine.block.getString(imageFill, "fill/image/imageFileURI")
                if (imageUrl && imageUrl !== SceneDefaultArtifacts.OutsideFront) {
                    setHasMissingOutsideFrontImage(false)
                    logger.debug("Finish with no errors: Validating Outside Front Image")
                    return
                }
            }
        }
        setHasMissingOutsideFrontImage(true)
        logger.debug("Finish with missing image error: Validating Outside Front Image")
    }

    const checkCardRearImage = () => {
        logger.debug("Validating Printed Postcard Rear Image")
        const pages = creativeEngine.scene.getPages();
        const backPage = pages[1]
        let allImageIds = creativeEngine.block.findByKind("image");


        for(let blockId of allImageIds){
            let parentId = creativeEngine.block.getParent(blockId)

            if(parentId === backPage){
                let imageFill = creativeEngine.block.getFill(blockId)
                let imageUrl = creativeEngine.block.getString(imageFill, "fill/image/imageFileURI")

                if (imageUrl && imageUrl !== SceneDefaultArtifacts.Rear) {
                    setHasMissingRearImage(false)
                    logger.debug("Finish with no errors: Validating Printed Postcard Rear Image")
                    return
                }
            }
        }
        setHasMissingRearImage(true)
        logger.debug("Finish with missing image error: Validating Printed Postcard Rear Image")
    }

    engineEventCallbackRef.current = (events) => {
        if (events.length > 0) {
            checkIfImageIsBetweenSafeZoneAndBleedZone()
            checkImageQuality();
            if (orderProductIsPrintedCard(order.product)) checkCardRearImage()
            else if(order?.product === ProductTypes.HandwrittenBiFoldCard) checkOutsideFrontImage()
        }
    }

    function checkForHandwrittenTextOutOfBounds() {
        const checkTextOutOfBounds = (blockName: string, text: string | undefined) => {
            let arr = creativeEngine.block.findByName(blockName);
    
            if (!order || !text || !arr || !arr.length) {
                return false;
            }
    
            let handwrittenBlockId = arr[0];
            return creativeEngine.block.getBool(handwrittenBlockId, "text/hasClippedLines");
        };
    
        const hasTextOutOfBounds = checkTextOutOfBounds(SceneBlockNames.HandwrittenText, order?.text) ||
                                   checkTextOutOfBounds(SceneBlockNames.HandwrittenText2, order?.text2);
    
        setHasTextOutsideOfHandwrittenTextArea(hasTextOutOfBounds);
    }    

    useEffect(() => {
        if (!creativeEngine) {
            setHasTextOutsideOfHandwrittenTextArea(false);
            return;
        }
    
        checkForHandwrittenTextOutOfBounds();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [creativeEngine, order?.text, order?.text2]);
   

    // Show or hide bleed lines and text when the selection or changes
    useEffect(() => {
        /*if(!creativeEngine || !currentPageBlockId){
            return
        }

        let show = false

        let pages = creativeEngine.scene.getPages()

        let isOnBackPage = currentPageBlockId === pages[1]

        if(isOnBackPage || (selectedBlocks && selectedBlocks.length && (selectedBlocks[0].name === SceneBlockNames.Image || selectedBlocks[0].name === SceneBlockNames.RearImage || selectedBlocks[0].name === SceneBlockNames.FrontLogo || selectedBlocks[0].name === SceneBlockNames.QRCode || selectedBlocks[0].name === SceneBlockNames.PrintedText))){
            show = true
        }

        let blocksToHide = []

        blocksToHide.push(...creativeEngine.block.findByName(SceneBlockNames.FullBleedSmallText))
        blocksToHide.push(...creativeEngine.block.findByName(SceneBlockNames.SafeZoneBigText))
        blocksToHide.push(...creativeEngine.block.findByName(SceneBlockNames.SafeZoneSmallText))
        blocksToHide.push(...creativeEngine.block.findByName(SceneBlockNames.SafeZone))

        for(let block of blocksToHide){
            creativeEngine.block.setVisible(block, show)
        }*/
    }, [creativeEngine, selectedBlocks, currentPageBlockId]);

    const setKeepOutWhite = async () => {
        if(orderProductIsPrintedCard(order?.product)) {
            await setWhiteOutBlockColor("white")
        } else if(order?.product === ProductTypes.HandwrittenPostCardA8) {
            await setKeepOutBlockColor("white")
        } else if( order?.product === ProductTypes.HandwrittenBiFoldCard) {
            await setKeepOutBlockColor("white", SceneBlockNames.OutsideKeepOutArea)
        }
    }
    
    useEffect(() => {
        if(orderProductIsPrintedCard(order?.product)) {
            setWhiteOutBlockColor(selectedBlocks?.length ? "red" : "white")
        } else if(order?.product === ProductTypes.HandwrittenPostCardA8) {
            setKeepOutBlockColor(selectedBlocks?.length ? "red" : "white")
        } else if( order?.product === ProductTypes.HandwrittenBiFoldCard) {
            setKeepOutBlockColor(selectedBlocks?.length ? "red" : "white", SceneBlockNames.OutsideKeepOutArea)
        }
    }, [creativeEngine?.block, order, selectedBlocks?.length, setKeepOutBlockColor, setWhiteOutBlockColor])

    function handleOnSelectionChange(){
        const newSelectedBlocks = creativeEngine.block
            .findAllSelected()
            .map((id) => ({
                id,
                type: creativeEngine.block.getType(id),
                name: creativeEngine.block.getName(id),
            }));

        if(!newSelectedBlocks || !newSelectedBlocks.length){
            setSelectedBlocks(null)
            return
        }

        if (!isEqual(newSelectedBlocks, selectedBlocks)) {
            logger.debug(
                "Selected blocks changed",
                selectedBlocks,
                newSelectedBlocks
            );

            setSelectedBlocks(newSelectedBlocks);

            showUploadFileIfPlaceholderBlockWasCalled(newSelectedBlocks[0].id)
        }

        const newCanUndo = creativeEngine.editor.canUndo();
        if (newCanUndo !== canUndo) {
            setCanUndo(newCanUndo);
        }
        // Extract and store canRedo
        const newCanRedo = creativeEngine.editor.canRedo();
        if (newCanRedo !== canRedo) {
            setCanRedo(newCanRedo);
        }
    }

    const value = {
        canRedo,
        canUndo,
        creativeEngine,
        currentPageBlockId,
        currentStep,
        currentlyLoadedEditor,
        editMode,
        engineIsLoaded,
        loadCurrentScene,
        resetEditor,
        saveCurrentScene,
        sceneIsLoaded,
        selectedBlocks,
        setSelectedBlocks,
        setCurrentlyLoadedEditor,
        setEnvelopeSceneString,
        setCardSceneString,
        setCurrentStep,
        setEditorDoesNotUpdateOrder,
        uploadNewImageArtifact,
        resetProduct,
        generateQrCodeFn,
        removeQrCodeFn,
        generatedQrCodeArtifact,
        orderArtifacts,
        addOrReplaceImageToScene,
        updateOrderTextFromCreativeEngine,
        checkImageQuality,
        checkForBlocksOnGivenSide,
        resizeImageAsLogoSize,
        selectedFont,
        glyphsForCurrentlySelectedFont,
        fonts,
        textSelectRange,
        setImageBlockDimensions,
        isThereImageBetweenSafeZoneAndBleedZone,
        processOrderArtifact,
        lowestFoundResolution,
        isRearImageLowResolution,
        hasInvalidMailMergePlaceholders,
        hasInvalidImageSize,
        hasTextOutsideOfHandwrittenTextArea,
        hasMissingRearImage,
        hasMissingOutsideFrontImage,
        checkForHandwrittenTextOutOfBounds,
        order,
        setKeepOutWhite
    };
    return (
        <EditorContext.Provider value={value}>
            {children}
        </EditorContext.Provider>
    );
}

export const useEditor = () => {
    const context = useContext(EditorContext);
    if (context === undefined) {
        throw new Error("useEditor must be used within a EditorProvider");
    }
    return context;
};

export {EditorProvider};