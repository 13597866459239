import { Divider, Grid, Typography } from '@mui/material';
import { formatNumber, formatPrice } from 'helpers/formatters';
import { ICheckout } from 'models/checkout';

interface CheckoutSummaryProps {
  checkout: ICheckout;
}

export default function CheckoutSummary({ checkout }: CheckoutSummaryProps) {
  const itemsLength = checkout.lineItems.length;
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h5">Product</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="h5">Cost</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="h5" textAlign={'center'}>
            Qty
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5" textAlign={'end'}>
            Amount
          </Typography>
        </Grid>
      </Grid>
      {checkout.lineItems.map((lineItem, index) => (
        <Grid container key={lineItem.name}>
          <Grid item xs={6}>
            {lineItem.name}
          </Grid>
          <Grid item xs>
            {formatPrice(lineItem.rate)}
          </Grid>
          <Grid item xs textAlign={'center'} mt={itemsLength % 2 === 0 ? '-12px' : 0}>
            {Math.floor(itemsLength / 2) === index && `x ${formatNumber(lineItem.quantity)}`}
          </Grid>
          <Grid item xs={3} textAlign={'end'}>
            {formatPrice(lineItem.total)}
          </Grid>
        </Grid>
      ))}
      <Divider light />
      {checkout.balance !== checkout.totalCharges && (
        <>
          <Grid container>
            <Grid item xs fontWeight={'bold'}>
              Sub Total
            </Grid>
            <Grid item xs={3} textAlign={'end'} fontWeight={'bold'}>
              {formatPrice(checkout.totalCharges)}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              Credits Used
            </Grid>
            <Grid item xs={3} textAlign={'end'}>
              {formatPrice(checkout.balance - checkout.totalCharges)}
            </Grid>
          </Grid>
        </>
      )}
      <Grid container>
        <Grid item xs>
          <Typography variant="h4" fontSize={18} color="#DA4819">
            Total Due
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign={'end'}>
          <Typography variant="h4" fontSize={18} color="#DA4819">
            {formatPrice(checkout.balance)}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
