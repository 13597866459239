import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Check4Icon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.745 9.41566 20.7162 6.93859 18.8888 5.11118C17.0614 3.28378 14.5843 2.25496 12 2.25ZM16.6406 10.2938L11.1469 15.5438C11.0049 15.6774 10.8169 15.7512 10.6219 15.75C10.5266 15.7514 10.4319 15.7338 10.3434 15.6984C10.2549 15.663 10.1743 15.6105 10.1063 15.5438L7.35938 12.9188C7.28319 12.8523 7.22123 12.7711 7.17722 12.6801C7.13321 12.589 7.10806 12.49 7.10328 12.389C7.0985 12.2881 7.11419 12.1871 7.14941 12.0924C7.18463 11.9976 7.23865 11.9109 7.30822 11.8375C7.3778 11.7642 7.46149 11.7056 7.55426 11.6654C7.64703 11.6252 7.74698 11.6042 7.84809 11.6036C7.94919 11.603 8.04938 11.6229 8.14261 11.662C8.23585 11.7011 8.32021 11.7587 8.39063 11.8312L10.6219 13.9594L15.6094 9.20625C15.7552 9.07902 15.9446 9.01309 16.1379 9.02223C16.3312 9.03138 16.5135 9.1149 16.6467 9.25533C16.7798 9.39576 16.8536 9.58222 16.8524 9.77575C16.8513 9.96928 16.7754 10.1549 16.6406 10.2938Z" fill="#F05A2A"/>
            </svg>
        </SvgIcon>
    )
}

export default Check4Icon;
