import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT from "../endpoint";

function SubscriptionService(){
    const {execute} = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/subscriptions`

    function createSubscriptionLink(id: number){
        return execute({
            endpoint: `${API_PATH}/${id}`,
            method: "GET"
        })
    }

    function getSubscriptionPricing(){
        return execute({
            endpoint: `${API_PATH}/pricing`,
            method: "GET"
        })
    }

    function getSubscriptionBonuses(){
        return execute({
            endpoint: `${API_PATH}/refer`,
            method: "GET"
        })
    }


    return {createSubscriptionLink, getSubscriptionPricing, getSubscriptionBonuses}
}

export default SubscriptionService