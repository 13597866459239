import { useEditor } from 'context/editor-context';
import { ProductTypes } from "models/enums/ProductTypes";
import { setTextByBlockName } from "helpers/ImgLyHelper"
import { addGlyphs } from "helpers/glyphHelper"
import { Order } from 'models/order';

// TODO: Pass the rest of page-settings-context logic related to the envelope
// Doing this we will be able to isolate the responsibilities and make it easier to update in the future
const useReturnAddressHandler = () => {
  const { creativeEngine, sceneIsLoaded, glyphsForCurrentlySelectedFont } = useEditor();

  const setReturnAddressScene = (order: Partial<Order>) => {
    if (!sceneIsLoaded || !creativeEngine || !order) return

    let template = "{{FIRST NAME}}{{LAST NAME}}{{ORGANIZATION}}\n{{ADDRESS 1}}{{ADDRESS 2}}\n{{CITY}}{{STATE}}{{ZIP}}"
    let organizationOnItsOwnLinePotentially = order.returnOrganization ? order.returnOrganization : "";
    //If we have a return first or last name, org needs to be on its own line
    if (order.returnFirstName || order.returnLastName) {
      if (organizationOnItsOwnLinePotentially) {
        organizationOnItsOwnLinePotentially = "\n" + order.returnOrganization;
      }
    }
    if (!order.returnFirstName && !order.returnLastName && !order.returnOrganization) {
      template = template.replaceAll("{{FIRST NAME}}{{LAST NAME}}{{ORGANIZATION}}\n", "")
    }

    const hideGlyphVariation = order.product !== ProductTypes.LargeHandwrittenCardA8 && order.product !== ProductTypes.HandwrittenBiFoldCard
    const text = template
      .replaceAll("{{FIRST NAME}}", order.returnFirstName ? addGlyphs(order.returnFirstName, glyphsForCurrentlySelectedFont, hideGlyphVariation) + ' ' : "")
      .replaceAll("{{LAST NAME}}", order.returnLastName ? addGlyphs(order.returnLastName, glyphsForCurrentlySelectedFont, hideGlyphVariation) + ' ' : "")
      .replaceAll("{{ORGANIZATION}}", addGlyphs(organizationOnItsOwnLinePotentially, glyphsForCurrentlySelectedFont, hideGlyphVariation))
      .replaceAll("{{ADDRESS 1}}", order.returnAddress1 ? addGlyphs(order.returnAddress1, glyphsForCurrentlySelectedFont, hideGlyphVariation) : "")
      .replaceAll("{{ADDRESS 2}}", order.returnAddress2 ? ", " + addGlyphs(order.returnAddress2, glyphsForCurrentlySelectedFont, hideGlyphVariation) : "")
      .replaceAll("{{CITY}}", order.returnCity ? addGlyphs(order.returnCity, glyphsForCurrentlySelectedFont, hideGlyphVariation) + ', ' : "")
      .replaceAll("{{STATE}}", order.returnState ? addGlyphs(order.returnState, glyphsForCurrentlySelectedFont, hideGlyphVariation) + ' ' : "")
      .replaceAll("{{ZIP}}", order.returnZip ? addGlyphs(order.returnZip, glyphsForCurrentlySelectedFont, hideGlyphVariation) : "")

    setTextByBlockName(creativeEngine, 'ReturnAddress', text);
  }

  return { setReturnAddressScene }
};

export default useReturnAddressHandler