import {Dialog, DialogContent, Stack, TextField} from "@mui/material";
import MDBox from "material-ui/components/MDBox";
import MDTypography from "material-ui/components/MDTypography";
import MDButton from "material-ui/components/MDButton";
import { useState} from "react";
import ProfileRecipientService from "services/profile-recipient";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {GridSearchParams} from "models/gridSearchParams";
import {formatNumber} from "../../../../helpers/formatters";
import { useGlobal } from "context/global-context";

interface BulkUpdateRecipientsModalProps{
    show: boolean;
    setShow: Function;
    selectedRecipients: number[];
    lastSearchParams: GridSearchParams;
    totalCount: number;
  }  

function BulkUpdateRecipientsModal({show, setShow, selectedRecipients, lastSearchParams, totalCount }: BulkUpdateRecipientsModalProps){
    const {setShowLoader} = useGlobal()
    const queryClient = useQueryClient()

    const bulkUpdateProfileRecipientsMutation = useMutation({
        mutationFn: () => {
            let postData = {
                profileRecipientIds: selectedRecipients,
                organizationName: "",
                radiusPinAddress: "",
                radiusPinStreet: "",
                lastSearchParams: lastSearchParams
            }

            if(organizationName){
                postData.organizationName = organizationName
            }
            if(radiusPinAddress){
                postData.radiusPinAddress = radiusPinAddress
            }
            if(radiusPinStreet){
                postData.radiusPinStreet = radiusPinStreet
            }

            return bulkUpdateProfileRecipients(postData)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: (data: any, variables: any, context: any) => {
            queryClient.invalidateQueries({queryKey: ["profileRecipients"]})
            setShow(false)
            setShowLoader(false)
        }
    })

    const {bulkUpdateProfileRecipients} = ProfileRecipientService()

    const [organizationName, setOrganizationName] = useState<string>("")
    const [radiusPinAddress, setRadiusPinAddress] = useState<string>("")
    const [radiusPinStreet, setRadiusPinStreet] = useState<string>("")

    return (
        <Dialog open={show} onClose={() => setShow(false)} maxWidth={false}>
            <DialogContent sx={{width: "500px"}}>
                <MDBox>
                    {/* <MDTypography mb={"30px"}>
                        Bulk Update {selectedRecipients.length} Recipient{selectedRecipients.length > 1? 's' : ''}
                    </MDTypography> */}
                   <MDTypography mb={"30px"}>
                    Bulk Update {
                        selectedRecipients.length === lastSearchParams.pageSize
                        ? `${formatNumber(totalCount)} Recipient${totalCount > 1 ? 's' : ''}`
                        : `${formatNumber(selectedRecipients.length)} Recipient${selectedRecipients.length > 1 ? 's' : ''}`
                    }
                    </MDTypography>


                    <MDBox>
                        <MDBox>
                            <TextField
                                sx={{ width: "100%" }}
                                type="text"
                                label="Organization Name"
                                InputLabelProps={{ shrink: true }}
                                value={organizationName}
                                onChange={(e) => {
                                    setOrganizationName(e.target.value)
                                }}
                            />
                        </MDBox>

                        <MDBox mt={3}>
                            <MDBox>
                                <TextField
                                    sx={{ width: "100%" }}
                                    type="text"
                                    label="Radius Pin Address"
                                    InputLabelProps={{ shrink: true }}
                                    value={radiusPinAddress}
                                    onChange={(e) => {
                                        setRadiusPinAddress(e.target.value)
                                    }}
                                />
                            </MDBox>

                            <MDBox mt={3}>
                                <TextField
                                    sx={{ width: "100%" }}
                                    type="text"
                                    label="Radius Pin Street"
                                    InputLabelProps={{ shrink: true }}
                                    value={radiusPinStreet}
                                    onChange={(e) => {
                                        setRadiusPinStreet(e.target.value)
                                    }}
                                />
                            </MDBox>
                        </MDBox>

                        <MDBox mt={3}>
                            <Stack direction="row" justifyContent="right" spacing={1}>
                                <MDButton
                                    color="light"
                                    circular={true}
                                    onClick={() => setShow(false)}
                                >
                                    Cancel
                                </MDButton>

                                <MDButton
                                    circular={true}
                                    onClick={() => {
                                        bulkUpdateProfileRecipientsMutation.mutate({})
                                    }}
                                >
                                    Update
                                </MDButton>
                            </Stack>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </DialogContent>
        </Dialog>
    )
}

export default BulkUpdateRecipientsModal