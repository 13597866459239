import { useEffect, useState } from "react"
import { Box, IconButton, Tooltip, Typography } from "@mui/material"
import { TooltipTutorialtype } from "./tooltip-tutorial.util"
import CloseIcon from "assets/icons/close"

interface TooltipTutorialProps {
  children: JSX.Element
  title?: string
  description?: string
  width?: number
  tutorialName: TooltipTutorialtype
  keepTooltip?: boolean
}

const TooltipTutorial = ({ children, title, description, width = 220, tutorialName, keepTooltip }: TooltipTutorialProps) => {
  const [sawTutorial, setSawTutorial] = useState<boolean>(false)
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  useEffect(() => {
    setSawTutorial(Boolean(localStorage.getItem(tutorialName)))
  }, [tutorialName])

  const handleTutorialClose = () => {
    localStorage.setItem(tutorialName, 'true')
    setSawTutorial(true)
  }

  return (
    <Tooltip
      open={!sawTutorial || showTooltip}
      onOpen={() => keepTooltip ? setShowTooltip(true) : null}
      onClose={() => keepTooltip ? setShowTooltip(false) : null}
      componentsProps={{ tooltip: { sx: { width, maxWidth: "unset" } } }}
      title={
        <Box color={"whitesmoke"} pb={title && description ? 1 : 0}> 
          <Box display={"flex"} justifyContent={sawTutorial ? "center" : "space-between"} py={1}>
            <Typography variant="h5" color={"inherit"} maxWidth={width - 40}>
              {title}
            </Typography>
            {!title ? description : null}
            {!sawTutorial && (
              <IconButton color="inherit" sx={{ float: "right" }} onClick={handleTutorialClose}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
          {title ? description : null}
        </Box>
      }
    >
      {children}
    </Tooltip>
  )
}

export default TooltipTutorial