import {CircularProgress, Dialog, DialogContent, DialogTitle, Stack} from "@mui/material";
import MDBox from "material-ui/components/MDBox";
import MDButton from "material-ui/components/MDButton";
import {useEditor} from "context/editor-context";

interface QRCodeModalProps {
    show: boolean
    setShow: Function
    removeQrCode: Function
}

function QRCodeModal({show, setShow, removeQrCode}: QRCodeModalProps) {
    const {
        generatedQrCodeArtifact
    } = useEditor()

    function handleRemoveQrCode(){
        setShow(false)

        removeQrCode()
    }

    return <Dialog open={show} onClose={() => setShow(false)}>
        <DialogContent>
            <DialogTitle>
                Please scan the QR code to confirm that it works as you expect
            </DialogTitle>

            {generatedQrCodeArtifact?
                <MDBox>
                    <MDBox textAlign={"center"}>
                        <img src={generatedQrCodeArtifact.blobUri} width={"250"} alt={""}/>
                    </MDBox>

                    <Stack direction="row" spacing={1} marginTop={2} justifyContent="flex-end">
                        <MDButton circular={true} color="light" onClick={handleRemoveQrCode}>
                            Remove
                        </MDButton>
                        <MDButton circular={true} color="primary" onClick={() => setShow(false)}>
                            Confirm
                        </MDButton>
                    </Stack>
                </MDBox>
                :
                <MDBox mt={5} display={"flex"} justifyContent={"center"}>
                    <CircularProgress color="primary" />
                </MDBox>
            }

        </DialogContent>
    </Dialog>
}

export default QRCodeModal