import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const HomeDollarIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.8333 8.83333L10 3L2.5 10.5H4.16667V16.3333C4.16667 16.7754 4.34226 17.1993 4.65482 17.5118C4.96738 17.8244 5.39131 18 5.83333 18H10.8333" stroke="#130B46" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.5 18.0007V13.0007C7.5 12.5586 7.6756 12.1347 7.98816 11.8221C8.30072 11.5096 8.72464 11.334 9.16667 11.334H10.8333C11.1558 11.334 11.4567 11.4257 11.7117 11.584M17.5 13.0007H15.4167C15.0851 13.0007 14.7672 13.1323 14.5328 13.3668C14.2984 13.6012 14.1667 13.9191 14.1667 14.2507C14.1667 14.5822 14.2984 14.9001 14.5328 15.1345C14.7672 15.369 15.0851 15.5007 15.4167 15.5007H16.25C16.5815 15.5007 16.8995 15.6323 17.1339 15.8668C17.3683 16.1012 17.5 16.4191 17.5 16.7507C17.5 17.0822 17.3683 17.4001 17.1339 17.6345C16.8995 17.869 16.5815 18.0007 16.25 18.0007H14.1667M15.8333 18.0007V18.834M15.8333 12.1673V13.0007" stroke="#130B46" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export default HomeDollarIcon;