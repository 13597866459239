import MDBox from "material-ui/components/MDBox";

interface ColorCircleProps{
    backgroundColor: string
    selected: boolean
    onClick: Function
}

function CheckIcon(){
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 8.5L7 12.5L13 3.5" stroke="#F3F5FB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}

function ColorCircle({backgroundColor, selected, onClick}: ColorCircleProps){
    return <MDBox style={{position: "relative", cursor: "pointer", borderRadius: "50%", width: "26px", height: "26px", backgroundColor: backgroundColor, display: "flex", alignItems: "center", justifyContent: "center"}} onClick={() => onClick()}>
        {selected?
            <CheckIcon/>
            :
            null
        }
    </MDBox>
}

export default ColorCircle