import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CardsToSendIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M18.4808 5.49609L12.5705 11.6109L6.66016 5.49609M18.4808 5.49609H6.66016M18.4808 5.49609V14.9463C18.4808 15.0937 18.4289 15.2351 18.3365 15.3394C18.2441 15.4436 18.1189 15.5022 17.9882 15.5022H7.15268C7.02206 15.5022 6.89678 15.4436 6.80441 15.3394C6.71205 15.2351 6.66016 15.0937 6.66016 14.9463V5.49609M11.4931 10.4991L6.81407 15.3424M18.3269 15.3424L13.6479 10.4991"
                    stroke="#130B46" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                    d="M1.51953 7.23438L4.76712 7.23438M1.51953 13.7542H4.76712M3.15564 11.5809L6.40323 11.5809M2.19983 9.40764L8.0378 9.40764"
                    stroke="#130B46" strokeWidth="1.4" strokeLinecap="round"/>
            </svg>
        </SvgIcon>
    )
}

export default CardsToSendIcon;