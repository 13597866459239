import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SidebarHomeIcon = (props: any) => {
    return (
        <SvgIcon {...props} viewBox={"0 0 32 32"}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.2934 5.12406C15.4809 4.93679 15.7351 4.8316 16.0001 4.8316C16.2651 4.8316 16.5193 4.93679 16.7068 5.12406L28.2934 16.7107C28.385 16.809 28.4954 16.8878 28.6181 16.9424C28.7407 16.9971 28.8731 17.0265 29.0074 17.0288C29.1417 17.0312 29.2751 17.0065 29.3996 16.9562C29.5241 16.9059 29.6372 16.8311 29.7322 16.7361C29.8271 16.6412 29.902 16.528 29.9523 16.4035C30.0026 16.279 30.0273 16.1456 30.0249 16.0114C30.0225 15.8771 29.9931 15.7447 29.9385 15.622C29.8838 15.4993 29.805 15.3889 29.7068 15.2974L18.1214 3.71072C17.8429 3.43214 17.5121 3.21116 17.1482 3.0604C16.7842 2.90963 16.3941 2.83203 16.0001 2.83203C15.6061 2.83203 15.216 2.90963 14.852 3.0604C14.4881 3.21116 14.1574 3.43214 13.8788 3.71072L2.29211 15.2974C2.11004 15.4861 2.00936 15.7387 2.01176 16.0009C2.01416 16.2631 2.11945 16.5139 2.30495 16.6992C2.49044 16.8845 2.74131 16.9896 3.0035 16.9917C3.2657 16.9939 3.51826 16.893 3.70677 16.7107L15.2934 5.12406Z" fill="white"/>
                <path d="M16 7.24681L26.8787 18.1255C26.9187 18.1655 26.9587 18.2028 27 18.2401V26.5041C27 27.8841 25.88 29.0041 24.5 29.0041H20C19.7348 29.0041 19.4804 28.8988 19.2929 28.7112C19.1054 28.5237 19 28.2694 19 28.0041V22.0041C19 21.7389 18.8946 21.4846 18.7071 21.297C18.5196 21.1095 18.2652 21.0041 18 21.0041H14C13.7348 21.0041 13.4804 21.1095 13.2929 21.297C13.1054 21.4846 13 21.7389 13 22.0041V28.0041C13 28.2694 12.8946 28.5237 12.7071 28.7112C12.5196 28.8988 12.2652 29.0041 12 29.0041H7.5C6.83696 29.0041 6.20107 28.7407 5.73223 28.2719C5.26339 27.8031 5 27.1672 5 26.5041V18.2401C5.04148 18.203 5.08194 18.1648 5.12133 18.1255L16 7.24414V7.24681Z" fill="white"/>
            </svg>
        </SvgIcon>
    )
}

export default SidebarHomeIcon;