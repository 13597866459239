import {styled, Theme} from "@mui/material/styles";
import {Radio} from "@mui/material";
import linearGradient from "../../theme/functions/linearGradient";

export default styled(Radio)(({ theme, ownerState }: { theme?: Theme | any; ownerState: any }) => {
    const {palette} = theme;
    const { color } = ownerState;
    const {white} = palette

    function whiteStyles(){
        return {
            ".MuiSvgIcon-root": {
                borderColor: "white !important"
            },

            "&:after": {
                backgroundImage: `${linearGradient(white.main, white.main)} !important`,
            }
        }
    }

    return {
        ...(color === "white" && whiteStyles()),
    };
});