import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT from "../endpoint";
import {Order} from "models/order";
import {HailTraceMap, ServiceAreaRecipientSearch} from "../../models/hail-trace/ServiceAreaRecipientSearch";

function AdminService(){
    const {execute} = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/adm`
    const DefaultNumberOfMaps = 30;

    function getOrder(id: number){
        return execute({
            endpoint: `${API_PATH}/order`,
            method: "GET"
        })
    }

    function postOrder(order: Order){
        return execute({
            endpoint: `${API_PATH}/order`,
            method: "POST",
            data: order
        })
    }

    function getOrders(){
        return execute({
            endpoint: `${API_PATH}/orders`,
            method: "GET"
        })
    }

    function bulkUpdateOrdersToMailed(orderIds: number[]){
        return execute({
            endpoint: `${API_PATH}/mark_orders_as_mailed`,
            method: "POST",
            data: orderIds
        })
    }

    function getHailTraceMaps(){
        return execute({
            endpoint: `${API_PATH}/hailtracemaps/${DefaultNumberOfMaps}`,
            method: "GET"
        })
    }

    function getHailTraceRecipientSearch(id: number){
        return execute({
            endpoint: `${API_PATH}/hailtracerecipientsearches/${id}`,
            method: "GET"
        })
    }

    function hailTraceRecipientSearchCalculateTargets(serviceArea: ServiceAreaRecipientSearch){
        return execute({
            endpoint: `${API_PATH}/hailtracerecipientsearches:prepare`,
            method: "PUT",
            data: serviceArea
        })
    }

    function hailTraceRecipientSearchUpdatePricePerCard(serviceArea: ServiceAreaRecipientSearch){
        return execute({
            endpoint: `${API_PATH}/hailtracerecipientsearches:recalculateprice`,
            method: "PUT",
            data: serviceArea
        })
    }

    function approveHailTraceRecipientSearch(serviceArea: ServiceAreaRecipientSearch){
        return execute({
            endpoint: `${API_PATH}/hailtracerecipientsearches`,
            method: "POST",
            data: serviceArea
        })
    }

    function refreshHailTraceMap(hailTraceMap: HailTraceMap){
        return execute({
            endpoint: `${API_PATH}/hailtracemaps:intersect`,
            method: "PUT",
            data: hailTraceMap
        })
    }

    return {getOrder, getOrders, bulkUpdateOrdersToMailed, postOrder, getHailTraceMaps, getHailTraceRecipientSearch, approveHailTraceRecipientSearch, hailTraceRecipientSearchCalculateTargets, hailTraceRecipientSearchUpdatePricePerCard, refreshHailTraceMap}
}

export default AdminService