import { Formik, Form, Field, FieldProps } from "formik"
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Autocomplete, Box, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Stack, TextField } from "@mui/material"
import MDButton from "material-ui/components/MDButton"
import { States } from "models/enums/States"
import MDInput from "material-ui/components/MDInput";
import { ReturnAddress } from "models/returnAddress";
import { ReturnAddressValidation } from "models/modelValidations/returnAddress";
import ProfileReturnAddress from "services/profile-return-address";
import FormikErrorMessage from "components/formik-error-message";
import { useGlobal } from "context/global-context";

interface ReturnAddressModalProps {
  show: boolean
  setShow: Function
  returnAddress: ReturnAddress | null
  isFirst: boolean
}

const ReturnAddressModal = ({ show, setShow, returnAddress, isFirst }: ReturnAddressModalProps) => {
  const queryClient = useQueryClient()
  const { setShowLoader } = useGlobal()
  const { postReturnAddress, deleteReturnAddress } = ProfileReturnAddress()

  const onMutationSuccess = () => {
    setShow(false)
    setShowLoader(false)
    queryClient.invalidateQueries({ queryKey: ["returnAddresses"] })
  }

  const { mutate: upsertReturnAddress, isPending } = useMutation({
    mutationFn: (returnAddress: ReturnAddress) => postReturnAddress(returnAddress),
    onMutate: () => setShowLoader(true),
    onSuccess: onMutationSuccess
  })

  const { mutate: removeReturnAddress } = useMutation({
    mutationFn: () => deleteReturnAddress(returnAddress.id),
    onMutate: () => setShowLoader(true),
    onSuccess: onMutationSuccess
  })

  const defaultReturnAddress = returnAddress || {
    id: 0,
    isDefault: false,
    name: "",
    firstName: "",
    lastName: "",
    toOrganization: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: ""
  }

  return (
    <Dialog open={show} onClose={() => setShow(false)} key={returnAddress?.id}>
      <DialogContent>
        <DialogTitle>
          {returnAddress ? "Edit" : "Add"} return address
        </DialogTitle>
        <Formik
          initialValues={defaultReturnAddress}
          validationSchema={ReturnAddressValidation}
          onSubmit={(values) => upsertReturnAddress(values)}
        >
          <Form>
            <Stack gap={2}>
              <Field name={"name"}>
                {({ field, meta }: FieldProps) => (
                  <>
                    <MDInput
                      fullWidth
                      type="text"
                      label="Name your return address"
                      InputLabelProps={{ shrink: true }}
                      {...field}
                    />
                    <FormikErrorMessage meta={meta} />
                  </>
                )}
              </Field>
              <Stack direction={"row"} gap={2}>
                <Field name={"firstName"}>
                  {({ field, meta }: FieldProps) => (
                    <>
                      <MDInput
                        fullWidth
                        type="text"
                        label="First Name"
                        InputLabelProps={{ shrink: true }}
                        {...field}
                      />
                      <FormikErrorMessage meta={meta} />
                    </>
                  )}
                </Field>
                <Field name={"lastName"}>
                  {({ field, meta }: FieldProps) => (
                    <>
                      <MDInput
                        fullWidth
                        type="text"
                        label="Last Name"
                        InputLabelProps={{ shrink: true }}
                        {...field}
                      />
                      <FormikErrorMessage meta={meta} />
                    </>
                  )}
                </Field>
              </Stack>
              <Field name={"toOrganization"}>
                {({ field, meta }: FieldProps) => (
                  <>
                    <MDInput
                      fullWidth
                      type="text"
                      label="Organization"
                      InputLabelProps={{ shrink: true }}
                      {...field}
                    />
                    <FormikErrorMessage meta={meta} />
                  </>
                )}
              </Field>
              <Field name={"address1"}>
                {({ field, meta }: FieldProps) => (
                  <>
                    <MDInput
                      fullWidth
                      type="text"
                      label="Address 1"
                      InputLabelProps={{ shrink: true }}
                      {...field}
                    />
                    <FormikErrorMessage meta={meta} />
                  </>
                )}
              </Field>
              <Field name={"address2"}>
                {({ field, meta }: FieldProps) => (
                  <>
                    <MDInput
                      fullWidth
                      type="text"
                      label="Address 2"
                      InputLabelProps={{ shrink: true }}
                      {...field}
                    />
                    <FormikErrorMessage meta={meta} />
                  </>
                )}
              </Field>
              <Stack direction={"row"} gap={2}>
                <Field name={"city"}>
                  {({ field, meta }: FieldProps) => (
                    <>
                      <MDInput
                        fullWidth
                        type="text"
                        label="City"
                        InputLabelProps={{ shrink: true }}
                        {...field}
                      />
                      <FormikErrorMessage meta={meta} />
                    </>
                  )}
                </Field>
                <Field name={"state"}>
                  {({ field, form, meta }: FieldProps) => {
                    return (
                      <>
                        <Autocomplete
                          options={Object.keys(States)}
                          value={field.value}
                          onChange={(_, value) => form.setFieldValue("state", value)}
                          onInputChange={(_, value) => {
                            if (!returnAddress && States[value]) form.setFieldValue("state", value)
                          }}
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              label={"State"}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                        <FormikErrorMessage meta={meta} />
                      </>
                    )
                  }}
                </Field>
                <Field name={"zip"}>
                  {({ field, meta }: FieldProps) => (
                    <>
                      <MDInput
                        fullWidth
                        type="text"
                        label={"Zip"}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 5 }}
                        {...field}
                      />
                      <FormikErrorMessage meta={meta} />
                    </>
                  )}
                </Field>
              </Stack>

              <Field name={"isDefault"}>
                {({ field }: FieldProps) => (
                  <Box width={"fit-content"}>
                    <FormControlLabel
                      disabled={isFirst}
                      sx={{ opacity: isFirst ? 0.6 : 1}}
                      control={<Checkbox {...field} checked={isFirst ? true : field.value} />}
                      label="Mark as default address"
                    />
                  </Box>
                )}
              </Field>

              <Stack direction="row" marginTop={3} justifyContent="space-between">
                {returnAddress && (
                  <MDButton circular color="error" variant="transparent" onClick={() => removeReturnAddress()}>
                    Delete
                  </MDButton>
                )}
                <Stack direction="row" spacing={1} flex={1} justifyContent="flex-end">
                  <MDButton circular color="light" onClick={() => setShow(false)}>
                    Cancel
                  </MDButton>
                  <MDButton circular type="submit" disabled={isPending}>
                    Save
                  </MDButton>
                </Stack>
              </Stack>
            </Stack>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default ReturnAddressModal