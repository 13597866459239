import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const HomeSearchIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 10.5L10 3L2.5 10.5H4.16667V16.3333C4.16667 16.7754 4.34226 17.1993 4.65482 17.5118C4.96738 17.8244 5.39131 18 5.83333 18H9.75" stroke="#130B46" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.5 18.0007V13.0007C7.5 12.5586 7.6756 12.1347 7.98816 11.8221C8.30072 11.5096 8.72464 11.334 9.16667 11.334H10.8333M16.8333 17.334L18.3333 18.834M12.5 15.5007C12.5 16.1637 12.7634 16.7996 13.2322 17.2684C13.7011 17.7373 14.337 18.0007 15 18.0007C15.663 18.0007 16.2989 17.7373 16.7678 17.2684C17.2366 16.7996 17.5 16.1637 17.5 15.5007C17.5 14.8376 17.2366 14.2017 16.7678 13.7329C16.2989 13.264 15.663 13.0007 15 13.0007C14.337 13.0007 13.7011 13.264 13.2322 13.7329C12.7634 14.2017 12.5 14.8376 12.5 15.5007Z" stroke="#130B46" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export default HomeSearchIcon;