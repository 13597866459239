import { ReactNode } from 'react';
import { FieldMetaProps, FormikValues } from 'formik';
import { CheckCircleOutline, ExpandMore, HighlightOff } from '@mui/icons-material';
import { AccordionSummary } from '@mui/material';

interface AccordionTitleProps {
  children: ReactNode;
  checked: boolean;
  meta?: FieldMetaProps<FormikValues>;
}

const AccordionTitle = ({ children, checked, meta }: AccordionTitleProps) => {
  return (
    <AccordionSummary expandIcon={<ExpandMore />} sx={{ backgroundColor: '#F3F5FB', fontWeight: 'bold' }}>
      {checked ? (
        <CheckCircleOutline sx={{ mr: 1 }} color="success" />
      ) : (
        <HighlightOff sx={{ mr: 1 }} color={meta?.touched && meta?.error ? 'error' : 'disabled'} />
      )}
      {children}
    </AccordionSummary>
  );
};

export default AccordionTitle;
