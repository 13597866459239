export enum OrderSteps{
    ProductSubpage = 0,
    DesignSubpage = 1,
    ReturnAddressSubpage = 2,
    RecipientsSubpage = 3,
    ReviewSubpage = 4,
    CheckoutSubpage = 5,
    SuccessSubpage = 6
}

export enum TemplateSteps{
    ProductSubpage = 0,
    DesignSubpage = 1,
    ReturnAddressSubpage = 2,
    ReviewSubpage = 3,
}