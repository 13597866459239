import {
  Home,
  FormatListBulleted,
  Call,
  Photo,
  SettingsPhone,
  PhoneForwarded,
  LocalOffer,
  ImportContacts,
  TravelExplore,
  Adjust,
  AutoMode,
  Construction,
  Tv,
  Settings,
  Person,
  CreditCard,
  PersonAddAlt1,
  SettingsSuggest,
  Help,
  Info,
  TipsAndUpdates,
  MailLock,
} from '@mui/icons-material';

import HailTraceLogoSmall from 'assets/images/hailtrace-logo-small.png';

import HomePage from 'pages/home';
import OrdersPage from 'pages/orders';
import MyTemplatesPage from 'pages/my-templates';
import CallTrackingOrderPage from 'pages/call-tracking/order';
import CallTrackingHistoryPage from 'pages/call-tracking/manage-numbers';
import DiscountCodes from 'pages/discount-codes';
import AddressBookPage from 'pages/address-book';
import RecipientSearchHistoryPage from 'pages/recipient-search/history';
import RadiusMailHistoryPage from 'pages/radius-mail/history';
import StormAutomationHistoryPage from 'pages/storm-automation/history';
import SmartAutomationPage from 'pages/smart-automation';
import IntegrationOrdersPage from 'pages/integrations/integration-orders';
import SubscriptionsPage from 'pages/subscriptions';
import ProfilePage from 'pages/settings/profile';
import Billing from 'pages/settings/billing';
import ReferalPage from 'pages/settings/refer-a-friend';
import SmartAutomationSetup from 'pages/smart-automation-setup';
import DoNotMail from 'pages/do-not-mail';

import { RouteType } from './types';

const sidebarRoutes: RouteType[] = [
  {
    icon: <Home />,
    name: 'Home',
    route: '/home',
    component: <HomePage />,
  },
  {
    icon: <FormatListBulleted />,
    name: 'Orders',
    route: '/orders',
    component: <OrdersPage />,
  },
  {
    icon: <Photo />,
    name: 'My Templates',
    route: '/my-templates',
    component: <MyTemplatesPage />,
  },
  {
    icon: <Call />,
    name: 'Call tracking',
    hasDivider: true,
    collapse: [
      {
        icon: <PhoneForwarded />,
        name: 'Order',
        route: '/call-tracking/order',
        component: <CallTrackingOrderPage />,
      },
      {
        icon: <SettingsPhone />,
        name: 'Manage Numbers',
        route: '/call-tracking/history',
        component: <CallTrackingHistoryPage />,
      },
    ],
  },
  {
    icon: <LocalOffer />,
    name: 'Discount Codes',
    route: '/discount-codes',
    component: <DiscountCodes />,
  },
  {
    icon: <ImportContacts />,
    name: 'Address Book',
    route: '/address-book',
    component: <AddressBookPage />,
  },
  {
    icon: <TravelExplore />,
    name: 'Recipient Search',
    route: '/recipient-search',
    component: <RecipientSearchHistoryPage />,
  },
  {
    icon: <Adjust />,
    name: 'Radius Mail',
    route: '/radius-mailing',
    component: <RadiusMailHistoryPage />,
    hasDivider: true,
  },
  {
    icon: <img src={HailTraceLogoSmall} alt={''} style={{ width: '24px', height: 'auto' }} />,
    name: 'Storm Automation',
    route: '/storm-automation',
    component: <StormAutomationHistoryPage />,
    hide: true,
  },
  {
    icon: <AutoMode />,
    name: 'Smart Automation',
    route: '/smart-automation',
    component: <SmartAutomationPage />,
  },
  {
    icon: <Construction />,
    name: 'Integration Orders',
    route: '/integration-orders',
    component: <IntegrationOrdersPage />,
    hasDivider: true,
  },
  {
    icon: <Tv />,
    name: 'Subscriptions',
    route: '/subscriptions',
    component: <SubscriptionsPage />,
  },
  {
    icon: <Settings />,
    name: 'Settings',
    collapse: [
      {
        icon: <Person />,
        name: 'Profile',
        route: '/settings/profile',
        component: <ProfilePage />,
      },
      {
        icon: <MailLock />,
        name: 'Do Not Mail',
        route: '/do-not-mail',
        component: <DoNotMail />,
      },
      {
        icon: <CreditCard />,
        name: 'Billing',
        route: '/settings/billing',
        component: <Billing />,
      },
      {
        icon: <PersonAddAlt1 />,
        name: 'Refer a Friend',
        route: '/settings/refer',
        component: <ReferalPage />,
      },
      {
        icon: <SettingsSuggest />,
        name: 'Smart Automation Setup',
        route: '/settings/smart-automation-setup',
        component: <SmartAutomationSetup />,
      },
    ],
  },
  {
    icon: <Help />,
    name: 'Help',
    hasDivider: true,
    collapse: [
      {
        icon: <Info />,
        name: 'Tutorials',
        href: 'https://www.lettrlabs.com/help-guides',
      },
      {
        icon: <TipsAndUpdates />,
        name: 'Suggestions',
        href: 'https://lettrlabs.kampsite.co/',
      },
    ],
  },
];

export default sidebarRoutes;
