import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "material-ui/components/MDButton";
import {CircularProgress, DialogContent, Stack} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import PremadeDesignService from "services/premade-design";
import PremadeDesignsBlock from "components/premade-designs-block";
import {PremadeDesign} from "models/premadeDesign";
import React, {useEffect, useState} from "react";
import MDBox from "material-ui/components/MDBox";
import {useEditor} from "context/editor-context";
import {SceneBlockNames} from "models/enums/SceneBlockNames";
import OrderService from "services/order";
import {Order} from "models/order";
import {addImage, setBlockToImageById, setBlockToImageByName, stretchImageToFullBleed} from "helpers/ImgLyHelper";
import { useGlobal } from "context/global-context";
import { ProductTypes } from "models/enums/ProductTypes";

interface AddPremadeDesignModalProps {
    show: boolean
    setShow: Function
    order: Order
}

function AddPremadeDesignModal({show, setShow, order}: AddPremadeDesignModalProps) {
    const {setShowLoader} = useGlobal()

    const {creativeEngine, setCurrentStep, currentPageBlockId, setImageBlockDimensions, checkForBlocksOnGivenSide} = useEditor()

    const {getPremadeDesigns} = PremadeDesignService()

    const {updateOrderDesignTemplate} = OrderService()

    const [premadeDesigns, setPremadeDesigns] = useState(null)

    const [selectedPremadeDesign, setSelectedPremadeDesign] = useState<PremadeDesign>(null)

    const responsive = {
        all: {
            breakpoint: {max: 10000, min: 0},
            items: 4
        }
    };

    useEffect(() => {
        if(show){
            getPremadeDesigns().then((result) => {
                setPremadeDesigns(result)
            })
        }
        else{
            setPremadeDesigns(null)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    function addTemplateToScene(){
        setCurrentStep("Design")

        const blockName = order?.product === ProductTypes.HandwrittenBiFoldCard ? SceneBlockNames.OutsideFrontImage : SceneBlockNames.RearImage
        let backImage = creativeEngine.block.findByName(blockName)

        setShowLoader(true)

        updateOrderDesignTemplate(order.id, selectedPremadeDesign.id).then((response) => {
            if(response){
                let artifact = response
                
                if(backImage[0]){
                    setBlockToImageByName(
                        creativeEngine,
                        blockName,
                        artifact.blobUri
                    );

                    setImageBlockDimensions(backImage[0], blockName)
                }
                else{
                    let newBlock = addImage(creativeEngine, currentPageBlockId, blockName)

                    setBlockToImageById(
                        creativeEngine,
                        newBlock,
                        artifact.blobUri
                    );

                    setImageBlockDimensions(newBlock, blockName)
                }

                setShow(false)

                if(order?.product === ProductTypes.HandwrittenBiFoldCard) {
                    const blocks = creativeEngine.block.findByName(blockName)
                    blocks.forEach((block) => stretchImageToFullBleed(creativeEngine, block, order?.product))
                    checkForBlocksOnGivenSide(0)
                } else checkForBlocksOnGivenSide(1)
            }

            setShowLoader(false)
        })
    }

    return <Dialog open={show} onClose={() => setShow(false)} maxWidth={false}>
        <DialogContent sx={{width: "1200px"}}>
            <DialogTitle>
                Select from our Free Card Designs
            </DialogTitle>

            {premadeDesigns?
                <PremadeDesignsBlock
                    data={premadeDesigns}
                    selectedPremadeDesign={selectedPremadeDesign}
                    designOnClick={(design: PremadeDesign) => {
                        setSelectedPremadeDesign(design)
                    }}
                    containerHeight={"72vh"}
                    responsiveSteps={responsive}
                />
                :
                <MDBox display={"flex"} justifyContent={"center"} width={"100%"}>
                    <CircularProgress color="primary"/>
                </MDBox>
            }

            <Stack
                direction="row"
                spacing={1}
                marginTop={2}
                justifyContent="flex-end"
            >
                <MDButton
                    color={"light"}
                    circular={true}
                    onClick={() => setShow(false)}
                >
                    Cancel
                </MDButton>

                <MDButton
                    onClick={addTemplateToScene}
                    circular={true}
                    disabled={!selectedPremadeDesign}
                >
                    Save
                </MDButton>
            </Stack>
        </DialogContent>
    </Dialog>
}

export default AddPremadeDesignModal