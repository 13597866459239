import {Order} from "models/order";
import MDBox from "material-ui/components/MDBox";
import MDCard from "material-ui/components/MDCard";
import MDTypography from "material-ui/components/MDTypography";
import {Checkbox, Grid} from "@mui/material";
import Check3Icon from "assets/icons/check-3";
import React, {useEffect, useState} from "react";
import {IOrderRecipient} from "models/orderRecipient";
import WarningIcon from "assets/icons/warning";
import {OrderSteps} from "models/orderSteps";
import LayoutMain from "../../layout-main";
import PreviewCardImagesModal from "components/preview-card-images-modal";
import ArtifactService from "services/artifact-service";
import {useEditor} from "context/editor-context";
import {YesNo} from "models/enums/yesNo";
import {getMaxCharacterCountForProduct} from "helpers/OrderHelper";
import {useNavigate} from "react-router-dom";
import StyledCarousel from "components/styled-carousel/styled-carousel";
import Loader from "components/loader";
import {orderProductIsPrintedCard} from "models/enums/ProductTypes";
import InfoModal from "components/info-modal";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { useGlobal } from "context/global-context";

interface ReviewSubpageProps {
    order: Order
    setActiveStep: Function
    checked?: boolean
    setChecked?: Function
    recipients?: IOrderRecipient[]
    undeliverableRows?: IOrderRecipient[]
    potentiallyUndeliverableRows?: IOrderRecipient[]
    missingMailMergeRows?: IOrderRecipient[]
    missingAddressFieldRows?: IOrderRecipient[]
}

function ReviewSubpage({order, setActiveStep, checked, setChecked, recipients = [], undeliverableRows = [], potentiallyUndeliverableRows = [], missingMailMergeRows = [], missingAddressFieldRows = []}: ReviewSubpageProps) {
    const navigate = useNavigate()

    const {lowestFoundResolution} = useEditor()
    const {navigationCallback, setNavigationCallback, templateFlow, setTemplateFlow} = useGlobal()

    const {getOrderProofs} = ArtifactService()

    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false)

    const [images, setImages] = useState<string[]>([])

    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)

    const responsiveSteps = {
        all: {
            breakpoint: {max: 10000, min: 0},
            items: 1
        }
    };

    useEffect(() => {
        fetchProofs(1)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function fetchProofs(requestAttempts: number){
        getOrderProofs(order.id)
            .then((response) => {
                if(response && response.payload.length){
                    setImages(response.payload.map((image: any) => image.blobUri))
                }
                else{
                    if(requestAttempts === 3){
                        setShowErrorModal(true)
                        return
                    }

                    setTimeout(() => {
                        fetchProofs(requestAttempts + 1)
                    }, 10000)
                }
            })
    }

    function onBack() {
        if(order.isTemplate){
            setActiveStep(OrderSteps.ReturnAddressSubpage)
        }
        else{
            setActiveStep(OrderSteps.RecipientsSubpage)
        }
    }

    function onNext() {
        if(order.isTemplate){            
            if(templateFlow.originalRoute) {
                setTemplateFlow({...templateFlow, order: {...templateFlow.order, template: order}})
                navigate(templateFlow.originalRoute)
            } else if(navigationCallback) {
                navigationCallback()
                setNavigationCallback(null)
            } else navigate(order.isIntegrationOrderTemplate ? '/integrations/integration-orders' : '/home')
        }
        else setActiveStep(OrderSteps.CheckoutSubpage)
    }

    return <LayoutMain showBackButton={true} showNextButton={true} backButtonOnClick={onBack} nextButtonOnClick={onNext} nextButtonDisabled={!order.isTemplate && !checked} nextButtonText={order.isTemplate ? "Save Template" : "Next"}>
        <Grid container justifyContent={"space-between"} gap={2} flexWrap={"nowrap"}>
            <Grid item xs={6}>
                <MDCard boxShadow={false} borderRadiusSize={"md"} border={true} borderColor={"light"} sx={{height: "100%"}}>
                    <MDBox px={6} py={5}>
                        <MDTypography variant={"h3"} color={"text"} sx={{fontSize: "28px"}}>Order Details</MDTypography>

                        <MDBox mt={4}>
                            <MDTypography variant={"h4"} color={"secondary"}>Product Details</MDTypography>

                            <Grid container mt={2} alignItems={"center"} gap={1}>
                                <Grid item display={"flex"}>
                                    <Check3Icon/>
                                </Grid>

                                <Grid item>
                                    <MDTypography variant={"h4"} fontWeight={"regular"} color={"text"}>{order.product}</MDTypography>
                                </Grid>
                            </Grid>
                        </MDBox>

                        <MDBox mt={4}>
                            <MDTypography variant={"h4"} color={"secondary"}>Optional Add-Ons</MDTypography>

                            {(!orderProductIsPrintedCard(order.product) && order.doubleSided === YesNo.Yes) || (order.text.length + (order.text2?.length ?? 0)) > getMaxCharacterCountForProduct(order.product)?
                                <MDBox>
                                    {!orderProductIsPrintedCard(order.product) && order.doubleSided === YesNo.Yes?
                                        <Grid container mt={2} alignItems={"center"} gap={1}>
                                            <Grid item display={"flex"}>
                                                <Check3Icon/>
                                            </Grid>

                                            <Grid item>
                                                <MDTypography variant={"h4"} fontWeight={"regular"} color={"text"}>Double Sided Printing</MDTypography>
                                            </Grid>
                                        </Grid>
                                        :
                                        null
                                    }

                                    {order.text.length > getMaxCharacterCountForProduct(order.product)?
                                        <Grid container mt={2} alignItems={"center"} gap={1}>
                                            <Grid item display={"flex"}>
                                                <Check3Icon/>
                                            </Grid>

                                            <Grid item>
                                                <MDTypography variant={"h4"} fontWeight={"regular"} color={"text"}>Extra Handwritten Text</MDTypography>
                                            </Grid>
                                        </Grid>
                                        :
                                        null
                                    }
                                </MDBox>
                                :
                                <Grid container mt={2} alignItems={"center"} gap={1}>
                                    <Grid item display={"flex"}>
                                        <NotInterestedIcon color="disabled" sx={{ height: 22 }} />
                                    </Grid>

                                    <Grid item>
                                        <MDTypography variant={"h4"} fontWeight={"regular"} color={"text"}>N/A</MDTypography>
                                    </Grid>
                                </Grid>
                            }
                        </MDBox>

                        {!order.isTemplate?
                            <MDBox mt={4}>
                                <MDTypography variant={"h4"} color={"secondary"}>Recipients</MDTypography>

                                <Grid container mt={2} alignItems={"center"} gap={1}>
                                    <Grid item display={"flex"}>
                                        <Check3Icon/>
                                    </Grid>

                                    <Grid item>
                                        <MDTypography variant={"h4"} fontWeight={"bold"} color={"text"}>Total Recipients:</MDTypography>
                                    </Grid>

                                    <Grid item>
                                        <MDTypography variant={"h4"} fontWeight={"regular"} color={"text"}>{recipients.length ?? ""}</MDTypography>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            :
                            null
                        }
                    </MDBox>
                </MDCard>
            </Grid>

            <Grid item xs={6}>
                <MDCard sx={{height: "100%"}} boxShadow={false} borderRadiusSize={"md"} border={true} borderColor={"light"}>
                    <Grid container px={6} py={5} flexDirection={'column'} height={"100%"}>
                        <Grid item>
                            <MDTypography variant={"h3"} color={"text"} sx={{fontSize: "28px"}}>Digital Proof</MDTypography>
                        </Grid>

                        <Grid item mt={4} flex={1} width={"100%"}>
                            {images.length ?
                                <StyledCarousel
                                    className={"arrows-orange"}
                                    responsive={responsiveSteps}
                                    showDots={false}
                                    renderArrowsWhenDisabled={false}
                                    slidesToSlide={1}
                                    swipeable={false}
                                    draggable={false}
                                    arrows={images.length > 1}
                                >
                                    {images.map((image, index) => {
                                        return <MDBox key={index} p={3} onClick={() => {
                                            setShowPreviewModal(true)
                                        }}>
                                            <MDCard borderRadiusSize={"md"} borderColor={"dark"} border={true} boxShadow={false}>
                                                <img alt={""} style={{width: "100%", height: "auto", borderRadius: "inherit"}} src={image}/>
                                            </MDCard>
                                        </MDBox>
                                    })}
                                </StyledCarousel>
                                :
                                <MDBox display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} width={"100%"} minHeight={"100%"}>
                                    <Loader/>

                                    <MDTypography mt={2} variant={"h5"} color={"primary"}>Please wait while your digital proof is loading</MDTypography>
                                </MDBox>
                            }
                        </Grid>
                    </Grid>
                </MDCard>
            </Grid>
        </Grid>

        {!order.isTemplate?
            <MDBox mt={4}>
                <MDCard boxShadow={false} borderRadiusSize={"md"} border={true} borderColor={"light"} sx={{height: "100%"}}>
                    <MDBox px={4} py={3}>
                        <MDTypography variant={"h3"} color={"text"} sx={{fontSize: "32px"}}>Proof Approval</MDTypography>

                        {undeliverableRows.length ?
                            <MDBox>
                                <Grid container mt={2} alignItems={"center"} gap={1}>
                                    <Grid item display={"flex"}>
                                        <WarningIcon color={"warning"}/>
                                    </Grid>

                                    <Grid item>
                                        <MDTypography variant={"h4"} fontWeight={"bold"} color={"text"}>Undeliverable Addresses:</MDTypography>
                                    </Grid>

                                    <Grid item>
                                        <MDTypography variant={"h4"} fontWeight={"regular"} color={"text"}>{undeliverableRows.length ?? ""}</MDTypography>
                                    </Grid>
                                </Grid>

                                <MDTypography variant={"h6"} fontWeight={"regular"} color={"text"}>Addresses may not be deliverable based on USPS + 3rd Party Delivery Database</MDTypography>
                            </MDBox>
                            :
                            null
                        }

                        {potentiallyUndeliverableRows.length ?
                            <MDBox>
                                <Grid container mt={2} alignItems={"center"} gap={1}>
                                    <Grid item display={"flex"}>
                                        <WarningIcon color={"warning"}/>
                                    </Grid>

                                    <Grid item>
                                        <MDTypography variant={"h4"} fontWeight={"bold"} color={"text"}>Potentially Undeliverable Addresses:</MDTypography>
                                    </Grid>

                                    <Grid item>
                                        <MDTypography variant={"h4"} fontWeight={"regular"} color={"text"}>{potentiallyUndeliverableRows.length ?? ""}</MDTypography>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            :
                            null
                        }

                        {missingMailMergeRows.length ?
                            <MDBox>
                                <Grid container mt={2} alignItems={"center"} gap={1}>
                                    <Grid item display={"flex"}>
                                        <WarningIcon color={"warning"}/>
                                    </Grid>

                                    <Grid item>
                                        <MDTypography variant={"h4"} fontWeight={"bold"} color={"text"}>Addresses missing mail merge fields:</MDTypography>
                                    </Grid>

                                    <Grid item>
                                        <MDTypography variant={"h4"} fontWeight={"regular"} color={"text"}>{missingMailMergeRows.length ?? ""}</MDTypography>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            :
                            null
                        }

                        {missingAddressFieldRows.length ?
                            <MDBox>
                                <Grid container mt={2} alignItems={"center"} gap={1}>
                                    <Grid item display={"flex"}>
                                        <WarningIcon color={"error"}/>
                                    </Grid>

                                    <Grid item>
                                        <MDTypography variant={"h4"} fontWeight={"bold"} color={"text"}>Recipients missing address fields:</MDTypography>
                                    </Grid>

                                    <Grid item>
                                        <MDTypography variant={"h4"} fontWeight={"regular"} color={"text"}>{missingAddressFieldRows.length ?? ""}</MDTypography>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            :
                            null
                        }

                        {lowestFoundResolution < 250 ?
                            <MDBox>
                                <Grid container mt={2} alignItems={"center"} gap={1}>
                                    <Grid item display={"flex"}>
                                        <WarningIcon color={lowestFoundResolution >= 200? "warning" : "error"}/>
                                    </Grid>

                                    <Grid item>
                                        <MDTypography variant={"h4"} fontWeight={"bold"} color={"text"}>
                                            {lowestFoundResolution >= 200? "Low Image Resolution" : "Bad Image Resolution:"}
                                        </MDTypography>
                                    </Grid>

                                    <Grid item>
                                        <MDTypography variant={"h4"} fontWeight={"regular"} color={"text"}>
                                            {lowestFoundResolution >= 200?
                                                "Images on your card have a low DPI resolution and may result blurry or pixelated images"
                                                :
                                                "Unacceptable Image Resolution: Less then 200 DPI. Image will appear pixelated or blurry once printed. Reduce the image size to improve the DPI or replace with a higher resolution image"
                                            }
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            :
                            null
                        }

                        <Grid container mt={3} alignItems={"center"} sx={{opacity: images.length? 1 : 0.5, pointerEvents: images.length? "all" : "none"}}>
                            <Grid item>
                                <Checkbox sx={{paddingLeft: 0}} checked={checked} onChange={() => {
                                    setChecked(!checked)
                                }}/>
                            </Grid>

                            <Grid item>
                                <MDTypography variant={"h4"} fontWeight={"regular"} color={"text"}>I've reviewed my proofs and they're approved to be produced</MDTypography>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDCard>
            </MDBox>
            :
            null
        }

        <PreviewCardImagesModal
            show={showPreviewModal}
            setShow={setShowPreviewModal}
            images={images}
            text1={`Preview Proofs`}
            text2={`${order.product}`}
        />

        <InfoModal
            show={showErrorModal}
            setShow={setShowErrorModal}
            headerText={'Something went wrong while trying to generate proofs for your order. Please refresh the page or contact us at support@lettrlabs.com'}
            showCancelButton={false}
            showConfirmButton={true}
            confirmButtonOnClick={() => {
                setShowErrorModal(false)
            }}
        />
    </LayoutMain>
}

export default ReviewSubpage