import { ignoreList } from './ignoreList';

type IFont = {
	[key in 'single' | 'double']: {
		[key: string]: { [key: string]: string[] };
	};
};

// Given a text string and a font, replaces the text with the handwritten glyphs
export const addGlyphs = (fromText: string, fontGlyphsJsonText: string, hideVariations?: boolean) => {
	if(hideVariations) return fromText
	const fontGlyphsJson = JSON.parse(fontGlyphsJsonText);
	return scriptalizeText(fontGlyphsJson, fromText);
};

// Given a text string and a font, replaces the glyphs with normal text
export const replaceGlyphs = (fromText: string, fontGlyphsJsonText: string) => {
	const fontGlyphsJson = JSON.parse(fontGlyphsJsonText);
	let outputText = '';

	for (let i = 0; i < fromText.length; i++) {
		const glyph = fromText[i];
		// Find the base character for the glyph
		const baseCharacter = getBaseCharacter(glyph, fontGlyphsJson);
		outputText += baseCharacter;
	}

	return outputText;
};

const scriptalizeText = (fontObj: IFont, text: string) => {
	let outputText = '';

	if(!text) return outputText

	for (let i = 0; i < text.length; i++) {
		const char = text[i];

		// const doubleChar = `${char}${i < text.length - 1 ? text[i + 1] : ''}`;
		// if (fontObj['double'].hasOwnProperty(doubleChar)) {
		// 	let replacementDouble = getVariation(doubleChar, 'double', fontObj);
		// 	outputText += replacementDouble;
		// 	i++;
		// } else {
			// Find type
			let charType;
			if (char === ' ') {
				charType = 'start';
			} else if (i === 0 || text[i - 1] === ' ') {
				charType = 'start';
			} else if (i === text.length - 1 || text[i + 1] === ' ') {
				charType = 'end';
			} else {
				charType = 'in';
			}

			outputText += getVariation(char, charType, fontObj);
		// }
	}

	return outputText;
};

const getBaseCharacter = (glyph: string, fontObj: IFont) => {
	// The line below is because Q is being interpreted as a space in the json files
	if (glyph === 'Q') return glyph;
	for (let baseCharacter in fontObj.single) {
		for (let position in fontObj.single[baseCharacter]) {
			if (fontObj.single[baseCharacter][position].includes(glyph)) {
				return baseCharacter;
			}
		}
	}
	for (let baseCharacter in fontObj.double) {
		for (let position in fontObj.double[baseCharacter]) {
			if (fontObj.double[baseCharacter][position].includes(glyph)) {
				return baseCharacter;
			}
		}
	}
	return glyph; // return the glyph itself if no base character is found
};


const getVariation = (baseCharacter: string, charType: string, variationsTable: IFont) => {
	// Space variations were messing with word selections
	if(baseCharacter === ' ') return baseCharacter

	let variation;
	try {
		const tableType = charType === 'double' ? 'double' : 'single';
		variation = variationsTable[tableType][baseCharacter][charType].shift();

		// TODO: Remove this "if" once imgly is fixed
		if (ignoreList.includes(variation.charCodeAt(0)) || variation.length > 1) {
			variation = baseCharacter;
		} else {
			variationsTable[tableType][baseCharacter][charType].push(variation);
		}
	} catch (error) {
		variation = baseCharacter;
	}
	return variation;
};
