import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT from "../endpoint";
import {RadiusMail} from "models/radius-mailing/radiusMail";

function RadiusMailService(){
    const {execute} = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/radius-mail-order`

    function getRadiusMailOrders(){
        return execute({
            endpoint: `${API_PATH}`,
            method: "GET"
        })
    }

    function postRadiusMail(radiusMail: RadiusMail){
        return execute({
            endpoint: `${API_PATH}`,
            method: "POST",
            data: radiusMail
        })
    }

    function getRadiusMail(id: number){
        return execute({
            endpoint: `${API_PATH}/${id}`,
            method: "GET"
        })
    }

    function getRadiusMailCheckout(id: number){
        return execute({
            endpoint: `${API_PATH}/${id}:checkout`,
            method: "GET"
        })
    }

    function postRadiusMailCheckout(id: number){
        return execute({
            endpoint: `${API_PATH}/${id}:checkout`,
            method: "POST"
        })
    }

    function deleteRadiusMail(id: number){
        return execute({
            endpoint: `${API_PATH}/${id}`,
            method: "DELETE"
        })
    }

    function deleteRadiusMailAddress(radiusMailId: number, addressId: number){
        return execute({
            endpoint: `${API_PATH}/${radiusMailId}/address/${addressId}`,
            method: "DELETE"
        })
    }

    return {getRadiusMailOrders, getRadiusMail, postRadiusMail, getRadiusMailCheckout, postRadiusMailCheckout, deleteRadiusMail, deleteRadiusMailAddress}
}

export default RadiusMailService