/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS Base Styles
import colors from "material-ui/theme/base/colors";

const { transparent } = colors;

// types
type Types = any;

const select: Types = {
  styleOverrides: {
    root: {
      width: "100%",

      "&.MuiInputBase-colorSuccess": {
        backgroundColor: "#E8F2EE",

        ".MuiSelect-select": {
          color: "#177D52",
          fontWeight: "bold"
        }
      },

      "&.MuiInputBase-colorWarning": {
        backgroundColor: "#FEF7E8",

        ".MuiSelect-select": {
          color: "#F6AD1B",
          fontWeight: "bold"
        }
      },

      "&.MuiInputBase-colorError": {
        backgroundColor: "#FBEDE8",

        ".MuiSelect-select": {
          color: "#D3471A",
          fontWeight: "bold"
        }
      }
    },

    select: {
      display: "grid",
      alignItems: "center",
      width: "100%",

      "& .Mui-selected": {
        backgroundColor: transparent.main,
      },
    },

    selectMenu: {
      background: "none",
      height: "none",
      minHeight: "none",
      overflow: "unset",
    },

    icon: {
      fontSize: `24px !important`,
    },
  },
};

export default select;
