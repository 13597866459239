import {
  AccountBalanceWallet,
  Assignment,
  Dashboard,
  Discount,
  History,
  IntegrationInstructions,
  Loyalty,
  MailSharp,
  ManageAccounts,
  MyLocation,
  NotificationImportant,
  PriceChange,
  Security,
  ThumbsUpDown,
  TransferWithinAStation,
} from '@mui/icons-material';

import AdminAssignBDRRepPage from 'pages/superadmin/assign-bdr';
import AdminPricingTiersPage from 'pages/superadmin/pricing-tiers';
import AdminSubscriptionPricing from 'pages/superadmin/subscription-pricing';
import AdminHailTracePage from 'pages/superadmin/hail-trace';
import MatchbackPage from 'pages/superadmin/matchback';
import SuperAdminStormAutomationHistoryPage from 'pages/superadmin/storm-automation/history';
import AdminProfilesPage from 'pages/superadmin/profiles';
import AdminDashboardPage from 'pages/superadmin/dashboard';
import AdminIntegrationOrdersPage from 'pages/superadmin/integration-orders';
import AdminOrdersWithIssues from 'pages/superadmin/orders-issues';

import { RouteType } from './types';
import SubscriptionCredits from 'pages/superadmin/subscription-credits';
import SuperAdminSubscriptionTransfer from 'pages/superadmin/subscription-transfer';
import SuperAdminDiscounts from 'pages/superadmin/discounts';
import DiscountApproval from 'pages/superadmin/discount-approval';

const sidebarSuperAdminRoutes: RouteType = {
  icon: <Security />,
  name: 'Super Admin',
  hasDivider: true,
  collapse: [
    {
      icon: <Assignment />,
      name: 'Assign Sales and BDR',
      route: '/superadmin/assign-bdr',
      component: <AdminAssignBDRRepPage />,
    },
    {
      icon: <PriceChange />,
      name: 'Pricing Tiers',
      route: '/superadmin/pricing-tiers',
      component: <AdminPricingTiersPage />,
    },
    {
      icon: <Loyalty />,
      name: 'Subscription Pricing',
      route: '/superadmin/subscription-pricing',
      component: <AdminSubscriptionPricing />,
    },
    {
      icon: <MyLocation />,
      name: 'Hail Trace Service Areas',
      route: '/superadmin/hailtrace',
      component: <AdminHailTracePage />,
    },
    {
      icon: <History />,
      name: 'Storm Automation',
      route: '/superadmin/radius-mail',
      component: <SuperAdminStormAutomationHistoryPage />,
    },
    {
      icon: <ManageAccounts />,
      name: 'Profiles',
      route: '/superadmin/profiles',
      component: <AdminProfilesPage />,
    },
    {
      icon: <Dashboard />,
      name: 'Dashboard',
      route: '/superadmin/dashboard',
      component: <AdminDashboardPage />,
    },
    {
      icon: <IntegrationInstructions />,
      name: 'Integration Orders',
      route: '/superadmin/integration-orders',
      component: <AdminIntegrationOrdersPage />,
    },
    {
      icon: <NotificationImportant />,
      name: 'Orders with Issues',
      route: '/superadmin/orders-issues',
      component: <AdminOrdersWithIssues />,
    },
    {
      icon: <AccountBalanceWallet />,
      name: 'Add / Remove Credits',
      route: '/superadmin/subscription-credits',
      component: <SubscriptionCredits />,
    },
    {
      icon: <Discount />,
      name: 'Add / Remove Discounts',
      route: '/superadmin/manage-discounts',
      component: <SuperAdminDiscounts />,
    },
    {
      icon: <ThumbsUpDown />,
      name: 'Discount Approval',
      route: '/superadmin/discount-approval',
      component: <DiscountApproval />,
      hide: true,
    },
    {
      icon: <TransferWithinAStation />,
      name: 'Transfer Subscription',
      route: '/superadmin/subscription-transfer',
      component: <SuperAdminSubscriptionTransfer />,
    },
    {
      icon: <MailSharp />,
      name: 'Matchback',
      route: '/superadmin/matchback',
      component: <MatchbackPage />,
    },
  ],
};

export default sidebarSuperAdminRoutes;
