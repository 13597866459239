import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT from "../endpoint";

function ProfileArtifactService(){
    const {execute} = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/profileartifacts`

    // Gets all profile artifacts
    function getProfileArtifacts(){
        return execute({
            endpoint: `${API_PATH}`,
            method: "GET"
        })
    }

    function postProfileArtifacts(file: File){
        const formData = new FormData();
        formData.append("file", file);
        formData.append("artifactType", "Image");

        return execute({
            endpoint: `${API_PATH}`,
            method: "POST",
            formData: formData
        })
    }

    function deleteProfileArtifact(id: number){
        return execute({
            endpoint: `${API_PATH}/${id}`,
            method: "DELETE"
        })
    }

    return {getProfileArtifacts, postProfileArtifacts, deleteProfileArtifact}
}

export default ProfileArtifactService