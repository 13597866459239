import React from "react";
import {IMask, IMaskInput} from "react-imask";

const TextMaskExpDate = React.forwardRef<HTMLElement>(
    function TextMaskCustom(props: any, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask={"mm/yy"}
                blocks={{
                    'mm': {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 12,
                        maxLength: 2,
                    },
                    'yy': {
                        mask: IMask.MaskedRange,
                        from: 0,
                        to: 99,
                        maxLength: 2,
                    }
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

export default TextMaskExpDate