import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SidebarHelpIcon = (props: any) => {
    return (
        <SvgIcon {...props} viewBox={"0 0 32 32"}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M3 16.0039C3 8.82391 8.82 3.00391 16 3.00391C23.18 3.00391 29 8.82391 29 16.0039C29 23.1839 23.18 29.0039 16 29.0039C8.82 29.0039 3 23.1839 3 16.0039ZM18.1707 10.7812C16.984 9.74524 15.016 9.74524 13.8307 10.7812C13.631 10.9559 13.3702 11.0442 13.1055 11.0265C12.8409 11.0089 12.594 10.8869 12.4193 10.6872C12.2446 10.4876 12.1564 10.2268 12.174 9.9621C12.1917 9.69743 12.3137 9.4506 12.5133 9.27591C14.452 7.57991 17.548 7.57991 19.4867 9.27591C21.504 11.0412 21.504 13.9666 19.4867 15.7319C19.1493 16.0261 18.7739 16.2736 18.3707 16.4679C17.4693 16.9052 17 17.4999 17 18.0039V19.0039C17 19.2691 16.8946 19.5235 16.7071 19.711C16.5196 19.8986 16.2652 20.0039 16 20.0039C15.7348 20.0039 15.4804 19.8986 15.2929 19.711C15.1054 19.5235 15 19.2691 15 19.0039V18.0039C15 16.2986 16.4133 15.1946 17.5 14.6679C17.7427 14.5506 17.968 14.4026 18.1707 14.2266C19.2773 13.2572 19.2773 11.7506 18.1707 10.7812ZM16 24.0039C16.2652 24.0039 16.5196 23.8986 16.7071 23.711C16.8946 23.5235 17 23.2691 17 23.0039C17 22.7387 16.8946 22.4843 16.7071 22.2968C16.5196 22.1093 16.2652 22.0039 16 22.0039C15.7348 22.0039 15.4804 22.1093 15.2929 22.2968C15.1054 22.4843 15 22.7387 15 23.0039C15 23.2691 15.1054 23.5235 15.2929 23.711C15.4804 23.8986 15.7348 24.0039 16 24.0039Z" fill="white"/>
            </svg>
        </SvgIcon>
    )
}

export default SidebarHelpIcon;