import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const HailSizeIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_7103_73166)">
                    <path
                        d="M4.29129 5.76842C4.29129 5.11889 4.4839 4.48396 4.84475 3.9439C5.20561 3.40384 5.71851 2.98292 6.31859 2.73436C6.91867 2.4858 7.57898 2.42076 8.21602 2.54748C8.85306 2.67419 9.43822 2.98697 9.8975 3.44625C10.3568 3.90553 10.6696 4.49069 10.7963 5.12773C10.923 5.76477 10.858 6.42508 10.6094 7.02516C10.3608 7.62524 9.93991 8.13814 9.39985 8.49899C8.85979 8.85985 8.22486 9.05246 7.57533 9.05246H3.96289C3.3532 9.05246 2.76849 8.81026 2.33737 8.37914C1.90626 7.94803 1.66406 7.36332 1.66406 6.75363C1.66406 6.14394 1.90626 5.55923 2.33737 5.12811C2.76849 4.697 3.3532 4.4548 3.96289 4.4548C4.15522 4.45473 4.34684 4.47816 4.53349 4.52459"
                        fill="white"/>
                    <path
                        d="M4.29129 5.76842C4.29129 5.11889 4.4839 4.48396 4.84475 3.9439C5.20561 3.40384 5.71851 2.98292 6.31859 2.73436C6.91867 2.4858 7.57898 2.42076 8.21602 2.54748C8.85306 2.67419 9.43822 2.98697 9.8975 3.44625C10.3568 3.90553 10.6696 4.49069 10.7963 5.12773C10.923 5.76477 10.858 6.42508 10.6094 7.02516C10.3608 7.62524 9.93991 8.13814 9.39985 8.49899C8.85979 8.85985 8.22486 9.05246 7.57533 9.05246H3.96289C3.3532 9.05246 2.76849 8.81026 2.33737 8.37914C1.90626 7.94803 1.66406 7.36331 1.66406 6.75363C1.66406 6.14394 1.90626 5.55923 2.33737 5.12811C2.76849 4.697 3.3532 4.4548 3.96289 4.4548C4.15522 4.45473 4.34684 4.47816 4.53349 4.52459"
                        stroke="#130B46" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M10.1466 7.2819C10.1466 6.4721 10.3867 5.68049 10.8366 5.00717C11.2865 4.33385 11.926 3.80906 12.6742 3.49917C13.4223 3.18927 14.2456 3.10819 15.0398 3.26617C15.834 3.42416 16.5636 3.81411 17.1362 4.38672C17.7088 4.95933 18.0987 5.68889 18.2567 6.48312C18.4147 7.27736 18.3336 8.1006 18.0237 8.84876C17.7138 9.59691 17.1891 10.2364 16.5157 10.6863C15.8424 11.1362 15.0508 11.3763 14.241 11.3763H9.73717C8.97704 11.3763 8.24804 11.0743 7.71055 10.5368C7.17305 9.99935 6.87109 9.27035 6.87109 8.51022C6.87109 7.75009 7.17305 7.02109 7.71055 6.48359C8.24804 5.9461 8.97704 5.64414 9.73717 5.64414C9.97696 5.64405 10.2159 5.67327 10.4486 5.73115"
                        fill="white"/>
                    <path
                        d="M10.1466 7.2819C10.1466 6.4721 10.3867 5.68049 10.8366 5.00717C11.2865 4.33385 11.926 3.80906 12.6742 3.49917C13.4223 3.18927 14.2455 3.10819 15.0398 3.26617C15.834 3.42416 16.5636 3.81411 17.1362 4.38672C17.7088 4.95933 18.0987 5.68889 18.2567 6.48312C18.4147 7.27736 18.3336 8.1006 18.0237 8.84876C17.7138 9.59691 17.1891 10.2364 16.5157 10.6863C15.8424 11.1362 15.0508 11.3763 14.241 11.3763H9.73717C8.97704 11.3763 8.24804 11.0743 7.71055 10.5368C7.17305 9.99935 6.87109 9.27035 6.87109 8.51022C6.87109 7.75009 7.17305 7.02109 7.71055 6.48359C8.24804 5.9461 8.97704 5.64414 9.73717 5.64414C9.97696 5.64405 10.2159 5.67327 10.4486 5.73115"
                        stroke="#130B46" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M3.84766 9.39433L3.84766 17.1885M10.0039 11.8228V19.5128M12.8125 11.9999V16.0987M6.43359 9.6979V14.8214M18.1781 9.6979V14.8214"
                        stroke="#130B46" strokeLinecap="round" strokeDasharray="2 2"/>
                </g>
                <defs>
                    <clipPath id="clip0_7103_73166">
                        <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    )
}

export default HailSizeIcon;