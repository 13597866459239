export interface IOrderRecipient {
    id: number;
    orderId: number;
    salutation: string;
    firstName: string;
    lastName: string;
    toOrganization: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    custom1: string;
    custom2: string;
    custom3: string;
    custom4: string;
    custom5: string;
    custom6: string;
    radiusPinStreet?: string;
    radiusPinAddress?: string;
    errors?: OrderRecipientError[]
    warnings?: OrderRecipientWarning[]
    missingMailMergeFields?: string[]
    isDeliverable?: boolean
}

export enum OrderRecipientWarning{
    MissingMailMergeField,
    PotentiallyUndeliverable,
    Undeliverable
}

export enum OrderRecipientError{
    MissingAddressFields,
    MissingNameFields,
    NonUsAddress
}

export interface IOrderRecipients extends Array<IOrderRecipient>{}