/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { forwardRef, FC } from "react";
import MDSelectRoot from "./MDSelectRoot";

// Custom styles for MDSelect
import {SelectProps} from "@mui/material";
import ArrowDownIcon from "assets/icons/arrow-down";

// declaring props types for MDSelect
interface Props extends SelectProps{
    [key: string]: any;
}

const MDSelect: FC<Props> = forwardRef(({ ...rest }, ref) => (
        <MDSelectRoot
            {...rest}
            ownerState={{}}
            IconComponent={ArrowDownIcon}
        />
    )
);

// Declaring default props for MDRadio
MDSelect.defaultProps = {

};

export default MDSelect;
