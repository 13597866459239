import {
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid, MenuItem,
    Radio,
    Select,
    Stack
} from "@mui/material";
import MDBox from "material-ui/components/MDBox";
import MDButton from "material-ui/components/MDButton";
import MDTypography from "material-ui/components/MDTypography";
import RadioSelectWrapper from "components/radio-select-wrapper";
import React, {useEffect, useState} from "react";
import {CTMPhoneNumber} from "models/CTMPhoneNumber";
import PhoneNumberService from "services/phone-number";
import {useQuery} from "@tanstack/react-query";
import {formatUSPhoneNumber} from "helpers/phoneNumberFormat";
import {addTrackablePhoneNumber, insertOrReplaceTextAtCursor} from "helpers/ImgLyHelper";
import {useEditor} from "context/editor-context";
import {Order} from "models/order";
import {SceneBlockNames} from "models/enums/SceneBlockNames";
import {orderProductIsPrintedCard} from "models/enums/ProductTypes";

interface AddCallTrackingModalProps{
    show: boolean
    setShow: Function
    order: Order
    setOrder: Function
}

function AddCallTrackingModal({show, setShow, order, setOrder}: AddCallTrackingModalProps){
    const {creativeEngine, currentPageBlockId, updateOrderTextFromCreativeEngine, textSelectRange} = useEditor()
    const {getPhoneNumbers} = PhoneNumberService()

    const getCallTrackingNumbersQuery = useQuery({
        queryKey: ["phoneNumbers"],
        queryFn: getPhoneNumbers,
        refetchOnWindowFocus: false
    })

    const [selectedNumber, setSelectedNumber] = useState<string>("")
    const [selectedTextType, setSelectedTextType] = useState<"Handwritten" | "Printed">("Handwritten")

    function confirm(){
        if(selectedTextType === "Handwritten"){
            insertOrReplaceTextAtCursor(
                creativeEngine,
                SceneBlockNames.HandwrittenText,
                formatUSPhoneNumber(selectedNumber),
                textSelectRange
            )

            updateOrderTextFromCreativeEngine()
        }
        else{
            addTrackablePhoneNumber(creativeEngine, currentPageBlockId, formatUSPhoneNumber(selectedNumber))
        }

        setOrder((prevState: Order) => {
            return {...prevState, trackingPhoneNumber: selectedNumber}
        })
        setShow(false)
    }

    useEffect(function () {
        if(show){
            if(creativeEngine){
                creativeEngine.editor.setEditMode('Transform')
            }

            if (orderProductIsPrintedCard(order.product)){
                setSelectedTextType("Printed")
            }
        }
        else{
            setSelectedNumber("")
        }
    }, [show, order, creativeEngine])

    return <Dialog open={show} onClose={() => setShow(false)} maxWidth={false}>
        <DialogContent sx={{width: "600px"}}>
            <DialogTitle>
                Call Tracking
            </DialogTitle>

            <MDBox mt={4}>
                <Select
                    fullWidth={true}
                    value={selectedNumber}
                    renderValue={value => {
                        if(!value){
                            return "Select a number"
                        }

                        return formatUSPhoneNumber(value)
                    }}
                    displayEmpty={true}
                    onChange={(event) => {
                        setSelectedNumber(event.target.value)
                    }}
                >
                    {getCallTrackingNumbersQuery.data?.payload.map((number: CTMPhoneNumber, index: number) => {
                        return <MenuItem key={index} value={number.number}>
                            {formatUSPhoneNumber(number.number)}
                        </MenuItem>
                    })}
                </Select>
            </MDBox>

            {!orderProductIsPrintedCard(order.product)?
                <MDBox>
                    <MDBox mt={2}>
                        <MDTypography variant={"h5"}>How do you want your tracking number to be displayed on the card?</MDTypography>
                    </MDBox>

                    <Grid container mt={2} alignItems={"center"} justifyContent={"space-between"} gap={2} flexWrap={"nowrap"}>
                        <Grid item xs={6}>
                            <RadioSelectWrapper selected={selectedTextType === "Handwritten"} onClick={() => {
                                setSelectedTextType("Handwritten")
                            }}>
                                <Grid container pl={1}>
                                    <Grid item>
                                        <FormControlLabel
                                            checked={selectedTextType === "Handwritten"}
                                            control={<Radio color={"primary"}/>}
                                            disableTypography={true}
                                            sx={{display: "flex"}}
                                            label={
                                                <MDTypography variant={"h5"}>Handwritten</MDTypography>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </RadioSelectWrapper>
                        </Grid>

                        <Grid item xs={6}>
                            <RadioSelectWrapper selected={selectedTextType === "Printed"} onClick={() => {
                                setSelectedTextType("Printed")
                            }}>
                                <Grid container pl={1}>
                                    <Grid item>
                                        <FormControlLabel
                                            checked={selectedTextType === "Printed"}
                                            control={<Radio color={"primary"}/>}
                                            disableTypography={true}
                                            sx={{display: "flex"}}
                                            label={
                                                <MDTypography variant={"h5"}>Printed</MDTypography>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </RadioSelectWrapper>
                        </Grid>
                    </Grid>
                </MDBox>
                :
                null
            }

            <Stack direction="row" spacing={1} marginTop={4} justifyContent="flex-end">
                <MDButton circular={true} color="light" onClick={() => setShow(false)}>
                    Cancel
                </MDButton>
                <MDButton circular={true} color="primary" onClick={confirm} disabled={!selectedTextType || !selectedNumber}>
                    Confirm
                </MDButton>
            </Stack>
        </DialogContent>
    </Dialog>
}

export default AddCallTrackingModal