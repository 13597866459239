import { GridActionsColDef } from '@mui/x-data-grid-pro';
import { GridSearchParams } from "models/gridSearchParams";

export const defaultSearchParams: GridSearchParams = {
  page: 1,
  pageSize: 100,
  sortBy: '',
  sortDirection: 'asc',
  search: '',
  filters: '',
};

export const actionColumnProps: Omit<GridActionsColDef, "getActions"> = {
  type: 'actions',
  field: 'actions',
  align: 'center',
  resizable: false,
  disableReorder: true,
  hideable: false,
}