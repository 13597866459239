import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const TextColorIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3611_23227)">
                    <path
                        d="M8.07636 14.584L8.07634 14.5839L8.07561 14.586C7.94103 14.9705 7.55183 15.2333 7.11 15.2333C6.39245 15.2333 5.90229 14.545 6.15276 13.9231L6.15302 13.9224L10.403 3.15099L10.4032 3.15062C10.6472 2.52484 11.2885 2.1 12 2.1C12.7115 2.1 13.3528 2.52484 13.5968 3.15062L13.597 3.15099L17.847 13.9224L17.8472 13.9231C18.098 14.5456 17.6168 15.2333 16.89 15.2333C16.449 15.2333 16.0585 14.9712 15.9139 14.5752L15.9138 14.5749L14.9238 11.8892L14.8997 11.8238H14.83H9.18H9.11068L9.08636 11.8887L8.07636 14.584ZM11.94 4.37619H11.8703L11.8462 4.44164L9.81616 9.95593L9.76663 10.0905H9.91H14.09H14.2334L14.1838 9.95593L12.1538 4.44164L12.1297 4.37619H12.06H11.94ZM2.1 20.0952C2.1 19.1074 2.95057 18.2905 4 18.2905H20C21.0494 18.2905 21.9 19.1074 21.9 20.0952C21.9 21.0831 21.0494 21.9 20 21.9H4C2.95057 21.9 2.1 21.0831 2.1 20.0952Z"
                        fill="currentColor" stroke="white" strokeWidth="0.2"/>
                    <rect x="2" y="18" width="20" height="4" rx="2" fill="#F05A2A"/>
                </g>
                <defs>
                    <clipPath id="clip0_3611_23227">
                        <rect width="24" height="24" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    )
}

export default TextColorIcon;