import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const FilterIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12 4.5C14.2958 4.5 16.5458 4.69334 18.7358 5.065C19.18 5.14 19.5 5.52834 19.5 5.97834V6.84834C19.5 7.09457 19.4515 7.33838 19.3573 7.56587C19.2631 7.79336 19.1249 8.00006 18.9508 8.17417L14.4242 12.7008C14.2501 12.8749 14.1119 13.0816 14.0177 13.3091C13.9235 13.5366 13.875 13.7804 13.875 14.0267V16.4658C13.8751 16.8141 13.7781 17.1556 13.595 17.4519C13.4119 17.7482 13.1499 17.9876 12.8383 18.1433L10.125 19.5V14.0267C10.125 13.7804 10.0765 13.5366 9.98228 13.3091C9.88805 13.0816 9.74994 12.8749 9.57583 12.7008L5.04917 8.17417C4.87506 8.00006 4.73695 7.79336 4.64272 7.56587C4.54849 7.33838 4.5 7.09457 4.5 6.84834V5.97834C4.5 5.52834 4.82 5.14 5.26417 5.065C7.48964 4.68821 9.74286 4.49921 12 4.5Z"
                    stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export default FilterIcon;