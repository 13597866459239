import {Coordinate} from "../models/coordinate";

export const mapOptions: google.maps.MapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeId: 'hybrid',
    mapTypeControlOptions: { mapTypeIds: [] }
}
export const mapContainerStyle = {
    width: '100%',
    height: '100%',
    borderRadius: "14px"
}

export const mapInfoTextContainer = {
    position: "absolute",
    bottom: 0,
    left: 0,
    transition: "transform 0.3s",
    backgroundColor: "#F05A2ACC",
    width: "100%",
    textAlign: "center",
    padding: "10px 0",
}

export function getCenterOfPolygon(coords: Coordinate[]) {
    let sumLat = 0;
    let sumLng = 0;

    coords.forEach(coord => {
        sumLat += coord.lat;
        sumLng += coord.lng;
    });

    const avgLat = sumLat / coords.length;
    const avgLng = sumLng / coords.length;

    return {lat: avgLat, lng: avgLng};
}

//Helper function that transforms lat and lng to an address string
export function getCoordinatesAddress(geocoder: any, lat: number, lng: number, cb: Function) {
    if (!lat || !lng) {
        cb({
            shortAddress: "",
            fullAddress: "",
            streetName: ""
        })
    }

    geocoder.geocode({location: new google.maps.LatLng(lat, lng)}, (geocoderResult: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
        if (status === google.maps.GeocoderStatus.OK) {
            if (geocoderResult[0] && geocoderResult[0].address_components[1]) {
                let streetName = ''
                let streetNumber = ''
                let locality = ''

                for (const component of geocoderResult[0].address_components) {
                    for (const type of component.types) {
                        if (type === "route") {
                            streetName = component.short_name
                        }
                        if (type === "street_number") {
                            streetNumber = component.long_name
                        }
                        if (type === "locality") {
                            locality = component.long_name
                        }
                    }
                }

                let fullAddress = geocoderResult[0].address_components[1].short_name
                let shortAddress = fullAddress
                if (!streetName) {
                    streetName = shortAddress
                }
                if (streetNumber && streetName) {
                    shortAddress = `${streetNumber} ${streetName}`
                    if (locality) {
                        fullAddress = `${streetNumber} ${streetName}, ${locality}`
                    }
                }

                cb({
                    shortAddress: shortAddress,
                    fullAddress: fullAddress,
                    streetName: streetName
                })
            } else {
                cb({
                    shortAddress: "",
                    fullAddress: "",
                    streetName: ""
                })
            }
        } else {
            cb({
                shortAddress: "",
                fullAddress: "",
                streetName: ""
            })
        }
    });
}

//Helper function that transforms an address string to a lat and lng object
export function getAddressCoordinates(geocoder: any, address: string, cb: Function) {
    if (!address) {
        cb(null, null)
    }

    geocoder.geocode({address}, (results: any, status: any) => {
        if (status === 'OK' && results && results.length > 0) {
            const {lat, lng} = results[0].geometry.location

            cb(lat(), lng())
        }
        if (status === 'ZERO_RESULTS') {
            cb(null, null)
        }
    })
}