import React from 'react';

const SocialFb = (props: any) => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="24" fill="#425893"/>
            <path
                d="M22.0797 25.0395C22.0197 25.0395 20.6997 25.0395 20.0997 25.0395C19.7797 25.0395 19.6797 24.9195 19.6797 24.6195C19.6797 23.8195 19.6797 22.9995 19.6797 22.1995C19.6797 21.8795 19.7997 21.7795 20.0997 21.7795H22.0797C22.0797 21.7195 22.0797 20.5595 22.0797 20.0195C22.0797 19.2195 22.2197 18.4595 22.6197 17.7595C23.0397 17.0395 23.6397 16.5595 24.3997 16.2795C24.8997 16.0995 25.3997 16.0195 25.9397 16.0195H27.8997C28.1797 16.0195 28.2997 16.1395 28.2997 16.4195V18.6995C28.2997 18.9795 28.1797 19.0995 27.8997 19.0995C27.3597 19.0995 26.8197 19.0995 26.2797 19.1195C25.7397 19.1195 25.4597 19.3795 25.4597 19.9395C25.4397 20.5395 25.4597 21.1195 25.4597 21.7395H27.7797C28.0997 21.7395 28.2197 21.8595 28.2197 22.1795V24.5995C28.2197 24.9195 28.1197 25.0195 27.7797 25.0195C27.0597 25.0195 25.5197 25.0195 25.4597 25.0195V31.5395C25.4597 31.8795 25.3597 31.9995 24.9997 31.9995C24.1597 31.9995 23.3397 31.9995 22.4997 31.9995C22.1997 31.9995 22.0797 31.8795 22.0797 31.5795C22.0797 29.4795 22.0797 25.0995 22.0797 25.0395Z"
                fill="#F3F5FB"/>
        </svg>
    )
}

export default SocialFb