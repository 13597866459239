import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const AddTagToRecipientIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 3.75C9.075 3.75 6.75 6.075 6.75 9C6.74487 9.86015 6.95239 10.7082 7.35408 11.4688C7.75578 12.2295 8.33921 12.879 9.0525 13.3597C6.3825 14.5095 4.5 17.169 4.5 20.25H6C6 16.95 8.7 14.25 12 14.25C14.925 14.25 17.25 11.925 17.25 9C17.25 6.075 14.925 3.75 12 3.75ZM12 5.25C14.1 5.25 15.75 6.9 15.75 9C15.75 11.1 14.1 12.75 12 12.75C9.9 12.75 8.25 11.1 8.25 9C8.25 6.9 9.9 5.25 12 5.25ZM15.75 14.25C14.925 14.25 14.25 14.925 14.25 15.75V19.05L18.75 23.55C19.05 23.85 19.425 24 19.8 24C20.175 24 20.55 23.85 20.85 23.55L23.55 20.85C23.85 20.55 24 20.175 24 19.8C24 19.425 23.85 19.05 23.55 18.75L19.05 14.25H15.75ZM15.75 15.75H18.45L22.5 19.8L19.8 22.5L15.75 18.45V15.75ZM17.25 16.5C17.0511 16.5 16.8603 16.579 16.7197 16.7197C16.579 16.8603 16.5 17.0511 16.5 17.25C16.5 17.4489 16.579 17.6397 16.7197 17.7803C16.8603 17.921 17.0511 18 17.25 18C17.4489 18 17.6397 17.921 17.7803 17.7803C17.921 17.6397 18 17.4489 18 17.25C18 17.0511 17.921 16.8603 17.7803 16.7197C17.6397 16.579 17.4489 16.5 17.25 16.5Z" fill="currentColor"/>
            </svg>

        </SvgIcon>
    )
}

export default AddTagToRecipientIcon;