// @ts-ignore
import Retool from "react-retool"
import MDBox from "material-ui/components/MDBox";

function MatchbackPage(){
    return <MDBox style={{ height: "90vh" }}>
        <Retool url="https://lettrlabs.retool.com/p/matchback-order-recipients" />
    </MDBox>
}

export default MatchbackPage