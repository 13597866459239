import * as Yup from 'yup';

export const leadRevealFinishSchema = Yup.object().shape({
  accountName: Yup.string().when('useServiceAccount', {
    is: false,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired(),
  }),
  useServiceAccount: Yup.boolean().required('Required'),
  hasDefaultCreditCard: Yup.boolean().oneOf([true], 'Required').required('Required'),
  hasInstalledTagManagerCorrectly: Yup.boolean().oneOf([true], 'Required').required('Required'),
  hasActiveSubscription: Yup.boolean().oneOf([true], 'Required').required('Required'),
  monthlyLeads: Yup.number()
    .oneOf([250, 500, 1000, 5000, 10000, 50000, 100000, 500000, 1000000], 'Required')
    .required('Required'),
  websiteUrl: Yup.string().required('Required').url('Must be a valid url'),
});

export const leadRevealManageSchema = Yup.object().shape({
  monthlyLeads: Yup.number()
    .oneOf([250, 500, 1000, 5000, 10000, 50000, 100000, 500000, 1000000], 'Required')
    .required('Required'),
  createAntiPixel: Yup.boolean(),
  antiPixelPages: Yup.array()
    .of(Yup.string())
    .when('createAntiPixel', {
      is: true,
      then: Yup.array().of(Yup.string()).min(1, 'Must have at least one.'),
      otherwise: Yup.array().of(Yup.string()).notRequired(),
    }),
});
