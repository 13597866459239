import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const BringToFrontIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 12L3 17L13 22L23 17L13 12Z" fill="white" stroke="black" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <path d="M13 7L3 12L13 17L23 12L13 7Z" fill="white" stroke="black" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <path d="M12.9131 2.03465L2.91309 7.03465L12.9131 12.0347L22.9131 7.03465L12.9131 2.03465Z"
                      fill="#B5B5B5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export default BringToFrontIcon;