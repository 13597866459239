import { ChangeEvent, useEffect, useState } from "react";
import { Box, Divider, Grid, MenuItem, Paper, Select, Stack, Tooltip, Typography } from "@mui/material";
import { useQuery, useMutation } from "@tanstack/react-query";
import { createGlobalStyle } from "styled-components";
import MDButton from "material-ui/components/MDButton";
import { Order } from "models/order";
import { Font } from "models/font";
import Info2Icon from "assets/icons/info-2";
import MDInput from "material-ui/components/MDInput";
import PhoneIcon from "assets/icons/phone";
import AddTextIcon from "assets/icons/add-text";
import AddImageIcon from "assets/icons/add-image";
import { addText, insertOrReplaceTextAtCursor } from "helpers/ImgLyHelper";
import { useEditor } from "context/editor-context";
import { addressMailMergeFields, customMailMergeFields, radiusMailMergeFields } from "helpers/OrderHelper";
import { orderProductIsPrintedCard, ProductTypes } from "models/enums/ProductTypes";
import OrderService from "services/order";
import { SceneBlockNames } from "models/enums/SceneBlockNames";
import { OrderParameter } from "models/orderParameter";
import { toCamelCase } from "helpers/StringHelper";
import PhoneNumberService from "services/phone-number";
import { formatUSPhoneNumber } from "helpers/phoneNumberFormat";
import EditIcon from "assets/icons/edit";
import IncrementDecrementButton from "components/increment-decrement-button";
import TextBar from "./text-bar";
import ImageBar from "./image-bar";
import QRCodeModal from "./qr-code-modal";
import AddCallTrackingModal from "./add-call-tracking-modal";
import { useGlobal } from "context/global-context";
import { OrderFontDefaults } from "models/enums/OrderDefaults";

interface HandwritingFontsProps {
  fonts: Font[];
}

const HandwritingFonts = createGlobalStyle<HandwritingFontsProps>`
  ${({ fonts }) => fonts.map((font) => `
    @font-face {
      src: url('${font.url}');
      font-family: '${font.label}';
    }
  `).join('')}
`;

interface DesignSubpageLeftSideProps {
  order: Order
  setOrder: Function
  orderParameters: OrderParameter[]
  setOrderParameters: Function
}

const DesignSubpageLeftSide = ({ order, setOrder, orderParameters, setOrderParameters }: DesignSubpageLeftSideProps) => {
  const { postOrder } = OrderService()
  const { getPhoneNumbers } = PhoneNumberService()
  const { setShowLoader, allowDiscount } = useGlobal()
    const { creativeEngine, generateQrCodeFn, removeQrCodeFn, currentPageBlockId, selectedBlocks, checkForBlocksOnGivenSide, updateOrderTextFromCreativeEngine, fonts, textSelectRange, setSelectedBlocks } = useEditor()

  const [showQRCodeModal, setShowQRCodeModal] = useState<boolean>(false)
  const [showAddTrackingNumberModal, setShowAddTrackingNumberModal] = useState<boolean>(false)
  const [showImageBar, setShowImageBar] = useState<boolean>(false)
  const [showSelect, setShowSelect] = useState<string>("")

  const { mutate: updateOrder } = useMutation({
    mutationFn: (updatedOrder: Order) => postOrder(updatedOrder),
    onMutate: () => setShowLoader(true),
    onSuccess: () => setShowLoader(false)
  })

  const { data: callTrackingData, isFetched: isCallTrackingFetched } = useQuery({
    queryKey: ["phoneNumbers"],
    queryFn: getPhoneNumbers,
    refetchOnWindowFocus: false
  })

  const callTrackingNumbers = callTrackingData?.payload || {}

  const mailMergeFieldsOptions: { title: string, fields: string[] }[] = [
    { title: "Address Fields", fields: addressMailMergeFields },
    { title: "Custom Fields", fields: customMailMergeFields },
    { title: "Radius Mail Fields", fields: radiusMailMergeFields }
  ]

  useEffect(() => {
    if (selectedBlocks?.length) setShowImageBar(false)
  }, [selectedBlocks]);

  const handleQRCodeGeneration = () => {
    setShowQRCodeModal(true)
    generateQrCodeFn()
  }

  const removeQrCode = async () => {
    setShowLoader(true)
    await removeQrCodeFn()
    const updatedOrder: Order = {
      ...order,
      qrAngle: null,
      qrCodeHeight: null,
      qrCodeWidth: null,
      qrCodeX: null,
      qrCodeY: null,
      qrUrl: null,
      useQr: false
    }
    setOrder(updatedOrder)
    await updateOrder(updatedOrder)
    if(order?.product === ProductTypes.HandwrittenBiFoldCard) checkForBlocksOnGivenSide(0)
    setShowLoader(false)
  }

  const updateOrderParameter = (key: string, value: string) => {
    setOrderParameters((prevState: OrderParameter[]) => {
      let newState = [...prevState]
      for (let a of newState) {
        if (a.parameterType === key) {
          a.parameterText = value
          break
        }
      }
      return newState
    })
  }

  const handleDeleteTrackingPhoneNumber = () => {
    const printedBlock = creativeEngine.block.findByName(SceneBlockNames.TrackingPhoneNumber)
    if (printedBlock.length) creativeEngine.block.destroy(printedBlock[0])
    const updatedText = order.text.replaceAll(formatUSPhoneNumber(order.trackingPhoneNumber), "")
    const updatedText2 = order.text2.replaceAll(formatUSPhoneNumber(order.trackingPhoneNumber), "")
    setOrder((prevState: Order) => ({ ...prevState, trackingPhoneNumber: null as string, text: updatedText, text2: updatedText2 }))
  }

  const renderLabel = (value: string) => {
    const selectedFont = fonts?.find(a => a.label === value)
    return (selectedFont ? selectedFont : fonts[0]).label
  }

  const renderAdornment = (type: "minus" | "plus") => (
    <IncrementDecrementButton
      type={type}
      value={order.fontSize}
      minValue={OrderFontDefaults.MinHandWrittenTextFontSize}
      maxValue={OrderFontDefaults.MaxHandWrittenTextFontSize}
      setValue={(newValue) => setOrder((prev: Order) => ({ ...prev, fontSize: newValue }))}
    />
  )

  const renderMailMergeFields = (title: string, fields: string[]) => (
    <>
      <Select
        fullWidth
        value={""}
        displayEmpty
        renderValue={() => title}
        open={showSelect === title}
        onClose={() => setShowSelect("")}
        onOpen={() => {
          creativeEngine.editor.setEditMode('Transform')
          setShowSelect(title)
        }}
      >
        {fields.map((field, index) => {
          if(field === "DISCOUNT CODE" && !allowDiscount) return null
          return (
          <MenuItem key={index} onClick={() => {
            if(selectedBlocks?.[0].name === SceneBlockNames.HandwrittenText2) {
              insertOrReplaceTextAtCursor(creativeEngine, SceneBlockNames.HandwrittenText2, "{{" + field + "}}", textSelectRange)
            } else {
              insertOrReplaceTextAtCursor(creativeEngine, SceneBlockNames.HandwrittenText, "{{" + field + "}}", textSelectRange)
            }
            updateOrderTextFromCreativeEngine()
            setShowSelect("")
          }}>
            {toCamelCase(field)}
          </MenuItem>
        )})}
      </Select>

      {orderParameters.filter((op) => fields.includes(op.parameterType)).map((value) => (
        <Box key={value.id} display={"flex"} gap={1} alignItems={"center"} ml={2}>
          <Typography minWidth={"130px"} variant={"h5"} fontWeight={"bold"}>{toCamelCase(value.parameterType)}</Typography>
          {value.parameterType === "DISCOUNT CODE" ? (
            <Typography variant="caption">
              Available for Klaviyo and single orders only
            </Typography>
          ) : ( 
            <MDInput
              fullWidth
              size="small"
              placeholder={"Fallback Value"}
              value={value.parameterText}
              onChange={(e: ChangeEvent<HTMLInputElement>) => updateOrderParameter(value.parameterType, e.target.value)}
            />
          )}  
        </Box>
      ))}
    </>
  )

  return (
    <Paper variant="outlined">
      <Stack p={2} gap={1}>
        {!orderProductIsPrintedCard(order.product) && fonts &&
          <>
            <HandwritingFonts fonts={fonts} />
            <Typography variant="body2" fontWeight={"bold"}>Handwriting Style</Typography>
            <Box display="flex" gap={1}>
              <Select
                fullWidth
                value={order?.font}
                onOpen={() => creativeEngine.editor.setEditMode('Transform')}
                onChange={(event) => setOrder((prevState: Order) => ({ ...prevState, font: event.target.value }))}
                renderValue={(value) => (
                  <Typography sx={{ fontFamily: renderLabel(order?.font), lineHeight: 1 }}>
                    {renderLabel(value)}
                  </Typography>
                )}
              >
                {fonts.map((font) => (
                  <MenuItem key={font.id} value={font.label}>
                    <Typography sx={{ fontFamily: font.label }}>
                      {font.label} - The quick brown fox jumped over the lazy fox
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
              <MDInput
                value={order.fontSize}
                sx={{ width: "160px" }}
                InputProps={{ startAdornment: renderAdornment("minus"), endAdornment: renderAdornment("plus") }}
                inputProps={{ disabled: true, sx: { textAlign: "center", paddingRight: 0 } }}
              />
            </Box>
            <Box><Divider light /></Box>
          </>
        }

        <Typography variant="body2" fontWeight={"bold"}>Card Formatting</Typography>
        <Grid container gap={1}>
          <Grid item xs>
            <MDButton fullWidth color={"light"} onClick={() => {
              if (selectedBlocks?.length) creativeEngine.block.setSelected(selectedBlocks[0].id, false)
              setShowImageBar(false)
              addText(creativeEngine, currentPageBlockId)
              if(order?.product === ProductTypes.HandwrittenBiFoldCard) checkForBlocksOnGivenSide(0)
              else checkForBlocksOnGivenSide(1)
              setSelectedBlocks(null)
            }}>
              <AddTextIcon sx={{ mr: 1 }} />
              Text
            </MDButton>
          </Grid>
          <Grid item xs>
            <MDButton fullWidth color={"light"} active={showImageBar} onClick={() => {
              if (selectedBlocks?.length) creativeEngine.block.setSelected(selectedBlocks[0].id, false)
              setShowImageBar(prevState => !prevState)
              setSelectedBlocks(null)
            }}>
              <AddImageIcon sx={{ mr: 1 }} />
              Image
            </MDButton>
          </Grid>
          {!orderProductIsPrintedCard(order.product) && order.product !== ProductTypes.HandwrittenBiFoldCard && (
            <Grid item xs>
              <MDButton
                fullWidth
                color={"light"}
                sx={{ minWidth: 180 }}
                onClick={() => setOrder((prevState: Order) => ({ ...prevState, noFrontLogo: prevState.noFrontLogo === "Yes" ? "No" : "Yes" }))}
              >
                <EditIcon sx={{ mr: 1 }} />
                {order.noFrontLogo === "Yes" ? "Add Logo" : "Remove Logo"}
              </MDButton>
            </Grid>
          )}
        </Grid>
        {(selectedBlocks && selectedBlocks.length && selectedBlocks[0].type === "//ly.img.ubq/text" && selectedBlocks[0].name !== SceneBlockNames.HandwrittenText && selectedBlocks[0].name !== SceneBlockNames.HandwrittenText2) &&
          <TextBar />
        }
        {(showImageBar || (selectedBlocks && selectedBlocks.length && selectedBlocks[0].type === "//ly.img.ubq/graphic" && selectedBlocks[0].name !== SceneBlockNames.QRCode)) &&
          <ImageBar order={order} />
        }

        <Box><Divider light /></Box>

        <Box display="flex" gap={1}>
          <Typography variant="body2" fontWeight={"bold"}>Tracking & Attribution</Typography>
          <Tooltip title={"Add a trackable QR Code or phone number to see who engages with your cards in real time!"}>
            <Box><Info2Icon color={"light2"} /></Box>
          </Tooltip>
        </Box>
        <Grid container alignItems={"center"} gap={1}>
          <Grid item xs>
            {order?.useQr ? (
              <MDButton color={"light"} onClick={removeQrCode}>
                Remove Qr Code
              </MDButton>
            ) : (
              <MDInput
                type="text"
                fullWidth
                value={order?.qrUrl ?? ""}
                placeholder={"Enter the URL of your QR Code"}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setOrder((prevState: Order) => ({ ...prevState, qrUrl: event.target.value }))}
                InputProps={{
                  endAdornment: order?.qrUrl && (
                    <MDButton size={"small"} color={"primary"} onClick={handleQRCodeGeneration}>
                      Generate
                    </MDButton>
                  )
                }}
              />
            )}
          </Grid>
          <Grid item>
            {order.trackingPhoneNumber ? (
              <MDButton fullWidth color={"light"} onClick={handleDeleteTrackingPhoneNumber}>
                Remove Call Tracking
              </MDButton>
            ) : (
              <Tooltip title={isCallTrackingFetched ? (callTrackingNumbers?.length ? "" : "You don't have any tracking phone numbers. You can purchase them in the call tracking page") : ""}>
                <Box>
                  <MDButton
                    fullWidth
                    color={"light"}
                    startIcon={<PhoneIcon />}
                    disabled={!callTrackingNumbers?.length}
                    onClick={() => setShowAddTrackingNumberModal(true)}
                  >
                    Call Tracking
                  </MDButton>
                </Box>
              </Tooltip>
            )}
          </Grid>
        </Grid>

        {!orderProductIsPrintedCard(order.product) &&
          <>
            <Box><Divider light /></Box>
            <Box display={"flex"} gap={1}>
              <Typography variant="body2" fontWeight={"bold"}>Mail-Merge Fields</Typography>
              <Tooltip title={"Mail merge fields are variables that will be replaced with your upload file. This allows you to personalize each card simply based on the data available. Additionally, fallback values will be used when the data file upload is missing data for a given individual, ensuring there are no unintended blank spaces on your card."}>
                <Box><Info2Icon color={"light2"} /></Box>
              </Tooltip>
            </Box>

            {mailMergeFieldsOptions.map(({ title, fields }) => renderMailMergeFields(title, fields))}
          </>
        }
      </Stack>

      <QRCodeModal show={showQRCodeModal} setShow={setShowQRCodeModal} removeQrCode={removeQrCode} />
      <AddCallTrackingModal show={showAddTrackingNumberModal} setShow={setShowAddTrackingNumberModal} order={order} setOrder={setOrder} />
    </Paper>
  )
}

export default DesignSubpageLeftSide