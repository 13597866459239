import MDTypography from "material-ui/components/MDTypography"
import React, {useEffect, useState} from "react"
import {
    Alert,
    Autocomplete, Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Snackbar,
    Stack,
    TextField
} from "@mui/material"
import MDBox from "material-ui/components/MDBox"

import MDButton from "material-ui/components/MDButton"
import {Formik, Form, Field} from "formik"
import {OrderRecipientValidation} from "models/modelValidations/orderRecipient"
import {IOrderRecipient} from "models/orderRecipient"
import {States} from "models/enums/States"
import {useMutation, useQueryClient} from "@tanstack/react-query";
import OrderRecipientService from "services/order-recipient";
import { useGlobal } from "context/global-context"

interface AddNewRecipientModalProps {
    show: boolean
    setShow: Function
    orderId: number
    recipientToManage?: IOrderRecipient
    setRecipientToManage: Function
    setShowGrid: Function
}

function AddRecipientModal({show, setShow, orderId, recipientToManage, setRecipientToManage, setShowGrid}: AddNewRecipientModalProps) {
    const {setShowLoader} = useGlobal()
    const {postOrderRecipient} = OrderRecipientService()
    const queryClient = useQueryClient()
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

    const [showAdditionalFields, setShowAdditionalFields] = useState<boolean>(false)

    const {mutate: postRecipient, isPending} = useMutation({
        mutationFn: (recipient: any) => {
            return postOrderRecipient(recipient)
        },
        onMutate: () => setShowLoader(true),
        onSuccess: (data) => {
            if(data.doNotMail) setShowErrorMessage(true)
            setShowLoader(false)
            setShowGrid(true)
            setShow(false)
            queryClient.invalidateQueries({queryKey: ["orderRecipients", orderId]})
        }
    })
    
    useEffect(function () {
        if (!show) {
            setRecipientToManage(null)
        }
    }, [show, setRecipientToManage])

    const defaultRecipient: IOrderRecipient = recipientToManage || {
        id: 0,
        orderId: orderId,
        salutation: null,
        firstName: null,
        lastName: null,
        toOrganization: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zipCode: null,
        country: null,
        custom1: null,
        custom2: null,
        custom3: null,
        custom4: null,
        custom5: null,
        custom6: null,
    }

    const states = Object.keys(States);

    return (
    <>
        <Dialog open={show} onClose={() => setShow(false)}>
            <DialogContent>
                <DialogTitle>Add Recipient</DialogTitle>

                <Formik
                    initialValues={defaultRecipient}
                    validationSchema={OrderRecipientValidation}
                    onSubmit={(values, formikHelpers) => {
                        postRecipient(values)
                    }}
                >
                    {({errors, touched}) => (
                        <Form>
                            <Grid container mt={2} gap={"20px"} justifyContent={"space-between"}>
                                <Grid item flex={1}>
                                    <MDTypography variant={"h5"} color={"secondary"} fontWeight={"bold"} mb={2}>First name</MDTypography>

                                    <Field name={"firstName"}>
                                        {({field, form, meta}: { field: any; form: any; meta: any }) => (
                                            <>
                                                <TextField
                                                    fullWidth={true}
                                                    type="text"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    {...field}
                                                />
                                                {meta.touched && meta.error && (
                                                    <MDTypography
                                                        variant={"h6"}
                                                        fontWeight={"regular"}
                                                        color="error"
                                                    >
                                                        {meta.error}
                                                    </MDTypography>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </Grid>

                                <Grid item flex={1}>
                                    <MDTypography variant={"h5"} color={"secondary"} fontWeight={"bold"} mb={2}>Last name</MDTypography>

                                    <Field name={"lastName"}>
                                        {({field, form, meta}: { field: any; form: any; meta: any }) => (
                                            <>
                                                <TextField
                                                    fullWidth={true}
                                                    type="text"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    {...field}
                                                />
                                                {meta.touched && meta.error && (
                                                    <MDTypography
                                                        variant={"h6"}
                                                        fontWeight={"regular"}
                                                        color="error"
                                                    >
                                                        {meta.error}
                                                    </MDTypography>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>

                            <MDBox mt={2}>
                                <Grid container alignItems={"center"} mb={2}>
                                    <Grid item>
                                        <MDTypography variant={"h5"} color={"secondary"} fontWeight={"bold"}>Organization</MDTypography>
                                    </Grid>

                                    <Grid item ml={2}>
                                        <MDTypography variant={"h6"} color={"light2"} fontWeight={"regular"}>(Optional)</MDTypography>
                                    </Grid>
                                </Grid>

                                <Field name={"toOrganization"}>
                                    {({field, form, meta}: { field: any; form: any; meta: any }) => (
                                        <>
                                            <TextField
                                                fullWidth={true}
                                                type="text"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                {...field}
                                            />
                                            {meta.touched && meta.error && (
                                                <MDTypography
                                                    variant={"h6"}
                                                    fontWeight={"regular"}
                                                    color="error"
                                                >
                                                    {meta.error}
                                                </MDTypography>
                                            )}
                                        </>
                                    )}
                                </Field>
                            </MDBox>

                            <MDBox mt={2}>
                                <MDTypography variant={"h5"} color={"secondary"} fontWeight={"bold"} mb={2}>Address</MDTypography>

                                <MDBox>
                                    <Field name={"address1"}>
                                        {({field, form, meta}: { field: any; form: any; meta: any }) => (
                                            <>
                                                <TextField
                                                    fullWidth={true}
                                                    type="text"
                                                    placeholder={"Address 1"}
                                                    {...field}
                                                />
                                                {meta.touched && meta.error && (
                                                    <MDTypography
                                                        variant={"h6"}
                                                        fontWeight={"regular"}
                                                        color="error"
                                                    >
                                                        {meta.error}
                                                    </MDTypography>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </MDBox>

                                <MDBox mt={2}>
                                    <Field name={"address2"}>
                                        {({field, form, meta}: { field: any; form: any; meta: any }) => (
                                            <>
                                                <TextField
                                                    fullWidth={true}
                                                    type="text"
                                                    placeholder="Address 2"
                                                    {...field}
                                                />
                                                {meta.touched && meta.error && (
                                                    <MDTypography
                                                        variant={"h6"}
                                                        fontWeight={"regular"}
                                                        color="error"
                                                    >
                                                        {meta.error}
                                                    </MDTypography>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </MDBox>

                                <Grid container mt={2} gap={"20px"} alignItems={"flex-start"} justifyContent={"space-between"}>
                                    <Grid item flex={1}>
                                        <Field name={"city"}>
                                            {({field, form, meta}: { field: any; form: any; meta: any }) => (
                                                <>
                                                    <TextField
                                                        fullWidth={true}
                                                        type="text"
                                                        placeholder="City"
                                                        {...field}
                                                    />
                                                    {meta.touched && meta.error && (
                                                        <MDTypography
                                                            variant={"h6"}
                                                            fontWeight={"regular"}
                                                            color="error"
                                                        >
                                                            {meta.error}
                                                        </MDTypography>
                                                    )}
                                                </>
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item flex={1}>
                                        <Field name={"state"}>
                                            {({field, form, meta}: { field: any; form: any; meta: any }) => {
                                                return (
                                                    <>
                                                        <Autocomplete
                                                            fullWidth={true}
                                                            options={states}
                                                            value={field.value}
                                                            onChange={(_, value) => {
                                                                form.setFieldValue("state", value)
                                                            }}
                                                            onInputChange={(_, value) => {
                                                                if(!recipientToManage && States[value]) form.setFieldValue("state", value)
                                                            }}
                                                            renderInput={(props: any) => (
                                                                <TextField
                                                                    {...props}
                                                                    placeholder={"State"}
                                                                />
                                                            )}
                                                        />

                                                        {meta.touched && meta.error && (
                                                            <MDTypography
                                                                variant={"h6"}
                                                                fontWeight={"regular"}
                                                                color="error"
                                                            >
                                                                {meta.error}
                                                            </MDTypography>
                                                        )}
                                                    </>
                                                )
                                            }}
                                        </Field>
                                    </Grid>

                                    <Grid item flex={1}>
                                        <Field name={"zipCode"}>
                                            {({field, form, meta}: { field: any; form: any; meta: any }) => (
                                                <>
                                                    <TextField
                                                        fullWidth={true}
                                                        inputProps={{
                                                            maxLength: 5,
                                                        }}
                                                        type="text"
                                                        placeholder={"Zip"}
                                                        {...field}
                                                    />
                                                    {meta.touched && meta.error && (
                                                        <MDTypography
                                                            variant={"h6"}
                                                            fontWeight={"regular"}
                                                            color="error"
                                                        >
                                                            {meta.error}
                                                        </MDTypography>
                                                    )}
                                                </>
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>
                            </MDBox>

                            <MDBox mt={1}>
                                <Grid container gap={0.5} alignItems={"center"} onClick={() => {
                                    setShowAdditionalFields(prevState => !prevState)
                                }}>
                                    <Grid item>
                                        <Checkbox checked={showAdditionalFields}/>
                                    </Grid>

                                    <Grid item>
                                        <MDTypography variant={"h5"} sx={{cursor: "pointer"}}>Show optional fields</MDTypography>
                                    </Grid>
                                </Grid>
                            </MDBox>

                            {showAdditionalFields?
                                <MDBox mt={2}>
                                    <Grid container alignItems={"center"} mb={2}>
                                        <Grid item>
                                            <MDTypography variant={"h5"} color={"secondary"} fontWeight={"bold"}>Custom Fields</MDTypography>
                                        </Grid>

                                        <Grid item ml={2}>
                                            <MDTypography variant={"h6"} color={"light2"} fontWeight={"regular"}>(Optional)</MDTypography>
                                        </Grid>
                                    </Grid>

                                    <MDBox mt={2}>
                                        <Field name={"custom1"}>
                                            {({field, form, meta}: { field: any; form: any; meta: any }) => (
                                                <>
                                                    <TextField
                                                        fullWidth={true}
                                                        type="text"
                                                        placeholder="Custom 1"
                                                        {...field}
                                                    />
                                                    {meta.touched && meta.error && (
                                                        <MDTypography
                                                            variant={"h6"}
                                                            fontWeight={"regular"}
                                                            color="error"
                                                        >
                                                            {meta.error}
                                                        </MDTypography>
                                                    )}
                                                </>
                                            )}
                                        </Field>
                                    </MDBox>

                                    <MDBox mt={2}>
                                        <Field name={"custom2"}>
                                            {({field, form, meta}: { field: any; form: any; meta: any }) => (
                                                <>
                                                    <TextField
                                                        fullWidth={true}
                                                        type="text"
                                                        placeholder="Custom 2"
                                                        {...field}
                                                    />
                                                    {meta.touched && meta.error && (
                                                        <MDTypography
                                                            variant={"h6"}
                                                            fontWeight={"regular"}
                                                            color="error"
                                                        >
                                                            {meta.error}
                                                        </MDTypography>
                                                    )}
                                                </>
                                            )}
                                        </Field>
                                    </MDBox>

                                    <MDBox mt={2}>
                                        <Field name={"custom3"}>
                                            {({field, form, meta}: { field: any; form: any; meta: any }) => (
                                                <>
                                                    <TextField
                                                        fullWidth={true}
                                                        type="text"
                                                        placeholder="Custom 3"
                                                        {...field}
                                                    />
                                                    {meta.touched && meta.error && (
                                                        <MDTypography
                                                            variant={"h6"}
                                                            fontWeight={"regular"}
                                                            color="error"
                                                        >
                                                            {meta.error}
                                                        </MDTypography>
                                                    )}
                                                </>
                                            )}
                                        </Field>
                                    </MDBox>

                                    <MDBox mt={2}>
                                        <Field name={"custom4"}>
                                            {({field, form, meta}: { field: any; form: any; meta: any }) => (
                                                <>
                                                    <TextField
                                                        fullWidth={true}
                                                        type="text"
                                                        placeholder="Custom 4"
                                                        {...field}
                                                    />
                                                    {meta.touched && meta.error && (
                                                        <MDTypography
                                                            variant={"h6"}
                                                            fontWeight={"regular"}
                                                            color="error"
                                                        >
                                                            {meta.error}
                                                        </MDTypography>
                                                    )}
                                                </>
                                            )}
                                        </Field>
                                    </MDBox>

                                    <MDBox mt={2}>
                                        <Field name={"custom5"}>
                                            {({field, form, meta}: { field: any; form: any; meta: any }) => (
                                                <>
                                                    <TextField
                                                        fullWidth={true}
                                                        type="text"
                                                        placeholder="Custom 5"
                                                        {...field}
                                                    />
                                                    {meta.touched && meta.error && (
                                                        <MDTypography
                                                            variant={"h6"}
                                                            fontWeight={"regular"}
                                                            color="error"
                                                        >
                                                            {meta.error}
                                                        </MDTypography>
                                                    )}
                                                </>
                                            )}
                                        </Field>
                                    </MDBox>

                                    <MDBox mt={2}>
                                        <Field name={"custom6"}>
                                            {({field, form, meta}: { field: any; form: any; meta: any }) => (
                                                <>
                                                    <TextField
                                                        fullWidth={true}
                                                        type="text"
                                                        placeholder="Custom 6"
                                                        {...field}
                                                    />
                                                    {meta.touched && meta.error && (
                                                        <MDTypography
                                                            variant={"h6"}
                                                            fontWeight={"regular"}
                                                            color="error"
                                                        >
                                                            {meta.error}
                                                        </MDTypography>
                                                    )}
                                                </>
                                            )}
                                        </Field>
                                    </MDBox>

                                    <MDBox mt={2}>
                                        <Field name={"salutation"}>
                                            {({field, form, meta}: { field: any; form: any; meta: any }) => (
                                                <>
                                                    <TextField
                                                        fullWidth={true}
                                                        type="text"
                                                        placeholder="Salutation"
                                                        {...field}
                                                    />
                                                    {meta.touched && meta.error && (
                                                        <MDTypography
                                                            variant={"h6"}
                                                            fontWeight={"regular"}
                                                            color="error"
                                                        >
                                                            {meta.error}
                                                        </MDTypography>
                                                    )}
                                                </>
                                            )}
                                        </Field>
                                    </MDBox>
                                </MDBox>
                                :
                                null
                            }

                            <Stack direction="row" spacing={1} marginTop={2} justifyContent="flex-end">
                                <MDButton circular={true} color="light" onClick={() => setShow(false)}>
                                    Cancel
                                </MDButton>
                                <MDButton
                                    circular={true}
                                    type="submit"
                                    color="primary"
                                    disabled={isPending}
                                >
                                    Save
                                </MDButton>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
        <Snackbar
            open={showErrorMessage}
            autoHideDuration={6000}
            onClose={() => setShowErrorMessage(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
            <Alert onClose={() => setShowErrorMessage(false)} severity="warning" variant="filled" sx={{ py: 0 }}>
                Recipient exists in the "Do Not Mail" list and was not added to the order.
            </Alert>
        </Snackbar>
    </>
    )
}

export default AddRecipientModal