export function filterRows(data: any[], search: string) {
    return data.filter((row) =>
        Object.values(row).some(
            (cellValue) =>
                cellValue &&
                cellValue
                    .toString()
                    .toLowerCase()
                    .includes(search.toLowerCase())
        )
    )
}