import { useNavigate } from "react-router-dom";
import { Field, FieldProps, Form, Formik } from "formik";
import { Box, Dialog, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import MDInput from "material-ui/components/MDInput";
import MDButton from "material-ui/components/MDButton";
import FormikErrorMessage from "components/formik-error-message";
import lettrLabsLogo from "assets/images/logo-inverted.png"
import useDataSourcesService from "services/data-sources";
import { smartAutomationSourceValidation } from "services/smart-automation/smart-automation.schema";
import { AuthenticatedStatusType } from "services/data-sources/data-sources.types";
import Loader from "components/loader";
import { isLettrLabsUser } from "helpers/profileHelper";
import ProfileService from "services/profile";

interface NewSmartAutomationModalProps {
  open: boolean
  setOpen: (Open: boolean) => void
}

const NewSmartAutomationModal = ({ open, setOpen }: NewSmartAutomationModalProps) => {
  const navigate = useNavigate()
  const { getProfile } = ProfileService()
  const { getAllDataSources } = useDataSourcesService();

  const {data: profile } = useQuery({
      queryKey: ["profile"],
      queryFn: getProfile,
      refetchOnWindowFocus: false
  })

  const { data: dataSourcesData, isLoading } = useQuery({
    queryKey: ["getAllDataSources"],
    queryFn: () => getAllDataSources(),
    refetchOnWindowFocus: false
  });

  const dataSources = dataSourcesData?.payload

  const handleSubmit = (values: { name: string; dataSourceId: string }) => {
    navigate(`/smart-automation/new?source=${values.dataSourceId}&name=${values.name}`)
    setOpen(false)
  }

  return (
    <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
      <DialogContent>
        <Typography variant="h4" mb={3}>New smart automation</Typography>
        {isLoading ? (
          <Box display={"flex"} justifyContent={"center"}>
            <Loader />
          </Box>
        ) : (
          <Formik
            initialValues={{ name: "", dataSourceId: "" }}
            validationSchema={smartAutomationSourceValidation}
            onSubmit={handleSubmit}
          >
            <Form>
              <Grid container gap={1}>
                <Grid item minWidth={200} flex={1}>
                  <Field name="dataSourceId">
                    {({ field, meta }: FieldProps) => (
                      <FormControl fullWidth>
                        <InputLabel id="sa-source" error={!!meta.touched && !!meta.error}>Source</InputLabel>
                        <Select {...field} label="Source" fullWidth labelId="sa-source" error={!!meta.touched && !!meta.error}>
                          <MenuItem value="" disabled>Select an option</MenuItem>
                          {dataSources?.filter(el => !(!isLettrLabsUser(profile) && el.displayName === "Lead Reveal") ).map((source) =>
                            <MenuItem
                              value={source.id}
                              key={`source${source.displayName}`}
                              disabled={!source.isEnabled || source.authenticationStatus !== AuthenticatedStatusType.Authenticated}
                            >
                              <Box display="flex" gap={1}>
                                <img alt="" src={source.logoImageUrl || lettrLabsLogo} width={22} height={22} />
                                {source.displayName}
                              </Box>
                            </MenuItem>
                          )}
                        </Select>
                        <FormikErrorMessage meta={meta} />
                      </FormControl>
                    )}
                  </Field>
                </Grid>
                <Grid item minWidth={200} flex={1}>
                  <Field name="name">
                    {({ field, meta }: FieldProps) => (
                      <FormControl fullWidth>
                        <MDInput {...field} fullWidth label="Name" error={!!meta.touched && !!meta.error} />
                        <FormikErrorMessage meta={meta} />
                      </FormControl>
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} mt={2} justifyContent="flex-end">
                <MDButton color="light" onClick={() => setOpen(false)}>Cancel</MDButton>
                <MDButton type="submit" color="primary">Next</MDButton>
              </Stack>
            </Form>
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default NewSmartAutomationModal