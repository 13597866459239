import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const HomeHeartIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 10.5L10 3L2.5 10.5H4.16667V16.3333C4.16667 16.7754 4.34226 17.1993 4.65482 17.5118C4.96738 17.8244 5.39131 18 5.83333 18H10.8333" stroke="#130B46" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.5 18.0007V13.0007C7.5 12.5586 7.6756 12.1347 7.98816 11.8221C8.30072 11.5096 8.72464 11.334 9.16667 11.334H10.8333C11.1583 11.334 11.4617 11.4273 11.7175 11.5873M15.8333 18.4173L17.9317 16.2673C18.1916 15.9972 18.3369 15.6368 18.3369 15.2619C18.3369 14.887 18.1916 14.5266 17.9317 14.2565C17.8052 14.1249 17.6534 14.0201 17.4854 13.9486C17.3174 13.8771 17.1367 13.8402 16.9542 13.8402C16.7716 13.8402 16.5909 13.8771 16.4229 13.9486C16.255 14.0201 16.1032 14.1249 15.9767 14.2565L15.8367 14.3998L15.6967 14.2565C15.5702 14.1249 15.4184 14.0201 15.2504 13.9486C15.0824 13.8771 14.9017 13.8402 14.7192 13.8402C14.5366 13.8402 14.3559 13.8771 14.1879 13.9486C14.02 14.0201 13.8682 14.1249 13.7417 14.2565C13.4819 14.5266 13.3369 14.8868 13.3369 15.2615C13.3369 15.6362 13.4819 15.9964 13.7417 16.2665L15.8333 18.4248V18.4173Z" stroke="#130B46" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export default HomeHeartIcon;
