import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SidebarSubscriptionsIcon = (props: any) => {
    return (
        <SvgIcon {...props} viewBox={"0 0 32 32"}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M3 7.00391C3 5.94304 3.42143 4.92562 4.17157 4.17548C4.92172 3.42533 5.93913 3.00391 7 3.00391H25C26.0609 3.00391 27.0783 3.42533 27.8284 4.17548C28.5786 4.92562 29 5.94304 29 7.00391V20.0039C29 21.0648 28.5786 22.0822 27.8284 22.8323C27.0783 23.5825 26.0609 24.0039 25 24.0039H21V24.3466C21 25.1426 21.316 25.9066 21.8787 26.4679L22.7067 27.2972C22.8463 27.4371 22.9414 27.6152 22.98 27.8091C23.0185 28.0029 22.9987 28.2039 22.923 28.3865C22.8474 28.5691 22.7194 28.7252 22.5551 28.8351C22.3908 28.945 22.1977 29.0037 22 29.0039H10C9.80235 29.0037 9.60918 28.945 9.44489 28.8351C9.2806 28.7252 9.15257 28.5691 9.07695 28.3865C9.00134 28.2039 8.98153 28.0029 9.02005 27.8091C9.05856 27.6152 9.15366 27.4371 9.29333 27.2972L10.1213 26.4679C10.6836 25.9056 10.9997 25.1431 11 24.3479V24.0039H7C5.93913 24.0039 4.92172 23.5825 4.17157 22.8323C3.42143 22.0822 3 21.0648 3 20.0039V7.00391ZM5 7.00391V17.0039C5 17.5343 5.21071 18.043 5.58579 18.4181C5.96086 18.7932 6.46957 19.0039 7 19.0039H25C25.5304 19.0039 26.0391 18.7932 26.4142 18.4181C26.7893 18.043 27 17.5343 27 17.0039V7.00391C27 6.47347 26.7893 5.96477 26.4142 5.58969C26.0391 5.21462 25.5304 5.00391 25 5.00391H7C6.46957 5.00391 5.96086 5.21462 5.58579 5.58969C5.21071 5.96477 5 6.47347 5 7.00391Z" fill="white"/>
            </svg>
        </SvgIcon>
    )
}

export default SidebarSubscriptionsIcon;