export enum OrderArtifactType {
  FrontLogo = "Front_Logo",
  RearImage = "Rear_Image",
  Other1 = "Other_1",
  Other2 = "Other_2",
  Other3 = "Other_3",
  QRCode = "QR_Code",
  TrackingPhoneNumber = "TrackingPhoneNumber",
  ContactUpload = "Contacts",
  Image = "Image",
  CreativeEditorScene = "Scene",
  EnvelopeScene = "EnvelopeScene",
}
