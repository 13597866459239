import { OrderFontDefaults } from "models/enums/OrderDefaults"
import { ProductTypes } from "models/enums/ProductTypes"

export class OrderHelper {
  public static getMaxFontsSizeForReturnAddress = (product: string) => {
    if (product === ProductTypes.HandwrittenPostCardA8) {
      return OrderFontDefaults.MaxPostcardFontSizeReturnAddress
    } else {
      return OrderFontDefaults.MaxHandwrittenFontSizeReturnAddress
    }
  }
  public static getMinFontsSizeForReturnAddress = (product: string) => {
    if (product === ProductTypes.HandwrittenPostCardA8) {
      return OrderFontDefaults.MinPostcardFontSizeReturnAddress
    } else {
      return OrderFontDefaults.MinHandwrittenFontSizeReturnAddress
    }
  }
}

export const getMaxCharacterCountForProduct = (product: string) => {
  switch (product) {
    case ProductTypes.LargeHandwrittenCardA8:
      return 800
    case ProductTypes.HandwrittenBiFoldCard:
      return 1600
    case ProductTypes.HandwrittenPostCardA8:
      return 350
    case ProductTypes.HandwrittenProductInserts:
      return 800
  }
}

export const getIncludedCharacterCountForProduct = (product: string) => {
  switch (product) {
    case ProductTypes.LargeHandwrittenCardA8:
      return 500
    case ProductTypes.HandwrittenBiFoldCard:
      return 1000
    case ProductTypes.HandwrittenPostCardA8:
      return 350
    case ProductTypes.HandwrittenProductInserts:
      return 500
  }
}

export const getExtraCharactersCountForProduct = (product: string) => {
  switch (product) {
    case ProductTypes.LargeHandwrittenCardA8:
    case ProductTypes.HandwrittenBiFoldCard:
      return 50
    case ProductTypes.HandwrittenPostCardA8:
      return 50
    case ProductTypes.HandwrittenProductInserts:
      return 50
  }
}

export const getPriceForAdditionalCharacters = (product: string) => {
  switch (product) {
    case ProductTypes.LargeHandwrittenCardA8:
    case ProductTypes.HandwrittenBiFoldCard:
      return 0.08
    case ProductTypes.HandwrittenPostCardA8:
      return 0.08
    case ProductTypes.HandwrittenProductInserts:
      return 0.08
  }
}

export const addressMailMergeFields = [
  "FIRST NAME",
  "LAST NAME",
  "TO ORGANIZATION",
  "ADDRESS 1",
  "ADDRESS 2",
  'STREET NAME',
  "CITY",
  "STATE",
  "ZIP CODE",
  "SALUTATION",
  "DISCOUNT CODE"
]

export const customMailMergeFields = [
  "CUSTOM 1",
  "CUSTOM 2",
  "CUSTOM 3",
  "CUSTOM 4",
  "CUSTOM 5",
  "CUSTOM 6"
]

export const radiusMailMergeFields = [
  "RADIUS PIN ADDRESS",
  "RADIUS PIN STREET"
]

export const mailMergeFields = [...addressMailMergeFields, ...customMailMergeFields, ...radiusMailMergeFields]