import { Grid } from "@mui/material";
import MDBox from "material-ui/components/MDBox";
import MDTypography from "material-ui/components/MDTypography";
import colors from "material-ui/theme/base/colors";
import CheckIcon from "assets/icons/check";

interface StepIndicatorProps {
  stepNumber: number;
  checked?: boolean;
}

const StepIndicator = ({ checked, stepNumber }: StepIndicatorProps) => {
  return (
    <Grid item>
      <Grid container alignItems="center" justifyContent="center" flexDirection="column">
        <Grid item>
          <MDBox 
            p={1} 
            display="flex" 
            alignItems="center" 
            justifyContent="center"
            sx={{
              backgroundColor: checked ? colors.primary.main : colors.secondary.main,
              borderRadius: "50%",
              width: "30px",
              height: "30px"
            }} 
            >
            {checked ?
              <CheckIcon color="white" />
              :
              <MDTypography sx={{ fontSize: 16 }} fontWeight="bold" color="white">
                {stepNumber}
              </MDTypography>
            }
          </MDBox>
        </Grid>

        <Grid item mt={0.4}>
          <MDTypography variant="h5">Step {stepNumber}</MDTypography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default StepIndicator