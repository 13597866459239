/* eslint-disable prefer-destructuring */
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Button from "@mui/material/Button";
import { styled, Theme } from "@mui/material/styles";

export default styled(Button)(({ theme, ownerState }: { theme?: Theme | any; ownerState: any }) => {
  const { palette, functions, borders } = theme;
  const { color, variant, size, circular, iconOnly, darkMode, active } = ownerState;

  const { white, text, grey} = palette;
  const { pxToRem } = functions;
  const { borderRadius } = borders;

  // styles for the button with variant="primary"
  const primaryStyles = () => {
    // background color value
    let backgroundValue = palette[color] ? palette[color].main : white.main;

    if(variant === "transparent"){
      backgroundValue = "transparent"
    }

    // color value
    let colorValue = white.main;
    if (!darkMode && (color === "white" || color === "light" || !palette[color])) {
      colorValue = text.main;
    } else if (darkMode && (color === "white" || color === "light" || !palette[color])) {
      colorValue = grey[600];
    }

    // color value when the button is hovered
    let hoverColorValue = white.main;

    // background color value when button is hovered
    let hoverBackgroundColorValue = white.main;
    if(color === "primary" || color === "light" || color === "secondary"){
      hoverBackgroundColorValue = palette.primary.focus
    }
    if(color === "white"){
      hoverBackgroundColorValue = palette.primary.main
    }

    return {
      backgroundColor: backgroundValue,
      color: colorValue,

      "&:hover": {
        backgroundColor: hoverBackgroundColorValue,
        color: hoverColorValue,
      },

      "&:disabled": {
        backgroundColor: backgroundValue,
        color: colorValue,
      },
    };
  };

  const circularStyles = () => ({
    borderRadius: borderRadius.section,
  });

  // styles for the button with iconOnly={true}
  const iconOnlyStyles = () => {
    // width, height, minWidth and minHeight values
    let sizeValue = pxToRem(38);

    if (size === "small") {
      sizeValue = pxToRem(25.4);
    } else if (size === "large") {
      sizeValue = pxToRem(52);
    }

    return {
      width: sizeValue,
      minWidth: sizeValue,
      fontSize: "50px",

      "& .material-icons": {
        marginTop: 0,
      },

      "&:hover, &:focus, &:active": {
        transform: "none",
      },
    };
  };

  const transparentStyles = () => {
    let backgroundValue = "transparent"

    let colorValue = palette[color].main;

    // color value when the button is hovered
    let hoverColorValue = white.main;

    // background color value when button is hovered
    let hoverBackgroundColorValue = palette.secondary.main

    return {
      backgroundColor: backgroundValue,
      border: `1px solid ${palette[color].main}`,
      color: colorValue,

      "&:hover": {
        backgroundColor: hoverBackgroundColorValue,
        color: hoverColorValue,
      },

      "&:disabled": {
        backgroundColor: backgroundValue,
        color: colorValue,
      },
    }
  }

  const activeStyles = () => {
    return {
      backgroundColor: `${palette.primary.focus} !important`,
      color: `${white.main} !important`
    }
  }

  return {
    ...(variant === "primary" && primaryStyles()),
    ...(variant === "transparent" && transparentStyles()),
    ...(active && activeStyles()),
    ...(circular && circularStyles()),
    ...(iconOnly && iconOnlyStyles()),
  };
});
