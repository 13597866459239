import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const EditIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2563_8215)">
                    <g clipPath="url(#clip1_2563_8215)">
                        <path
                            d="M10.6583 9.99165L9.97749 13.03C9.94915 13.1666 9.95525 13.3082 9.99522 13.4419C10.0352 13.5756 10.1078 13.6973 10.2065 13.796C10.3052 13.8947 10.4268 13.9673 10.5606 14.0072C10.6943 14.0472 10.8358 14.0533 10.9725 14.025L14.0108 13.3442C14.1648 13.3097 14.3058 13.2323 14.4175 13.1208L21.235 6.30249C21.7134 5.83018 21.9878 5.18919 21.9991 4.51698C22.0105 3.84477 21.758 3.19487 21.2958 2.70665C20.8081 2.2432 20.1578 1.98987 19.485 2.00125C18.8123 2.01263 18.171 2.2878 17.6992 2.76749L10.8825 9.58332C10.7705 9.69535 10.6927 9.837 10.6583 9.99165ZM12.2317 10.5925L18.8783 3.94582C19.0508 3.76914 19.2849 3.66611 19.5317 3.65832C19.7483 3.65442 19.9578 3.73583 20.115 3.88499C20.1902 3.96181 20.2491 4.05314 20.2879 4.15343C20.3267 4.25371 20.3447 4.36085 20.3408 4.46832C20.3328 4.71486 20.2302 4.94884 20.0542 5.12165L13.4075 11.7683L11.8892 12.1092L12.2317 10.5925Z"
                            fill="currentColor"/>
                        <path
                            d="M6.16667 22H14.5C15.6047 21.9987 16.6637 21.5593 17.4448 20.7782C18.2259 19.997 18.6653 18.938 18.6667 17.8333V13.2333C18.6667 13.0123 18.5789 12.8004 18.4226 12.6441C18.2663 12.4878 18.0543 12.4 17.8333 12.4C17.6123 12.4 17.4004 12.4878 17.2441 12.6441C17.0878 12.8004 17 13.0123 17 13.2333V17.8333C17 18.4964 16.7366 19.1323 16.2678 19.6011C15.7989 20.0699 15.163 20.3333 14.5 20.3333H6.16667C5.50362 20.3333 4.86774 20.0699 4.3989 19.6011C3.93006 19.1323 3.66667 18.4964 3.66667 17.8333V8.66667C3.66667 8.00363 3.93006 7.36774 4.3989 6.8989C4.86774 6.43006 5.50362 6.16667 6.16667 6.16667H11.6C11.821 6.16667 12.033 6.07887 12.1893 5.92259C12.3455 5.76631 12.4333 5.55435 12.4333 5.33333C12.4333 5.11232 12.3455 4.90036 12.1893 4.74408C12.033 4.5878 11.821 4.5 11.6 4.5H6.16667C5.062 4.50132 4.00296 4.94073 3.22185 5.72185C2.44073 6.50296 2.00132 7.562 2 8.66667L2 17.8333C2.00132 18.938 2.44073 19.997 3.22185 20.7782C4.00296 21.5593 5.062 21.9987 6.16667 22Z"
                            fill="currentColor"/>
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_2563_8215">
                        <rect width="24" height="24" fill="white"/>
                    </clipPath>
                    <clipPath id="clip1_2563_8215">
                        <rect width="20" height="20" fill="white" transform="translate(2 2)"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    )
}

export default EditIcon;