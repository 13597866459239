import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CreateOrderIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3325_16283)">
                    <path
                        d="M17.2136 12C17.2136 12.1658 17.1477 12.3247 17.0305 12.4419C16.9133 12.5592 16.7544 12.625 16.5886 12.625H12.625V16.5886C12.625 16.7543 12.5592 16.9133 12.4419 17.0305C12.3247 17.1477 12.1658 17.2136 12 17.2136C11.8342 17.2136 11.6753 17.1477 11.5581 17.0305C11.4408 16.9133 11.375 16.7543 11.375 16.5886V12.625H7.41145C7.24569 12.625 7.08671 12.5592 6.9695 12.4419C6.85229 12.3247 6.78645 12.1658 6.78645 12C6.78645 11.8342 6.85229 11.6753 6.9695 11.5581C7.08671 11.4408 7.24569 11.375 7.41145 11.375H11.375V7.41145C11.375 7.24569 11.4408 7.08671 11.5581 6.9695C11.6753 6.85229 11.8342 6.78645 12 6.78645C12.1658 6.78645 12.3247 6.85229 12.4419 6.9695C12.5592 7.08671 12.625 7.24569 12.625 7.41145V11.375H16.5886C16.7544 11.375 16.9133 11.4408 17.0305 11.5581C17.1477 11.6753 17.2136 11.8342 17.2136 12ZM22 5.52273V18.4773C21.9989 19.4112 21.6275 20.3066 20.9671 20.9671C20.3066 21.6275 19.4112 21.9989 18.4773 22H5.52277C4.58881 21.9989 3.69339 21.6275 3.03297 20.9671C2.37256 20.3066 2.00106 19.4112 2 18.4773L2 5.52273C2.00106 4.58877 2.37256 3.69336 3.03297 3.03295C3.69339 2.37254 4.58881 2.00105 5.52277 2H18.4773C19.4112 2.00105 20.3066 2.37254 20.9671 3.03295C21.6275 3.69336 21.9989 4.58877 22 5.52273ZM20.75 5.52273C20.7493 4.92018 20.5097 4.34249 20.0836 3.91642C19.6575 3.49035 19.0798 3.25068 18.4773 3.25H5.52277C4.92021 3.25067 4.34252 3.49033 3.91644 3.91641C3.49036 4.34248 3.25068 4.92017 3.25 5.52273V18.4773C3.25068 19.0798 3.49036 19.6575 3.91644 20.0836C4.34252 20.5097 4.92021 20.7493 5.52277 20.75H18.4773C19.0798 20.7493 19.6575 20.5097 20.0836 20.0836C20.5097 19.6575 20.7493 19.0798 20.75 18.4773V5.52273Z"
                        fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_3325_16283">
                        <rect width="20" height="20" fill="white" transform="translate(2 2)"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    )
}

export default CreateOrderIcon;