/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS Base Styles
import colors from "material-ui/theme/base/colors";

// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from "material-ui/theme/functions/pxToRem";
import { sidebarFullWidth } from "newStandard/src/components/Layout/Sidebar/StyledSidebar";

const { background } = colors;

// types
type Types = any;

const sidenav: Types = {
  styleOverrides: {
    root: {
      position: "fixed",
      top: 0,
      left: 0,
      width: pxToRem(sidebarFullWidth),
      whiteSpace: "nowrap",
      border: "none",
    },

    paper: {
      position: "relative",
      width: pxToRem(sidebarFullWidth),
      backgroundColor: background.sidenav,
      height: `100vh`,
      border: "none",
    },

    paperAnchorDockedLeft: {
      borderRight: "none",
    },
  },
};

export default sidenav;
