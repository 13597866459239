import { Formik } from 'formik';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useGlobal } from 'context/global-context';
import useDataSourcesService from 'services/data-sources';
import { convertKeysToCamelCase } from 'helpers/pascalToCamelCase';
import { ILeadRevealSourcePayload, ISetLeadRevealPayload } from 'services/data-sources/data-sources.types';
import Loader from 'components/loader';
import LeadRevealManageForm from './lead-reveal-manage-form';
import MDButton from 'material-ui/components/MDButton';
import { leadRevealManageSchema } from 'services/data-sources/data-sources.schema';

interface LeadRevealManageModalProps {
  open: boolean;
  onClose: () => void;
}

export default function LeadRevealManageModal({ open, onClose }: LeadRevealManageModalProps) {
  const queryClient = useQueryClient();
  const { setShowLoader } = useGlobal();
  const { getLeadRevealSource, updateLeadRevealSource, revokeDataSource } = useDataSourcesService();

  const { data, isLoading } = useQuery({ queryKey: ['getLeadRevealSource'], queryFn: getLeadRevealSource });
  const source: ILeadRevealSourcePayload = data?.payload?.payload
    ? convertKeysToCamelCase(JSON.parse(data?.payload?.payload))
    : undefined;

  const { mutate: updateLeadReveal } = useMutation({
    mutationFn: updateLeadRevealSource,
    onMutate: () => setShowLoader(true),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAllDataSources'] });
      queryClient.invalidateQueries({ queryKey: ['getLeadRevealSource'] });
      setShowLoader(false);
    },
  });

  const { mutate: revoke } = useMutation({
    mutationFn: () => revokeDataSource('lead-reveal'),
    onMutate: () => {
      setShowLoader(true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAllDataSources'] });
      onClose();
      setShowLoader(false);
    },
  });

  const handleSubmit = (values: ISetLeadRevealPayload) => {
    const payload = JSON.stringify(values);
    updateLeadReveal(payload);
    if (source) onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth={!!source}>
      <DialogContent>
        <DialogTitle display={'flex'} sx={{ mb: 1 }}>
          LeadReveal
          {source?.projectId && (
            <MDButton sx={{ ml: 'auto' }} color="error" variant="transparent" onClick={() => revoke()}>
              Revoke Setup
            </MDButton>
          )}
        </DialogTitle>
        {isLoading ? (
          <Loader />
        ) : (
          <Formik
            initialValues={source}
            validationSchema={leadRevealManageSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {(formProps) => <LeadRevealManageForm onClose={onClose} {...formProps} />}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
}
