import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT from "../endpoint";

function ProductService(){
    const {execute} = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/products`

    function getProductPricing(){
        return execute({
            endpoint: `${API_PATH}/pricing`,
            method: "GET"
        })
    }

    return {getProductPricing}
}

export default ProductService