import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const HomeAlt2Icon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1_5483_81496" fill="white">
                    <path d="M10.59 2.40861C10.5125 2.3305 10.4204 2.26851 10.3188 2.2262C10.2173 2.18389 10.1083 2.16211 9.99832 2.16211C9.88831 2.16211 9.77939 2.18389 9.67784 2.2262C9.57629 2.26851 9.48412 2.3305 9.40665 2.40861L1.90665 9.90861C1.82855 9.98608 1.76655 10.0782 1.72425 10.1798C1.68194 10.2813 1.66016 10.3903 1.66016 10.5003C1.66016 10.6103 1.68194 10.7192 1.72425 10.8208C1.76655 10.9223 1.82855 11.0145 1.90665 11.0919C1.98452 11.1692 2.07687 11.2303 2.1784 11.2718C2.27993 11.3132 2.38865 11.3342 2.49832 11.3336H3.33165V17.1669C3.33165 17.609 3.50725 18.0329 3.81981 18.3455C4.13237 18.658 4.55629 18.8336 4.99832 18.8336H14.9983C15.4403 18.8336 15.8643 18.658 16.1768 18.3455C16.4894 18.0329 16.665 17.609 16.665 17.1669V11.3336H17.4983C17.7193 11.3336 17.9313 11.2458 18.0876 11.0895C18.2439 10.9333 18.3317 10.7213 18.3317 10.5003C18.3323 10.3906 18.3113 10.2819 18.2698 10.1804C18.2283 10.0788 18.1672 9.98648 18.09 9.90861L10.59 2.40861ZM4.99832 17.1669V9.17528L9.99832 4.17527L14.9983 9.17528V17.1669H4.99832Z"/>
                </mask>
                <path d="M10.59 2.40861C10.5125 2.3305 10.4204 2.26851 10.3188 2.2262C10.2173 2.18389 10.1083 2.16211 9.99832 2.16211C9.88831 2.16211 9.77939 2.18389 9.67784 2.2262C9.57629 2.26851 9.48412 2.3305 9.40665 2.40861L1.90665 9.90861C1.82855 9.98608 1.76655 10.0782 1.72425 10.1798C1.68194 10.2813 1.66016 10.3903 1.66016 10.5003C1.66016 10.6103 1.68194 10.7192 1.72425 10.8208C1.76655 10.9223 1.82855 11.0145 1.90665 11.0919C1.98452 11.1692 2.07687 11.2303 2.1784 11.2718C2.27993 11.3132 2.38865 11.3342 2.49832 11.3336H3.33165V17.1669C3.33165 17.609 3.50725 18.0329 3.81981 18.3455C4.13237 18.658 4.55629 18.8336 4.99832 18.8336H14.9983C15.4403 18.8336 15.8643 18.658 16.1768 18.3455C16.4894 18.0329 16.665 17.609 16.665 17.1669V11.3336H17.4983C17.7193 11.3336 17.9313 11.2458 18.0876 11.0895C18.2439 10.9333 18.3317 10.7213 18.3317 10.5003C18.3323 10.3906 18.3113 10.2819 18.2698 10.1804C18.2283 10.0788 18.1672 9.98648 18.09 9.90861L10.59 2.40861ZM4.99832 17.1669V9.17528L9.99832 4.17527L14.9983 9.17528V17.1669H4.99832Z" fill="#130B46" stroke="white" strokeWidth="0.1" mask="url(#path-1-inside-1_5483_81496)"/>
            </svg>

        </SvgIcon>
    )
}

export default HomeAlt2Icon;