import { useState } from 'react';
import { Box, IconButton, Paper, SxProps, Theme } from '@mui/material';
import { ContentCopyRounded, LibraryAddCheckRounded } from '@mui/icons-material';

interface TerminalCodeBlockProps {
  code: string;
  sx?: SxProps<Theme>
}

const TerminalCodeBlock = ({ code, sx }: TerminalCodeBlockProps) => {
  const [copied, setCopied] = useState(false);
  const CopyIcon = copied ? LibraryAddCheckRounded : ContentCopyRounded;

  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(code);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Paper variant="outlined" sx={sx}>
      <Box position={'relative'} p={1}>
        <pre style={{ whiteSpace: 'pre-wrap', fontSize: 12 }}>
          <code>{code}</code>
        </pre>
        <Box position={'absolute'} top={0} right={0}>
          <IconButton onClick={handleCopy}>
            <CopyIcon />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
};

export default TerminalCodeBlock;
