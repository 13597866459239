import { ElementType, useState } from "react";
import { Field, FieldProps, Form, Formik, FormikValues } from "formik";
import { Alert, Dialog, DialogContent, Grid, InputAdornment, InputBaseComponentProps, Stack, TextField, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Icon from "@mui/material/Icon";
import { PaymentValidationCallTracking } from "models/modelValidations/payment";
import MDButton from "material-ui/components/MDButton";
import TextMaskCardNumber from "components/text-mask-card-number";
import TextMaskExpDate from "components/text-mask-exp-date";
import FormikErrorMessage from "components/formik-error-message";
import PhoneNumberService from "services/phone-number";
import { useGlobal } from "context/global-context";

interface CheckoutModalProps {
  show: boolean
  setShow: Function
  checkout: Function
}

const defaultValues = { cardholderName: "", cardNumber: "", expirationDate: "", cvv: "", address: "", city: "", zip: "" }

const CheckoutModal = ({ show, setShow, checkout }: CheckoutModalProps) => {
  const queryClient = useQueryClient()
  const { setShowLoader } = useGlobal()
  const { postPaymentMethod } = PhoneNumberService()
  const [showErrorAlert, setShowErrorAlert] = useState<string>("")

  const handlePostSuccess = (data: { [field: string]: string }) => {
    if (data.errors || data.warnings) {
      if (data.errors) setShowErrorAlert(data.errors[0])
      else if (data.warnings) setShowErrorAlert(data.warnings[0])
    } else {
      queryClient.invalidateQueries({ queryKey: ["callTrackingPaymentMethod"] })
      checkout()
    }
    setShowLoader(false)
  }

  const { mutate: createPaymentMethod } = useMutation({
    mutationFn: (req: { [field: string]: string }) => postPaymentMethod(req),
    onSuccess: handlePostSuccess
  })

  const handleSubmit = (values: FormikValues) => {
    setShowLoader(true)
    const req: { [field: string]: string } = { ...values }
    const spl = req.expirationDate.split('/')
    req.expirationMonth = spl[0];
    req.expirationYear = `20${spl[1]}`;
    createPaymentMethod(req)
  }

  return (
    <Dialog open={show} onClose={() => setShow(false)}>
      <DialogContent sx={{ maxWidth: 484 }}>
        <Typography variant={"h5"} mb={3}>Card Details</Typography>

        <Formik
          initialValues={defaultValues}
          validationSchema={PaymentValidationCallTracking}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ isValid }) => (
            <Form>
              <Field name={"cardNumber"}>
                {({ field, meta }: FieldProps) => (
                  <>
                    <TextField
                      sx={{ width: "100%" }}
                      label="Card Number"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        inputComponent: TextMaskCardNumber as ElementType<InputBaseComponentProps>,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon>payment</Icon>
                          </InputAdornment>
                        ),
                      }}
                      {...field}
                    />
                    <FormikErrorMessage meta={meta} />
                  </>
                )}
              </Field>

              <Grid container gap={1} mt={3}>
                <Grid item flex={1}>
                  <Field name={"expirationDate"}>
                    {({ field, meta }: FieldProps) => (
                      <>
                        <TextField
                          sx={{ width: "100%" }}
                          label="Expiration Date"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            inputComponent: TextMaskExpDate as ElementType<InputBaseComponentProps>,
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon>calendar_month</Icon>
                              </InputAdornment>
                            ),
                          }}
                          {...field}
                        />
                        <FormikErrorMessage meta={meta} />
                      </>
                    )}
                  </Field>
                </Grid>

                <Grid item flex={1}>
                  <Field name={"cvv"}>
                    {({ field, meta }: FieldProps) => (
                      <>
                        <TextField
                          sx={{ width: "100%" }}
                          type="text"
                          label="CVV"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 4 }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon>lock</Icon>
                              </InputAdornment>
                            ),
                          }}
                          {...field}
                        />
                        <FormikErrorMessage meta={meta} />
                      </>
                    )}
                  </Field>
                </Grid>
              </Grid>

              <Grid container mt={3}>
                <Field name={"cardholderName"}>
                  {({ field, meta }: FieldProps) => (
                    <>
                      <TextField
                        sx={{ width: "100%" }}
                        type="text"
                        label="Cardholder Name"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon>person</Icon>
                            </InputAdornment>
                          ),
                        }}
                        {...field}
                      />
                      <FormikErrorMessage meta={meta} />
                    </>
                  )}
                </Field>
              </Grid>

              <Grid container mt={3}>
                <Field name={"address"}>
                  {({ field, meta }: FieldProps) => (
                    <>
                      <TextField
                        sx={{ width: "100%" }}
                        type="text"
                        label="Address"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon>home</Icon>
                            </InputAdornment>
                          ),
                        }}
                        {...field}
                      />
                      <FormikErrorMessage meta={meta} />
                    </>
                  )}
                </Field>
              </Grid>

              <Grid container mt={3} gap={1}>
                <Grid item flex={1}>
                  <Field name={"city"}>
                    {({ field, meta }: FieldProps) => (
                      <>
                        <TextField
                          sx={{ width: "100%" }}
                          type="text"
                          label="City"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon>location_city</Icon>
                              </InputAdornment>
                            ),
                          }}
                          {...field}
                        />
                        <FormikErrorMessage meta={meta} />
                      </>
                    )}
                  </Field>
                </Grid>

                <Grid item flex={1}>
                  <Field name={"zip"}>
                    {({ field, meta }: FieldProps) => (
                      <>
                        <TextField
                          sx={{ width: "100%" }}
                          type="text"
                          label="Zip"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon>numbers</Icon>
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{
                            maxLength: 5
                          }}
                          {...field}
                        />
                        <FormikErrorMessage meta={meta} />
                      </>
                    )}
                  </Field>
                </Grid>
              </Grid>

              {showErrorAlert && (
                <Alert variant="outlined" severity="error" sx={{ mt: 2 }} onClose={() => setShowErrorAlert("")}>
                  <Typography variant="h5">
                    {showErrorAlert}
                  </Typography>
                </Alert>
              )}

              <Stack
                p={1}
                direction="row"
                spacing={2}
                marginTop={2}
                justifyContent="flex-end"
              >
                <MDButton
                  color="light"
                  onClick={() => setShow(false)}
                >
                  Cancel
                </MDButton>
                <MDButton
                  color="primary"
                  type={"submit"}
                  disabled={!isValid}
                >
                  Checkout
                </MDButton>
              </Stack>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default CheckoutModal