import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const InfoIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.375 11.375L11.4092 11.3583C11.516 11.3049 11.6359 11.2833 11.7547 11.296C11.8735 11.3086 11.9862 11.3551 12.0794 11.4298C12.1726 11.5045 12.2424 11.6043 12.2806 11.7175C12.3189 11.8307 12.3238 11.9524 12.295 12.0683L11.705 14.4317C11.676 14.5476 11.6808 14.6695 11.7189 14.7828C11.757 14.8961 11.8269 14.9961 11.9201 15.071C12.0134 15.1458 12.1261 15.1923 12.245 15.205C12.3639 15.2177 12.4839 15.196 12.5908 15.1425L12.625 15.125M19.5 12C19.5 12.9849 19.306 13.9602 18.9291 14.8701C18.5522 15.7801 17.9997 16.6069 17.3033 17.3033C16.6069 17.9997 15.7801 18.5522 14.8701 18.9291C13.9602 19.306 12.9849 19.5 12 19.5C11.0151 19.5 10.0398 19.306 9.12987 18.9291C8.21993 18.5522 7.39314 17.9997 6.6967 17.3033C6.00026 16.6069 5.44781 15.7801 5.0709 14.8701C4.69399 13.9602 4.5 12.9849 4.5 12C4.5 10.0109 5.29018 8.10322 6.6967 6.6967C8.10322 5.29018 10.0109 4.5 12 4.5C13.9891 4.5 15.8968 5.29018 17.3033 6.6967C18.7098 8.10322 19.5 10.0109 19.5 12ZM12 8.875H12.0067V8.88167H12V8.875Z" stroke="#130B46" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export default InfoIcon;