import { Google } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import { Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';

import useSourceInstallRequest from 'hooks/useSourceInstallRequest';
import MDButton from 'material-ui/components/MDButton';
import logo from 'assets/images/logo.svg';

export function LeadRevealSetupModal() {
  const { setInstallSource } = useSourceInstallRequest();
  const [searchParams, setSearchParams] = useSearchParams();
  const open = searchParams.get('modal') === 'SetupLeadReveal';

  return (
    <Dialog open={open} onClose={() => setSearchParams('', { replace: true })} maxWidth="md">
      <DialogContent>
        <DialogTitle>LeadReveal</DialogTitle>
        <Typography fontSize={16} maxWidth={500}>
          Do you want to re-target your anonymous website traffic with direct mail and increase your website
          conversions? LeadReveal is LettrLabs newest solution that allows us to unmask your anonymous website traffic
          using our patent pending unmasking solution. Our solution DOESN'T USE IP addresses and isn't impacted by iOS14
          privacy changes.
        </Typography>
        <Stack spacing={1} mt={2}>
          <Typography variant="h5" fontSize={16}>
            Do you have a Google Tag Manager Account?
          </Typography>
          <MDButton onClick={() => setInstallSource('lead-reveal')}>
            <Google sx={{ mr: 1 }} />
            Yes, use my account
          </MDButton>
          <MDButton color="secondary" onClick={() => setSearchParams('modal=LeadReveal')}>
            <img alt="" src={logo} height={24} style={{ marginRight: 8 }} />
            No, LettrLabs help me!
          </MDButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
