// @ts-ignore
import Retool from "react-retool"
import MDBox from "material-ui/components/MDBox";

function AdminIntegrationOrdersPage(){
    return <MDBox style={{ height: "90vh" }}>
        <Retool url="https://lettrlabs.retool.com/embedded/public/7dc28019-6fe5-46c5-a0d6-2f095ae009b3" />
    </MDBox>
}

export default AdminIntegrationOrdersPage