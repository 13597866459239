// TODO: Delete this list once imgly is fixed
export const ignoreList = [
	8598, 9787, 9829, 9830, 9824, 9827, 9792, 9794, 9788, 9834, 9835, 8597, 8252, 8617, 8618, 20549, 20550, 20551,
	20552, 20553, 20554, 20555, 20556, 20480, 20481, 20522, 20523, 20524, 20498, 20499, 20500, 20525, 20526, 20527,
	20482, 20483, 20501, 20502, 20503, 20484, 20485, 20504, 20505, 20506, 20528, 20529, 20530, 20486, 20487, 20507,
	20508, 20509, 20531, 20532, 20533, 20488, 20489, 20510, 20511, 20512, 20534, 20535, 20536, 20537, 20538, 20490,
	20491, 20513, 20514, 20515, 20492, 20493, 20540, 20541, 20542, 20494, 20495, 20516, 20517, 20518, 20543, 20544,
	20496, 20497, 20519, 20520, 20521, 20546, 20547, 20548, 20539, 20545, 24576, 24577, 24596, 24610, 24611, 24622,
	24578, 24579, 24579, 24598, 24599, 24580, 24581, 24614, 24615, 24624, 24625, 24642, 24643, 24582, 24583, 24600,
	24601, 24626, 24627, 24644, 24584, 24585, 24602, 24603, 24628, 24629, 24586, 24587, 24616, 24617, 24630, 24588,
	24589, 24632, 24633, 24646, 24647, 24590, 24591, 24604, 24605, 24634, 24635, 24592, 24593, 24606, 24607, 24618,
	24619, 24636, 24367, 24648, 24649, 24594, 24595, 24608, 24609, 24620, 24621, 24650, 24651, 24597, 24612, 24631,
	24613, 24640, 24645, 24637, 24623, 24641, 24638, 24639, 8596, 8601, 24652, 24653, 24654, 24655, 24656, 24657, 24658,
	24659, 24652, 8599
];

// Those are the missing or emoji like characters:
// g, n, o, p, s, t, v, w, A, B, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, -
// q and Q don't exist in the font
// None of the numbers have a valid option

// Tests to ensure that every font is working
// Amount of times to type in the editor -> What to type
// 10x -> abcde fg mhijkl no rqp st us vw xzy
// 5x -> ABCDEFGHIJKLMNOPQRSTUVWXYZ
// 10x -> 1234567890
// 8x -> ! ' " , . # $ % & ( ) * - / : ; ? @ 
// 8x -> ffggllmmnnofonooorosotoupprrssthttvewawh
