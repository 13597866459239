import { GridColDef } from "@mui/x-data-grid-pro";
import { formatDate } from "helpers/formatters";
import { OrderType } from "models/enums/OrderType";
import { IVM_GridOrder } from "models/views/gridOrderVm";

export const getColumns = (order: IVM_GridOrder) => {
  const columns: GridColDef[] = [
      {
          field: 'firstName',
          headerName: 'First Name',
          width: 150
      },
      {
          field: 'lastName',
          headerName: 'Last Name',
          width: 150
      },
      {
          field: 'toOrganization',
          headerName: 'To Organization',
          width: 150
      },
      {
          field: 'address1',
          headerName: 'Address 1',
          width: 200
      },
      {
          field: 'address2',
          headerName: 'Address 2',
          width: 200
      },
      {
          field: 'city',
          headerName: 'City',
          width: 150
      },
      {
          field: 'state',
          headerName: 'State',
          width: 150
      },
      {
          field: 'zipCode',
          headerName: 'Zip Code',
          width: 120
      },
      {
          headerName: "Custom 1",
          field: "custom1",
          minWidth: 200
      },
      {
          headerName: "Custom 2",
          field: "custom2",
          minWidth: 200
      },
      {
          headerName: "Custom 3",
          field: "custom3",
          minWidth: 200
      },
      {
          headerName: "Custom 4",
          field: "custom4",
          minWidth: 200
      },
      {
          headerName: "Custom 5",
          field: "custom5",
          minWidth: 200
      },
      {
          headerName: "Custom 6",
          field: "custom6",
          minWidth: 200
      }
  ];

  if (order.orderType === OrderType.Integration) {
      columns.push({
          field: 'updatedDate',
          headerName: 'Submitted Date',
          valueFormatter: (params) => {
              return formatDate(params.value)
          }
      });

      columns.push({
          field: 'mailedDate',
          headerName: 'Mailed Date',
          valueFormatter: (params) => {
              return formatDate(params.value)
          }
      });
  }

  columns.push(
      {
          field: 'qrScanCount',
          headerName: 'QR Scans',
          minWidth: 100
      },
      {
          field: 'lastQrScanDate',
          headerName: 'Last QR Scan',
          minWidth: 150,
          valueFormatter: (params) => {
              return formatDate(params.value)
          }
      },
      {
          field: 'status',
          headerName: 'Status',
          minWidth: 200
      },
      {
        field: 'coupon',
        headerName: 'Coupon',
        minWidth: 200
      },
      {
        field: 'productionStatus',
        headerName: 'Production Status',
        minWidth: 200
      }
  );

  return columns;
}