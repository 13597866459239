export const caseAssetPath = (path, caseId = "post-greeting-cards") => {
  return `${
    window.location.protocol + "//" + window.location.host
  }/cases/${caseId}${path}`
}

export const lettrLabsCaseAssetPath = (id, editorType) => { 
  var random = Math.floor(Math.random() * 1000000000000000)
  return `${
    window.location.protocol + "//" + window.location.host
  }/api/scene/${id}/${editorType}/?${random}`
}
