import {PremadeDesign} from "models/premadeDesign";
import {ITemplate} from "models/template";
import React, {useEffect, useState} from "react";
import {DialogContent, FormControlLabel, Grid, Radio, RadioGroup, Stack} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import MDBox from "material-ui/components/MDBox";
import MDButton from "material-ui/components/MDButton";
import RadioSelectWrapper from "components/radio-select-wrapper";
import MDTypography from "material-ui/components/MDTypography";
import {products} from "helpers/products";
import {ProductTypes} from "models/enums/ProductTypes";
import MDInput from "material-ui/components/MDInput";
import {IProduct} from "models/product";
// @ts-ignore
import MDCard from "material-ui/components/MDCard";

interface NewOrderStep3ModalProps {
    show: boolean
    setShow: Function
    selectedPremadeDesign: PremadeDesign
    selectedTemplate: ITemplate
    onBack: Function
    onNext: Function
    productType: IProduct
    setProductType: Function
    orderName: string
    setOrderName: Function
    onClose: Function
}

function NewOrderStep3Modal({show, selectedPremadeDesign, selectedTemplate, onNext, onBack, productType, setProductType, orderName, setOrderName, onClose}: NewOrderStep3ModalProps) {
    const [showProducts, setShowProducts] = useState<boolean>(false)

    // Only show products if we're creating an order from a pre-made design
    useEffect(function () {
        if(show){
            if(selectedPremadeDesign){
                setShowProducts(true)
            }
        }
        else{
            setShowProducts(false)
        }
    }, [show, selectedPremadeDesign])

    return <Dialog open={show} onClose={() => onClose()} maxWidth={false}>
        <DialogContent sx={{width: selectedTemplate || selectedPremadeDesign ? "1100px" : "600px"}}>
            <Grid container>
                <Grid item xs={selectedTemplate || selectedPremadeDesign ? 7 : 12}>
                    {showProducts?
                        <MDBox mb={3}>
                            <Grid container mb={3} pl={6} pr={1}>
                                <Grid item xs={6}>
                                    <MDTypography variant={"h4"} fontWeight={"bold"}>
                                        Product
                                    </MDTypography>
                                </Grid>

                                <Grid item xs={2}>
                                    <MDTypography variant={"h4"}>
                                        Size
                                    </MDTypography>
                                </Grid>

                                <Grid item xs={4}>
                                    <MDTypography variant={"h4"}>
                                        Length
                                    </MDTypography>
                                </Grid>
                            </Grid>

                            <RadioGroup
                                aria-labelledby="product-selection-group"
                                defaultValue={ProductTypes.PrintedPostcard4x6}
                            >
                                <Grid container gap={"10px"}>
                                    {products.map((product, index) => {
                                        if(product.name === ProductTypes.PrintedPostcard4x6 || product.name === ProductTypes.PrintedPostcard6x9 || product.name === ProductTypes.PrintedPostcard6x11){
                                            return null
                                        }

                                        return <Grid key={index} item width={"100%"}>
                                            <RadioSelectWrapper selected={productType?.id === product.id}>
                                                <MDBox>
                                                    <FormControlLabel
                                                        checked={productType?.id === product.id}
                                                        value={product.label}
                                                        control={<Radio color={"primary"}/>}
                                                        sx={{margin: 0, display: "flex"}}
                                                        disableTypography={true}
                                                        label={
                                                            <Grid container alignItems={"center"}>
                                                                <Grid item xs={6}>
                                                                    <MDTypography variant={"h5"} fontWeight={"bold"}>
                                                                        {product.label}
                                                                    </MDTypography>
                                                                </Grid>

                                                                <Grid item xs={2}>
                                                                    <MDTypography variant={"h5"} fontWeight={"normal"}>
                                                                        {product.bullet1}
                                                                    </MDTypography>
                                                                </Grid>

                                                                <Grid item xs={4}>
                                                                    <MDTypography variant={"h5"} fontWeight={"normal"}>
                                                                        {product.bullet2_short || product.bullet2}
                                                                    </MDTypography>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                        onClick={() => setProductType(product)}
                                                    />
                                                </MDBox>
                                            </RadioSelectWrapper>
                                        </Grid>
                                    })}
                                </Grid>
                            </RadioGroup>
                        </MDBox>
                        :
                        null
                    }

                    <MDBox>
                        <MDInput
                            value={orderName}
                            onChange={(e: { target: { value: any } }) => {
                                setOrderName(e.target.value)
                            }}
                            label={"Order Name"}
                            fullWidth={true}
                            helperText={"Please name your order, this will help you identify it later"}
                        />
                    </MDBox>
                </Grid>

                {selectedTemplate || selectedPremadeDesign ?
                    <Grid item xs={5} pl={3}>
                        {selectedTemplate?
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <MDCard borderRadiusSize={0} sx={{display: "flex", alignItems: "center", justifyContent: "center"}} border={true} borderColor={"dark"} borderSize={2}>
                                        <img alt={""} style={{width: "100%", height: "100%"}} src={selectedTemplate.sceneFrontThumb}/>
                                    </MDCard>
                                </Grid>

                                <Grid item xs={6}>
                                    <MDCard borderRadiusSize={0} sx={{display: "flex", alignItems: "center", justifyContent: "center"}} border={true} borderColor={"dark"} borderSize={2}>
                                        <img alt={""} style={{width: "100%", height: "100%"}} src={selectedTemplate.sceneBackThumb}/>
                                    </MDCard>
                                </Grid>
                            </Grid>
                            :
                            null
                        }

                        {selectedPremadeDesign?
                            <MDCard color={"white"} borderRadiusSize={"lg"}>
                                <img alt={""} src={selectedPremadeDesign.thumbUrl}
                                     style={{width: "100%", height: "auto", borderRadius: "10px"}}/>
                            </MDCard>
                            :
                            null
                        }
                    </Grid>
                    :
                    null
                }
            </Grid>

            <Stack
                direction="row"
                spacing={1}
                marginTop={2}
                justifyContent="flex-end"
            >
                <MDButton
                    color={"light"}
                    circular={true}
                    onClick={() => onBack()}
                >
                    Back
                </MDButton>

                <MDButton
                    onClick={() => onNext()}
                    circular={true}
                    disabled={!orderName || (showProducts && !productType)}
                >
                    Create
                </MDButton>
            </Stack>
        </DialogContent>
    </Dialog>
}

export default NewOrderStep3Modal