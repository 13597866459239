import { ReactNode, createContext, useContext, useState } from 'react';

interface MoversContextProps {
  disabled?: boolean;
  targetsCalculated?: boolean;
  setTargetsCalculated?: (targetsCalculated?: boolean) => void;
  mapTouched?: boolean;
  setMapTouched?: (mapTouched?: boolean) => void;
  pollingZips?: boolean;
  setPollingZips?: (pollingZips?: boolean) => void;
}

const MoversContext = createContext<MoversContextProps>({});
MoversContext.displayName = 'MoversContext';

export const useMoversContext = () => {
  const context = useContext(MoversContext);
  if (context) return context;
  throw new Error('useMoversContext must be used within a MoversProvider');
};

interface MoversContextProviderProps {
  disabled?: boolean;
  children: ReactNode;
}

const MoversContextProvider = ({ children, disabled }: MoversContextProviderProps) => {
  const [targetsCalculated, setTargetsCalculated] = useState<boolean>(false);
  const [mapTouched, setMapTouched] = useState<boolean>(false);
  const [pollingZips, setPollingZips] = useState<boolean>(false);

  return (
    <MoversContext.Provider
      value={{
        disabled,
        targetsCalculated,
        setTargetsCalculated,
        mapTouched,
        setMapTouched,
        pollingZips,
        setPollingZips,
      }}
    >
      {children}
    </MoversContext.Provider>
  );
};

export default MoversContextProvider;
